import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import styles from './button.module.scss';
import { IButton } from 'helpers/interfaces';

// label: Button text
// withBackground: takes a hex color string for Button color
// icon: optional icon next to label text inside Button
// size: 'sm' || 'lg' and if no value provided then normal default size
// customClass: extra class if any special handling is needed for this Button, there's custom classes defined in button.module.scss
// disabled: if button is disabled
// onSubmit: function to be called on button click

export default function Button({
  label,
  withBackground,
  icon,
  size,
  customClasses = [],
  disabled,
  customStyles = {},
  noCapitalize = false,
  onSubmit
}: IButton) {
  return (
    <button
      type="button"
      className={combineClassNames(
        ...[
          'no-select',
          styles.ButtonWrapper,
          styles[size],
          ...customClasses.map((c) => styles[c] || c)
        ]
      )}
      style={{
        backgroundColor: withBackground,
        ...customStyles
      }}
      disabled={disabled}
      onClick={onSubmit}
    >
      {icon}
      {label && <div className={noCapitalize ? '' : styles.ButtonWrapper_label}>{label}</div>}
    </button>
  );
}
