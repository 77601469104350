import React from 'react';
import SongObject from 'components/CreateRelease/SongObject';
import SongUploader from 'components/CreateRelease/SongUploader';
import { userContext } from 'components/contexts/auth/auth.context';
import SongPreview from 'components/CreateRelease/SongPreview';
import EditButton from 'components/CreateRelease/EditButton';
import styles from './create.module.scss';
import {
  ICreateSingleFormValues,
  IFormFields,
  ISection
} from 'interfaces/formsComponents.interface';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Form from 'components/Reusable/Form';
import { gateway } from 'helpers/gateway-helper';
import ReleaseHeader from 'components/Landing/headers/release';
import StepsBar from 'components/Reusable/StepsBar';
import colors from 'styles/partials/_colors_exports.module.scss';
import { combineClassNames } from 'helpers/styling-helper';
import YoutubeUploader from 'components/CreateRelease/YoutubeUploader';
import getReleaseDetails, {
  IGetReleaseDetailsResponse
} from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import { convertFormValuesToFields } from 'helpers/form-helper';
import {
  extractSingleFormValuesFromApiData,
  validateFormFields,
  IFormConfigs,
  getFormSectionsAndFields,
  getUpdatedFormFields
} from 'helpers/create-release-helper';
import { toastContext } from 'components/contexts/toast/toast.context';
import { useMutation, useQueryClient } from 'react-query';
import { postSingleUploadEndpoint } from 'helpers/http-helper';
import { loaderContext } from 'components/contexts/loader/loader.context';
import { RELEASE_TYPES } from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import { encodeQueryParams, getEncodedQueryParam, setPageTitle } from 'helpers/url-helper';
import { CONTENT_TYPES, LIBRARY_TYPE } from '@anghami/neogateway/dist/endpoints/getUserLibrary';
import { logAmplitudeEvent, logGeneralErrorEvent } from 'helpers/analytics-helper';
import { SAVE_AND_PROCEED, SAVE_AS_DRAFT, SUBMIT } from 'constants/amplitudeEvents';
import LinkItem from 'components/Reusable/LinkItem';
import ReleaseDateTimePreview from 'components/CreateRelease/ReleaseDateTimePreview';
import { AccountTypeEnums } from 'interfaces/auth.interface';

export default function SingleReleasePage() {
  const { user, userType } = React.useContext(userContext);
  const { openToast, closeToast } = React.useContext(toastContext);
  const { isLoading, setIsLoading } = React.useContext(loaderContext);
  const { t } = useTranslation('common');
  const [formValues, setFormValues] = React.useState<ICreateSingleFormValues>({});
  const [formFields, setFormFields] = React.useState<IFormFields>(null);
  const [formSections, setFormSections] = React.useState<ISection[]>(null);
  const [didCoverArtChange, setDidCoverArtChange] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const navigate = useNavigate();
  const reactQueryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const songidQP = searchParams.get('songid');
  const albumidQP = searchParams.get('albumid');
  const location = useLocation();
  const keyProp = (location?.state as { keyProp: string })?.keyProp;
  const containerRef: React.LegacyRef<HTMLDivElement> = React.useRef();
  const mutation = useMutation(
    (formValues: any) => {
      if (!formValues) return;
      return postSingleUploadEndpoint(formValues);
    },
    {
      retry: 3,
      mutationKey: 'postAlbumUploadEndpoint',
      onError: (err: any) => {
        setIsLoading(false);
        openToast(err?.message || t('Something went wrong'), 'error');
        logAmplitudeEvent(SAVE_AND_PROCEED, {
          step: step,
          status: 'fail',
          error_message: err?.message || err
        });
      },
      onSuccess: (data, variables) => {
        setIsLoading(false);
        if (!data) return;
        if (data.error?.message) {
          openToast(data.error.message || t('Something went wrong'), 'error');
          logAmplitudeEvent(SAVE_AND_PROCEED, {
            step: step,
            status: 'fail',
            error_message: data.error.message,
            error_code: data.error.code
          });
          return;
        }
        if (data.message) {
          openToast(data.message, data.albumid ? 'success' : 'warning');
        }
        if (step === 2) {
          if (variables.saveasdraft) {
            handleSaveAsDraftRedirection();
            return;
          }
          reactQueryClient.refetchQueries({
            queryKey: [user?.anid, CONTENT_TYPES.music, 'library', LIBRARY_TYPE.live],
            exact: false
          });
          logAmplitudeEvent(SUBMIT, {
            content_id: formValues.songid,
            artist_id: user.d_artist.id,
            owner_id: user.ownerid,
            status: 'success'
          });
          navigate('/library');
          return;
        }
        if (data.albumid) {
          setFormValues({ ...formValues, albumid: data.albumid, songid: data.songid });
          setSearchParams(
            { songid: data.songid },
            {
              state: { keyProp: keyProp }
            }
          );
          logAmplitudeEvent(SAVE_AND_PROCEED, {
            step: step,
            status: 'success'
          });
          setStep(step + 1);
        }
      },
      onSettled: () => {
        setIsLoading(false);
      }
    }
  );

  const getRelease = (
    releaseId: string,
    releaseType: RELEASE_TYPES
  ): Promise<IGetReleaseDetailsResponse['data']> => {
    return new Promise((resolve) => {
      setIsLoading(true);
      gateway
        .callEndpoint(getReleaseDetails, {
          release_id: releaseId,
          release_type: releaseType,
          sid: user?.socketsessionid,
          output: 'jsonhp'
        })
        .then(({ data }) => {
          setIsLoading(false);
          if (!data) resolve(null);
          if (data?.error) {
            openToast(data.error.message, 'error');
            // 403 is when I don't own the release, redirect to library
            if (data.error.code === 403) {
              navigate('/library');
            }
            resolve(null);
          }
          resolve(data?.data || null);
        })
        .catch((err) => {
          console.log('🔴 ~ getReleaseDetails error ~ err', err);
          setIsLoading(false);
          logGeneralErrorEvent(err, 'create single, getReleaseDetails error');
          openToast(t('something-went-wrong-try-again'), 'error');
          resolve(null);
        });
    });
  };

  React.useEffect(() => {
    if (containerRef?.current) {
      containerRef.current.scrollIntoView();
    }
    if (step > 2) {
      setStep(2);
      return;
    }
    if (step < 0) {
      setStep(0);
      return;
    }
  }, [step]);

  const fillFormFields = (data, isSong = false) => {
    const queryParamsForm = getEncodedQueryParam(searchParams.get('form'));
    let newFormValues: ICreateSingleFormValues = {
      // Fill user (artist) info
      owner: user?.owner_name,
      artist_name: user?.user,
      // default to international
      languageid: 2,
      ...(queryParamsForm.artists?.length > 0 && {
        artists: queryParamsForm.artists,
        artistsValue: queryParamsForm.artistsValue
      }),
      ...(queryParamsForm.albumToAddToId && {
        albumid: queryParamsForm.albumToAddToId,
        albumtoaddto: queryParamsForm.albumToAddToId,
        albumtoaddtoValue: queryParamsForm.albumToAddToIdValue,
        isaddingtoexistingalbum: true
      })
    };
    if (data) {
      newFormValues = extractSingleFormValuesFromApiData({ ...newFormValues, ...data }, 0);
      setStep(data.stage ? Number(data.stage) - 1 : 0);
    }
    const configs: IFormConfigs = {
      releaseType:
        isSong || queryParamsForm.albumToAddToId ? RELEASE_TYPES.Song : RELEASE_TYPES.Single,
      accountType: userType,
      data: {
        languageId: newFormValues.languageid
      }
    };
    const { formFields, formSections } = getFormSectionsAndFields(configs);
    setFormSections(formSections);
    setFormValues(newFormValues);
    setFormFields(convertFormValuesToFields(newFormValues, formFields));
  };

  const handleSetParentAlbum = (albumid) => {
    // when diselecting the show
    if (!albumid || albumid === '0') {
      searchParams.delete('form');
      searchParams.delete('albumid');
      setSearchParams(searchParams);
      setIsLoading(false);
      return;
    }
    getRelease(albumid, RELEASE_TYPES.Album).then((data) => {
      if (!data) return;
      // song inherits coverart, language, releasedate, albumtype, genre, collab artists from album
      fillFormFields(
        {
          languageid: data.languageid,
          coverkey: data.coverkey || data.coverurl,
          coverurl: data.coverurl,
          releasedate: data.releasedate,
          albumtype: data.albumtype,
          albumid: data.albumid,
          genre: data.genre,
          category: data.category,
          artists: data.artists,
          albumtoaddto: data.albumid,
          albumtoaddtoValue: { value: data.albumid, label: data.albumname },
          isaddingtoexistingalbum: true
        },
        true
      );
      searchParams.set(
        'form',
        encodeQueryParams(
          JSON.stringify({
            albumToAddToId: data.albumid,
            albumToAddToIdValue: { value: data.albumid, label: data.albumname },
            isaddingtoexistingalbum: true
          })
        )
      );
      searchParams.set('albumid', data.albumid);
      setSearchParams(searchParams);
    });
  };

  const handleQueryParams = () => {
    if (!songidQP) {
      if (!albumidQP) {
        fillFormFields(null);
        return;
      }
      setIsLoading(true);
      handleSetParentAlbum(albumidQP);
      return;
    }
    setIsLoading(true);
    getRelease(songidQP, RELEASE_TYPES.Single).then((data) => {
      fillFormFields({ albumid: albumidQP, ...data });
    });
  };

  const resetForm = () => {
    setPageTitle('Upload Single');
    if (!userType) return;
    setStep(0);
    handleQueryParams();
  };

  React.useEffect(() => {
    setPageTitle('Upload Single');
  }, []);

  React.useEffect(resetForm, [keyProp, userType]);

  const postSingleValues = () => {
    const req = {
      ...formValues,
      // if coverart changed, send it, otherwise send null, cause we sometimes set coverkey from coverurl for the preview
      coverurl: null,
      ...(!didCoverArtChange && {
        coverkey: null
      }),
      stage: step + 1
    };
    mutation.mutate(req);
  };

  const validateForm = () => {
    if (!formValues) return false;
    if (step === 0) {
      const { missingFields, isValid, updatedFormFields, lastErrorMessage } =
        validateFormFields(formFields);
      if (!isValid) {
        setFormFields(updatedFormFields);
        if (missingFields.length === 1 && missingFields[0] === 'coverkey') {
          openToast(t('missing coverart'), 'error');
        } else {
          openToast(lastErrorMessage || t('Form validation fields required'), 'error');
        }
      } else {
        closeToast();
      }
      return isValid;
    } else if (step === 1) {
      if (!formValues.audiokey && !formValues.youtubeLink) {
        openToast(t('You need to upload a file to proceed'), 'error');
        return false;
      }
      return true;
    } else {
      return true;
    }
  };

  const onProceedAction = () => {
    setIsLoading(true);
    const isValid = validateForm();
    if (!isValid) {
      setIsLoading(false);
      return;
    }
    postSingleValues();
  };

  const handleSaveAsDraftRedirection = () => {
    logAmplitudeEvent(SAVE_AS_DRAFT, {
      content_id: formValues.songid,
      artist_id: user?.d_artist?.id,
      owner_id: user.ownerid,
      status: 'success'
    });
    openToast(t('Draft saved'), 'warning');
    reactQueryClient.refetchQueries({
      queryKey: [user?.anid, CONTENT_TYPES.music, 'library', LIBRARY_TYPE.draft],
      exact: false
    });
    navigate('/library/drafts');
  };

  const saveAsDraft = () => {
    // final step
    if (step === 2) {
      mutation.mutate({ ...formValues, stage: step + 1, saveasdraft: true });
      return;
    }
    handleSaveAsDraftRedirection();
  };

  const handleFormDataChange = (newFormFields: IFormFields, fieldName: string) => {
    const tempFormFields: IFormFields = getUpdatedFormFields(newFormFields, fieldName);
    if (fieldName === 'coverkey') {
      setDidCoverArtChange(true);
    }
    if (fieldName === 'albumtoaddto') {
      setIsLoading(true);
      handleSetParentAlbum(newFormFields.albumtoaddto.value);
    }
    setFormFields(tempFormFields);
    setFormValues({
      ...formValues,
      [fieldName]: tempFormFields[fieldName].value,
      ...(tempFormFields[fieldName].extraValueParam && {
        [tempFormFields[fieldName].extraValueParam]: tempFormFields[fieldName].extraValue
      })
    });
  };

  const handleSongUploaderChange = (song) =>
    setFormValues({
      ...formValues,
      uploadedSong: song,
      audiokey: song?.key,
      filename: song?.name,
      filesize: song?.size
    });

  return (
    <div
      ref={containerRef}
      className={combineClassNames(styles.main_container, 'f-column f-grow-1')}
    >
      <div className={styles.fixed_header}>
        <ReleaseHeader
          title={t('Create a release')}
          description={t('Single release')}
          actionButtons={
            step === 2
              ? [
                  {
                    label: step === 2 ? t('Submit') : t('Proceed'),
                    withBackground: colors['branding-purple'],
                    size: 'lg',
                    disabled: !formValues || isLoading,
                    onSubmit: onProceedAction
                  },
                  {
                    label: t('Save as draft'),
                    size: 'lg',
                    customClasses: ['purple', 'f-center', styles.save_as_draft_button],
                    onSubmit: saveAsDraft
                    // disabled TODO: to be checked how to do it with the flow
                  }
                ]
              : [
                  {
                    label: t('Proceed'),
                    withBackground: colors['branding-purple'],
                    size: 'lg',
                    disabled:
                      !formValues ||
                      isLoading ||
                      (step === 1 && !formValues.audiokey && !formValues.youtubeLink),
                    onSubmit: onProceedAction
                  }
                ]
          }
        />
        <StepsBar
          steps={[
            { value: t('Fill Info') },
            { value: t('Upload Content') },
            { value: t('Review') }
          ]}
          stepIndex={step}
          stepClicked={setStep}
        />
      </div>
      {formSections && formFields ? (
        step === 0 ? (
          <Form
            id={`single-form-${formValues?.songid || formValues?.albumid}`}
            customClasses={{ container: styles.form_container }}
            sections={formSections}
            formValues={formFields}
            setFormValues={handleFormDataChange}
          />
        ) : step === 1 ? (
          <div className={styles.upload_song_container}>
            <SongObject
              songInfo={{
                uploadedCoverArt: formFields['coverkey']?.extraValue,
                name: formFields['songname']?.value,
                artist: formValues?.artist_name,
                releaseDate: formFields['releasedate']?.value
              }}
              onEdit={() => setStep(0)}
            />
            <h2>{t('Upload Track')}</h2>
            {formValues?.uploadedSong?.src ? (
              <></>
            ) : (
              <div className={styles.youtube_container}>
                <YoutubeUploader
                  value={formValues?.youtubeLink || ''}
                  onUpdate={(link) =>
                    setFormValues({
                      ...formValues,
                      youtubeLink: link,
                      audioonly: !!link,
                      isyoutubeupload: !!link
                    })
                  }
                  customInputStyle={styles.form_input}
                />
              </div>
            )}
            {!formValues?.youtubeLink && (
              <>
                <h3>{t('Upload your files')}</h3>
                <SongUploader song={formValues?.uploadedSong} onChange={handleSongUploaderChange} />
              </>
            )}
          </div>
        ) : (
          formValues && (
            <div className={styles.preview_section}>
              <div>
                <div className="d-flex f-align-center">
                  <h3>{t('Cover')}</h3>
                  <EditButton onClick={() => setStep(0)} customClass={styles.edit_button} />
                </div>
                <div className="d-flex">
                  <img
                    src={formFields['coverkey']?.extraValue?.src}
                    className={styles.coverArt_preview}
                    alt="coverart-preview"
                  />
                  <span className={styles.coverArt_preview_text}>
                    {formFields['coverkey']?.extraValue?.name || formValues?.songname}
                  </span>
                </div>
              </div>
              <div>
                <div className="d-flex f-align-center">
                  <h3>{t('Single Information')}</h3>
                  <EditButton onClick={() => setStep(0)} customClass={styles.edit_button} />
                </div>
                <div className={styles.preview_form}>
                  <div className={styles.preview_item}>
                    <span className="bold">{t('Name')}</span>
                    <span>{formValues.songname}</span>
                  </div>
                  {formValues.songnamear && (
                    <div className={styles.preview_item}>
                      <span className="bold">{t('Arabic Name')}</span>
                      <span>{formValues.songnamear}</span>
                    </div>
                  )}
                  <div className={styles.preview_item}>
                    <span className="bold">{t('Genre')}</span>
                    <span>{formValues.genre || 'N/A'}</span>
                  </div>
                  <div className={styles.preview_item}>
                    <span className="bold">{t('Release Date')}</span>
                    <ReleaseDateTimePreview
                      releaseDate={formValues?.releasedate}
                      releaseTime={formValues?.releasetime}
                    />
                  </div>
                  {formValues?.owner && (
                    <div className={styles.preview_item}>
                      <span className="bold">{t('Owner')}</span>
                      <span>{formValues.owner}</span>
                    </div>
                  )}
                  <div className={styles.preview_item}>
                    <span className="bold">{t('Artist Name')}</span>
                    <span>
                      {userType === AccountTypeEnums.LABEL
                        ? formValues?.artistsValue?.map((a) => a.label).join(', ')
                        : formValues.artist_name}
                    </span>
                  </div>
                  <div className={styles.preview_item}>
                    <span className="bold">{t('ISRC')}</span>
                    <span>{formValues.isrc || 'N/A'}</span>
                  </div>
                  {formValues.upc && (
                    <div className={styles.preview_item}>
                      <span className="bold">{t('UPC')}</span>
                      <span>{formValues.upc}</span>
                    </div>
                  )}
                </div>
                <div className={styles.preview_item}>
                  <span className="bold">{t('Lyrics')}</span>
                  <span>{formValues.lyrics || 'N/A'}</span>
                </div>
              </div>
              <div className="d-flex f-align-center">
                <h3>{t('Track File')}</h3>
                <EditButton onClick={() => setStep(1)} customClass={styles.edit_button} />
              </div>
              {formValues?.isyoutubeupload ? (
                <div className="d-flex f-align-center gap-1">
                  <span className="bold">{t('Youtube link')}</span>
                  <LinkItem
                    link={formValues.youtubeLink}
                    text={formValues.youtubeLink}
                    newTab={true}
                  />
                </div>
              ) : (
                <SongPreview song={formValues?.uploadedSong} />
              )}
            </div>
          )
        )
      ) : null}
    </div>
  );
}
