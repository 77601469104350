import { userContext } from 'components/contexts/auth/auth.context';
import React from 'react';

export default function useUserConditions() {
  const { permissions, user } = React.useContext(userContext);
  const [canCreateRelease, setCanCreateRelease] = React.useState(true);
  const [shouldSignAgreement, setShouldSignAgreement] = React.useState(false);

  React.useEffect(() => {
    if (!permissions) return;
    setCanCreateRelease(
      permissions?.upload_album ||
        permissions?.upload_episode ||
        permissions?.upload_show ||
        permissions?.upload_single
    );
    setShouldSignAgreement(Number(user?.d_statusid) === 2 && !permissions?.skip_agreement);
  }, [permissions]);

  return { canCreateRelease, shouldSignAgreement };
}
