/* eslint-disable jsx-a11y/autocomplete-valid */
import React from 'react';
import Button from 'components/Reusable/Button';
import { Modal } from 'antd';
import { gateway } from 'helpers/gateway-helper';
import { useTranslation } from 'react-i18next';
import { USER_LOGIN_FAIL } from 'constants/amplitudeEvents';
import { logAmplitudeEvent } from 'helpers/analytics-helper';
import { userContext } from 'components/contexts/auth/auth.context';
import { getErrorMessage } from 'helpers/api-helper';
import { dashboardAuthenticate } from '@anghami/neogateway/dist/endpoints/authentication';
import { IAuthenticateRes } from 'interfaces/auth.interface';
import { ReactComponent as LoadingIcon } from 'icons/loaders/tail-spin.svg';
import VerificationPending from 'components/Landing/frames/VerificationPending';
import { combineClassNames } from 'helpers/styling-helper';
import { Link, useNavigate } from 'react-router-dom';
import './modalOverride.scss';
import styles from './login.module.scss';
import commonStyles from 'components/Landing/frames/common.module.scss';

const gatewayLogin = async (username = '', password = '') => {
  gateway?.setTokens({
    socketsessionid: ''
  });
  return gateway.callEndpoint(dashboardAuthenticate, {
    u: username,
    p: encodeURIComponent(password),
    appsid: '',
    sid: ''
  });
};

export default function Login() {
  const { t } = useTranslation('common');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const { handleAuthSuccess } = React.useContext(userContext);
  const navigate = useNavigate();

  const login = () => {
    if (!username?.trim().length || !password?.trim().length) return;
    setLoading(true);
    gatewayLogin(username, password)
      .then(({ data }) => {
        setLoading(false);
        const authResponse = data;
        if (authResponse?.verify_email) {
          navigate('/verify-email', { state: { email: username } });
        }
        if (!authResponse || authResponse.error || !authResponse.authenticate) {
          setError(getErrorMessage(authResponse.error, t('unable-to-login-please-try-again')));
          logAmplitudeEvent(USER_LOGIN_FAIL, {
            error: authResponse?.error?.message || authResponse.error || 'not known'
          });
          setPassword('');
          const errorCode = authResponse?.error?.code;
          if (errorCode === 103 && !dialogOpen) {
            setDialogOpen(true);
          }
          return;
        }
        const authObject: IAuthenticateRes =
          authResponse.authenticate._attributes || authResponse.authenticate;
        if (authObject.afc.access_token) {
          gateway.setTokens({
            jwtToken: authObject.afc.access_token
          });
        }
        if (!authObject.afc) {
          setError(t('unable-to-login-please-try-again'));
          logAmplitudeEvent(USER_LOGIN_FAIL, {
            error: 'no afc'
          });
          return;
        }
        handleAuthSuccess(authObject);
      })
      .catch((err: Error) => {
        setLoading(false);
        console.error('🔴 ~ Login error', err);
        setError(t('unable-to-login-please-try-again'));
        logAmplitudeEvent(USER_LOGIN_FAIL, {
          error: err?.message || err || 'not known'
        });
      });
  };

  const handleKeyPress = (event: any) => {
    if (event.keyCode !== 13) return;
    login();
  };
  return (
    <>
      <div className="h-100 w-100 p-2 position-relative f-column f-justify-between f-align-center">
        <div className="w-100 d-flex f-justify-end">
          <Link to={'/claim'}>
            <Button
              label={t('Claim your profile')}
              customClasses={[commonStyles.landingTopButton]}
            ></Button>
          </Link>
        </div>

        <div className="w-100">
          <h1 className={commonStyles.landingHeader} style={{ marginBottom: '2rem' }}>
            {t(`login to your account`)}
          </h1>
          <label className={styles.inputLabel} htmlFor="email">
            {t('Email')}
          </label>
          <input
            type="text"
            name="email"
            className={styles.bigInput}
            placeholder={t('Email')}
            onChange={(e: any) => setUsername(e.target.value)}
            value={username}
            autoComplete="username"
            onKeyUp={handleKeyPress}
          />
          <label className={styles.inputLabel} htmlFor="password">
            {t('Password')}
          </label>
          <input
            name="password"
            type="password"
            className={styles.bigInput}
            placeholder={t('Password')}
            onChange={(e: any) => setPassword(e.target.value)}
            value={password}
            autoComplete="password"
            onKeyUp={handleKeyPress}
          />
          <a
            className={styles.forgetPassSpan}
            href="https://dash.anghami.com/#/core/resetpassword"
            target="_blank"
            rel="noreferrer"
          >
            {t('Forgot password?')}
          </a>
          <span className={styles.errorMessage}>{error}</span>
          <Button
            customClasses={[styles.customButton, 'purpleFilled']}
            onSubmit={login}
            icon={
              loading ? (
                <LoadingIcon color={'white'} style={{ margin: 'unset' }} width={20} height={20} />
              ) : (
                <></>
              )
            }
            label={t('Login')}
          />
        </div>
        <div
          className={combineClassNames(
            'd-flex f-align-center gap-1',
            commonStyles.alternativeFooter
          )}
        >
          <span>{t('new-to-creators')}</span>
          <Link to={'/signup'}>
            <Button label={t('Sign up')} customClasses={[commonStyles.ctaButton]} />
          </Link>
        </div>
      </div>
      <Modal
        centered
        open={dialogOpen}
        onCancel={() => setDialogOpen(false)}
        className={'login-verification-pending'}
        footer={null}
      >
        <VerificationPending isDialog />
      </Modal>
    </>
  );
}
