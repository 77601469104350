import React, { useEffect, useState } from 'react';
import SocialMediaLinks from 'components/Reusable/SocialMediaLinks/socialMediaLinks';
import Button from 'components/Reusable/Button';
import { Link } from 'react-router-dom';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { ReactComponent as MenuIcon } from 'icons/menu.svg';
import { ReactComponent as CloseIcon } from 'icons/close-landing.svg';
import { useTranslation } from 'react-i18next';
import { combineClassNames } from 'helpers/styling-helper';
import styles from './landingHeader.module.scss';
import LanguageSelector from 'components/LanguageSelector';
import { environment } from 'env/environment';

const MAX_WIDTH_MOBILE = 900;
const HEADER_LINKS = {
  claim: '/claim',
  helpCenter: 'https://support.anghami.com/hc/en-us/categories/360000029987--Artist-Connect',
  login: '/login'
};

function LandingHeader() {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(width < 900 ? false : true);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(!(width < MAX_WIDTH_MOBILE));
  }, [width]);

  return (
    <div className={styles.headerContainer}>
      <div className="d-flex f-justify-around f-align-center w-100 ">
        <div className={styles.logoContainer}>
          <Link to="/">
            <div style={{ cursor: 'pointer' }}>
              <img
                className={styles.fullLogo}
                src={`${environment.creators_assets_cdn}/images/anghami_creators_black.png`}
                alt="anghami"
                width={'13rem'}
                height={65}
              />
            </div>
          </Link>
          <div className="d-flex gap-1 f-align-center">
            <Link to={HEADER_LINKS['login']} className={styles.loginMobile}>
              <Button label={t(`Login`, 'Login')} customClasses={[styles.customButton]} />
            </Link>
            <div className={styles.navbarAction} onClick={toggleNavbar}>
              {isOpen ? <CloseIcon /> : <MenuIcon />}{' '}
            </div>
          </div>
        </div>
        {isOpen && (
          <div className={styles.menuDropdown}>
            <Link to={HEADER_LINKS['claim']}>
              <div className={combineClassNames('cursor-pointer', styles.pinkColor)}>{`${t(
                'Claim your profile',
                'Claim your profile'
              )}`}</div>
            </Link>
            <Link target="_blank" to={HEADER_LINKS['helpCenter']}>
              <div className="cursor-pointer">{`${t('help-center', 'Help Center')}`}</div>
            </Link>
            <LanguageSelector hideLabel={true} />
            <SocialMediaLinks />
            <Link to={HEADER_LINKS['login']} className={styles.loginDesktop}>
              <Button label={t(`Login`, 'Login')} customClasses={[styles.customButton]} />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default LandingHeader;
