import React from 'react';
import styles from './progressBar.module.scss';
interface IProgressBarProps {
  progress: number; // number should be between 0 and 100
  backgroundColor?: string;
  progressColor?: string;
}
export default function ProgressBar({
  progress,
  backgroundColor,
  progressColor
}: IProgressBarProps) {
  return (
    <div
      className={styles.progress_background}
      style={backgroundColor && { background: backgroundColor }}
    >
      <div
        className={styles.progress}
        style={
          progressColor
            ? { width: `${progress}%`, background: progressColor }
            : { width: `${progress}%` }
        }
      ></div>
    </div>
  );
}
