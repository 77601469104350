import {
  RELEASE_STATUSES,
  RELEASE_TYPES
} from '@anghami/neogateway/dist/endpoints/getreleasedetails';

export interface IEditReleaseConfigProps {
  hideUploadCoverArt?: boolean;
  hideUploadSong?: boolean;
}

export interface IEditReleaseDialogProps {
  release_id: string;
  release_type: RELEASE_TYPES;
  release_status: RELEASE_STATUSES;
  album_id: string;
  config?: IEditReleaseConfigProps;
  closeDialog?: () => void;
}

export enum STEPS {
  form = 'form',
  coverart = 'coverart',
  songs = 'songs'
}

export enum CHANGES_ERROR_TYPE {
  error = 'error',
  warning = 'warning'
}

export interface IChangeError {
  message: string;
  type: CHANGES_ERROR_TYPE;
}
export interface IChangesError {
  [STEPS.form]: IChangeError;
  [STEPS.coverart]: IChangeError;
  [STEPS.songs]: IChangeError;
}
