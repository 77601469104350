let textArea;

const isIOS = () => {
  return /ipad|iphone/i.exec(navigator.userAgent);
};

const createTextArea = (text) => {
  textArea = document.createElement('textArea');
  textArea.setAttribute('readonly', true);
  textArea.setAttribute('contenteditable', true);
  textArea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
  textArea.value = text;
  document.body.appendChild(textArea);
};

const selectText = () => {
  textArea.focus();
  textArea.select();

  if (isIOS()) {
    const range = document.createRange();
    range.selectNodeContents(textArea);

    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);

    textArea.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.
  }
};

const copyToClipboard = () => {
  document.execCommand('copy');
  document.body.removeChild(textArea);
};

export const copyTextToClipboardWithNavigator = async (text: string) => {
  return new Promise<void>((resolve, reject) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          console.log('Something went wrong', err);
          reject();
        });
    } else {
      reject();
    }
  });
};

export const copyLinkToClipboard = (text) => {
  createTextArea(text);
  selectText();
  copyToClipboard();
};
