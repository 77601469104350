/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import styles from './shareDialog.module.scss';
import * as sharer from 'helpers/share-helper';
import { copyLinkToClipboard } from 'helpers/copy-clipboard-helper';
import { environment } from 'env/environment';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'icons/close.svg';

export enum SHARE_TYPES {
  COPY = 'copy',
  FB = 'facebook',
  TWTR = 'twitter',
  WTSP = 'whatsapp',
  TLGRM = 'telegram',
  SAVE = 'save'
}

interface IShareDialogProps {
  closeDialog?: () => void;
  shareData: {
    image: string;
    text: string;
    link: string;
    fileName?: string;
    hashtag?: string;
  };
  allowedShare: SHARE_TYPES[];
}

export default function ShareDialog({ closeDialog, shareData, allowedShare }: IShareDialogProps) {
  const [copiedState, setCopiedState] = React.useState(false);
  const { t } = useTranslation('common');
  const { image, text, link, fileName, hashtag } = shareData;

  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(link || text)
        .then(() => {
          setCopiedState(true);
          setTimeout(() => {
            setCopiedState(false);
          }, 10000);
        })
        .catch((err) => {
          console.log('Something went wrong', err);
          copyLinkToClipboard(text);
        });
    } else {
      copyLinkToClipboard(link || text);
    }
  };

  const shareOptions: {
    [key in SHARE_TYPES]: { text: string; image: string; func: () => void };
  } = {
    [SHARE_TYPES.COPY]: {
      text: copiedState ? t('Copied') : t('Copy'),
      image: environment.main_images_cdn_url + '/endofyear20/images/copylink.png',
      func: handleCopy
    },
    [SHARE_TYPES.FB]: {
      text: 'Facebook',
      image: environment.main_images_cdn_url + '/endofyear20/images/facebook_icon.png',
      func: () => sharer.shareFB(text, link)
    },
    [SHARE_TYPES.TWTR]: {
      text: 'Twitter',
      image: environment.main_images_cdn_url + '/endofyear20/images/twitter_icon.png',
      func: () => sharer.shareTwitter(text, link, hashtag)
    },
    [SHARE_TYPES.WTSP]: {
      text: 'Whatsapp',
      image: environment.main_images_cdn_url + '/endofyear20/images/whatsapp.png',
      func: () => sharer.shareWhatsapp(link)
    },
    [SHARE_TYPES.TLGRM]: {
      text: 'Telegram',
      image: `${environment.cdn_url}/year-in-music/_next/static/imgs/telegram.png`,
      func: () => sharer.shareTelegram(link)
    },
    [SHARE_TYPES.SAVE]: {
      text: t('Save'),
      image: environment.main_images_cdn_url + '/endofyear20/images/save_icon.png',
      func: () => sharer.saveImage(image, fileName)
    }
  };
  return (
    <div>
      <div className={styles.dialogHeader}>
        <div className={styles.shareTitle}>{t('Share') + ' ' + text}</div>
        <div className={styles.close} onClick={closeDialog}>
          <CloseIcon />
        </div>
      </div>
      <div className={styles.dialogContent}>
        <img alt="preview" src={image} className={styles.previewImage} />
        <div className={styles.sharingPlatforms}>
          {allowedShare.map((sharetype) => {
            const so = shareOptions[sharetype];
            return (
              <div key={sharetype} className={styles.socialIcon}>
                <img alt={so.text} src={so.image} onClick={so.func} />
                <p>{so.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
