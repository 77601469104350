export const formatCurrency = (number: number, currency = 'USD') =>
  new Intl.NumberFormat('en', {
    compactDisplay: 'long',
    currency: currency,
    currencyDisplay: 'symbol',
    style: 'currency'
  }).format(number);

export const formatPercentage = (number: number) =>
  new Intl.NumberFormat('en', {
    compactDisplay: 'long',
    style: 'percent'
  }).format(number);

export const formatDecimal = (number: number) =>
  new Intl.NumberFormat('en', {
    compactDisplay: 'long',
    style: 'decimal'
  }).format(number);

export const shortenNumbers = (number: number) =>
  new Intl.NumberFormat('en', {
    notation: 'compact',
    compactDisplay: 'short'
  }).format(number);

export const shortenNumberPrecise = (num: number, digits = 2) => {
  const lookup = [
    { value: 1, symbol: '' },
    // K	Thousand (Kilo)	10^3	1000
    { value: 1e3, symbol: 'K' },
    // M	Million	10^6
    { value: 1e6, symbol: 'M' },
    // B	Billion	10^9
    { value: 1e9, symbol: 'B' },
    // t	Trillion	10^12
    { value: 1e12, symbol: 't' },
    // q	Quadrillion	10^15
    { value: 1e15, symbol: 'q' },
    // Q	Quintillion	10^18
    { value: 1e18, symbol: 'Q' },
    // s	Sextillion	10^21
    { value: 1e21, symbol: 's' },
    // S	Septillion	10^24
    { value: 1e24, symbol: 'S' },
    // o	Octillion	10^27
    { value: 1e27, symbol: 'o' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};
