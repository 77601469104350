import React from 'react';
import { setLanguageCookies } from 'helpers/cookies-helper';
import { useTranslation } from 'react-i18next';
import { combineClassNames } from 'helpers/styling-helper';
import styles from './languageSelector.module.scss';

export default function LanguageSelector({ hideLabel }: { hideLabel?: boolean }) {
  const { i18n, t } = useTranslation();
  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setLanguageCookies(lang);
    window.location.reload();
  };
  return (
    <div className={combineClassNames('d-flex gap-1 f-align-center', styles.languageContainer)}>
      {!hideLabel && <span>{t('Language')} :</span>}
      <select
        className={styles.languageSelector}
        value={i18n.language}
        onChange={(event) => changeLanguage(event.target.value)}
      >
        <option value="en">English</option>
        <option value="ar">عربي</option>
      </select>
    </div>
  );
}
