import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import Button from 'components/Reusable/Button';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './library.module.scss';
import './override.styles.scss';
import CircularDropDown from 'components/Reusable/CircularDropDown';
import { gateway } from 'helpers/gateway-helper';
import getUserLibrarySections from '@anghami/neogateway/dist/endpoints/getUserLibrarySections';
import { useQuery } from 'react-query';
import { toastContext } from 'components/contexts/toast/toast.context';
import { IOption } from 'interfaces/formsComponents.interface';
import LibraryTabsWrapper from 'components/Library/LibraryTabsHOC/libraryTabsHOC';
import { loaderContext } from 'components/contexts/loader/loader.context';
import { IAlbumInfo, IArtistInfo, libaryContext } from 'components/Library/Context/library.context';
import { dialogsContext } from 'components/contexts/dialogs/toast/dialogs.context';
import CreateReleaseDialog from 'components/Dialogs/CreateReleaseDialog';
import { userContext } from 'components/contexts/auth/auth.context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CONTENT_TYPES } from '@anghami/neogateway/dist/endpoints/getUserLibrary';
import ArtistLibraryHeader from 'components/Library/ArtistLibraryHeader';
import { setPageTitle } from 'helpers/url-helper';
import {
  CLICKED_ON_CREATE_RELEASE,
  SELECT_CONTENT_TYPE,
  VIEW_CONTRACT_SIGNING
} from 'constants/amplitudeEvents';
import { logAmplitudeEvent } from 'helpers/analytics-helper';
import { AccountTypeEnums } from 'interfaces/auth.interface';
import SignContractDialog from 'components/Dialogs/SignContractDialog';
import useUserConditions from 'hooks/useUserConditions';

type ICTOption = Omit<IOption, 'value'> & { value: CONTENT_TYPES };

const ContentTypes: { [key: string]: ICTOption } = {
  music: {
    label: 'Music',
    value: CONTENT_TYPES.music
  },
  podcast: {
    label: 'Podcast',
    value: CONTENT_TYPES.podcast
  }
};

export default function Library({ isDraft }) {
  const { t } = useTranslation('common');
  const [selectedTab, setSelectedTab] = React.useState<ICTOption>(null);
  const { showToast } = React.useContext(toastContext);
  const { setIsLoading } = React.useContext(loaderContext);
  const { showDialog } = React.useContext(dialogsContext);
  const [selectedAlbum, setSelectedAlbum] = React.useState<IAlbumInfo>(null);
  const [selectedArtist, setSelectedArtist] = React.useState<IArtistInfo>(null);
  const { user, userType } = useContext(userContext);
  const { shouldSignAgreement, canCreateRelease } = useUserConditions();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { isLoading, data, isError } = useQuery(
    [
      user?.anid,
      'librarySections',
      isDraft ? 'draft' : 'main',
      selectedArtist?.artist_id || 'allArtists'
    ],
    async () => {
      const res = await gateway.callEndpoint(getUserLibrarySections, {
        output: 'jsonhp',
        ...(selectedArtist?.artist_id && { artist_id: selectedArtist.artist_id })
      });
      if (res?.data?.data) {
        return res.data.data.map((item) => ContentTypes[item]) || [];
      }
      if (res?.data?.error) {
        showToast({
          title: res.data.error.message || t('Something went wrong'),
          type: 'error'
        });
        return null;
      }
    },
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  );

  React.useEffect(() => {
    if (!data) return;
    if (
      searchParams.get('type')?.length > 0 &&
      Object.keys(ContentTypes).includes(searchParams.get('type'))
    ) {
      setSelectedTab(ContentTypes[searchParams.get('type')]);
    } else {
      setSelectedTab(data[0] || ContentTypes[CONTENT_TYPES.music]);
    }
  }, [data]);

  React.useEffect(() => {
    if (!selectedTab || selectedTab.value === searchParams.get('type')) return;
    // if there's an artist_id then don't reset searchParams
    if (searchParams.get('artist_id')) {
      if (selectedTab.value) {
        searchParams.set('type', selectedTab.value);
      }
      setSearchParams(searchParams);
      return;
    }
    setSearchParams(selectedTab.value ? { type: selectedTab.value } : {});
  }, [selectedTab]);

  React.useEffect(() => {
    setPageTitle('Library');
    if (searchParams.get('album_id')?.length > 0) {
      handleSelectingAlbum({
        album_id: searchParams.get('album_id'),
        album_name: searchParams.get('album_name'),
        is_podcast: Boolean(Number(searchParams.get('is_podcast')))
      });
      return;
    }
    handleSelectingAlbum(null);
    if (searchParams.get('artist_id')?.length > 0) {
      handleSelectingArtist({
        artist_id: searchParams.get('artist_id'),
        artist_name: searchParams.get('artist_name'),
        cover_art_id: searchParams.get('cover_art_id')
      });
      return;
    }
    handleSelectingArtist(null);
  }, [searchParams]);

  React.useEffect(() => {
    // prevent label from accesing empty library when no artist is selected
    if (!userType) return;
    if (userType === AccountTypeEnums.LABEL && !(searchParams.get('artist_id')?.length > 0)) {
      navigate('/library/roster');
    }
  }, [userType]);

  React.useEffect(() => {
    setIsLoading(isLoading);
    return () => {
      setIsLoading(false);
    };
  }, [isLoading]);

  const setSelectedTabWrapper = (tab: IOption) => {
    logAmplitudeEvent(SELECT_CONTENT_TYPE, {
      library_type: isDraft ? 'draft' : 'live',
      content_type: tab.label
    });
    setSelectedTab(tab as ICTOption);
  };

  const showCreateReleaseDialog = () => {
    logAmplitudeEvent(CLICKED_ON_CREATE_RELEASE, {
      source: 'library'
    });
    showDialog({
      dialog: <CreateReleaseDialog />,
      backdrop: true
    });
  };

  const showSignContractDialog = () => {
    logAmplitudeEvent(VIEW_CONTRACT_SIGNING, {});
    showDialog({
      dialog: <SignContractDialog onSuccessFn={() => showCreateReleaseDialog()} />,
      backdrop: true
    });
  };

  const handleCreateAction = () => {
    if (shouldSignAgreement) {
      showSignContractDialog();
      return;
    }
    showCreateReleaseDialog();
  };

  const handleSelectingAlbum = (album: IAlbumInfo) => {
    setSelectedAlbum(album);
    const type = searchParams.get('type');
    setSearchParams(
      album
        ? {
            ...album,
            is_podcast: album.is_podcast ? '1' : '0',
            ...(type && { type })
          }
        : type
        ? { type }
        : {}
    );
  };

  const handleSelectingArtist = (artist: IArtistInfo) => {
    setSelectedArtist(artist);
    const type = searchParams.get('type');
    setSearchParams(
      artist
        ? { ...artist, type: searchParams.get('type') }
        : type
        ? { type: searchParams.get('type') }
        : {}
    );
  };

  return (
    <libaryContext.Provider
      value={{ selectedAlbum, setSelectedAlbum: handleSelectingAlbum, selectedArtist }}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div>
            {selectedArtist?.artist_id ? (
              <ArtistLibraryHeader {...selectedArtist} isDraft={isDraft} />
            ) : (
              <h1>{t('Your Library')}</h1>
            )}
            {!isError && data?.length > 0 && (
              <div className={styles.dropdownContainer}>
                <CircularDropDown
                  options={data || []}
                  value={selectedTab}
                  onChange={setSelectedTabWrapper}
                />
              </div>
            )}
          </div>
          {canCreateRelease && (
            <Button
              label={t('Create a release')}
              icon={<PlusOutlined />}
              customClasses={[styles.create_button]}
              onSubmit={handleCreateAction}
            />
          )}
        </div>
        {selectedTab && (
          <LibraryTabsWrapper
            contentType={selectedTab?.value}
            albumInfo={selectedAlbum}
            artistInfo={selectedArtist}
            isDraft={isDraft}
          />
        )}
      </div>
    </libaryContext.Provider>
  );
}
