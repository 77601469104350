import React from 'react';
import styles from './errorPage.module.scss';
import { combineClassNames } from 'helpers/styling-helper';
import Button from 'components/Reusable/Button';
export default function ErrorPage() {
  const reload = () => {
    window.location.reload();
  };
  return (
    <div className={combineClassNames('f-column w-100 text-center', styles.errorPage)}>
      <div className={styles.browser}>
        <div className={styles.controls}>
          <i></i>
          <i></i>
          <i></i>
        </div>

        <div className={styles.eye}></div>
        <div className={styles.eye}></div>
        <div className={styles.mouth}>
          <div className={styles.lips}></div>
          <div className={styles.lips}></div>
          <div className={styles.lips}></div>
          <div className={styles.lips}></div>
          <div className={styles.lips}></div>
          <div className={styles.lips}></div>
          <div className={styles.lips}></div>
          <div className={styles.lips}></div>
        </div>
      </div>
      <div className="f-column f-center p-2 gap-025">
        <h1 className="mb-1">
          <b>Oops!</b>
        </h1>
        <h2>Looks like something went wrong, it&apos;s not your fault</h2>
        <h2>We&apos;re looking to see what happened</h2>
        <Button
          label="Please try again."
          onSubmit={reload}
          customClasses={[styles.tryAgain]}
        ></Button>
      </div>
    </div>
  );
}
