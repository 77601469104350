import React from 'react';
import { IReportResponse } from '@anghami/neogateway/dist/endpoints/getOwnersReports';
import { columnCellsWidth, DISPLAY_DATE_FORMAT, keysMap } from '../constants';
import { TablesTypes, freeDataTableItem, TableColumnType } from '../interfaces';
import { t } from 'i18next';
import { format } from 'date-fns';

export const getTableData = (
  reponse: IReportResponse,
  tableType: TablesTypes,
  dateKeys: string[],
  extras?: { ownerName?: string; groupOwnerName?: string }
): freeDataTableItem[] => {
  return keysMap
    .map((keyitem) => {
      const valueKey = `${keyitem.key}${tableType ? '_' + tableType : ''}`;
      const customDateValues = {};
      dateKeys.forEach((dk) => {
        customDateValues[dk] = Number(reponse[dk][valueKey])
          ? keyitem.formatFunc(Number(reponse[dk][valueKey]))
          : null;
      });
      const item = {
        ...customDateValues,
        summaryValue: Number(reponse.TOTAL[valueKey])
          ? keyitem.formatFunc(Number(reponse.TOTAL[valueKey]))
          : null,
        label: keyitem
          .label(tableType)
          .replace('%@account', extras.ownerName || 'Account')
          .replace('%@groupname', extras.groupOwnerName || 'Owner'),
        key: valueKey
      };
      return item;
    })
    .filter((item) => !!item.summaryValue);
};

export const getTableColumns = (
  type: TablesTypes,
  customDatesColumnTitles: string[]
): TableColumnType[] => {
  return [
    {
      title: '',
      width: columnCellsWidth.label_cell,
      key: type + '0',
      dataIndex: 'label',
      render: (value: string) => <div>{value}</div>
    },
    ...customDatesColumnTitles.map((dc) => ({
      title: format(new Date(dc), DISPLAY_DATE_FORMAT),
      width: columnCellsWidth.number_cell,
      key: type + '2',
      dataIndex: dc,
      render: (value: string) => <div>{value}</div>
    })),
    {
      title: t('Total'),
      width: columnCellsWidth.number_cell,
      key: type + '3',
      dataIndex: 'summaryValue',
      render: (value: string) => <div>{value}</div>
    }
  ];
};
