import React from 'react';
import { ALLOWED_VIDEOS_FILETYPES } from 'constants/releases.constants';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VideoIcon } from 'icons/video.svg';
import styles from './videoUpload.module.scss';

export default function VideoUploadBox({ onSelectFile }) {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.uploadBox}>
        <input
          type={'file'}
          onChange={onSelectFile}
          accept={ALLOWED_VIDEOS_FILETYPES.join(',')}
        ></input>
        <VideoIcon width={40} height={40} />
        <span>{t('Drag and drop or choose from your desktop')}</span>
      </div>
      <span>{t('upload video info message')}</span>
    </>
  );
}
