import React from 'react';
import RadioButton from 'components/Reusable/RadioButton';
import TextArea from 'components/Reusable/TextArea';
import TextInput from 'components/Reusable/TextInput';
import { getCoverArtImage } from 'helpers/releases-helper';
import { combineClassNames } from 'helpers/styling-helper';
import { useTranslation } from 'react-i18next';
import SocialPlatformItem, { SocialsEnums } from '../SocialPlatformItem/socialPlatformItem';
import styles from '../profileCommon.module.scss';
import Switch from 'antd/lib/switch';
import UploadCoverArt from 'components/CreateRelease/UploadCoverArt';
import { IUploadCoverArtProps, IUploadedFile } from 'interfaces/formsComponents.interface';
import { IProfileFormValues } from '../interfaces';

export default function ProfileEdit({
  profileData,
  onUpdate,
  onProfileImageChange,
  onProfileImageError
}: {
  profileData: IProfileFormValues;
  onUpdate: (fieldName: string, value: any) => void;
  onProfileImageChange: (uploadedImage: IUploadedFile) => void;
  onProfileImageError: (error: string) => void;
}) {
  const { t } = useTranslation('common');
  const uploadProfileConfigs: IUploadCoverArtProps['config'] = {
    notCoverArt: true,
    hideBottomInfo: true,
    changeButtonText: t('Change profile'),
    uploadButtonText: t('Upload Profile picture'),
    title: t('Upload Profile picture'),
    hideStatus: !profileData.uploadedImage?.key,
    maxSize: 1000000 // 1MB
  };
  return (
    <div>
      <div className={styles.section}>
        <h2>{t('Artist Profile pic')}</h2>
        <p>{t('Choose a picture for your artist profile')}</p>
        <div className="d-flex f-align-center gap-1">
          <div>
            {/* show toggle only when fbdata is true (set on getDashboardProfile) */}
            {profileData.fbdata && (
              <div className="d-flex f-align-center gap-05">
                <Switch
                  size="small"
                  checked={profileData?.showoncover === '1'}
                  onChange={(val) => onUpdate('showoncover', val ? '1' : '0')}
                />
                <span>{t('Use picture from Facebook Page as default artist picture')}</span>
              </div>
            )}
            <UploadCoverArt
              value={
                profileData.uploadedImage &&
                (profileData?.coverartid !== 'FB' || profileData.showoncover === '1')
                  ? profileData.uploadedImage
                  : {
                      key: profileData?.coverartid,
                      src: profileData?.coverartid
                        ? profileData?.coverartid === 'FB' || profileData.showoncover === '1'
                          ? profileData.facebookdata.profile_picture
                          : getCoverArtImage(profileData.coverartid)
                        : null
                    }
              }
              onChange={onProfileImageChange}
              onError={onProfileImageError}
              config={uploadProfileConfigs}
            />
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <h2>{t('Artist Info')}</h2>
        <p>{t('Your basic information')}</p>
        <div className="d-flex f-align-center gap-2 mb-1 f-wrap">
          <div className={combineClassNames('f-column', styles.infoItem)}>
            <span className="bold">{t('Arabic Name')}</span>
            <TextInput
              value={profileData.artistnamearabic}
              onChange={(e) => onUpdate('artistnamearabic', e.target.value)}
            />
          </div>
          <div className={combineClassNames('f-column', styles.infoItem)}>
            <span className="bold">{t('English Name')}</span>
            <TextInput
              value={profileData.artistname}
              onChange={(e) => onUpdate('artistname', e.target.value)}
            />
          </div>
        </div>
        <div className="d-flex f-align-center gap-2 mb-1">
          <div className={combineClassNames('f-column', styles.infoItem)}>
            <span className="bold">{t('Music Language')}</span>
            <RadioButton
              value={String(profileData.artistlanguage)}
              items={[
                { label: t('Arabic'), value: '1' },
                { label: t('International'), value: '2' },
                { label: t('Mixed'), value: '0' }
              ]}
              onChange={(v) => onUpdate('artistlanguage', v)}
            />
          </div>
        </div>
        <div className="d-flex f-align-center gap-2 mb-1 f-wrap">
          <div className={combineClassNames('f-column', styles.infoItem)}>
            <span className="bold">{t('Arabic bio')}</span>
            <TextArea
              value={profileData.ar_bio}
              onChange={(e) => onUpdate('ar_bio', e.target.value)}
            />
          </div>
          <div className={combineClassNames('f-column', styles.infoItem)}>
            <span className="bold">{t('English Bio')}</span>
            <TextArea
              value={profileData.en_bio}
              onChange={(e) => onUpdate('en_bio', e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="d-flex f-align-center gap-2 mb-1 f-wrap">
        <div className={styles.section}>
          <h2>{t('Personal Information')}</h2>
          <p>{t('these-information-wont-show-on-your-artist-profile')}</p>
          <div className={combineClassNames('f-column', styles.infoItem)}>
            <span className="bold">{t('Name')}</span>
            <span>{profileData.artistname}</span>
          </div>
          <div className={combineClassNames('f-column', styles.infoItem)}>
            <span className="bold">{t('Email')}</span>
            <span>{profileData.email}</span>
          </div>
          <div className={combineClassNames('f-column', styles.infoItem)}>
            <span className="bold">{t('Phone Number')}</span>
            <TextInput
              value={profileData.phone}
              onChange={(e) => onUpdate('phone', e.target.value)}
            />
          </div>
        </div>
        <div className={styles.section}>
          <h2 className={styles.socialsTitle}>{t('Social Links')}</h2>
          {Object.values(SocialsEnums).map((sp) => {
            return (
              <div key={sp} className="mb-1">
                <SocialPlatformItem
                  type={sp}
                  account={profileData[sp]}
                  isEdit={true}
                  onChange={(value) => onUpdate(sp, value)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
