import React from 'react';
import { NavLink } from 'react-router-dom';
import { logGoToTab } from './sidemenu.helper';
import { IMenuItem } from './sidemenu.interface';
import styles from './sideMenuPopover.module.scss';

const SideMenuPopover = ({ items = [], title }: { title: string; items?: IMenuItem[] }) => (
  <div className={styles.popoverSideMenu}>
    <h3 className="capitalize">{title}</h3>
    <div className="">
      {items.map((item) => (
        <NavLink
          className={({ isActive }) => `${styles.popoverSideMenuItem} ${isActive && styles.active}`}
          key={item.id}
          to={item.link}
          onClick={() => logGoToTab(item.id, item.link)}
          end
        >
          <div className="capitalize">{item.label}</div>
        </NavLink>
      ))}
    </div>
  </div>
);

export default SideMenuPopover;
