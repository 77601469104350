import getManagePodcasts, {
  IGetManagePodcastsRequest as IReq
} from '@anghami/neogateway/dist/endpoints/getManagePodcasts';
import { gateway } from './gateway-helper';

const callManagePodcasts = (params: IReq) => {
  if (!params) return;
  return gateway.callEndpoint(getManagePodcasts, params).then((res) => res?.data || null);
};

// getcategories: get categories used in podcasts creation (albumtype and q)
const getCategories = (albumtype: IReq['albumtype'], q: IReq['q']) =>
  callManagePodcasts({
    option: 'getcategories',
    albumtype: albumtype,
    ...(q && { q })
  });

// addserver: add a new rss feed podcast (server, languageid, artistid, artistname, category, categoryid)
const addServer = (params: {
  server?: IReq['server'];
  languageid?: IReq['languageid'];
  artistid?: IReq['artistid'];
  artistname?: IReq['artistname'];
  category?: IReq['category'];
  categoryid?: IReq['categoryid'];
}) =>
  callManagePodcasts({
    option: 'addserver',
    ...params
  });

// getservers: get rss feed added podcasts (status, offset)
const getServers = (status: 1 | 0, offset: IReq['offset']) =>
  callManagePodcasts({
    option: 'getservers',
    status,
    offset
  });

// activate: activate an rss feed podcast (rssid)
const activate = (rssid: IReq['rssid']) => callManagePodcasts({ option: 'activate', rssid });

// deactivate: deactivate an rss feed podcast (rssid, reason)
const deactivate = (rssid: IReq['rssid'], reason: IReq['reason'] = '') =>
  callManagePodcasts({ option: 'deactivate', rssid, reason });

// updateRSSFeedURL: update rss feed podcast (rssid, newurl, name)
const updateRSSFeedURL = (rssid: IReq['rssid'], newurl: IReq['newurl'], name: IReq['name']) =>
  callManagePodcasts({ option: 'updateRSSFeedURL', rssid, newurl, name });

// fetch: get rss feed podcast by id (rssid)
const fetch = (rssid: IReq['rssid']) => callManagePodcasts({ option: 'fetch', rssid });

// search: search rss feed podcasts (q)
const search = (query: IReq['q']) => callManagePodcasts({ option: 'search', q: query });

// getfiles: (serverid and offset)
const getFiles = (serverid: string, offset: IReq['offset'], status: 1 | 0 = 1, q = '') =>
  callManagePodcasts({ option: 'getfiles', offset, serverid, status, q });

const managePodcasts = {
  getCategories,
  addServer,
  getServers,
  activate,
  deactivate,
  updateRSSFeedURL,
  fetch,
  search,
  getFiles
};

export default managePodcasts;
