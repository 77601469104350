import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as LeftArrow } from 'icons/arrow-left.svg';
import { ReactComponent as FeaturedIcon } from 'icons/featured.svg';
import { ReactComponent as LibraryIcon } from 'icons/library.svg';
import { ReactComponent as ReportsIcon } from 'icons/reports.svg';
import { ReactComponent as PitchIcon } from 'icons/promotion.svg';
import { ReactComponent as ClaimSongIcon } from 'icons/claim-song.svg';
import { ReactComponent as AnalyticsIcon } from 'icons/analytics.svg';
import { userContext } from 'components/contexts/auth/auth.context';
import { AccountTypeEnums } from 'interfaces/auth.interface';
import Popover from 'antd/es/popover';
import styles from './sidemenuNavigation.module.scss';
import { SidemenuContext } from './sidemenu.context';
import { IMenuItem } from './sidemenu.interface';
import { logGoToTab } from './sidemenu.helper';
import SideMenuPopover from './sideMenuPopover';

export default function SidemenuNavigation() {
  const { isMinimized, isMobile, setIsMobileExpanded } = React.useContext(SidemenuContext);
  const { t } = useTranslation();
  const { permissions, userType } = React.useContext(userContext);
  const [expandedMenuItem, setExpandedMenuItem] = React.useState('');
  const location = useLocation();

  const sidemenuItems: IMenuItem[] = [
    {
      label: t('Analytics'),
      id: 'Analytics',
      link: '/analytics',
      submenu: null,
      isHidden: false,
      icon: <AnalyticsIcon />
    },
    {
      label: t('Library'),
      id: 'Library',
      link: null,
      submenu:
        userType !== AccountTypeEnums.LABEL
          ? [
              {
                id: 'Library',
                label: t('Main'),
                link: '/library'
              },
              {
                id: 'Library RSS',
                label: t('RSS Feeds'),
                link: '/library/rss'
              },
              {
                id: 'Drafts',
                label: t('Drafts'),
                link: '/library/drafts'
              },
              {
                id: 'Change Requests',
                label: t('Change Requests'),
                link: '/changerequests'
              }
            ]
          : [
              {
                id: 'Roster',
                label: t('roster'),
                link: '/library/roster'
              },
              {
                id: 'Change Requests',
                label: t('Change Requests'),
                link: '/changerequests'
              }
            ],
      isHidden: false,
      icon: <LibraryIcon />
    },
    {
      label: t('Promotions'),
      id: 'Promotions',
      link: null,
      submenu: [
        {
          id: 'Pitch to playlist',
          label: t('Pitch Playlist'),
          link: '/pitchplaylist',
          isHidden: !permissions?.promo_pitch_playlist
        },
        {
          id: 'Promotions Requests',
          label: t('Requests'),
          link: '/contentpromotion',
          isHidden: !permissions?.promo_create
        }
      ],
      isHidden: !permissions?.promo_pitch_playlist && !permissions?.promo_create,
      icon: <PitchIcon />
    },
    {
      label: t('Featured'),
      id: 'Featured',
      link: '/featured',
      submenu: null,
      isHidden: !permissions?.manage_featured_items,
      icon: <FeaturedIcon />
    },
    {
      label: t('Reports'),
      id: 'Reports',
      link: '/reports',
      submenu: null,
      icon: <ReportsIcon />
    },
    {
      label: t('claim_your_song_tab'),
      id: 'ClaimSong',
      link: '/thumbrace',
      submenu: null,
      isHidden: userType !== AccountTypeEnums.ARTIST,
      icon: <ClaimSongIcon />
    }
  ];
  const isIconsWithoutLabels = isMinimized && !isMobile;
  return (
    <div
      className={combineClassNames(
        `w-100 f-column sidemnu-custom-class`,
        `${isIconsWithoutLabels ? 'f-align-center' : 'f-align-start'}`,
        isMobile && 'px-1 pt-1 gap-1'
      )}
    >
      {sidemenuItems
        .filter((i) => !i.isHidden)
        .map((item) => {
          if (item.submenu?.length > 0) {
            const isExpanded = expandedMenuItem === item.id;
            const isActive = item.submenu.map((item) => item.link).includes(location.pathname);
            return (
              <div key={item.id} className={combineClassNames(styles.section_with_children)}>
                <Popover
                  placement="right"
                  content={<SideMenuPopover title={item.label} items={item.submenu} />}
                  trigger="hover"
                  open={isIconsWithoutLabels ? undefined : false}
                  color="#131415"
                  style={{ borderRadius: 5 }}
                  arrow={false}
                  className="sidemenu-custom-class"
                >
                  <div
                    onClick={() => {
                      setExpandedMenuItem(expandedMenuItem === item.id ? '' : item.id);
                    }}
                    className={combineClassNames(
                      styles.section,
                      'd-flex',
                      'f-align-center',
                      'f-justify-start',
                      styles.icon_wrapper,
                      ((isExpanded && !isIconsWithoutLabels) || isActive) && styles.active,
                      isIconsWithoutLabels && styles.isMinimized,
                      isMobile && styles.isMobile
                    )}
                  >
                    <div
                      className={combineClassNames(
                        'd-flex',
                        'f-align-center',
                        'f-justify-center',
                        styles.icon_wrapper
                      )}
                    >
                      {item.icon}
                      <div className={styles.animationSection} />
                    </div>
                    {!isIconsWithoutLabels && (
                      <>
                        <div className={combineClassNames('capitalize', styles.section_title)}>
                          {item.label}
                        </div>
                        <LeftArrow
                          width={13}
                          height={13}
                          className={combineClassNames(
                            styles.menuitem_arrow,
                            isExpanded && styles.expanded
                          )}
                        />
                      </>
                    )}
                  </div>
                  {!isIconsWithoutLabels && isExpanded && (
                    <ul className={combineClassNames(styles.sub_menu, isMobile && styles.isMobile)}>
                      {item.submenu
                        .filter((item) => !item.isHidden)
                        .map((subMenuItem) => (
                          <li key={subMenuItem.id}>
                            <NavLink
                              onClick={() => {
                                logGoToTab(item.id, item.link);
                                setIsMobileExpanded(false);
                              }}
                              className={({ isActive }) =>
                                `capitalize ${isActive ? styles.active : ''}`
                              }
                              to={subMenuItem.link}
                              end
                            >
                              {t(subMenuItem.label)}
                            </NavLink>
                          </li>
                        ))}
                    </ul>
                  )}
                </Popover>
              </div>
            );
          }
          return (
            <NavLink
              key={item.id}
              onClick={() => {
                logGoToTab(item.id, item.link);
                setExpandedMenuItem('');
                setIsMobileExpanded(false);
              }}
              to={item.link}
              className={({ isActive }) =>
                combineClassNames(
                  styles.section,
                  'd-flex',
                  'f-align-center',
                  'f-justify-center',
                  isActive && styles.active,
                  isIconsWithoutLabels && styles.isMinimized,
                  isMobile && styles.isMobile
                )
              }
            >
              <Popover
                placement="right"
                content={<SideMenuPopover title={item.label} />}
                trigger="hover"
                open={isIconsWithoutLabels ? undefined : false}
                color="#131415"
                style={{ borderRadius: 5, padding: 0 }}
                arrow={false}
                className="sidemenu-custom-class"
              >
                <div
                  className={combineClassNames(
                    'd-flex',
                    'f-align-center',
                    'f-justify-center',
                    styles.icon_wrapper
                  )}
                >
                  {item.icon}
                  <div className={styles.animationSection} />
                </div>
              </Popover>
              {!isIconsWithoutLabels && <div className={styles.section_title}>{item.label}</div>}
            </NavLink>
          );
        })}
    </div>
  );
}
