import getFeaturedItems, {
  IFeaturedItem
} from '@anghami/neogateway/dist/endpoints/getFeaturedItems';
import { userContext } from 'components/contexts/auth/auth.context';
import DropDown from 'components/Reusable/DropDown';
import { gateway } from 'helpers/gateway-helper';
import { IOption } from 'interfaces/formsComponents.interface';
import React from 'react';
import { useQuery } from 'react-query';
import { ReactComponent as SearchIcon } from 'icons/search.svg';
import { FeaturedTypes } from '../Interfaces/featured.interface';
import { useTranslation } from 'react-i18next';
import styles from './searchFeaturedInput.module.scss';

interface ISearchFeaturedInputProps {
  value?: IOption;
  onChange: (value: IFeaturedItem) => void;
  type: FeaturedTypes;
  selectedItemsIds: string[];
  disabled: boolean;
}
export default function SearchFeaturedInput({
  type,
  value,
  onChange,
  selectedItemsIds,
  disabled
}: ISearchFeaturedInputProps) {
  const { user } = React.useContext(userContext);
  const { t } = useTranslation();
  const [options, setOptions] = React.useState<IOption[]>([]);
  const { data } = useQuery(['user-featured-filled'], async () => {
    const { data } = await gateway.callEndpoint(getFeaturedItems, {
      operation: 'load',
      artistid: user?.anid
    });
    return data;
  });

  React.useEffect(() => {
    if (!data) return;
    setOptions(
      data[type]
        ?.filter((i) => selectedItemsIds.indexOf(i.id) === -1)
        .map((item) => ({ value: item.id, label: item.name }))
    );
  }, [data, selectedItemsIds]);

  const handleChange = (option: IOption) => {
    onChange(data[type].find((i) => i.id === option?.value));
  };

  return (
    <DropDown
      value={value}
      onChange={handleChange}
      options={options}
      hideArrow={true}
      placeholder={t('featured search placeholder').replace('%@', t(type))}
      icon={<SearchIcon width={20} height={20} />}
      customInputClass={styles.customDropDownStyles}
      clearOnSelect
      disabled={disabled}
    />
  );
}
