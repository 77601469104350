import React from 'react';
import postBetaUser from '@anghami/neogateway/dist/endpoints/postBetaUser';
import Button from 'components/Reusable/Button';
import { JOIN_BETA } from 'constants/amplitudeEvents';
import { BASE_URL } from 'constants/appConstants';
import { logAmplitudeEvent } from 'helpers/analytics-helper';
import { gateway } from 'helpers/gateway-helper';
import { useTranslation } from 'react-i18next';

export default function ExitBetaButton() {
  const { t } = useTranslation('common');
  const handleOptOutClick = () => {
    logAmplitudeEvent(JOIN_BETA, {
      status: 'out'
    });
    gateway
      .callEndpoint(postBetaUser, {
        action: 'unsubscribe'
      })
      .then(() => {
        window.location.href = BASE_URL;
      });
  };
  return (
    <Button
      label={t('Exit Beta')}
      customClasses={['default', 'sm', 'centered']}
      customStyles={{ minWidth: 'fit-content' }}
      onSubmit={handleOptOutClick}
    />
  );
}
