import React from 'react';
import { ReactComponent as EditSongIcon } from 'icons/edit.svg';
import styles from './editButton.module.scss';
import { combineClassNames } from 'helpers/styling-helper';
import colors from 'styles/partials/_colors_exports.module.scss';

interface IEditButtonProps {
  onClick: () => void;
  customClass?: string;
}

export default function EditButton({ onClick, customClass }: IEditButtonProps) {
  return (
    <div className={combineClassNames(styles.edit_button, customClass)} onClick={onClick}>
      <EditSongIcon fill={colors['dark-10']} />
    </div>
  );
}
