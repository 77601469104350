import React from 'react';
import { IUploadCoverArtProps, UploaderFileTypes } from 'interfaces/formsComponents.interface';
import useFileUploader from 'hooks/useFileUploader';
import UploadCoverArtWithDialog from '../UploadCoverArtWithDialog';
import UploadCoverArtInplace from '../UploadCoverArtInplace';
import { logAmplitudeEvent } from 'helpers/analytics-helper';
import { userContext } from 'components/contexts/auth/auth.context';

export default function UploadCoverArt(props: IUploadCoverArtProps) {
  const { onChange, value, isInplace } = props;
  const { user } = React.useContext(userContext);
  const uploader = useFileUploader({
    fileType: UploaderFileTypes.image,
    maxSize: props.config?.maxSize,
    onSuccess: (uploadedImage) => {
      logAmplitudeEvent('Upload image', {
        artist_id: user.anid,
        owner_id: user.ownerid,
        status: 'success'
      });
      onChange(uploadedImage);
    },
    onError: (err) => {
      console.error('🔴 ~ file: uploadCoverArt.tsx ~ line 49 ~ err', err);
      logAmplitudeEvent('Upload image', {
        artist_id: user.anid,
        owner_id: user.ownerid,
        status: 'fail',
        error_message: err?.message || err,
        error_code: err?.code || 'no code'
      });
      if (props.onError) props.onError(err?.message);
    },
    initialFile: value
  });
  if (isInplace) {
    return <UploadCoverArtInplace {...props} uploader={uploader} />;
  }
  return <UploadCoverArtWithDialog {...props} uploader={uploader} />;
}
