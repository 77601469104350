import React from 'react';
import { ChartTypes, ISectionData } from '@anghami/neogateway/dist/endpoints/getAnalyticsSections';
import {
  ListChart,
  AnalyticsLineChart,
  AnalyticsAreaChart,
  PercentageChart,
  TableChart,
  SummaryChart
} from '../charts';
import { shortenNumberPrecise } from 'helpers/numbers-helper';

export const enhanceListChartData = (section: ISectionData) => {
  if (!section) return null;
  const unit = (section as any).unit;
  const rows = section.data.map((r) => ({
    ...r,
    value: Number(r.value) || 0,
    label: getFormatedNumber(Number(r.value), unit)
  }));
  // if unit is not percentage, change value to be a percentage
  const sumValue = rows.reduce((a, b) => a + b.value, 0);
  const updatedValues = rows.map((row) => ({
    ...row,
    value: (row.value * 100) / sumValue
  }));
  return { ...section, data: updatedValues.sort((a, b) => b.value - a.value) };
};

export const enhancePercentageChart = (section: ISectionData) => {
  if (!section) return null;
  if (!section?.data?.some((item) => item.value > 100)) return section;
  const sumValue = section.data.map((i) => Number(i.value)).reduce((a, b) => a + b);

  return {
    ...section,
    data: section.data
      .map((item) => ({
        ...item,
        value: Math.round((Number(item.value) * 100) / sumValue)
      }))
      .sort((a, b) => b.value - a.value)
  };
};

export const enhanceTableChartData = (section: ISectionData) => {
  if (!section) return null;
  return { ...section, data: section.data.sort((a, b) => Number(b.value) - Number(a.value)) };
};

export const enhanceLineChartData = (section: ISectionData) => {
  if (!section) return null;
  return { ...section, data: section.data.map((d) => ({ ...d, value: Number(d.value) })) };
};

export const getChart = (chartType: ChartTypes, data: ISectionData) => {
  const charts = {
    [ChartTypes.listChart]: <ListChart {...enhanceListChartData(data)} color={'#44E6B0'} />,
    [ChartTypes.lineChart]: <AnalyticsLineChart {...data} />,
    [ChartTypes.areaChart]: <AnalyticsAreaChart {...data} />,
    [ChartTypes.percentageChart]: <PercentageChart {...enhancePercentageChart(data)} />,
    [ChartTypes.table]: <TableChart {...data} />,
    [ChartTypes.summary]: <SummaryChart {...data} />
  };
  return charts[chartType] || null;
};

export const getFormatedNumber = (number: number, unit?: string) => {
  return `${number.toLocaleString('en-US', {
    notation: 'compact',
    compactDisplay: 'short'
  })}${unit && ' ' + unit}`;
};

export const chartTickFormatter = (value: any) =>
  Number(value) > 1000 ? shortenNumberPrecise(Number(value), 2) : value;
