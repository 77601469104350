import Skeleton from 'antd/lib/skeleton';
import { combineClassNames } from 'helpers/styling-helper';
import useGetReleaseDetails from 'hooks/useGetReleaseDetails';
import React from 'react';
import styles from './analyticsReleaseHeader.module.scss';
import { ReactComponent as ArrowIcon } from 'icons/arrow-left.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function AnalyticsReleaseHeader({ releaseId, releaseType }) {
  const { t } = useTranslation('common');
  const { data, isLoading, error } = useGetReleaseDetails({
    release_id: releaseId,
    release_type: releaseType
  });
  const navigate = useNavigate();
  if (isLoading) {
    return (
      <div className="d-flex f-align-center gap-1">
        <Skeleton.Image
          active={true}
          style={{ width: '4rem', height: '4rem', borderRadius: '6px' }}
        />
        <Skeleton.Input active={true} size="large" />
      </div>
    );
  }
  if (!data?.data || data.error || error) {
    return <></>;
  }
  return (
    <div className={combineClassNames('d-flex f-align-center gap-1', styles.container)}>
      <div
        className={combineClassNames('f-center', styles.backButton)}
        onClick={() => navigate(-1)}
      >
        <ArrowIcon width={15} height={15} />
      </div>
      <img src={data.data.coverurl} alt="coverart" />
      <div className="f-column">
        <span className={combineClassNames(styles.releaseType, 'no-select')}>{releaseType}</span>
        <h3>{t('release analytics').replace('%@', data.data.songname || data.data.albumname)}</h3>
      </div>
    </div>
  );
}
