import React from 'react';

export default function AlbumVisual({ className, secondaryclass }) {
  return (
    <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect className={secondaryclass} x="7" width="42" height="42" rx="10" fill="#E5E7EA" />
      <rect className={className} y="7" width="42" height="42" rx="10" fill="#CCD0D3" />
      <path
        d="M30.4846 19.73C30.4856 19.5378 30.4104 19.353 30.2755 19.2162C30.1405 19.0793 29.9568 19.0016 29.7646 19C29.7106 19 29.6566 19.0081 29.6026 19.0161L29.4206 19.057C29.4056 19.057 29.3926 19.048 29.3766 19.048C29.3226 19.048 29.2686 19.056 29.2146 19.064L19.1856 21.294C19.024 21.3289 18.8794 21.4183 18.7759 21.5472C18.6725 21.6762 18.6166 21.8367 18.6176 22.002L18.6316 31.25C18.5906 31.228 18.5536 31.207 18.5106 31.186L18.4696 31.167C17.4705 30.7542 16.3628 30.6839 15.3196 30.967C13.0436 31.587 11.5976 33.628 12.0996 35.527C12.3496 36.2267 12.8077 36.8332 13.4125 37.2649C14.0172 37.6966 14.7396 37.9329 15.4826 37.942C15.5826 37.942 15.6826 37.937 15.7646 37.93H15.7756C16.212 37.9296 16.6464 37.8714 17.0676 37.757C17.9618 37.5806 18.775 37.1197 19.3858 36.4431C19.9966 35.7665 20.3722 34.9106 20.4566 34.0031C20.4696 33.8031 20.4746 33.631 20.4706 33.46V33.085L20.4576 22.912L28.6366 21.095L28.5766 29.733L28.4346 29.657L28.3936 29.639C27.3946 29.2257 26.2868 29.1554 25.2436 29.439C22.9676 30.054 21.5216 32.094 22.0236 33.999C22.2746 34.6988 22.7336 35.305 23.3389 35.7365C23.9443 36.168 24.6672 36.4041 25.4106 36.413C25.5044 36.415 25.5982 36.411 25.6916 36.401H25.7026C26.139 36.4006 26.5734 36.3424 26.9946 36.228C27.8883 36.0516 28.701 35.5909 29.3114 34.9147C29.9218 34.2385 30.2972 33.3831 30.3816 32.476C30.3964 32.2807 30.3997 32.0847 30.3916 31.889L30.4846 20.108V19.732V19.73Z"
        fill="white"
      />
    </svg>
  );
}
