import React from 'react';
import DebugRouter from 'hoc/debugRoutes';
import ErrorBoundary from 'hoc/errorBoundary';
import { Routes, Route } from 'react-router-dom';
import PG from 'components/contexts/auth/PermissionGuard';
import AuthGuard from 'components/contexts/auth/AuthGuard';
import LandingPage from './Landing/LandingPage';
import LandingContainerLoader from 'components/Landing/LandingContainer/landingContainerLoader';
import { BeatLoader } from 'components/Reusable/Loaders';
import HomePage from './Home';
import Library from './Home/library';
import Login from './Landing/Login';
import LandingContainer from 'components/Landing/LandingContainer';
import ClaimProfile from './Landing/ClaimProfile';
import CreateAccount from './Landing/CreateAccount';
import CreateSingleReleasePage from './Home/create/single';
import CreateAlbumReleasePage from './Home/create/album';
import EpisodeReleasePage from './Home/create/episode';
import CreateShowReleasePage from './Home/create/show';
import CreateShowFromRSSPage from './Home/create/RSSPodcast';
import LibraryRoster from './Home/library/roster';
import AnalyticsPage from './Home/analytics';
import ChangeRequestsPage from './Home/changeRequests';
import ProfilePage from './Home/profile';
import FeaturedPage from './Home/featured';
import ReportsPage from './Home/reports';
import PitchPlaylists from './Home/pitchPlaylists';
import ContentPromotion from './Home/contentPromotion';
import RSSFeedsPage from './Home/rssFeeds';
import RSSFeedTable from 'components/RssFeed/RssFeedTables/RssFeedTable';
import RssFeedFilesTable from 'components/RssFeed/RssFeedTables/RssFeedFilesTable';
import ThumbracePage from './Home/thumbrace';
import UnverifiedEmail from 'components/Landing/UnverifiedEmail';
import ValidateEmail from 'components/Landing/ValidateEmail';

export default function AppRoutes() {
  return (
    <ErrorBoundary>
      <DebugRouter>
        <Routes>
          <Route
            element={
              <AuthGuard>
                <React.Suspense fallback={<BeatLoader />}>
                  <HomePage />
                </React.Suspense>
              </AuthGuard>
            }
          >
            <Route path="/library">
              <Route path="drafts" element={<Library isDraft={true} />}></Route>
              <Route path="roster" element={<LibraryRoster />}></Route>
              <Route path="rss" element={<RSSFeedsPage />}>
                <Route path="feed/:feedId" element={<RssFeedFilesTable />}></Route>
                <Route path="" element={<RSSFeedTable />}></Route>
              </Route>
              <Route index element={<Library isDraft={false} />}></Route>
            </Route>
            <Route path="/create">
              <Route
                path="single"
                element={
                  <PG permission="upload_single">
                    <CreateSingleReleasePage />
                  </PG>
                }
              ></Route>
              <Route
                path="album"
                element={
                  <PG permission="upload_album">
                    <CreateAlbumReleasePage />
                  </PG>
                }
              ></Route>
              <Route
                path="episode"
                element={
                  <PG permission="upload_episode">
                    <EpisodeReleasePage />
                  </PG>
                }
              ></Route>
              <Route
                path="show/rss"
                element={
                  <PG permission="upload_show">
                    <CreateShowFromRSSPage />
                  </PG>
                }
              ></Route>
              <Route
                path="show"
                element={
                  <PG permission="upload_show">
                    <CreateShowReleasePage />
                  </PG>
                }
              ></Route>
            </Route>
            <Route path="/podcaster-analytics" element={<AnalyticsPage />} />
            <Route path="/podcaster-analytics/*" element={<AnalyticsPage />} />
            <Route path="/analytics" element={<AnalyticsPage />}></Route>
            <Route path="/analytics/:type/:id" element={<AnalyticsPage />}></Route>
            <Route path="/changerequests/:type" element={<ChangeRequestsPage />}></Route>
            <Route path="/changerequests" element={<ChangeRequestsPage />}></Route>
            <Route
              path="/profile"
              element={
                <PG permission={'manage_artist_profile'}>
                  <ProfilePage />
                </PG>
              }
            ></Route>
            <Route
              path="/featured"
              element={
                <PG permission="manage_featured_items">
                  <FeaturedPage />
                </PG>
              }
            ></Route>
            <Route path="/reports" element={<ReportsPage />}></Route>
            <Route path="/thumbrace" element={<ThumbracePage />}></Route>
            <Route
              path="/pitchplaylist"
              element={
                <PG permission="promo_pitch_playlist">
                  <PitchPlaylists />
                </PG>
              }
            ></Route>
            <Route
              path="/contentpromotion"
              element={
                <PG permission="promo_create">
                  <ContentPromotion />
                </PG>
              }
            ></Route>
          </Route>
          <Route
            element={
              <AuthGuard isLanding={true}>
                <React.Suspense fallback={<LandingContainerLoader />}>
                  <LandingContainer />
                </React.Suspense>
              </AuthGuard>
            }
          >
            <Route path="signup" element={<CreateAccount />} />
            <Route path="claim" element={<ClaimProfile />} />
            <Route path="login" element={<Login />} />
            <Route path="verify-email" element={<UnverifiedEmail />} />
            <Route path="emailvalidation" element={<ValidateEmail />} />
          </Route>
          <Route
            path="*"
            element={
              <AuthGuard isLanding={true}>
                <LandingPage />
              </AuthGuard>
            }
          />
        </Routes>
      </DebugRouter>
    </ErrorBoundary>
  );
}
