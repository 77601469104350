import Toast, { IToastProps } from 'components/Reusable/Toast/toast';
import React from 'react';
import { toastContext } from './toast.context';

interface IProps {
  children?: any;
}

const ToastWrapper = ({ children }: IProps) => {
  const [toast, setToast] = React.useState<IToastProps>();

  const closeToast = () => {
    setToast({ ...toast, isOpen: false, type: 'warning' });
  };

  const showToast = (toast: IToastProps) => {
    setToast({ ...toast, isOpen: true });
  };

  const openToast = (title: string, type: IToastProps['type'], subtitle?: string) => {
    setToast({ isOpen: true, title, type, subtitle });
  };

  return (
    <toastContext.Provider value={{ showToast, openToast, closeToast }}>
      {children}
      <Toast {...toast} onClose={closeToast} />
    </toastContext.Provider>
  );
};

export default ToastWrapper;
