import React from 'react';
import { BeatLoader } from 'components/Reusable/Loaders';
import Sidemenu from 'components/Landing/sidemenu';
import DialogWrapper from 'components/contexts/dialogs/toast';
import LoaderWrapper from 'components/contexts/loader';
import styles from './homeLayout.module.scss';

export default function HomeLayout({ children }) {
  return (
    <>
      <DialogWrapper>
        <div className={styles.mainWrapper}>
          <Sidemenu />
          <div className={styles.page_container}>
            <LoaderWrapper loaderComponent={<BeatLoader />}>{children}</LoaderWrapper>
          </div>
        </div>
      </DialogWrapper>
    </>
  );
}
