import getArtistSongClaimers from '@anghami/neogateway/dist/endpoints/getArtistSongClaimers';
import { toastContext } from 'components/contexts/toast/toast.context';
import { userContext } from 'components/contexts/auth/auth.context';
import { gateway } from 'helpers/gateway-helper';
import React, { useRef, useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import ClaimedSongInfo from '../ClaimedSongInfo/ClaimedSongInfo';
import styles from './ClaimedSongList.module.scss';
import { SpinningLoader } from 'components/Reusable/Loaders';
import NoData from 'components/Reusable/NoData';
import { useTranslation } from 'react-i18next';
import { loaderContext } from 'components/contexts/loader/loader.context';
import { GET_CLAIMED_SONGS } from 'constants/reactQueryKeys';

export default function ClaimedSongsList() {
  const { openToast } = React.useContext(toastContext);
  const { user } = React.useContext(userContext);
  const { setIsLoading } = React.useContext(loaderContext);
  const { t } = useTranslation();

  const [refreshKey, setRefreshKey] = useState(1);

  const songsListRef = useRef<HTMLDivElement>(null);
  let scrollTimeout: ReturnType<typeof setTimeout> = null;

  useEffect(() => {
    setRefreshKey(refreshKey + 1);
    return () => clearInterval(scrollTimeout);
  }, []);

  useEffect(() => {
    if (!songsListRef) return;

    songsListRef.current.addEventListener('scroll', handleScroll);

    return () => {
      if (!songsListRef?.current) return;
      songsListRef.current.removeEventListener('scroll', handleScroll);
    };
  }, [refreshKey]);

  const { data, fetchNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery(
    [GET_CLAIMED_SONGS],
    async ({ pageParam = 1 }) => {
      const { data } = await gateway.callEndpoint(getArtistSongClaimers, {
        output: 'jsonhp',
        page: pageParam
      });
      if (!data || data.error) {
        openToast(data?.error?.message || t('something-went-wrong-try-again'), 'error');
        return [];
      }
      return data.data;
    },
    {
      getNextPageParam: (lastPage, allPages) =>
        !lastPage || lastPage.length === 0 ? undefined : allPages.length + 1,
      keepPreviousData: true,
      enabled: !!user?.anid
    }
  );

  React.useEffect(() => {
    setIsLoading(isLoading);
    return () => {
      setIsLoading(false);
    };
  }, [isLoading]);

  const handleScroll = (_event: any) => {
    const element = _event.target;
    if (
      !isFetchingNextPage &&
      element.scrollTop > 0 &&
      element.offsetHeight + element.scrollTop >= element.scrollHeight - 50
    ) {
      if (scrollTimeout) return;
      scrollTimeout = setTimeout(() => {
        fetchNextPage();
        clearTimeout(scrollTimeout);
        scrollTimeout = null;
      }, 5) as unknown as ReturnType<typeof setTimeout>;
    }
  };

  return (
    <div className="p-2 h-100 position-relative overflow-hidden">
      <div className="d-flex f-align-center f-justify-between">
        <h1>{t('Songs claimed')}</h1>
      </div>
      <div className={styles.center_box} ref={songsListRef}>
        {!isLoading && (
          <>
            <div className={styles.claimedSongsList_wrapper}>
              {data?.pages?.flat()?.length > 0 ? (
                data.pages.flatMap((page) =>
                  page?.flatMap((songData) => (
                    <ClaimedSongInfo
                      songCoverArtId={songData?.song_cover_art_id}
                      key={songData.song_id}
                      songId={songData?.song_id}
                      songName={songData?.song_name}
                      songClaimerUserId={songData?.user_id}
                      songClaimerUserName={songData?.user_name}
                      songClaimerUserImage={songData?.user_profile}
                    />
                  ))
                )
              ) : (
                <NoData customMessage={t('No claimed songs')} />
              )}
            </div>
            <div className={`${styles.infinite_loader} ${isFetchingNextPage ? styles.active : ''}`}>
              <div className={styles.wrapper}>
                <SpinningLoader />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
