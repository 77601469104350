import React from 'react';
import styles from './analytics.module.scss';
// import Button from 'components/Reusable/Button';
// import { ReactComponent as ExportExcelIcon } from 'icons/excel-export.svg';
import AnalyticsSection from 'components/Analytics/AnalyticsSection';
import { combineClassNames } from 'helpers/styling-helper';
import { useQuery } from 'react-query';
import { gateway } from 'helpers/gateway-helper';
import getAnalyticsSections, {
  AnalyticsType
} from '@anghami/neogateway/dist/endpoints/getAnalyticsSections';
import { userContext } from 'components/contexts/auth/auth.context';
import { toastContext } from 'components/contexts/toast/toast.context';
import { useTranslation } from 'react-i18next';
import { loaderContext } from 'components/contexts/loader/loader.context';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { existValueInEnum } from 'helpers/utils-helper';
import PeriodSelector from 'components/Analytics/PeriodSelector';
import {
  getAnalyticsPeriods,
  IPeriodValue
} from 'components/Analytics/constants/analytics.contants';
import { periodContext } from 'components/Analytics/context/selectedPeriod.context';
import AnalyticsReleaseHeader from 'components/Analytics/AnalyticsReleaseHeader';
import { RELEASE_TYPES } from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import AnalyticsArtistHeader from 'components/Analytics/AnalyticsArtistHeader';
import { AccountTypeEnums } from 'interfaces/auth.interface';
import { encodeQueryParams, getEncodedQueryParam, setPageTitle } from 'helpers/url-helper';
import { logAmplitudeEvent } from 'helpers/analytics-helper';
import { OPEN_ANALYTICS } from 'constants/amplitudeEvents';
import { getSimplifiedReleaseTypes } from 'helpers/releases-helper';
import { TimePeriods } from '@anghami/neogateway/dist/endpoints/getAnalyticsData';

const pageTitleByUserType = {
  [AccountTypeEnums.ARTIST]: 'Artist Analytics',
  [AccountTypeEnums.LABEL]: 'Label Analytics',
  [AccountTypeEnums.PODCASTER]: 'Podcaster Analytics',
  [AccountTypeEnums.UNKOWN]: 'Analytics'
};

export default function AnalyticsPage() {
  const { user, userType } = React.useContext(userContext);
  const { openToast } = React.useContext(toastContext);
  const { setIsLoading } = React.useContext(loaderContext);
  const [itemId, setItemId] = React.useState(null);
  const [analyticsType, setAnalyticsType] = React.useState<AnalyticsType>(null);
  const [selectedPeriod, setSelectedPeriod] = React.useState<IPeriodValue>(
    getAnalyticsPeriods()[TimePeriods.LAST_SEVEN_DAYS]
  );
  const { t } = useTranslation('common');
  const { type, id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    setPageTitle('Analytics');
    const periodFromQP = searchParams.get('period');
    if (!periodFromQP) return;
    try {
      const period = getEncodedQueryParam(periodFromQP) as IPeriodValue;
      setSelectedPeriod(period);
    } catch (err) {
      console.log('unable to parse period from query params');
      handlePeriodChange(getAnalyticsPeriods()[TimePeriods.LAST_SEVEN_DAYS]);
    }
  }, []);

  React.useEffect(() => {
    setItemId(id || user?.d_artist?.id || '');
    const typetemp =
      id && existValueInEnum(AnalyticsType, type) ? (type as AnalyticsType) : AnalyticsType.OWN;
    setAnalyticsType(typetemp);
    logAmplitudeEvent(OPEN_ANALYTICS, { content_type: typetemp });
  }, [id, type]);

  const { data, isLoading } = useQuery(['analytics', analyticsType, itemId], async () => {
    if (!analyticsType) return null;
    const { data } = await gateway.callEndpoint(getAnalyticsSections, {
      ...(itemId && { id: itemId }),
      analytics_type: analyticsType,
      output: 'jsonhp'
    });
    if (!data || data.error) {
      openToast(data.error.message || t('something-went-wrong-try-again'), 'error');
      // 403 is when I don't own the release, redirect to library
      if (data.error.code === 403) {
        navigate('/library');
      }
      return null;
    }
    return data.data;
  });

  React.useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  const handlePeriodChange = (val: IPeriodValue) => {
    setSelectedPeriod(val);
    searchParams.set('period', encodeQueryParams(JSON.stringify(val)));
    setSearchParams(searchParams);
  };

  return (
    <periodContext.Provider value={{ selectedPeriod, setSelectedPeriod: handlePeriodChange }}>
      <div className={styles.analyticsContainer}>
        <div
          className={combineClassNames('d-flex f-align-center f-justify-between', styles.header)}
        >
          {type && id ? (
            type === AnalyticsType.ARTIST ? (
              <AnalyticsArtistHeader id={id} />
            ) : (
              <AnalyticsReleaseHeader
                releaseId={id}
                releaseType={getSimplifiedReleaseTypes(type as RELEASE_TYPES)}
              />
            )
          ) : (
            <h1>{t(pageTitleByUserType[userType] || 'Analytics')}</h1>
          )}
          {/* <Button
            label={t('Export to Excel')}
            icon={<ExportExcelIcon />}
            size="md"
            customClasses={[styles.exportButton]}
          /> */}
        </div>
        <div className={styles.periodSelectorContaienr}>
          <PeriodSelector value={selectedPeriod} onChange={handlePeriodChange}></PeriodSelector>
        </div>
        {data?.map((section, index) => (
          <AnalyticsSection
            key={section.title + '-' + index}
            {...section}
            id={itemId}
            analyticsType={analyticsType}
          />
        ))}
      </div>
    </periodContext.Provider>
  );
}
