import { getMainApiUrl } from './platform-helper';
import getTabSearch from '@anghami/neogateway/dist/endpoints/getTabSearch';
import {
  IAlbumSong,
  ICreateAlbumFormValues,
  ICreateSingleFormValues
} from 'interfaces/formsComponents.interface';
import {
  dashboardRegisterUser,
  IRegisterUserRequest
} from '@anghami/neogateway/dist/endpoints/registerUser';
import {
  dashboardAuthenticate,
  IAuthenticateResponse
} from '@anghami/neogateway/dist/endpoints/authentication';
import { gateway } from 'helpers/gateway-helper';
import { IRegistrationForm } from 'components/Landing/landing.interface';
import getOwnProfileData from '@anghami/neogateway/dist/endpoints/getOwnProfileData';
import postDashboardRegisterAttempt from '@anghami/neogateway/dist/endpoints/postDashboardRegisterAttempt';
import postAlbumSong, {
  IPostAlbumSongesponse
} from '@anghami/neogateway/dist/endpoints/postAlbumSong';
import postMsisdnLogin from '@anghami/neogateway/dist/endpoints/postMsisdnLogin';
import validateMsisdnCode from '@anghami/neogateway/dist/endpoints/validateMsisdnCode';
import getArtistAppPlaylistInsights from '@anghami/neogateway/dist/endpoints/getArtistAppPlaylistInsights';
import updateDashboardProfile from '@anghami/neogateway/dist/endpoints/updateDashboardProfile';
import { environment } from 'env/environment';
import postAlbumUpload, {
  IPostAlbumUploadesponse
} from '@anghami/neogateway/dist/endpoints/postAlbumUpload';
import postSongUpload, {
  IPostSongUploadesponse
} from '@anghami/neogateway/dist/endpoints/postSongUpload';
import updateSongInfo, {
  IUpdateSongInfoRequest
} from '@anghami/neogateway/dist/endpoints/updateSongInfo';
import updateAlbumInfo, {
  IUpdateAlbumInfoRequest
} from '@anghami/neogateway/dist/endpoints/updateAlbumInfo';
import postEditSong from '@anghami/neogateway/dist/endpoints/postEditSong';
import replaceSongRequest, {
  REPLACE_REQUEST_ACTIONS
} from '@anghami/neogateway/dist/endpoints/replaceSongRequest';
import postReplaceSong from '@anghami/neogateway/dist/endpoints/postReplaceSong';
import postAutomaticDeleteRelease from '@anghami/neogateway/dist/endpoints/postAutomaticDeleteRelease';
import updateAlbumSongsOrder from '@anghami/neogateway/dist/endpoints/updateAlbumSongsOrder';
import postLogout from '@anghami/neogateway/dist/endpoints/postLogout';
import { clearObjectOfNulls } from 'helpers/utils-helper';
import getArtistProfile from '@anghami/neogateway/dist/endpoints/getArtistProfile';
import getArtistProfileClaimed from '@anghami/neogateway/dist/endpoints/getArtistProfileClaimed';
// import searchEdge from '@anghami/neogateway/dist/endpoints/searchEdge';

export const reAuthenticate = async (sss): Promise<IAuthenticateResponse> => {
  return gateway.callEndpoint(dashboardAuthenticate, {
    appsid: sss,
    reauthenticate: true
  });
};

export const getUserOwnProfileData = async () => {
  return gateway.callEndpoint(getOwnProfileData, {
    output: 'jsonhp'
  });
};

export const searchArtists = async (query) => {
  return gateway.callEndpoint(
    getTabSearch,
    {
      output: 'jsonhp',
      dashboard: true,
      edge: 1,
      query,
      musiclanguage: 1,
      searchtype: 'artist',
      page: 0,
      count: 3,
      source: 'artistsdashboard'
    },
    false,
    getMainApiUrl()
  );
};

export const getArtistProfileEndpoint = async (id: any) => {
  return gateway.callEndpoint(getArtistProfile, { artistId: id }, false, getMainApiUrl());
};

export const getArtistProfileClaimedEndpoint = async (id: string) => {
  return gateway.callEndpoint(getArtistProfileClaimed, { artistid: String(id) }, false);
};

interface IRegistrationData extends IRegistrationForm {
  phoneNumber?: string;
  countrycode?: string;
}

export const postRegisterAttempt = async (registerData: IRegistrationData) => {
  const formatedUser = {
    userType: registerData.usertype,
    language: registerData.musiclanguage,
    name: registerData.artistname,
    firstName: registerData.firstname,
    lastName: registerData.lastname,
    email: registerData.email,
    phoneNumber: registerData.phoneNumber,
    countrycode: registerData.countrycode,
    password: registerData.password,
    confirmpassword: registerData.confirmpassword,
    // msisdn code
    code: registerData.code,
    ...(registerData?.artistId && { artistId: registerData.artistId })
  };
  const registerUserParams = {
    email: registerData.email,
    password: registerData.password,
    user: formatedUser,
    newsignup: true
  };
  return gateway.callEndpoint(postDashboardRegisterAttempt, {
    ...registerUserParams
  });
};

export const verifyMSISDN = async (msisdn) => {
  if (!msisdn || String(msisdn).trim() === '') {
    return;
  }
  return gateway.callEndpoint(
    postMsisdnLogin,
    { msisdn: msisdn, disableCaptcha: true },
    false,
    getMainApiUrl()
  );
};

export const validateMSISDN = async (msisdn, code) => {
  return gateway.callEndpoint(validateMsisdnCode, { msisdn: msisdn, code: code }, false);
};

export const registerUser = async (registrationForm: IRegistrationData) => {
  const formatedUser = {
    userType: registrationForm.usertype,
    language: registrationForm.musiclanguage,
    name: registrationForm.artistname,
    firstName: registrationForm.firstname,
    lastName: registrationForm.lastname,
    email: registrationForm.email,
    phoneNumber: registrationForm.phoneNumber,
    countrycode: registrationForm.countrycode,
    password: registrationForm.password,
    confirmpassword: registrationForm.confirmpassword,
    code: registrationForm.code // msisdn code,
  };
  const registerUserParams: IRegisterUserRequest = {
    email: registrationForm.email,
    password: registrationForm.password,
    user: formatedUser,
    newsignup: true,
    output: 'jsonhp'
  };
  return gateway.callEndpoint(dashboardRegisterUser, registerUserParams);
};

interface IRegisterUserResponse {
  anid: string;
  dauid: string;
  uploadtoken: string;
}

export const updateProfile = async (
  registrationForm: IRegistrationData,
  registerUserResponse: IRegisterUserResponse
) => {
  const params = {
    uid: registerUserResponse.anid,
    dauid: registerUserResponse.dauid,
    uploadtoken: registerUserResponse.uploadtoken,
    name: registrationForm.artistname,
    email: registrationForm.email,
    bydistributor: registrationForm.bydistributor,
    distributor: registrationForm.distributor,
    isexistinglabel: registrationForm.isexistinglabel,
    contactperson: `${registrationForm.firstname} ${registrationForm.lastname}`,
    artistnamear: registrationForm.artistnamear || registrationForm.artistname,
    countrycodenumber: registrationForm.countrycode,
    phone: registrationForm.phone.fullPhone,
    artistlanguage: registrationForm.language,
    typeid: registrationForm.usertype,
    ...(registrationForm?.artistId && { artistid: registrationForm.artistId }),
    newsignup: true
  };
  return gateway.callEndpoint(updateDashboardProfile, { ...params });
};

export const getCountry = (callback, onError) => {
  const fetchWithHeader = async (url) =>
    fetch(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => response.json());
  fetchWithHeader('https://ipinfo.io/json')
    .then((data: { country: string }) => {
      if (data?.country) {
        callback(data.country);
        return;
      }
      throw new Error('error getting country');
    })
    .catch(() => {
      fetchWithHeader('http://ip-api.com/json/?fields=status,country,countryCode')
        .then((data: { country: string }) => {
          if (data?.country) {
            callback(data.country);
            return;
          }
          throw new Error('error getting country second retry');
        })
        .catch(() => onError());
    });
};

export const getPlaylistInsights = async (params) => {
  return gateway.callEndpoint(getArtistAppPlaylistInsights, {
    ...params,
    dashboardaccess: 'yes'
  });
};

export const postAlbumSongEndpoint = (song: IAlbumSong): Promise<IPostAlbumSongesponse> => {
  if (!song) return;
  let releasedate = song.releasedate ? new Date(song.releasedate) : null;
  if (song.releasetime) {
    const time = new Date(song.releasetime);
    releasedate = new Date(
      releasedate.setHours(time.getHours(), time.getMinutes(), time.getSeconds())
    );
  }
  const mainReq: IAlbumSong = {
    bucket: environment.upload_s3_bucket,
    languageid: song.languageid || 2,
    audiokey: song?.audiokey,
    filename: song?.uploadedSong?.name,
    filesize: song?.uploadedSong?.size,
    albumid: song.albumid,
    tracknb: song.tracknb,
    ispodcast: song.ispodcast
  };
  const req = clearObjectOfNulls({
    // only send rest of data when I have a song name or it's a delete, cause sometimes we have a songid but with no information
    ...(song?.songname || song.delete
      ? {
          ...song,
          ...mainReq,
          uploadedCoverArt: null,
          uploadedSong: null,
          genreValue: null,
          albumTypeValue: null,
          albumCoverArt: null,
          ...(song.releasedate ? { releasedate: releasedate.getTime() } : {}),
          artists: song.artists?.map((artist) => ({ id: artist, isnew: false })) || null,
          ...(song.languageid == 1 && { songnamear: song.songnamear })
        }
      : mainReq)
  });
  return gateway
    .callEndpoint(postAlbumSong, { ...(req as any), output: 'jsonhp' })
    .then((res) => res?.data || null);
};

export const postAlbumUploadEndpoint = (
  formValues: ICreateAlbumFormValues,
  isPodcast = false
): Promise<IPostAlbumUploadesponse> => {
  if (!formValues) return;
  let releasedate = formValues.releasedate ? new Date(formValues.releasedate) : null;
  if (formValues.releasetime) {
    const time = new Date(formValues.releasetime);
    releasedate = new Date(
      releasedate.setHours(time.getHours(), time.getMinutes(), time.getSeconds())
    );
  }
  const req = clearObjectOfNulls({
    ...formValues,
    bucket: environment.upload_s3_bucket,
    isalbumupload: 1,
    multilanguage: formValues.languageid == 0,
    ...(releasedate && { releasedate: releasedate.getTime() }),
    artists: formValues.artists?.map((artist) => ({ id: artist, isnew: false })) || null,
    albumnamear: formValues.languageid == 1 ? formValues.albumnamear : null,
    proceed: formValues.proceed || false,
    ...(isPodcast && {
      ispodcast: 1,
      descriptionar: formValues.languageid == 1 ? formValues.descriptionar : null,
      category: formValues.categoryValue
    }),
    albumTypeValue: null,
    genreValue: null,
    categoryValue: null,
    featuringartistidsValue: null,
    artistsValue: null,
    uploadedCoverArt: null
  });
  return gateway
    .callEndpoint(postAlbumUpload, { ...(req as any), output: 'jsonhp' })
    .then((res) => res?.data || null);
};

export const postSingleUploadEndpoint = (
  formValues: ICreateSingleFormValues,
  isPodcast?: boolean
): Promise<IPostSongUploadesponse> => {
  if (!formValues) return;
  let releasedate = formValues.releasedate ? new Date(formValues.releasedate) : null;
  if (formValues.releasetime) {
    const time = new Date(formValues.releasetime);
    releasedate = new Date(
      releasedate.setHours(time.getHours(), time.getMinutes(), time.getSeconds())
    );
  }
  const req = clearObjectOfNulls({
    ...formValues,
    bucket: environment.upload_s3_bucket,
    single: true,
    ...(releasedate && { releasedate: releasedate.getTime() }),
    artists: formValues.artists?.map((artist) => ({ id: artist, isnew: false })),
    songnamear: formValues.languageid == 1 ? formValues.songnamear : null,
    ...(isPodcast && {
      ispodcast: 1,
      descriptionar: formValues.languageid == 1 ? formValues.descriptionar : null,
      category: formValues.categoryValue
    }),
    ...(formValues.delete && { delete: 1 }),
    uploadedSong: null,
    albumTypeValue: null,
    genreValue: null,
    categoryValue: null,
    featuringartistidsValue: null,
    artistsValue: null,
    uploadedCoverArt: null
  });
  return gateway
    .callEndpoint(postSongUpload, { ...(req as any), output: 'jsonhp' })
    .then((res) => res?.data || null);
};

export const updateSongInfoEndpoint = (
  songid: string,
  albumid: string,
  artistid: string,
  formValues: IUpdateSongInfoRequest['data']
) =>
  gateway
    .callEndpoint(updateSongInfo, { songid, albumid, artistid, data: formValues, output: 'jsonhp' })
    .then((res) => res?.data || null);

export const updateAlbumInfoEndpoint = (
  albumid: string,
  artistid: string,
  formValues: IUpdateAlbumInfoRequest['data']
) =>
  gateway
    .callEndpoint(updateAlbumInfo, { albumid, artistid, ...formValues, output: 'jsonhp' })
    .then((res) => res?.data || null);

export const postEditSongEndpoint = (
  songid: string,
  key: string,
  discardOldRequest: boolean,
  songOnly: boolean
) =>
  gateway
    .callEndpoint(postEditSong, {
      songid,
      key,
      bucket: environment.upload_s3_bucket,
      output: 'jsonhp',
      discardexisting: discardOldRequest,
      agree: 1,
      songonly: songOnly ? 1 : 0
    })
    .then((res) => res?.data || null);

export const checkForExistingSongReplaceRequest = (songid: string) =>
  gateway
    .callEndpoint(replaceSongRequest, {
      songid,
      action: REPLACE_REQUEST_ACTIONS.check_for_existing_request,
      output: 'jsonhp'
    })
    .then((res) => res?.data || null);

export const checkForExistingCoverartReplaceRequest = (songid: string) =>
  gateway
    .callEndpoint(postEditSong, {
      songid,
      agree: 1,
      action: REPLACE_REQUEST_ACTIONS.check_for_existing_request,
      output: 'jsonhp'
    })
    .then((res) => res?.data || null);

export const replaceSong = (
  songid: string,
  key: string,
  discardOldRequest: boolean,
  replaceId?: string
) =>
  gateway
    .callEndpoint(postReplaceSong, {
      songid,
      key,
      bucket: environment.upload_s3_bucket,
      agree: 1,
      discardexisting: discardOldRequest,
      output: 'jsonhp',
      ...(replaceId && { replaceid: replaceId })
    })
    .then((res) => res?.data || null);

export const autoDeleteRelease = (releaseId: string, isAlbum = false) =>
  gateway
    .callEndpoint(postAutomaticDeleteRelease, {
      ...(isAlbum ? { albumid: releaseId } : { songid: releaseId }),
      output: 'jsonhp'
    })
    .then((res) => res?.data || null);

export const reorderAlbumSongs = (albumid: string, newOrder: string[]) =>
  gateway
    .callEndpoint(updateAlbumSongsOrder, {
      albumid,
      neworder: newOrder,
      output: 'jsonhp'
    })
    .then((res) => res?.data || null);

export const postLogoutWrapper = () => {
  return gateway.callEndpoint(postLogout, {});
};
