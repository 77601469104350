import React from 'react';
import styles from './spinningLoader.module.scss';

const SpinningLoader = () => (
  <div className={styles.SpinningLoader}>
    <div className={styles.inner} />
  </div>
);

export default SpinningLoader;
