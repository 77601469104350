import React from 'react';
import { gateway } from 'helpers/gateway-helper';
import DropDown from 'components/Reusable/DropDown';
import { useQuery } from 'react-query';
import { userContext } from 'components/contexts/auth/auth.context';
import getUserLibrary, {
  CONTENT_TYPES,
  LIBRARY_TYPE
} from '@anghami/neogateway/dist/endpoints/getUserLibrary';
import { RELEASE_TYPES } from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import { useTranslation } from 'react-i18next';
import { IOption } from 'interfaces/formsComponents.interface';
import { IOwnAlbumsSelectProps } from '../create.interface';

export default function OwnShowsSelect(props: IOwnAlbumsSelectProps) {
  const { user } = React.useContext(userContext);
  const { t } = useTranslation();
  const key = [user?.anid, CONTENT_TYPES.podcast, 'library', LIBRARY_TYPE.live, 'compact'];
  const { data, isLoading } = useQuery(
    key,
    async () => {
      const { data } = await gateway.callEndpoint(getUserLibrary, {
        release_type: RELEASE_TYPES.Show,
        content_type: CONTENT_TYPES.podcast,
        library_type: LIBRARY_TYPE.live,
        compact: true
      });
      if (data?.data?.length > 0) {
        return data.data;
      } else {
        return [];
      }
    },
    {
      retry: 3
    }
  );

  const handleChange = (value: IOption) => {
    if (props.returnFullObject) {
      const selectedShow = data.find((show) => show.album_id === value?.value);
      props.onChange(selectedShow);
      return;
    }
    return props.onChange(value as any);
  };

  return !isLoading && (!data || data.length === 0) ? (
    <span>{t('you have no shows')}</span>
  ) : (
    <DropDown
      {...props}
      options={
        data?.map((podcast) => ({ label: podcast.release_name, value: podcast.album_id })) || []
      }
      allowClear={false}
      onChange={handleChange}
    />
  );
}
