import { ChangeRequestsStatus } from '@anghami/neogateway/dist/endpoints/getPendingSongsRequests';
import Select from 'antd/es/select';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SucessStatus } from 'icons/status-succeed.svg';
import { ReactComponent as FailedStatus } from 'icons/status-failed.svg';
import { ReactComponent as PendingStatus } from 'icons/status-pending.svg';

export default function FilterWrapper({ children }) {
  const { t } = useTranslation();
  const [selectedFilter, setSelectedFilter] = React.useState<ChangeRequestsStatus>(
    ChangeRequestsStatus.all
  );
  const statusFilterItems = [
    {
      value: ChangeRequestsStatus.all,
      label: <span>{t('All')}</span>
    },
    {
      value: ChangeRequestsStatus.approved,
      label: (
        <span className="d-flex f-align-center gap-05">
          <SucessStatus width={15} />
          {t('Approved')}
        </span>
      )
    },
    {
      value: ChangeRequestsStatus.rejected,
      label: (
        <span className="d-flex f-align-center gap-05">
          <FailedStatus width={15} />
          {t('Rejected')}
        </span>
      )
    },
    {
      value: ChangeRequestsStatus.pending,
      label: (
        <span className="d-flex f-align-center gap-05">
          <PendingStatus width={15} />
          {t('Pending')}
        </span>
      )
    }
  ];
  const childrenWithProps = React.Children.map(
    children,
    (child: React.ReactElement<{ selectedFilter: ChangeRequestsStatus }, string>) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { selectedFilter });
      }
      return child;
    }
  );
  return (
    <>
      <div className="d-flex f-align-center gap-05 p-1">
        <h3>{t('Filter')}</h3>
        <Select
          defaultValue={selectedFilter}
          style={{ width: 155 }}
          onChange={(value: ChangeRequestsStatus) => setSelectedFilter(value)}
          options={statusFilterItems}
        />
      </div>
      {childrenWithProps}
    </>
  );
}
