import React from 'react';
import MultiSelect from 'components/Reusable/MultiSelect';
import { IMultiSelectProps } from 'components/Reusable/MultiSelect/multiSelect';
import { useQuery } from 'react-query';
import { gateway } from 'helpers/gateway-helper';
import getMoods from '@anghami/neogateway/dist/endpoints/getMoods';

export default function MoodsSelect(props: IMultiSelectProps) {
  const { data: moods } = useQuery(['moods'], async () => {
    const { data } = await gateway.callEndpoint(getMoods, {});
    return data.moods?.map((c) => ({ label: c.title, value: c.id }));
  });
  return <MultiSelect {...props} options={moods} hideArrow={false} limit={2} />;
}
