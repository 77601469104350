import { IFormFields, IFormValues, ISection } from 'interfaces/formsComponents.interface';

export const convertFormFieldsToValues = (formFields: IFormFields): IFormValues => {
  const values = {};
  Object.keys(formFields)?.forEach((key) => {
    if (!formFields[key].value || formFields[key].notSentToApi) return;
    values[key] = formFields[key].getApiValue
      ? formFields[key].getApiValue(formFields[key])
      : formFields[key].value;
  });
  return values;
};

export const convertFormValuesToFields = (
  formValues: IFormValues,
  formFields: IFormFields
): IFormFields => {
  Object.keys(formFields)?.forEach((key) => {
    // Remove null set keys
    if (formFields[key] === null) {
      delete formFields[key];
      return;
    }
    formFields[key].value = formValues[key]?.value || formValues[key];
    formFields[key].extraValue = formValues[formFields[key].extraValueParam];
    formFields[key].error = null;
  });
  return formFields;
};

export const clearFormFields = (formFields: IFormFields): IFormFields => {
  Object.keys(formFields)?.forEach((key) => {
    formFields[key].value = null;
    formFields[key].extraValue = null;
  });
  return formFields;
};

export const getFormSectionsFlattened = (formSections: ISection[]): string[] => {
  let flattened = [];
  formSections?.forEach((section) => {
    flattened = [...flattened, ...section.data.flat()];
  });
  return flattened;
};

export const filterObjectWithKeyArray = (obj: Record<string, unknown>, keyArray: string[]) =>
  Object.keys(obj)
    ?.filter((key) => keyArray.indexOf(key) > -1)
    .reduce((result, key) => ({ ...result, [key]: obj[key] }), {});

export const getFormFieldsFilteredByFormSections = (
  formFields: IFormFields,
  formSections: ISection[]
) => filterObjectWithKeyArray(formFields, getFormSectionsFlattened(formSections));
