import React from 'react';
import { ReactComponent as GoToIcon } from 'icons/go-to.svg';
import { SONG_IMAGE_PLACEHOLDER } from 'constants/releases.constants';
import styles from './tableChart.module.scss';
import { combineClassNames } from 'helpers/styling-helper';
import { IEnhancedSectionData } from '../../interfaces';
import SectionItemHeader from '../SectionItemHeader/sectionItemHeader';
import { ReactComponent as ArrowIcon } from 'icons/arrow-left.svg';
import NoData from 'components/Reusable/NoData';
import { getCoverArtImage } from 'helpers/releases-helper';
import { useTrackVisibility } from 'react-intersection-observer-hook';
import { useWindowDimensions } from 'hooks/useWindowDimensions';

const TableItem = ({ title, subtitle, cover, link }) => {
  const [ref, { isVisible }] = useTrackVisibility();
  return (
    <div
      className={combineClassNames(
        'w-100 h-100 d-flex f-justify-start f-align-center gap-05 no-select',
        styles.tableItemContainer
      )}
      style={isVisible ? { opacity: 1 } : {}}
      ref={ref}
    >
      <img
        src={cover}
        alt="item-cover"
        onError={(e) => ((e.target as any).src = SONG_IMAGE_PLACEHOLDER)}
      />
      <div
        className={combineClassNames(
          'd-flex f-justify-between f-align-center f-grow-1',
          styles.textSection
        )}
      >
        <div className={combineClassNames('f-column', styles.textContainer)}>
          <h5>{title}</h5>
          <p>{subtitle}</p>
        </div>
        <a href={link} target="_blank" rel="noreferrer">
          <GoToIcon width={15} height={15} />
        </a>
      </div>
    </div>
  );
};

export default function TableChart({ data, title, unit, allTimeData }: IEnhancedSectionData) {
  const [hasMorePages, setHasMorePages] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const { isMobile } = useWindowDimensions();
  // Count per page
  const CPP = isMobile ? 4 : 9;

  React.useEffect(() => {
    setHasMorePages(data.length > currentPage * CPP);
  }, [data, currentPage]);

  const handleNextPageClick = () => setCurrentPage(currentPage + 1);
  const handlePreviousPageClick = () => setCurrentPage(currentPage - 1);

  return (
    <div>
      <SectionItemHeader title={title} allTimeData={allTimeData} />
      {data.length === 0 ? (
        <NoData allTimeData={allTimeData} />
      ) : (
        <div className={styles.tableContainer}>
          {data.slice((currentPage - 1) * CPP, (currentPage - 1) * CPP + CPP).map((item, index) => (
            <TableItem
              key={item.key + '-' + index}
              title={item.key}
              cover={item?.extras?.image || getCoverArtImage(item?.extras?.coverart_id)}
              subtitle={
                Number(item.value).toLocaleString('en', {
                  notation: 'compact',
                  compactDisplay: 'short'
                }) +
                ' ' +
                unit
              }
              link={item.extras?.link || 'https://play.anghami.com/playlist/' + item.key}
            />
          ))}
          <div className={combineClassNames('f-center f-column gap-05', styles.carouselControls)}>
            <div
              className={combineClassNames(
                'f-center',
                styles.arrowContainer,
                currentPage === 1 && styles.disabled
              )}
              onClick={currentPage === 1 ? null : handlePreviousPageClick}
            >
              <ArrowIcon />
            </div>
            <div
              className={combineClassNames(
                'f-center',
                styles.arrowContainer,
                !hasMorePages && styles.disabled
              )}
              onClick={!hasMorePages ? null : handleNextPageClick}
            >
              <ArrowIcon style={{ transform: 'rotate(180deg)' }} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
