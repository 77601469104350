import React from 'react';
import { SELECT_SEARCHED_ARTIST, PERFORM_SEARCH_ARTIST } from 'constants/amplitudeEvents';
import Button from 'components/Reusable/Button';
import { logAmplitudeEvent } from 'helpers/analytics-helper';
import {
  getArtistProfileClaimedEndpoint,
  getArtistProfileEndpoint,
  searchArtists
} from 'helpers/http-helper';
import { IArtist } from 'interfaces/artist.interface';
import message from 'antd/es/message';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { combineClassNames } from 'helpers/styling-helper';
import { ReactComponent as BackArrow } from 'icons/arrow-left.svg';
import { ReactComponent as SearchIcon } from 'icons/search.svg';
import { ReactComponent as LoadingIcon } from 'icons/loaders/tail-spin.svg';
import ArtistItem from 'components/Landing/ArtistItem';

import styles from './claimProfile.module.scss';
import commonStyles from 'components/Landing/frames/common.module.scss';

export default function ClaimProfile() {
  const [query, setQuery] = React.useState('');
  const [results, setResults] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  let searchTimeout = null;

  React.useEffect(() => {
    if (query.trim() === '') {
      setResults(null);
      return;
    }
    if (query.length > 2 && !searchTimeout) {
      searchTimeout = setTimeout(() => {
        handleGoPress();
      }, 500);
    }
  }, [query]);

  const handleBackPress = () => {
    navigate(-1);
  };

  const updateInputValue = (e: any) => {
    setQuery(e.target.value);
  };

  const handleGoPress = () => {
    if (query.indexOf('play.anghami.com') > -1 && query.search(/(artist|podcaster)/) > -1) {
      setLoading(true);
      const matches = query.match(/(\d+)/) || [];
      getArtistProfileEndpoint(Number(matches[0]))
        .then(({ data }) => {
          setResults([{ id: data.id, name: data.name, ArtistArt: data.ArtistArt }]);
          setLoading(false);
          searchTimeout = null;
        })
        .catch(() => {
          setLoading(false);
          searchTimeout = null;
        });
      logAmplitudeEvent(PERFORM_SEARCH_ARTIST, {
        searchQuery: matches[0],
        usedLink: true
      });
    } else {
      setLoading(true);
      searchArtists(query)
        .then(({ data }) => {
          setResults([...data.sections[0].data]);
          setLoading(false);
          searchTimeout = null;
        })
        .catch(() => {
          setLoading(false);
          searchTimeout = null;
        });
      logAmplitudeEvent(PERFORM_SEARCH_ARTIST, {
        searchQuery: query
      });
    }
  };

  const handleArtistClick = ({ id, name, ArtistArt, is_podcaster }: IArtist) => {
    // move to create account
    logAmplitudeEvent(SELECT_SEARCHED_ARTIST, {
      artistID: id
    });
    setLoading(true);
    getArtistProfileClaimedEndpoint(String(id)).then((res) => {
      if (res.data && !res.data.error && !res.data.claimed) {
        navigate(
          `/signup?claim=${id}&ArtistArt=${ArtistArt}&name=${name}&is_podcaster=${
            is_podcaster || 0
          }`
        );
      } else {
        message.error(
          {
            type: 'error',
            content: t('Artist already claimed'),
            className: 'custom-class',
            style: {
              marginTop: '1em'
            }
          },
          5
        );
        setLoading(false);
      }
    });
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      handleGoPress();
    }
  };

  const artists =
    results?.length > 0
      ? results.map((artist) => (
          <ArtistItem {...artist} key={artist.id} selectArtist={() => handleArtistClick(artist)} />
        ))
      : null;
  const inputPlaceholder = t('app.containers.HomePage.claimArtist.placeholder');

  return (
    <div className="w-100 h-100 p-2 f-column f-justify-between">
      <div className={combineClassNames('f-column position-relative', styles.container)}>
        <span className={commonStyles.landingHeader}>
          <BackArrow className={styles.backIcon} onClick={handleBackPress} />
          {t('app.containers.HomePage.form.findAccount')}
        </span>
        <div className={styles.inputContainer}>
          <SearchIcon className={styles.searchIcon} />
          <input
            type="text"
            placeholder={inputPlaceholder}
            value={query}
            onChange={updateInputValue}
            onKeyDown={handleKeyDown}
          />
          <Button
            label={loading ? '' : t('app.containers.HomePage.form.go')}
            onSubmit={handleGoPress}
            customClasses={[styles.customButton]}
            icon={
              loading ? <LoadingIcon style={{ margin: 'unset' }} width={20} height={20} /> : null
            }
          />
        </div>
        {artists && (
          <div className={combineClassNames('f-column', styles.searchContainer)}>{artists}</div>
        )}
      </div>
      <div
        className={combineClassNames('d-flex f-align-center gap-1', commonStyles.alternativeFooter)}
      >
        <span>{t('Already have any account')}</span>
        <Link to={'/login'}>
          <Button label={t('Login instead')} customClasses={[commonStyles.ctaButton]} />
        </Link>
      </div>
    </div>
  );
}
