import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { combineClassNames } from 'helpers/styling-helper';
import styles from './landingHeroSection.module.scss';
import Button from 'components/Reusable/Button';
import { useTranslation } from 'react-i18next';
import { environment } from 'env/environment';

export default function LandingHeroSection() {
  const { t, i18n } = useTranslation();
  const [section, setSection] = useState(0);
  const heroSectionData = [
    {
      id: 0,
      header: `${t('creators.landing.header.title.1', 'Create. Publish. Grow.')}`,
      headerParagraph: t('creators.landing.header.title.2', 'Join the Community of Top Creators'),
      primaryButton: {
        label: `${t('creators.landing.cta.claim', 'Claim your profile')}`,
        link: `/claim`
      },
      secondaryButton: {
        label: `${t('creators.landing.cta.signup', 'Sign up')}`,
        link: `/signup`
      }
    }
  ];

  const setCurrentSection = () => {
    setSection((section + 1) % heroSectionData.length);
  };

  useEffect(() => {
    const interval = setInterval(() => setCurrentSection(), 5000);
    return () => clearInterval(interval);
  });

  return (
    <>
      <div className={combineClassNames('d-flex', styles.heroContainer)}>
        <img
          src={`${environment.creators_assets_cdn}/images/landing-image${
            i18n.language === 'ar' ? '-ar' : ''
          }.png`}
          className={styles.landingImage}
          alt="landing-hero"
        />
        <div className="f-column">
          <div className={styles.heroTitle}>
            <div>
              <h1 className="superdino-1">{heroSectionData[0].header}</h1>
              <p>{heroSectionData[0].headerParagraph}</p>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            {heroSectionData[section].primaryButton && (
              <Link to={heroSectionData[section].primaryButton.link}>
                <Button
                  label={heroSectionData[section].primaryButton.label}
                  customClasses={[
                    'd-flex',
                    'f-justify-center',
                    styles.heroButton,
                    styles.primaryHeroButton
                  ]}
                />
              </Link>
            )}
            {heroSectionData[section].secondaryButton && (
              <Link to={heroSectionData[section].secondaryButton.link}>
                <Button
                  label={heroSectionData[section].secondaryButton.label}
                  customClasses={[
                    'd-flex',
                    'f-justify-center',
                    styles.heroButton,
                    styles.secondaryHeroButton
                  ]}
                />
              </Link>
            )}
          </div>
          {heroSectionData.length > 1 && (
            <div className={combineClassNames('d-flex', 'w-100', styles.sectionSelectorContainer)}>
              {heroSectionData.map((s) => (
                <div
                  className={styles.sectionSelector}
                  key={s.id}
                  onClick={() => setSection(s.id)}
                  style={{
                    opacity: s.id == section ? '0.8' : '0.3'
                  }}
                ></div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
