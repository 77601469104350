import NeoGateway from '@anghami/neogateway';
import { environment } from 'env/environment';
import { getQueryStringValueFromURL, getSidFromUrl } from 'helpers/url-helper';
import Cookies from './cookies-helper';

export let gateway: NeoGateway;

const initGateway = async (SID, lang, customHeaders = null) => {
  const isHttps = window.location.href.indexOf('https') > -1;
  const isLocal = window.location.href.indexOf('angha.me') > -1;
  const gatewayInstance = new NeoGateway({
    autoloadSodium: false,
    catchURLFromEnv: false,
    apiURL: isLocal
      ? isHttps
        ? environment.secure_staging_local_api_endpoint
        : environment.staging_local_api_endpoint
      : environment.prod_api_endpoint,
    kousaApiUrl: isHttps
      ? environment.creators_koussa_prod_api_endpoint
      : environment.creators_koussa_local_api_endpoint,
    appSid: SID,
    customParams: {
      web_medium: 'anghami-creators',
      lang,
      userlanguageprod: lang,
      language: lang,
      web2: false,
      creators: true
    },
    timeout: 300000,
    ...(customHeaders && { customHeaders })
  });
  try {
    await gatewayInstance.ready();
  } catch (err) {
    console.log('🔴 ~ Fail to init neogateway err => ', err);
  }

  return gatewayInstance;
};

const isSupportedLang = (lang: string) => ['ar', 'en', 'fr'].includes(lang);
const defaultLang = 'en';
const setLanguageCookies = (lang: string) => {
  ['userlanguageprod', 'language'].forEach((cookieName) => {
    Cookies.set(cookieName, lang, 30);
  });
};

export const getSID = () => {
  const sidFromLocalStorage =
    typeof localStorage !== 'undefined' && localStorage
      ? JSON.parse(localStorage.getItem('user'))?.socketsessionid
      : '';
  return getSidFromUrl() || Cookies.get('socketsessionid') || sidFromLocalStorage;
};

export const initNeoGatewayBrowser = async () => {
  if (gateway) {
    return gateway;
  }
  let lang = getQueryStringValueFromURL('lang') || Cookies.get('c-lang') || 'en';

  if (!isSupportedLang(lang?.toString() || defaultLang)) {
    lang = defaultLang;
  }
  setLanguageCookies(lang);
  const sid = getSID();

  gateway = await initGateway(sid, lang);
  return gateway;
};
