import { format } from 'date-fns';
import React from 'react';

interface IReleaseDateTimePreviewProps {
  releaseDate: Date;
  releaseTime?: Date;
}

export default function ReleaseDateTimePreview({
  releaseDate,
  releaseTime
}: IReleaseDateTimePreviewProps) {
  let displayValue = releaseDate ? new Date(releaseDate) : null;
  if (releaseTime) {
    const time = new Date(releaseTime);
    displayValue = new Date(
      displayValue.setHours(time.getHours(), time.getMinutes(), time.getSeconds())
    );
  }
  return <span>{displayValue ? format(displayValue, 'PPpp') : null}</span>;
}
