import { userContext } from 'components/contexts/auth/auth.context';
import { environment } from 'env/environment';
import { IUploadedFile, UploaderFileTypes } from 'interfaces/formsComponents.interface';
import React from 'react';
import { useTus } from 'use-tus';
import { gateway, getSID } from 'helpers/gateway-helper';
import postFailUpload from '@anghami/neogateway/dist/endpoints/postFailUpload';
import postVerifySession from '@anghami/neogateway/dist/endpoints/postVerifySession';
import validateUploadSize from '@anghami/neogateway/dist/endpoints/validateUploadSize';
import { createFile, generateKey } from 'helpers/file-helper';
import { logGeneralErrorEvent } from 'helpers/analytics-helper';

interface IUploadFileHookProps {
  fileType: UploaderFileTypes;
  maxSize?: number;
  onSuccess: (uploadedFile: IUploadedFile) => void;
  onError: (err: any) => void;
  initialFile?: IUploadedFile;
}
export interface IUseFileUploaderReturnType {
  uploadFile: (uploadedFile: IUploadedFile) => void;
  clearUploadedFile: () => void;
  uploadedFile: IUploadedFile;
  loadingProgress: number;
  isLoading: boolean;
  isUploadSuccess: boolean;
}

function useFileUploader({
  fileType,
  maxSize,
  onSuccess,
  onError,
  initialFile
}: IUploadFileHookProps): IUseFileUploaderReturnType {
  const [uploadedFile, setUploadedFile] = React.useState<IUploadedFile>(initialFile || null);
  const [loadingProgress, setLoadingProgress] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isUploadSuccess, setIsUploadSuccess] = React.useState(false);
  const { user, reauth } = React.useContext(userContext);
  const keyRef = React.useRef(null);
  let timeout = null;
  const validateFileUploaded = async (retryTimes = 0) => {
    const { data } = await gateway.callEndpoint(validateUploadSize, {
      key: keyRef?.current,
      bucket: environment.upload_s3_bucket,
      output: 'jsonhp'
    });
    if (!data || data.error) {
      if (retryTimes < 1) {
        timeout = setTimeout(() => {
          validateFileUploaded(retryTimes + 1);
        }, 2000);
        return;
      }
      clearTimeout(timeout);
      logError(new Error(data?.error.message || 'Failed to validate file (key not on s3)'));
    }
  };
  const handleUploadSuccess = async () => {
    if (!keyRef?.current) {
      handleUploadError(new Error('failed to save key'));
      return;
    }
    validateFileUploaded();
    setIsLoading(false);
    setIsUploadSuccess(true);
    onSuccess({ ...uploadedFile, isUploaded: true, key: keyRef.current });
  };
  const handleUploadError = (err) => {
    setIsLoading(false);
    setIsUploadSuccess(false);
    setUploadedFile({ ...uploadedFile, isUploaded: false });
    gateway.callEndpoint(postFailUpload, {
      reason: err.message,
      timestamp: Date.now(),
      feature: 'new creators'
    });
    logError(err);
    onError(err);
  };

  const handleOnShouldRetry = (error, retryAttempt) => {
    if (error.message.includes('response code: 401')) {
      reauth();
      return true;
    }
    return retryAttempt < 5;
  };

  const { setUpload, remove, upload, isSuccess } = useTus({
    uploadOptions: {
      onProgress: (sent, total) => {
        setLoadingProgress(Math.round((sent / total) * 100));
      },
      onError: handleUploadError,
      onShouldRetry: handleOnShouldRetry
      // onSuccess: handleUploadSuccess
    }
  });

  React.useEffect(() => {
    // setUploadedFile(initialFile || uploadedFile || null);
    if (!initialFile) return;
    setUploadedFile(initialFile);
    setIsUploadSuccess(!!initialFile?.key);
  }, [initialFile]);

  React.useEffect(() => {
    if (!upload) return;
    upload.start();
    return () => {
      upload.abort();
    };
  }, [upload]);

  React.useEffect(() => {
    if (!isSuccess || !upload || !upload.url) return;
    handleUploadSuccess();
  }, [isSuccess]);

  React.useEffect(() => {
    // this endpoint is used by tus to validate session
    const callPostVerifySession = async () => {
      const { data } = await gateway.callEndpoint(postVerifySession, {
        validatesid: getSID(),
        output: 'jsonhp'
      });
      if (data?.error && data.error.code === 401) {
        reauth();
      }
    };
    callPostVerifySession();
    return () => clearTimeout(timeout);
  }, []);

  const uploadFile = async (uf: IUploadedFile) => {
    if (maxSize && uf.size > maxSize) {
      onError({ message: 'Image size must be less than ' + maxSize / 1000000 + 'MB' });
      return;
    }
    try {
      const key = generateKey(user?.anid || 'anon', uf, fileType);
      keyRef.current = key;
      setUploadedFile({ ...uf, key });
      setIsLoading(true);
      setLoadingProgress(0);
      const file = uf.file ? uf.file : await createFile(uf);
      if (!file) return;
      await setUpload(file, {
        endpoint: 'https://tusk.anghami.com/files/',
        retryDelays: [0, 1000, 3000, 5000],
        ...(file.size > 50000000 && { chunkSize: 50000000 }),
        metadata: {
          key: key,
          filename: key,
          filetype: uf.type,
          token: user?.socketsessionid || environment.general_s3_token,
          bucket: environment.upload_s3_bucket,
          multiparttest: '1',
          output: 'jsonhp'
        },
        removeFingerprintOnSuccess: true,
        uploadSize: file.size,
        storeFingerprintForResuming: true
      });
    } catch (error) {
      console.error('🔴 ~ Error uploading file', error);
      handleUploadError(error);
    }
  };

  const logError = (error) => {
    logGeneralErrorEvent(error, 'useFileUploader', {
      error: 'validateUploadSize failed',
      message: error.message,
      key: keyRef.current,
      file: JSON.stringify(uploadedFile)
    });
  };

  const clearUploadedFile = () => {
    remove();
    if (uploadedFile?.src) {
      URL.revokeObjectURL(uploadedFile?.src);
    }
    setUploadedFile(null);
  };

  return {
    uploadFile,
    clearUploadedFile,
    uploadedFile,
    loadingProgress,
    isLoading,
    isUploadSuccess
  };
}

export default useFileUploader;
