import React from 'react';
import styles from './searchInput.module.scss';
import { ReactComponent as SearchIcon } from 'icons/search.svg';

interface ISearchInputProps {
  placeholder?: string;
  onChange: (val: string) => void;
  value: string;
}

export default function SearchInput({ placeholder, onChange, value }: ISearchInputProps) {
  return (
    <div className={styles.searchInput}>
      <input
        type={'text'}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
      <SearchIcon />
    </div>
  );
}
