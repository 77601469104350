import { BeatLoader } from 'components/Reusable/Loaders';
import LoaderContainer from 'components/Reusable/Loaders/loaderContainer';
import React from 'react';

const LoaderSuspence: React.FC<{ children: React.ReactElement }> = ({ children }) => (
  <React.Suspense
    fallback={
      <LoaderContainer>
        <BeatLoader />
      </LoaderContainer>
    }
  >
    {children}
  </React.Suspense>
);

export default LoaderSuspence;
