import getDashboardProfile from '@anghami/neogateway/dist/endpoints/getDashboardProfile';
import postArtistPromotionRequest, {
  IPostArtistPromotionRequestRequest,
  IPostArtistPromotionRequestResponse
} from '@anghami/neogateway/dist/endpoints/postArtistPromotionRequest';
import CheckCircleOutlined from '@ant-design/icons/lib/icons/CheckCircleOutlined';
import CloseCircleOutlined from '@ant-design/icons/lib/icons/CloseCircleOutlined';
import { userContext } from 'components/contexts/auth/auth.context';
import Button from 'components/Reusable/Button';
import CloseIcon from 'components/Reusable/CloseIcon';
import PhoneWithCountry, {
  IPhoneWithCountryValue
} from 'components/Reusable/PhoneWithCountry/phoneWithCountry';
import TextArea from 'components/Reusable/TextArea';
import { ValidatePhoneNumber } from 'helpers/form-validator-helper';
import { gateway } from 'helpers/gateway-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import styles from './promotionRequestDialog.module.scss';
import { combineClassNames } from 'helpers/styling-helper';
import { ReactComponent as LoaderIcon } from 'icons/loaders/tail-spin.svg';
import { toastContext } from 'components/contexts/toast/toast.context';
import { logAmplitudeEvent } from 'helpers/analytics-helper';
import {
  PROMOTION_SUBMIT_PITCH_PLAYLIST,
  SUBMIT_PROMOTION_REQUEST
} from 'constants/amplitudeEvents';

interface IPromotionRequestDialogProps {
  closeDialog?: () => void;
}
export default function PromotionRequestDialog({ closeDialog }: IPromotionRequestDialogProps) {
  const { t, i18n } = useTranslation('common');
  const { user } = React.useContext(userContext);
  const [text, setText] = React.useState('');
  const [phoneValue, setPhoneValue] = React.useState<IPhoneWithCountryValue>(null);
  const [isNewPhone, setIsNewPhone] = React.useState(false);
  const [previousPhone, setPreviousPhone] = React.useState('');
  const [selectedPhone, setSelectedPhone] = React.useState('');
  const [phoneError, setPhoneError] = React.useState('');
  const [submitError, setSubmitError] = React.useState('');
  const [isSubmitLoading, setIsSubmitLoading] = React.useState(false);
  const { openToast } = React.useContext(toastContext);
  const { data, isLoading } = useQuery(['profile-data'], async () => {
    const { data } = await gateway.callEndpoint(getDashboardProfile, {
      artistId: Number(user.anid)
    });
    return data?.response;
  });

  React.useEffect(() => {
    if (!data || !data.phone) return;
    setSelectedPhone(data.phone);
  }, [data]);

  const mutation = useMutation(
    (params: IPostArtistPromotionRequestRequest) =>
      gateway.callEndpoint(postArtistPromotionRequest, params),
    {
      retry: 3,
      onSettled: (data: IPostArtistPromotionRequestResponse, error: any) => {
        setIsSubmitLoading(false);
        if (!data || error) {
          // handle error
          setSubmitError(
            t('There was an error submitting your request. Please try again or contact us for help')
          );
          logAmplitudeEvent(SUBMIT_PROMOTION_REQUEST, {
            status: 'fail',
            error: error || 'unkown error'
          });
          return;
        }
        if (data?.error?.message) {
          setSubmitError(data?.error?.message || t('Something went wrong'));
          logAmplitudeEvent(SUBMIT_PROMOTION_REQUEST, {
            status: 'fail',
            error: data?.error?.message || 'unkown error'
          });
          return;
        }
        logAmplitudeEvent(SUBMIT_PROMOTION_REQUEST, {
          status: 'success'
        });
        openToast(t('Thank you! Your request has been successfully submitted.'), 'success');
        closeDialog();
      }
    }
  );

  const handleSubmit = () => {
    setIsSubmitLoading(true);
    const params: IPostArtistPromotionRequestRequest = {
      data: {
        phone: selectedPhone,
        info: text,
        newPhone: isNewPhone
      },
      reqlang: (i18n.language?.split('-')[0] as 'en' | 'ar' | 'fr') || 'en'
    };
    console.log('handling submit', params);
    mutation.mutate(params);
  };

  const handleSubmitPhone = () => {
    if (!phoneValue?.country || !phoneValue?.phone || !ValidatePhoneNumber(phoneValue)) {
      setPhoneError(t('app.containers.HomePage.form.mobileInvalid'));
      return;
    }
    logAmplitudeEvent(PROMOTION_SUBMIT_PITCH_PLAYLIST, {});
    setPhoneError(null);
    setIsNewPhone(true);
    setSelectedPhone(phoneValue.fullPhone);
  };

  const handleCancelPhone = () => {
    setSelectedPhone(previousPhone || data?.phone || '');
    setIsNewPhone(previousPhone !== data?.phone);
    setPhoneError('');
  };

  const handleChangeClick = () => {
    setPreviousPhone(selectedPhone);
    setSelectedPhone(null);
  };

  return (
    <div className={styles.container}>
      <h2>{t('Promotion Request')}</h2>
      <CloseIcon onClick={closeDialog} customClassName={styles.closeButton} />
      <p>{t('You can now get more plays with our special promotion plans!')}</p>
      <p>{t('An Anghami representative will get in touch with you')}</p>
      {isLoading ? (
        ''
      ) : selectedPhone ? (
        <p>
          {`${t('They will contact you on')} ${selectedPhone} `}{' '}
          <span className={styles.changeButton} onClick={handleChangeClick}>
            ({t('Change')})
          </span>
          {` ${t('for more information')}`}
        </p>
      ) : (
        <>
          <p>{t('Set phone number so we can get in touch with you')}</p>
          <div className="d-flex f-align-center gap-05">
            <PhoneWithCountry
              placeHolder={'0000000'}
              value={phoneValue}
              name={'phone'}
              onChange={setPhoneValue}
              error={phoneError}
            />
            <CheckCircleOutlined
              className={combineClassNames(styles.iconButton, styles.set)}
              onClick={handleSubmitPhone}
            />
            <CloseCircleOutlined
              className={combineClassNames(styles.iconButton, styles.cancel)}
              onClick={handleCancelPhone}
            />
          </div>
        </>
      )}
      <h4 className="bold mt-1">{t('Additional Information')}</h4>
      <TextArea
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder={t('You can tell us more about your request here')}
      />
      <span className={styles.errorMessage}>{submitError}</span>
      <Button
        label={t('Submit')}
        customClasses={[styles.submitButton]}
        onSubmit={handleSubmit}
        disabled={!selectedPhone || isSubmitLoading}
        icon={isSubmitLoading ? <LoaderIcon width={20} height={20} /> : null}
      />
    </div>
  );
}
