import UploadCoverArtDialog from 'components/Dialogs/UploadCoverArtDialog';
import Button from 'components/Reusable/Button';
import Dialog from 'components/Reusable/Dialog';
import React from 'react';
import styles from './uploadCoverArtWithDialog.module.scss';
import colors from 'styles/partials/_colors_exports.module.scss';
import WithLabelWrapper from 'components/Reusable/WithLabelWrapper';
import { ReactComponent as SongIcon } from 'icons/song.svg';
import { ReactComponent as RetryIcon } from 'icons/retry.svg';
import { combineClassNames } from 'helpers/styling-helper';
import ProgressBar from 'components/Reusable/ProgressBar';
import { useTranslation } from 'react-i18next';
import { IUploadCoverArtProps } from 'interfaces/formsComponents.interface';

export default function UploadCoverArtWithDialog(props: IUploadCoverArtProps) {
  const { customLabelClass, uploader, config } = props;
  const [isUploadCoverArtDialogOpen, setIsUploadCoverArtDialogOpen] = React.useState(false);
  const { t } = useTranslation('common');
  const {
    uploadFile,
    clearUploadedFile,
    uploadedFile: uploadedCoverArt,
    loadingProgress,
    isLoading,
    isUploadSuccess
  } = uploader;
  const openUploadDialog = () => setIsUploadCoverArtDialogOpen(true);
  const closeUploadDialog = () => setIsUploadCoverArtDialogOpen(false);
  return (
    <>
      <WithLabelWrapper
        {...props}
        customLabelClass={combineClassNames(styles.customLabel, customLabelClass)}
      >
        <div className={styles.upload_coverart_container}>
          {uploadedCoverArt?.src ? (
            <>
              <img src={uploadedCoverArt.src} alt={'uploaded-pic'} className={styles.coverArt} />
              <div className={styles.progress_button_container}>
                {config?.hideStatus ? null : isLoading ? (
                  <ProgressBar progress={loadingProgress} />
                ) : (
                  <div className="d-flex f-align-center" style={{ marginInlineStart: '0.5rem' }}>
                    {isUploadSuccess || uploadedCoverArt.isUploaded ? (
                      <span style={{ color: colors['success-color'] }}>
                        {t('Upload Successful')}
                      </span>
                    ) : (
                      <>
                        <span style={{ color: colors['failure-color'] }}>{t('Unsuccessful')}</span>
                        <div
                          className="d-flex f-align-center"
                          onClick={() => {
                            uploadFile(uploadedCoverArt);
                          }}
                        >
                          <span style={{ color: colors['dark-10'], margin: '0em 0.5em' }}>
                            {t('Retry')}
                          </span>
                          <RetryIcon fill={colors['dark-10']} />
                        </div>
                      </>
                    )}
                  </div>
                )}
                <Button
                  label={config?.changeButtonText || t('Change cover')}
                  withBackground={colors['light-6']}
                  customClasses={[styles.retry_button]}
                  onSubmit={() => {
                    clearUploadedFile();
                    setIsUploadCoverArtDialogOpen(true);
                  }}
                  icon={<RetryIcon width={20} />}
                  disabled={isLoading}
                />
              </div>
            </>
          ) : (
            <>
              <div
                className={combineClassNames(styles.coverArt_placeholder, styles.coverArt)}
                onClick={openUploadDialog}
              >
                <SongIcon />
              </div>
              <Button
                label={config?.uploadButtonText || t('Upload a cover')}
                withBackground={colors['branding-purple']}
                onSubmit={openUploadDialog}
              />
            </>
          )}
        </div>
      </WithLabelWrapper>
      <Dialog
        isOpen={isUploadCoverArtDialogOpen}
        onClose={closeUploadDialog}
        isBackDrop={false}
        customClassName={styles.dialog}
      >
        <UploadCoverArtDialog
          closeDialog={closeUploadDialog}
          onUploadCoverArt={uploadFile}
          customTitle={config?.title}
        ></UploadCoverArtDialog>
      </Dialog>
    </>
  );
}
