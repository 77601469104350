import React from 'react';
import { ReactComponent as GoToIcon } from 'icons/go-to.svg';
import { combineClassNames } from 'helpers/styling-helper';
import styles from './linkItem.module.scss';

interface ILinkItemProps {
  text?: string;
  hideIcon?: boolean;
  link?: string;
  newTab?: boolean;
  customClassName?: string;
  onClick?: () => void;
}
export default function LinkItem({
  text,
  hideIcon,
  link,
  newTab,
  customClassName,
  onClick
}: ILinkItemProps) {
  return (
    <a
      className={combineClassNames(
        'd-flex gap-05 f-align-center',
        styles.linkItem,
        customClassName
      )}
      href={link}
      target={newTab && '_blank'}
      onClick={onClick}
    >
      <span>{text}</span>
      {!hideIcon && <GoToIcon />}
    </a>
  );
}
