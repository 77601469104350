import SongUploadBox from 'components/CreateRelease/SongUploadBox';
import React from 'react';
import { IAlbumSong, IUploadedFile } from 'interfaces/formsComponents.interface';
import UploadedSongBox from 'components/CreateRelease/UploadedSongBox';
import SortableList from 'components/Reusable/SortableList';
import { SortableItem } from 'components/Reusable/SortableList/sortableList';
import { useTranslation } from 'react-i18next';
import Button from 'components/Reusable/Button';
import styles from './multiSongUploader.module.scss';
import { ReactComponent as AddIcon } from 'icons/add-sm.svg';
import colors from 'styles/partials/_colors_exports.module.scss';
import FileUploadButton from '../FileUploadButton';
import { combineClassNames } from 'helpers/styling-helper';
import { toastContext } from 'components/contexts/toast/toast.context';
import { ALLOWED_MUSIC_FILETYPES } from 'constants/releases.constants';
import { getIsReleaseDown } from 'helpers/releases-helper';
import { v4 as uuidv4 } from 'uuid';

interface MultiSongUploaderProps {
  onChange: (songs: IAlbumSong[]) => void;
  onReorder: () => void;
  onDelete?: (song: IAlbumSong) => void;
  isFromEdit?: boolean;
  songs: IAlbumSong[];
  title?: string;
  albumInfo: {
    albumid: string;
    coverArt: IUploadedFile;
    releasedate: Date;
    releasetime: Date;
    ispodcast: 1 | 0;
    languageid: number;
    artistsValue: { label: string; value: string }[];
    genreValue?: { label: string; value: string };
    categoryValue?: { name: string; id: string };
    albumtype: number;
  };
}

export default function MultiSongUploader({
  songs,
  title,
  onChange,
  onReorder,
  onDelete,
  isFromEdit,
  albumInfo
}: MultiSongUploaderProps) {
  const { t } = useTranslation('common');
  const [isAllCollapsed, setIsAllCollapsed] = React.useState(false);
  const [unUnploadedQueue, setUnUploadedQueue] = React.useState([]);
  const [isReorderEnabled, setIsReorderEnabled] = React.useState(false);
  const { openToast } = React.useContext(toastContext);
  React.useEffect(() => {
    setIsAllCollapsed(!songs.some((song) => !song.collapsed));
  }, [songs]);

  const deleteSong = (song: IAlbumSong) => {
    if (onDelete) {
      onDelete(song);
      return;
    }
    const newSongs = songs.filter((s) => s.id !== song.id);
    setUnUploadedQueue(unUnploadedQueue?.filter((i) => i !== song.id) || []);
    onChange(newSongs);
  };

  const updateSong = (song: IAlbumSong) => {
    const newSongs = songs.map((s) => {
      if (s.id !== song.id) return s;
      if (s.uploadedSong?.isUploaded === song.uploadedSong?.isUploaded) {
        if (!song.audiokey) {
          setUnUploadedQueue(unUnploadedQueue?.filter((i) => i !== s.id) || []);
          return song;
        }
        return song;
      }
      setUnUploadedQueue(unUnploadedQueue?.filter((i) => i !== s.id) || []);
      return song;
    });
    onChange(newSongs);
  };

  const getInheritedAlbumProps = (songInfo: IAlbumSong = {}) => {
    const date = new Date(albumInfo.releasedate);
    const time = new Date(albumInfo.releasetime);
    const releasedate = new Date(
      date.setHours(time.getHours(), time.getMinutes(), time.getSeconds())
    );
    return {
      albumCoverArt: albumInfo.coverArt,
      albumid: albumInfo.albumid,
      albumReleaseDate: releasedate,
      languageid: albumInfo.languageid,
      albumLanguageId: albumInfo.languageid,
      ...(albumInfo.artistsValue?.length > 0 && {
        artistsValue: albumInfo.artistsValue,
        artists: albumInfo.artistsValue.map((artist) => artist.value)
      }),
      ...(albumInfo.genreValue && {
        genreValue: albumInfo.genreValue,
        genre: albumInfo.genreValue.value
      }),
      ...(albumInfo.categoryValue && {
        categoryValue: albumInfo.categoryValue,
        category: albumInfo.categoryValue.id
      }),
      ispodcast: albumInfo.ispodcast,
      albumtype: albumInfo.albumtype,
      ...songInfo
    };
  };

  const addNewSongs = (files: IUploadedFile[]) => {
    if (!files) return;
    const uniqueFiles = files.filter((file) => !isSongAlreadyExist(file));
    if (uniqueFiles.length === 0) {
      openToast(t('Song already added'), 'warning');
      return;
    }

    const uploadedFiles = uniqueFiles.map((file) => ({
      uploadedSong: file,
      id: uuidv4(),
      ...getInheritedAlbumProps()
    }));
    setUnUploadedQueue([...unUnploadedQueue, ...uploadedFiles.map((s) => s.id)]);
    onChange([...songs, ...uploadedFiles]);
  };

  const isSongAlreadyExist = (file) => {
    return songs.some(
      (s) => s.uploadedSong.name === file.name && s.uploadedSong.size === file.size
    );
  };

  const handleReorderClick = () => {
    if (!isReorderEnabled) {
      onChange(songs.map((s) => ({ ...s, collapsed: true })));
    }
    setIsReorderEnabled(!isReorderEnabled);
  };

  const songsToDisplay = songs?.filter((s) => !getIsReleaseDown(s?.status)) || [];
  return (
    <div className={combineClassNames('f-column', styles.container)}>
      <div
        className={combineClassNames(
          'd-flex f-align-center f-justify-between',
          styles.title_section_container
        )}
      >
        <div className="d-flex f-align-center">
          <h3>{t(title)}</h3>
          <div className={styles.title_description}>
            {songsToDisplay?.length > 0 &&
              `${songsToDisplay?.length} ${
                songsToDisplay?.length === 1 ? t('Track') : t('Tracks')
              } ${t('Uploaded')}`}
          </div>
          {!isFromEdit && songsToDisplay?.length > 0 ? (
            <FileUploadButton
              customClass={styles.upload_button}
              onUpload={addNewSongs}
              multiple={true}
              fileTypes={ALLOWED_MUSIC_FILETYPES.join(',')}
              label={t('Add')}
              customIcon={<AddIcon fill={colors['dark-1']} width="15" height="15" />}
            />
          ) : null}
        </div>
        {isFromEdit ? (
          <> </>
        ) : (
          <div className="d-flex f-align-center gap-05">
            <Button
              label={isReorderEnabled ? t('Save') : t('Reorder')}
              withBackground={colors['light-8']}
              customClasses={['default', 'sm']}
              onSubmit={handleReorderClick}
              disabled={Boolean(songs?.length <= 1)}
            />
            <Button
              label={!isAllCollapsed ? t('Collapse All') : t('Expand all')}
              withBackground={colors['light-8']}
              customClasses={['default', 'sm']}
              onSubmit={() => {
                onChange(songs.map((s) => ({ ...s, collapsed: !isAllCollapsed })));
              }}
              disabled={Boolean(songs?.length === 0) || isReorderEnabled}
            />
          </div>
        )}
      </div>
      {songs?.length > 0 ? (
        <SortableList items={songs} updateItems={onChange} onDragEndCallback={onReorder}>
          {songsToDisplay.map((song, index) => (
            <SortableItem key={song.id} id={song.id} disabled={!isReorderEnabled}>
              <UploadedSongBox
                key={song.id + '-inside-song'}
                index={index + 1}
                song={{ ...getInheritedAlbumProps(song), tracknb: index + 1 }}
                canUpload={unUnploadedQueue.findIndex((s) => s === song.id) === 0}
                canDelete={songs?.length > 1}
                isEditDisabled={isFromEdit}
                isOrderingDisabled={!isReorderEnabled}
                collapsed={isFromEdit || song.collapsed}
                setCollapsed={(collapsed) => updateSong({ ...song, collapsed })}
                onDelete={deleteSong}
                onUpdate={updateSong}
              />
            </SortableItem>
          ))}
        </SortableList>
      ) : (
        <SongUploadBox
          onUpload={addNewSongs}
          fileTypes={ALLOWED_MUSIC_FILETYPES.join(',')}
          multiple={true}
        />
      )}
    </div>
  );
}
