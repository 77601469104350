import { getCoverArtImage } from 'helpers/releases-helper';
import React from 'react';
import styles from './ClaimedSongInfo.module.scss';
import { ReactComponent as RightArrowIcon } from 'icons/right-outlined-icon.svg';
import { useTranslation } from 'react-i18next';

interface IClaimedSongInfoProps {
  songCoverArtId: string;
  songId: string;
  songName: string;
  songClaimerUserId: string;
  songClaimerUserName: string;
  songClaimerUserImage: string;
}

export default function ClaimedSongInfo({
  songCoverArtId,
  songId,
  songName,
  songClaimerUserId,
  songClaimerUserName,
  songClaimerUserImage
}: IClaimedSongInfoProps) {
  const { t } = useTranslation();
  const visitUserProfile = (type, id) => {
    window.open(`https://play.anghami.com/${type}/${id}`, '_blank');
  };

  return (
    <div className={styles.ClaimedSongInfo_wrapper}>
      <img
        className={styles.user_image}
        src={songClaimerUserImage}
        alt="claimed song user profile img"
      />
      <div className={styles.SongInfo_box}>
        <div className={styles.username_wrapper}>
          <div
            className={styles.user_name}
            onClick={() => {
              visitUserProfile('profile', songClaimerUserId);
            }}
          >
            {songClaimerUserName}
          </div>
          <RightArrowIcon className={styles.arrow} />
        </div>
        <div>
          <div className={styles.song_description}>{t('claimed song')}</div>
          <div
            className={styles.songInfo_flexbox}
            onClick={() => {
              visitUserProfile('song', songId);
            }}
          >
            <img src={getCoverArtImage(songCoverArtId)} alt="song covert art" />
            <div className={styles.song_name}>{songName}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
