import React from 'react';
import { IGetManagePodcastsItem } from '@anghami/neogateway/dist/endpoints/getManagePodcasts';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeactivateIcon } from 'icons/close.svg';
import { ReactComponent as EditIcon } from 'icons/edit.svg';
import { ReactComponent as RefetchLogo } from 'icons/retry.svg';
import { ReactComponent as ActivateIcon } from 'icons/tick.svg';
import styles from './RssActionsCell.module.scss';
import { combineClassNames } from 'helpers/styling-helper';
import { dialogsContext } from 'components/contexts/dialogs/toast/dialogs.context';
import InputDialog from 'components/Dialogs/InputDialog/inputDialog';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import useManagePodcasts from 'components/RssFeed/hooks/useManagePodcasts';

export default function RssActionsCell({
  rssItem,
  isActive
}: {
  rssItem: IGetManagePodcastsItem;
  isActive: boolean;
}) {
  const { t } = useTranslation();
  const { showDialog, closeDialog } = React.useContext(dialogsContext);
  const { deactivateMutation, activateMutation, editMutation, refetchMutation } = useManagePodcasts(
    {
      onSettledCallback: closeDialog
    }
  );

  const handleDeactivate = () => {
    showDialog({
      dialog: (
        <InputDialog
          title={t('could-you-please-tell-us-why-you-want-to-deactivate-your-feed')}
          onSubmit={(value: string) => deactivateMutation.mutate({ rssItem, reason: value })}
          buttonLabel={t('Deactivate')}
          isTextArea={true}
        />
      ),
      backdrop: true
    });
  };

  const handleActivate = () => {
    showDialog({
      dialog: (
        <ConfirmationDialog
          title={t('are-you-sure-you-want-to-activate-this-feed')}
          onSubmit={() => activateMutation.mutate({ rssItem })}
          submitButtonLabel={t('Activate')}
          cancelButtonLabel={t('Cancel')}
        />
      ),
      backdrop: true
    });
  };

  const handleEdit = () => {
    showDialog({
      dialog: (
        <InputDialog
          title={t('enter-your-new-podcast-rss-feed-url-for').replace('%@', rssItem.name)}
          onSubmit={(value: string) => editMutation.mutate({ rssItem, newUrl: value })}
          buttonLabel={t('Submit')}
          isTextArea={false}
          initialValue={rssItem.youtube_url || rssItem.url}
        />
      ),
      backdrop: true
    });
  };

  const handleRefetch = () => {
    refetchMutation.mutate({ rssItem });
  };

  return (
    <div className="w-100">
      <div>{rssItem.youtube_url || rssItem.url}</div>
      <div className="d-flex f-align-center gap-05 mt-1">
        {isActive ? (
          <div
            className={combineClassNames('d-flex f-align-center gap-025', styles.actionButton)}
            onClick={handleDeactivate}
          >
            <DeactivateIcon width={11} height={11} />
            <span>{t('Deactivate')}</span>
          </div>
        ) : (
          <div
            className={combineClassNames('d-flex f-align-center gap-025', styles.actionButton)}
            onClick={handleActivate}
          >
            <ActivateIcon width={13} height={13} />
            <span>{t('Activate')}</span>
          </div>
        )}

        <div
          className={combineClassNames('d-flex f-align-center gap-025', styles.actionButton)}
          onClick={handleEdit}
        >
          <EditIcon width={19} height={19} />
          <span>{t('Edit')}</span>
        </div>
        <div
          className={combineClassNames('d-flex f-align-center gap-025', styles.actionButton)}
          onClick={handleRefetch}
        >
          <RefetchLogo width={17} height={17} />
          <span>{t('Refetch')}</span>
        </div>
      </div>
    </div>
  );
}
