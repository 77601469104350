import React from 'react';
import { useTranslation } from 'react-i18next';
import EmailStatusImage from '../EmailStatusImage';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { gateway } from 'helpers/gateway-helper';
import postValidateEmailToken from '@anghami/neogateway/dist/endpoints/postValidateEmailToken';
import { getErrorMessage } from 'helpers/api-helper';
import { BeatLoader } from 'components/Reusable/Loaders';
import useLocalStorage from 'hooks/useLocalStorage';

const validateEmailToken = async (token: string) => {
  return gateway.callEndpoint(postValidateEmailToken, { validatetype: 'dashboard', token });
};

export default function ValidateEmail() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [, setEmailResentTimeSent] = useLocalStorage('resend_email_time_sent', 1);

  const {
    data: validationRes,
    isLoading,
    error
  } = useQuery(
    ['email-validation', token],
    async () => {
      const { data } = await validateEmailToken(token);
      return data;
    },
    {
      enabled: !!token,
      retry: 0,
      refetchOnWindowFocus: false
    }
  );
  const [title, subTitle, isFailed] =
    !validationRes || error || validationRes?.error
      ? [
          <span key="title" className="failure-color">
            {t('app.containers.HomePage.emailVerify.emailVerificationFailed')}
          </span>,
          <span key="success-failure-message">
            {token
              ? getErrorMessage(validationRes?.error, t('Something went wrong'))
              : t('No verification token provided')}
          </span>,
          true
        ]
      : [
          <span key="pending-title" className="pink-color">
            {t('app.containers.HomePage.emailVerify.emailVerificationSuccess')}
          </span>,
          <span key="pending-message">{t('artist_signup_pending_review')}</span>,
          false
        ];

  React.useEffect(() => {
    if (isFailed) return;
    setEmailResentTimeSent(1);
  }, [isFailed]);

  return (
    <div className={'h-100 f-column f-justify-center gap-05 p-2'}>
      <h1 className="mb-1">{t('Verify your email')}</h1>
      {isLoading ? (
        <BeatLoader />
      ) : (
        <>
          <EmailStatusImage status={isFailed ? 'error' : 'success'} />
          <h2 className="mt-1">{title}</h2>
          <h3>{subTitle}</h3>
        </>
      )}
    </div>
  );
}
