import Button from 'components/Reusable/Button';
import TextInput from 'components/Reusable/TextInput';
import { gateway } from 'helpers/gateway-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import updateDashboardUserStatus from '@anghami/neogateway/dist/endpoints/updateDashboardUserStatus';
import PhoneWithCountry from 'components/Reusable/PhoneWithCountry';
import { IPhoneWithCountryValue } from 'components/Reusable/PhoneWithCountry/phoneWithCountry';
import { toastContext } from 'components/contexts/toast/toast.context';
import { getErrorMessage } from 'helpers/api-helper';
import { ValidatePhoneNumber } from 'helpers/form-validator-helper';

export default function RejectedUserDialog({ closeAndLogout }: { closeAndLogout: () => void }) {
  const { t } = useTranslation();
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState<IPhoneWithCountryValue>();
  const [phoneError, setPhoneError] = React.useState('');
  const { openToast } = React.useContext(toastContext);
  const handleSubmitRequest = async () => {
    if (!name || !phone) return;
    if (!ValidatePhoneNumber(phone)) {
      setPhoneError(t('app.containers.HomePage.form.mobileInvalid'));
      return;
    }
    const res = await gateway.callEndpoint(updateDashboardUserStatus, {
      phone: phone.fullPhone,
      name,
      review: true
    });
    if (!res?.data || res.status !== 200) {
      openToast(
        t('There was an error submitting your request. Please try again or contact us for help'),
        'error'
      );
      closeAndLogout();
    }
    if (res.data.error) {
      openToast(
        getErrorMessage(
          res.data.error,
          t('There was an error submitting your request. Please try again or contact us for help')
        ),
        'error'
      );
      return;
    }
    openToast(t('Submit-user-status-request-success'), 'success');
    closeAndLogout();
  };
  return (
    <div className="f-column p-2 gap-05">
      <h1>{t('Review Account Info')}</h1>
      <p dangerouslySetInnerHTML={{ __html: t('review-account-dialog-desc') }} />
      <TextInput value={name} onChange={(e) => setName(e.target.value)} label={t('Name')} />
      <PhoneWithCountry
        value={phone}
        onChange={(val) => {
          setPhoneError('');
          setPhone(val);
        }}
        label={t('Phone Number')}
        customClasses={{
          phone: 'f-1'
        }}
      />
      {phoneError && <span className="d-block failure-color">{phoneError}</span>}
      <div className="d-flex f-align-center f-justify-end gap-1">
        <Button
          label={t('Logout')}
          customClasses={['default']}
          size="lg"
          onSubmit={closeAndLogout}
        />
        <Button
          label={t('submit request')}
          customClasses={['purpleFilled']}
          size="lg"
          disabled={!name || !phone || !phone.country || !phone.phone}
          onSubmit={handleSubmitRequest}
        />
      </div>
    </div>
  );
}
