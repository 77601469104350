import Tabs from 'antd/lib/tabs';
import React from 'react';
import styles from './changeRequests.module.scss';
import { ReactComponent as SongIcon } from 'icons/song.svg';
import { ReactComponent as ProfileIcon } from 'icons/profile.svg';
import { useTranslation } from 'react-i18next';
import { combineClassNames } from 'helpers/styling-helper';
import './override.scss';
import ProfileRequests from 'components/ChangeRequests/ProfileRequests';
import SongsRequests from 'components/ChangeRequests/SongsRequests';
import { useParams } from 'react-router-dom';
import { setPageTitle } from 'helpers/url-helper';
import { userContext } from 'components/contexts/auth/auth.context';
import FilterWrapper from 'components/ChangeRequests/FilterWrapper';

export default function ChangeRequestsPage() {
  const { t } = useTranslation('common');
  const { type } = useParams();
  const { permissions } = React.useContext(userContext);
  const ref = React.useRef();
  React.useEffect(() => {
    setPageTitle('Change Requests');
  }, []);
  return (
    <div ref={ref} className={styles.pageContainer}>
      <h1>{t('Change Requests')}</h1>
      <Tabs
        className="f-grow-1 h-100"
        defaultActiveKey={type && type === 'songs' ? '2' : '1'}
        items={[
          ...(permissions?.manage_artist_profile
            ? [
                {
                  label: (
                    <span
                      className={combineClassNames(
                        'd-flex f-justify-center f-align-center gap-05',
                        styles.tabItem
                      )}
                    >
                      <ProfileIcon width={20} height={20} />
                      {t('Profile Changes')}
                    </span>
                  ),
                  key: '1',
                  children: (
                    <FilterWrapper>
                      <ProfileRequests parentElement={ref?.current} />
                    </FilterWrapper>
                  )
                }
              ]
            : []),
          {
            label: (
              <span
                className={combineClassNames(
                  'd-flex f-justify-center f-align-center gap-05',
                  styles.tabItem
                )}
              >
                <SongIcon width={15} height={15} />
                {t('Content Changes')}
              </span>
            ),
            key: '2',
            children: (
              <FilterWrapper>
                <SongsRequests parentElement={ref?.current} />
              </FilterWrapper>
            )
          }
        ]}
      />
    </div>
  );
}
