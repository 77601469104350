// eslint-disable-next-line @typescript-eslint/no-var-requires
const pSBC = require('shade-blend-color').default;

export const colorDarkenLighten = (color: string, index: number, length: number) => {
  // if (length >= 15) {
  //   return pSBC(((index - (length - 6)) * 2) / (4 * length), color);
  // }

  // if (length >= 6) {
  //   return pSBC(((index - (length - 6)) * 2) / (2 * length), color);
  // }

  return pSBC(index / length, color);
};
