import React from 'react';
import styles from './actionsMenu.module.scss';
import { combineClassNames } from 'helpers/styling-helper';
import { AVAILABLE_ACTIONS } from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import ActionItem from './actionItem';
import ActionSheet from './actionSheet';
export interface IActionMenuProps {
  actions: AVAILABLE_ACTIONS[];
  onActionClick: (action: AVAILABLE_ACTIONS) => void;
}

export default function ActionsMenu({ actions, onActionClick }: IActionMenuProps) {
  return (
    <div className={combineClassNames('d-flex f-align-center', styles.actionsMenuContainer)}>
      {actions?.length > 0 ? (
        <>
          {actions.slice(0, 3).map((action) => (
            <ActionItem
              key={action}
              action={action}
              onActionClick={onActionClick}
              customClassName={styles.actionItemInline}
            />
          ))}
          {actions?.length > 3 ? (
            <ActionSheet actions={actions.slice(3)} onActionClick={onActionClick} />
          ) : null}
        </>
      ) : null}
    </div>
  );
}
