import { TimePeriods } from '@anghami/neogateway/dist/endpoints/getAnalyticsData';
import i18n from 'i18n';
const { t } = i18n;

export interface IPeriodValue {
  displayname: string;
  graphName: string;
  value: TimePeriods;
  from?: string;
  to?: string;
}

export const getAnalyticsPeriods = (): { [key in TimePeriods]: IPeriodValue } => ({
  [TimePeriods.YESTERDAY]: {
    displayname: t('Yesterday'),
    graphName: t('of yesterday'),
    value: TimePeriods.YESTERDAY
  },
  [TimePeriods.THIS_WEEK]: {
    displayname: t('This Week'),
    graphName: t('in this current week'),
    value: TimePeriods.THIS_WEEK
  },
  [TimePeriods.LAST_SEVEN_DAYS]: {
    displayname: t('Last 7 days'),
    graphName: t('in the last 7 days'),
    value: TimePeriods.LAST_SEVEN_DAYS
  },
  [TimePeriods.LAST_THIRTY_DAYS]: {
    displayname: t('Last 30 days'),
    graphName: t('in the last 30 days'),
    value: TimePeriods.LAST_THIRTY_DAYS
  },
  [TimePeriods.LAST_MONTH]: {
    displayname: t('Last Month'),
    graphName: t('in last month'),
    value: TimePeriods.LAST_MONTH
  },
  [TimePeriods.LAST_SIX_MONTH]: {
    displayname: t('Last 6 months'),
    graphName: t('in the last 6 months'),
    value: TimePeriods.LAST_SIX_MONTH
  },
  [TimePeriods.LAST_YEAR]: {
    displayname: t('Last year'),
    graphName: t('in the last year'),
    value: TimePeriods.LAST_YEAR
  },
  [TimePeriods.THIS_YEAR]: {
    displayname: t('This Year'),
    graphName: t('in this current year'),
    value: TimePeriods.THIS_YEAR
  },
  [TimePeriods.CUSTOM]: {
    displayname: t('Custom range'),
    graphName: t('in the custom date range'),
    value: TimePeriods.CUSTOM
  }
});
