import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VideoIcon } from 'icons/video-filled.svg';
import { dialogsContext } from 'components/contexts/dialogs/toast/dialogs.context';
import UploadVideoDialog from 'components/Dialogs/UploadVideoDialog';
import Button from 'components/Reusable/Button';
import styles from './videoContentCell.module.scss';
import { IReleaseItem } from '@anghami/neogateway/dist/endpoints/getUserLibrary';

export default function VideoContentCell({
  hasVideo,
  video,
  showUploadButton,
  songId,
  isPodcast
}: {
  hasVideo: boolean;
  video: IReleaseItem['video'];
  showUploadButton: boolean;
  songId: string;
  isPodcast?: boolean;
}) {
  const { t } = useTranslation('common');
  const { showDialog } = React.useContext(dialogsContext);
  const handleUploadVideoClick = () => {
    showDialog({
      dialog: (
        <UploadVideoDialog songId={songId} video={video} isPodcast={isPodcast}></UploadVideoDialog>
      ),
      backdrop: true,
      customClassName: styles.videoDialog,
      hasCloseIcon: true
    });
  };

  const [text, buttonText] = hasVideo
    ? [t('Available'), t('Edit')]
    : [t('Not Available'), t('Upload')];

  return (
    <div>
      <div className="d-flex f-column">
        {showUploadButton ? (
          <>
            <span className="d-block">{text}</span>
            <Button
              label={buttonText}
              icon={<VideoIcon width={16} height={16} />}
              size="xsm"
              customClasses={['default', 'fullWidth', styles.uploadVideoButton]}
              onSubmit={handleUploadVideoClick}
            />
          </>
        ) : (
          <span className="d-block">{t('N/A')}</span>
        )}
      </div>
    </div>
  );
}
