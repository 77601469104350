// Amplitude Events
export const OPEN_APP = 'User opened app';
export const USER_LOGIN_SUCCESS = 'User Log in success';
export const USER_LOGIN_FAIL = 'User Log in Failed';
export const PERFORM_SEARCH_ARTIST = 'Claim artist - User searched for artist';
export const SELECT_SEARCHED_ARTIST = 'Claim artist - User clicked on searched artist';
export const USER_CLICKED_BUTTON = 'User clicked button';
export const USER_CREATE_ACCOUNT_SUCCESS = 'User created account success';
export const USER_CREATE_ACCOUNT_FAIL = 'User created account failed';
export const EMAIL_VALIDATION_RESEND = 'Create Account, Email validation resend click';
export const MOBILE_VALIDATION_CONTINUE = 'Create Account, mobile validation continue click';
export const PHONE_VERIFICATION_BUTTON_CLICK = 'Phone verification button click';
export const SELECT_USER_TYPE = 'Select user type';

export const UPLOAD_AUDIO_FILE = 'Upload audio file';
export const UPLOAD_IMAGE = 'Upload image';
export const SAVE_AS_DRAFT = 'Save as draft';
export const CLICK_LIBRARY_ACTION_BUTTON = 'Click on library action button';
export const OPEN_ANALYTICS = 'Open analytics';
export const UPLOAD = 'Upload';
export const CLICKED_ON_CREATE_RELEASE = 'click on create new release';
export const SELECT_CONTENT_TYPE = 'Select content type';
export const SELECT_RELEASE_TYPE = 'Select release type';
export const SAVE_AND_PROCEED = 'Save and proceed';
export const SUBMIT = 'Submit';
export const GO_TO_ALBUM_VIEW = 'Go to album view';
export const LOGIN_SUCCESSFUL = 'Login successful';
export const LOGIN_ERROR = 'Login error';
export const AUTHENTICATE = 'Authenticate';
export const REGISTER = 'Register';
export const VIEW_CONTRACT_SIGNING = 'View contract signing ';
export const SUBMIT_CONTRACT_SIGNING = 'Submit contract signing';
export const GO_TO_TAB = 'Go to tab';
export const VIEW_ROSTER = 'View roster';
export const JOIN_BETA = 'JOIN_BETA';

export const SETUP_PITCH_PLAYLIST = 'setup pitch to playlist';
export const SUBMIT_PITCH_PLAYLIST = 'submit pitch to playlist';
export const CLICK_CREATE_PROMOTION_REQUEST = 'click to create promotion request ';
export const PROMOTION_SUBMIT_PITCH_PLAYLIST = 'change phone number';
export const SUBMIT_PROMOTION_REQUEST = 'submit promotion request';

export const GENERAL_ERROR_EVENT = 'Error Reported';
