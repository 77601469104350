import LoaderContainer from 'components/Reusable/Loaders/loaderContainer';
import React, { ReactElement } from 'react';
import { loaderContext } from './loader.context';

interface IProps {
  children?: any;
  loaderComponent?: ReactElement;
}

const LoaderWrapper = ({ children, loaderComponent }: IProps) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  return (
    <loaderContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
      {isLoading && <LoaderContainer>{loaderComponent}</LoaderContainer>}
    </loaderContext.Provider>
  );
};

export default LoaderWrapper;
