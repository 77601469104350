import React from 'react';
import styles from './create.module.scss';
import { useTranslation } from 'react-i18next';
import ReleaseHeader from 'components/Landing/headers/release';
import colors from 'styles/partials/_colors_exports.module.scss';
import { combineClassNames } from 'helpers/styling-helper';
import { useMutation } from 'react-query';
import { toastContext } from 'components/contexts/toast/toast.context';
import { loaderContext } from 'components/contexts/loader/loader.context';
import { getEncodedQueryParam, setPageTitle } from 'helpers/url-helper';
import {
  ICreateRSSShowFormValue,
  IFormFields,
  ISection
} from 'interfaces/formsComponents.interface';
import {
  getRssShowFormSectionsAndFields,
  getUpdatedFormFields
} from 'helpers/create-release-helper';
import Form from 'components/Reusable/Form';
import { convertFormValuesToFields } from 'helpers/form-helper';
import { useNavigate, useSearchParams } from 'react-router-dom';
import managePodcasts from 'helpers/manage-podcasts-helper';
import { getErrorMessage } from 'helpers/api-helper';
import { userContext } from 'components/contexts/auth/auth.context';

export default function CreateShowFromRSSPage() {
  const { setIsLoading } = React.useContext(loaderContext);
  const { openToast } = React.useContext(toastContext);
  const { t } = useTranslation('common');
  const { user } = React.useContext(userContext);
  const containerRef: React.LegacyRef<HTMLDivElement> = React.useRef();
  const [formValues, setFormValues] = React.useState<ICreateRSSShowFormValue>({});
  const [formFields, setFormFields] = React.useState<IFormFields>(null);
  const [formSections, setFormSections] = React.useState<ISection[]>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const mutation = useMutation(
    () => {
      if (!formValues) return;
      return managePodcasts.addServer({
        server: formValues.rssLink,
        languageid: formValues.languageid,
        category: formValues.categoryValue.name,
        categoryid: formValues.categoryValue.id,
        artistid: Number(user.d_artist.id),
        artistname: user.d_artist.name
      });
    },
    {
      retry: 3,
      mutationKey: 'managepodcasts-addserver',
      onError: (err: any) => {
        console.log('🔴 ~ managePodcasts add server Error --', err);
        openToast(getErrorMessage(err?.message, t('Something went wrong')), 'error');
      },
      onSuccess: (data) => {
        if (!data) return;
        if (data.error) {
          openToast(getErrorMessage(data.error, t('Failed to add rss url')), 'error');
          return;
        }
        if (data.response && typeof data.response === 'string') {
          openToast(data.response, 'success');
        }
        navigate('/library/rss');
      },
      onSettled: () => {
        setIsLoading(false);
      }
    }
  );

  React.useEffect(() => {
    setPageTitle('Upload Show RSS');
    resetForm();
  }, []);

  const handleFormDataChange = (newFormFields: IFormFields, fieldName: string) => {
    const tempFormFields: IFormFields = getUpdatedFormFields(newFormFields, fieldName);
    setFormFields(tempFormFields);
    setFormValues({
      ...formValues,
      [fieldName]: tempFormFields[fieldName].value,
      ...(tempFormFields[fieldName].extraValueParam && {
        [tempFormFields[fieldName].extraValueParam]: tempFormFields[fieldName].extraValue
      })
    });
  };

  const validateForm = () => {
    // in case there's validation later
    return true;
  };

  const fillFormFields = () => {
    const queryParamsForm = getEncodedQueryParam(searchParams.get('form'));
    const newFormValues: ICreateRSSShowFormValue = {
      languageid: 2,
      ...queryParamsForm
    };
    const { formSections, formFields: newFormFields } = getRssShowFormSectionsAndFields();
    // if (data) {
    //   newFormValues = extractSingleFormValuesFromApiData({ ...newFormValues, ...data }, 1);
    // }
    setFormSections(formSections);
    setFormValues(newFormValues);
    setFormFields(convertFormValuesToFields(newFormValues, newFormFields));
  };
  const resetForm = () => {
    const { formSections, formFields } = getRssShowFormSectionsAndFields();
    setFormSections(formSections);
    setFormFields(formFields);
    setFormValues({});
    fillFormFields();
  };

  const onProceedAction = () => {
    setIsLoading(true);
    const isValid = validateForm();
    if (!isValid) {
      setIsLoading(false);
      return;
    }
    mutation.mutate();
  };

  return (
    <>
      <div
        ref={containerRef}
        className={combineClassNames(styles.main_container, 'f-column f-grow-1')}
      >
        <div className={styles.fixed_header}>
          <ReleaseHeader
            title={t('Add your RSS feed')}
            description={t('Show release')}
            smallerPadding={true}
            actionButtons={[
              {
                label: t('Proceed'),
                withBackground: colors['branding-purple'],
                size: 'lg',
                disabled: !formValues.categoryValue || !formValues.rssLink,
                onSubmit: onProceedAction
              }
            ]}
          />
        </div>
        <div>
          <Form
            id={`show-form-${formValues?.albumid}`}
            customClasses={{ container: 'px-2' }}
            sections={formSections}
            formValues={formFields}
            setFormValues={handleFormDataChange}
          />
        </div>
      </div>
    </>
  );
}
