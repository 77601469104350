/* eslint no-useless-escape:0 */
import { IPhoneWithCountryValue } from 'components/Reusable/PhoneWithCountry/phoneWithCountry';
import { SELECT_USER_TYPE } from 'constants/amplitudeEvents';
import { isValidPhoneNumber } from 'libphonenumber-js';

// have one @, at least one character before @, before the period and after it and no whitespaces
export const ValidateEmail = (mail = '') => /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(mail);

export const ValidatePassword = (password: string) => {
  //  _ not contain white spaces.
  //  _ be at least 8 chars long.
  //  _ contain at least 2 digits.
  //  _ contain at least 2 letters.
  //  _ contain a mix of letters and numbers.
  if (password.search(' ') > -1) {
    return 'password-should-not-contain-white-spaces';
  }
  if (password.length < 8) {
    return 'password-should-be-at-least-8-chars-long';
  }
  if (password.replace(/[^0-9]/g, '').length < 2) {
    return 'password-should-containe-at-least-2-digits';
  }
  if (password.replace(/[^a-zA-Z]/g, '').length < 2) {
    return 'password-should-at-least-contain-2-letters';
  }
  return '';
};

export const ValidateRegisterFormFirstStep = (
  formData: { usertype?: number; artistname?: any; isexistinglabel?: any },
  formInfo: {
    userType?: { name: string | number };
    artistName?: { name: string | number };
    isExistingLabel?: { name: string | number };
  },
  logAmplitudeEvent: (arg0: any, arg1: { type: any }) => void,
  fieldrequiredMessage: any
) => {
  const error = {};
  let errorExistFirstStep = false;
  if (!formData?.usertype) {
    error[formInfo.userType.name] = fieldrequiredMessage;
    errorExistFirstStep = true;
  } else {
    logAmplitudeEvent(SELECT_USER_TYPE, {
      type: formData?.usertype
    });
  }
  if (!formData?.artistname) {
    error[formInfo.artistName.name] = fieldrequiredMessage;
    errorExistFirstStep = true;
  }
  if (formData?.usertype === 3 && !formData.isexistinglabel) {
    error[formInfo.isExistingLabel.name] = fieldrequiredMessage;
    errorExistFirstStep = true;
  }
  return {
    errorExistFirstStep,
    error
  };
};

export const ValidateRegisterFormSecondStep = (
  formData: {
    firstname?: any;
    lastname?: any;
    email?: string | undefined;
    password?: string;
    confirmpassword?: any;
  },
  formInfo: {
    firstName?: { name: any };
    lastName?: { name: string | number };
    email?: { name: string | number };
    phoneNumber?: { name: string | number };
    password?: { name: string | number };
    confirmPassword?: { name: string | number };
  },
  errors: { [x: string]: any },
  errorMessages: {
    fieldrequired: any;
    passwordsdontmatch: any;
    emailinvalid: any;
    mobileinvalid: any;
  }
) => {
  const error = {};
  let errorExistSecondStep = false;
  error[formInfo.firstName.name] = !formData.firstname ? errorMessages.fieldrequired : '';
  error[formInfo.lastName.name] = !formData.lastname ? errorMessages.fieldrequired : '';
  error[formInfo.email.name] = !formData.email ? errorMessages.fieldrequired : '';
  error[formInfo.phoneNumber.name] = !formData[formInfo.phoneNumber.name]
    ? errorMessages.fieldrequired
    : '';
  error[formInfo.password.name] = !formData.password
    ? errorMessages.fieldrequired
    : ValidatePassword(formData.password);
  error[formInfo.confirmPassword.name] = !formData.confirmpassword
    ? errorMessages.fieldrequired
    : formData.password !== formData.confirmpassword
    ? errorMessages.passwordsdontmatch
    : '';
  error[formInfo.email.name] = !ValidateEmail(formData?.email) ? errorMessages.emailinvalid : '';
  error[formInfo.phoneNumber.name] = !ValidatePhoneNumber(formData[formInfo.phoneNumber.name])
    ? errorMessages.mobileinvalid
    : '';
  if (Object.values(error).filter(Boolean).length > 0) {
    errorExistSecondStep = true;
  }
  return {
    error: { ...errors, ...error },
    errorExistSecondStep
  };
};

export const ValidatePhoneNumber = (phoneObject: IPhoneWithCountryValue): boolean => {
  if (phoneObject?.fullPhone) {
    let valid = isValidPhoneNumber(String(phoneObject.fullPhone));
    if (!valid) {
      let countrycode = phoneObject?.country?.toString() || '';
      const exp = new RegExp(/(\+00|00|\+|)[0-9]{1,4}/);
      // handle lebanese numbers when it's 03
      if (exp.test(countrycode)) {
        if (countrycode.slice(0, 2) === '00') {
          countrycode = `+${countrycode.slice(2, countrycode.length)}`;
          return ValidatePhoneNumber({
            ...phoneObject,
            country: countrycode,
            fullPhone: countrycode + phoneObject.phone
          });
        }
        if (countrycode.slice(0, 3) === '+00') {
          countrycode = `+${countrycode.slice(3, countrycode.length)}`;
          return ValidatePhoneNumber({
            ...phoneObject,
            country: countrycode,
            fullPhone: countrycode + phoneObject.phone
          });
        }
      }
      if (countrycode.slice(0, 1) !== '+' && new RegExp(/^\d+$/).test(countrycode)) {
        countrycode = `+${countrycode}`;
        return ValidatePhoneNumber({
          ...phoneObject,
          country: countrycode,
          fullPhone: countrycode + phoneObject.phone
        });
      }
      if (
        phoneObject.country === '+961' &&
        phoneObject.phone?.length === 7 &&
        phoneObject.phone[0] === '3'
      ) {
        valid = true;
      }
    }
    return valid;
  }
  return false;
};
