// TODO: rename component to Container
import React from 'react';
import { CONTENT_TYPES } from '@anghami/neogateway/dist/endpoints/getUserLibrary';
import TableHeader from 'components/Reusable/TableHeader';
import { IAlbumInfo, IArtistInfo } from '../Context/library.context';
import ReleasesTableHOC from '../LibraryTableHOC/releasesTableHOC';
import styles from './libraryTabsHoc.module.scss';

interface ILibraryTabsWrapperProps {
  contentType: CONTENT_TYPES;
  albumInfo?: IAlbumInfo;
  artistInfo?: IArtistInfo;
  isDraft?: boolean;
}

export default function LibraryTabsWrapper({
  contentType,
  albumInfo,
  artistInfo,
  isDraft
}: ILibraryTabsWrapperProps) {
  return (
    <div className={styles.tabs_container}>
      {albumInfo?.album_id && (
        <>
          <TableHeader albumName={albumInfo.album_name}></TableHeader>
        </>
      )}
      <ReleasesTableHOC
        contentType={contentType}
        isDraft={isDraft}
        artistInfo={artistInfo}
        albumId={albumInfo?.album_id}
      />
    </div>
  );
}
