export interface freeDataTableItem {
  [key: string]: string;
  summaryValue: string;
  label: string;
  key: string;
}

export enum TablesTypes {
  free = 'free',
  premium = 'premium',
  telco = 'telco',
  total = 'total'
}

export type TableColumnType = {
  title: string;
  width: string;
  dataIndex: string;
  key: string;
  fixed?: string;
  render: (value: any, data: any) => JSX.Element;
};
