import React, { useState } from 'react';
import { combineClassNames } from 'helpers/styling-helper';
import { IAdditionalInfo } from 'interfaces/formsComponents.interface';
import Tooltip from '../Tooltip';
import styles from './withLabelWrapper.module.scss';
import { ReactComponent as InfoIcon } from 'icons/info.svg';

interface InputWithLabelProps {
  label?: string;
  extraLabel?: string;
  additionalInfo?: IAdditionalInfo;
  description?: string;
  error?: string;
  customLabelClass?: string;
  children: React.ReactNode;
  showErrorText?: boolean;
}

const ADDITIONAL_INFO_ICON_COLOR = '#a1a5ac';

export default function WithLabelWrapper({
  children,
  label,
  description,
  customLabelClass,
  error,
  additionalInfo,
  extraLabel,
  showErrorText
}: InputWithLabelProps) {
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  return (
    <div className={styles.container}>
      <div
        className={combineClassNames(
          'd-flex',
          'f-align-center',
          'position-relative',
          additionalInfo?.customClass || 'f-justify-between'
        )}
      >
        <div className="d-flex f-align-center">
          <div
            className={combineClassNames(styles.label, customLabelClass, error ? styles.error : '')}
          >
            {label}
          </div>
          {extraLabel && <div className={styles.input_attribute}>({extraLabel})</div>}
        </div>
        {additionalInfo && (
          <div>
            <div
              className={combineClassNames('d-flex', 'f-column-reverse', styles.icon_wrapper)}
              onMouseEnter={() => {
                setShowInfoTooltip(true);
              }}
              onMouseLeave={() => {
                setShowInfoTooltip(false);
              }}
            >
              <InfoIcon fill={ADDITIONAL_INFO_ICON_COLOR} width={25} height={25} />
              <Tooltip isOpen={showInfoTooltip}>
                <div>{additionalInfo?.text}</div>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
      {description && <div className={styles.description}>{description}</div>}
      {children}
      {error && showErrorText && <div className={styles.input_form_err}>{error}</div>}
    </div>
  );
}
