import React from 'react';
import { format } from 'date-fns/esm';

interface IReleaseDateCellProps {
  timestamp: string;
  hideTime?: boolean;
}

export default function ReleaseDateCell({ timestamp, hideTime }: IReleaseDateCellProps) {
  if (!timestamp) return <></>;
  return <div>{format(new Date(Number(timestamp)), hideTime ? 'PP' : 'PPpp')}</div>;
}
