import React from 'react';
import { colorDarkenLighten } from 'helpers/color-darken-lighten';
import styles from './percentageChart.module.scss';
import { IEnhancedSectionData } from '../../interfaces';
import SectionItemHeader from '../SectionItemHeader/sectionItemHeader';
import { useTrackVisibility } from 'react-intersection-observer-hook';
import NoData from 'components/Reusable/NoData';

const PercentageChart = ({ data, color, title, allTimeData }: IEnhancedSectionData) => {
  const [ref, { isVisible, wasEverVisible }] = useTrackVisibility();

  return (
    <div className={styles.HorizontalChart} ref={ref}>
      <SectionItemHeader title={title} allTimeData={allTimeData} />
      {data.length === 0 ? (
        <NoData allTimeData={allTimeData} />
      ) : (
        <>
          <div className={styles.bars}>
            {data.map((item, index) => (
              <span
                key={item.key}
                className={styles.bar}
                style={{
                  width:
                    wasEverVisible || isVisible ? `${Number(item.value) > 5 ? item.value : 5}%` : 0,
                  backgroundColor: colorDarkenLighten(color || '#DF4EE5', index, data.length)
                }}
              />
            ))}
          </div>
          <div className={styles.titles}>
            {data.map((item, index) => (
              <span
                key={item.key}
                className={styles.title}
                style={{
                  width: `${Number(item.value) > 5 ? item.value : 5}%`,
                  opacity: wasEverVisible || isVisible ? 1 : 0,
                  transitionDelay: index * 300 + 'ms'
                }}
              >
                <div className={styles.header}>{item.key}</div>
                <div className={styles.text}>
                  {item.value}
                  {item.unit || '%'}
                </div>
              </span>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default PercentageChart;
