import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IArtistRequest } from '@anghami/neogateway/dist/endpoints/getPendingArtistsRequests';
import styles from './artistRequestItem.module.scss';
import RequestStatusField from '../RequestStatusField';
import LinkItem from 'components/Reusable/LinkItem';

const ArtistRequestItem = ({ request }: { request: IArtistRequest }) => {
  const { t } = useTranslation('common');
  return (
    <div className={combineClassNames('d-flex', styles.artistItemContainer)}>
      <div className={combineClassNames('f-1', styles.section)}>
        <div className={styles.firstSectionItem}>
          <h3>{t('Request ID')}</h3>
          <p>{request.id}</p>
        </div>
        <div className={styles.firstSectionItem}>
          <h3>{t('Requested on')}</h3>
          <p>{request.createdon}</p>
        </div>
        <div className={styles.firstSectionItem}>
          <h3>{t('Artist ID')}</h3>
          <LinkItem
            link={`https://play.anghami.com/artist/${request.artistid}`}
            text={request.artistid}
            newTab={true}
          />
        </div>
        <div className={styles.firstSectionItem}>
          <h3>{t('Dashboard User ID')}</h3>
          <p>{request.userid}</p>
        </div>
        <div className={styles.firstSectionItem}>
          <h3>{t('Status')}</h3>
          <RequestStatusField status={request.status} />
        </div>
      </div>
      <div className={combineClassNames('f-1 f-column gap-1', styles.section)}>
        <div>
          <div className="d-flex f-align-center gap-05 mb-1">
            <h3 className={styles.uppercaseHeader}>{t('Changes')}</h3>
          </div>
          {request.languageid && (
            <div
              className={combineClassNames('d-flex f-align-center gap-2', styles.secondSectionItem)}
            >
              <h4>{t('Artist Language')} </h4>
              <div>
                <p>{request.languageid == 1 ? t('Arabic') : t('International')}</p>
              </div>
            </div>
          )}
          {request.Name && (
            <div
              className={combineClassNames('d-flex f-align-center gap-2', styles.secondSectionItem)}
            >
              <h4>{t('Artist name')} </h4>
              <div>
                <p>{request.Name}</p>
              </div>
            </div>
          )}
          {request.NameAr && (
            <div
              className={combineClassNames('d-flex f-align-center gap-2', styles.secondSectionItem)}
            >
              <h4>{t('Arabic artist name')}: </h4>
              <div>
                <p>{request.NameAr}</p>
              </div>
            </div>
          )}
          {request.About && (
            <div
              className={combineClassNames('d-flex f-align-center gap-2', styles.secondSectionItem)}
            >
              <h4>{t('Bio')}: </h4>
              <div>
                <p>{request.WikiEn}</p>
              </div>
            </div>
          )}
          {request.WikiAr && (
            <div
              className={combineClassNames('d-flex f-align-center gap-2', styles.secondSectionItem)}
            >
              <h4>{t('Arabic bio')}: </h4>
              <div>
                <p>{request.WikiAr}</p>
              </div>
            </div>
          )}
          {request.delete_bio !== 'None' && (
            <div
              className={combineClassNames('d-flex f-align-center gap-2', styles.secondSectionItem)}
            >
              <h4>{t('Delete bio')}: </h4>
              <div>
                <p>
                  {request.delete_bio == 'both' ? 'Arabic and International' : request.delete_bio}
                </p>
              </div>
            </div>
          )}
          {request.FacebookLink && request.FacebookLink !== 'null' && (
            <div
              className={combineClassNames('d-flex f-align-center gap-2', styles.secondSectionItem)}
            >
              <h4>{t('Facebook page')}: </h4>
              <div>
                <p>facebook.com/{request.FacebookLink}</p>
              </div>
            </div>
          )}
          {request.UseUploadedCover && (
            <div
              className={combineClassNames('d-flex f-align-center gap-2', styles.secondSectionItem)}
            >
              <h4>{t('Use uploaded image')}: </h4>
              <div>
                <p>{request.UseUploadedCover == 1 ? t('Yes') : t('No')}</p>
              </div>
            </div>
          )}
          {request.ShowOnLive && (
            <div
              className={combineClassNames('d-flex f-align-center gap-2', styles.secondSectionItem)}
            >
              <h4>{t('Use Facebook image')}: </h4>
              <div>
                <p>{Number(request.ShowOnLive) === 1 ? t('Yes') : t('No')}</p>
              </div>
            </div>
          )}
          {request.TwitterLink && request.TwitterLink !== 'null' && (
            <div
              className={combineClassNames('d-flex f-align-center gap-2', styles.secondSectionItem)}
            >
              <h4>{t('Twitter account')}: </h4>
              <div>
                <p>twitter.com/{request.TwitterLink} </p>
              </div>
            </div>
          )}
          {request.InstagramLink && request.TwitterLink !== 'null' && (
            <div
              className={combineClassNames('d-flex f-align-center gap-2', styles.secondSectionItem)}
            >
              <h4>{t('Instagram account')} </h4>
              <div>
                <p>instagram.com/{request.InstagramLink} </p>
              </div>
            </div>
          )}
          {request.phone && (
            <div
              className={combineClassNames('d-flex f-align-center gap-2', styles.secondSectionItem)}
            >
              <h4>{t('Phone')} </h4>
              <div>
                <p>{request.phone}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArtistRequestItem;
