import React from 'react';
import colors from 'styles/partials/_colors_exports.module.scss';
import Button from 'components/Reusable/Button';
import { combineClassNames } from 'helpers/styling-helper';
import styles from './downloadSection.module.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { environment } from 'env/environment';

const DOWNLOAD_LINKS = {
  android:
    'https://play.google.com/store/apps/details?id=com.anghami.anghamicreators&hl=en&gl=US&pli=1',
  ios: 'https://apps.apple.com/ba/app/anghami-for-artists/id1501491257'
};

function DownloadSection() {
  const { t } = useTranslation();
  return (
    <div className={styles.sectionContainer}>
      <div
        className={combineClassNames(
          'd-flex',
          'f-justify-center',
          'f-align-center',
          styles.sectionContent
        )}
      >
        <div className={styles.sectionTitle}>{`${t('creators.landing.section7.app.title')}`}</div>

        <div className={combineClassNames('d-flex', styles.buttonsContainer)}>
          <Link target="_blank" to={DOWNLOAD_LINKS['ios']}>
            <Button
              noCapitalize
              label={`${t('creators.landing.section7.app.apple.cta')}`}
              withBackground={colors['dark-3']}
              size="lg"
              icon={
                <img
                  src={`${environment.creators_assets_cdn}/images/apple.png`}
                  alt="ios_logo"
                  width={15}
                  height={18}
                />
              }
              customClasses={[styles.downloadButton]}
            />
          </Link>
          <Link target="_blank" to={DOWNLOAD_LINKS['android']}>
            <Button
              label={`${t('creators.landing.section7.app.android.cta')}`}
              withBackground={colors['dark-3']}
              size="lg"
              icon={
                <img
                  src={`${environment.creators_assets_cdn}/images/android.png`}
                  alt="android_logo"
                  width={15}
                  height={18}
                />
              }
              customClasses={[styles.downloadButton]}
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default DownloadSection;
