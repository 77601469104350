import WithLabelWrapper from 'components/Reusable/WithLabelWrapper';
import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import common from '../common.module.scss';
import { IInputWithLabel } from 'interfaces/formsComponents.interface';
import styles from './textArea.module.scss';

interface TextAreaProps extends Omit<IInputWithLabel, 'value' | 'onChange'> {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  placeholder?: string;
  error?: string;
  customInputClass?: string;
  allowResize?: boolean;
  height?: string;
  width?: string;
  required?: boolean;
}

export default function TextArea(props: TextAreaProps) {
  const {
    placeholder,
    onChange,
    value,
    error,
    disabled,
    customInputClass,
    allowResize,
    height,
    width,
    required
  } = props;
  return (
    <WithLabelWrapper {...props}>
      <textarea
        style={{ resize: allowResize ? 'both' : 'none', width: width, height: height }}
        className={combineClassNames(
          common.input,
          styles.input,
          error ? common.error : '',
          customInputClass || ''
        )}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        disabled={disabled}
        required={required}
      />
    </WithLabelWrapper>
  );
}
