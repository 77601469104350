import Button from 'components/Reusable/Button';
import TextInput from 'components/Reusable/TextInput';
import React from 'react';
import styles from './signContractDialog.module.scss';
import { useMutation, useQuery } from 'react-query';
import { gateway } from 'helpers/gateway-helper';
import signContract from '@anghami/neogateway/dist/endpoints/signContract';
import downloadContract from '@anghami/neogateway/dist/endpoints/downloadContract';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { combineClassNames } from 'helpers/styling-helper';
import { useTranslation } from 'react-i18next';
import { userContext } from 'components/contexts/auth/auth.context';
import LinkItem from 'components/Reusable/LinkItem';
import { toastContext } from 'components/contexts/toast/toast.context';
import { ReactComponent as LoaderIcon } from 'icons/loaders/tail-spin.svg';
import { SUBMIT_CONTRACT_SIGNING } from 'constants/amplitudeEvents';
import { logAmplitudeEvent, logGeneralErrorEvent } from 'helpers/analytics-helper';
import { getErrorCode } from 'helpers/api-helper';
interface ISignContractDialogProps {
  closeDialog?: () => void;
  onSuccessFn?: () => void;
}
export default function SignContractDialog({ closeDialog, onSuccessFn }: ISignContractDialogProps) {
  const [signature, setSignature] = React.useState('');
  const { t } = useTranslation('common');
  const { user, setUser } = React.useContext(userContext);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(null);
  const { openToast } = React.useContext(toastContext);
  const {
    data: agreementLink,
    isLoading: agreementLoading,
    isError: isAgreementFailed
  } = useQuery(['view-agreement'], async () => {
    const { data } = await gateway.callEndpoint(downloadContract, { view: '1' });
    if (!data || data.error) {
      throw new Error(data?.error?.message || t('unable to get contract'));
    }
    return data.url;
  });
  const mutation = useMutation(
    () => {
      return gateway.callEndpoint(signContract, { signature, output: 'jsonhp' });
    },
    {
      onSettled: (data, error) => {
        setIsLoading(false);
        if (!data?.data || error) {
          logGeneralErrorEvent(error || 'no data', 'SignContractDialog signContract');
          setErrorMessage(t('something-went-wrong-try-again'));
          return;
        }
        const errorCode = getErrorCode(data.data.error);
        if (data.data.error && errorCode !== 1999) {
          setErrorMessage(data.data.error.message || t('something-went-wrong-try-again'));
          return;
        }
        setUser({ ...user, d_statusid: '3' });
        logAmplitudeEvent(SUBMIT_CONTRACT_SIGNING, {});
        openToast(t('Agreement signed succesfully'), 'success');
        closeDialog();
        onSuccessFn();
      }
    }
  );

  const handleSubmit = () => {
    if (!signature) {
      setErrorMessage(t('Signature is required'));
      return;
    }
    setIsLoading(true);
    mutation.mutate();
  };

  return (
    <div className={styles.signDialogContainer}>
      <div className={combineClassNames('d-flex f-justify-between', styles.header)}>
        <h2>{t('Agreement Signing')}</h2>
        <div className={combineClassNames('f-center', styles.closeButton)} onClick={closeDialog}>
          <CloseIcon />
        </div>
      </div>
      <div className={styles.body}>
        <h3>{t('Hello name').replace('%@', user?.d_name || '')}</h3>
        <p>{t('signature desc')}</p>
        <TextInput
          value={signature}
          error={errorMessage}
          showErrorText={true}
          onChange={(e) => {
            setErrorMessage(null);
            setSignature(e.target.value);
          }}
          placeholder={t('Write your full name here')}
        />
        <div className="d-flex f-justify-between">
          {!agreementLoading && !isAgreementFailed && (
            <LinkItem link={agreementLink} text={t('View Agreement')} newTab={true} />
          )}
          <Button
            label={t('Sign Agreenment')}
            onSubmit={handleSubmit}
            size="md"
            customClasses={['purpleFilled']}
            icon={isLoading ? <LoaderIcon width={20} height={20} /> : null}
          />
        </div>
      </div>
    </div>
  );
}
