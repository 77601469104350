import WithLabelWrapper from 'components/Reusable/WithLabelWrapper';
import { combineClassNames } from 'helpers/styling-helper';
import { IInputWithLabel } from 'interfaces/formsComponents.interface';
import React from 'react';
import styles from './radioButton.module.scss';

type IRadioButtonData = {
  label?: string;
  value?: string;
};

interface RadioButtonProps extends IInputWithLabel {
  value: string;
  items: IRadioButtonData[];
  onChange: (value: any) => void;
  error?: string;
  customInputClass?: string;
}

export default function RadioButton(props: RadioButtonProps) {
  const { items, onChange, value, customInputClass } = props;
  return (
    <WithLabelWrapper {...props}>
      <div className="d-flex f-align-center">
        {items.map((item, index) => {
          return (
            <div key={`${item?.value}-${index}`} className={styles.radioButton_item_container}>
              <input
                className={combineClassNames(
                  styles.radioInput,
                  customInputClass || '',
                  item?.value === value && styles.checked
                )}
                type={'radio'}
                value={item?.value}
                checked={item?.value === value}
                onChange={(e) => onChange(e.target.value)}
              />
              <span className={styles.custom_checkbox_span}></span>
              <div className={styles.label}>{item?.label}</div>
            </div>
          );
        })}
      </div>
    </WithLabelWrapper>
  );
}
