import SongUploadBox from 'components/CreateRelease/SongUploadBox';
import { ReactComponent as DeleteIcon } from 'icons/delete.svg';
import React from 'react';
import colors from 'styles/partials/_colors_exports.module.scss';
import styles from './songUploader.module.scss';
import useFileUploader from 'hooks/useFileUploader';
import { useTranslation } from 'react-i18next';
import SongPreview from '../SongPreview';
import { IUploadedFile, UploaderFileTypes } from 'interfaces/formsComponents.interface';
import { getFileSizeInMegabytes, getProgressInMegabytes } from 'helpers/create-release-helper';
import FileUploadButton from '../FileUploadButton';
import { ReactComponent as RetryIcon } from 'icons/retry.svg';
import { ALLOWED_MUSIC_FILETYPES } from 'constants/releases.constants';
import { toastContext } from 'components/contexts/toast/toast.context';
import Progress from 'antd/es/progress';
import { logGeneralErrorEvent } from 'helpers/analytics-helper';

interface SongUploaderProps {
  onChange: (file: IUploadedFile) => void;
  song: IUploadedFile;
}

export default function SongUploader({ song, onChange }: SongUploaderProps) {
  const { t } = useTranslation('common');
  const { openToast } = React.useContext(toastContext);
  const [hasError, setHasError] = React.useState(false);
  const {
    uploadFile,
    clearUploadedFile,
    uploadedFile: uploadedSong,
    loadingProgress,
    isLoading,
    isUploadSuccess
  } = useFileUploader({
    fileType: UploaderFileTypes.audio,
    onSuccess: (file) => {
      setHasError(false);
      onChange(file);
    },
    onError: (err) => {
      console.error('🔴 ~ file: songUploader.tsx ~ line 49 ~ err', err.message);
      setHasError(true);
      logGeneralErrorEvent(err, 'uploading single song');
      openToast(t('Failed to upload file').replace('%@', ''), 'error');
    },
    initialFile: song
  });

  const handleDetele = () => {
    clearUploadedFile();
    onChange(null);
  };
  return (
    <>
      {uploadedSong?.src || uploadedSong?.file ? (
        <div className={styles.uploaded_song_box}>
          <div className="d-flex f-align-center f-justify-between">
            <SongPreview song={uploadedSong} customClass={styles.song_preview} />
            <div className="d-flex f-align-center gap-05">
              <FileUploadButton
                customClass={styles.changeFile_button}
                label={t('Change File')}
                fileTypes={ALLOWED_MUSIC_FILETYPES.join(',')}
                customIcon={<RetryIcon />}
                onUpload={(files) => {
                  clearUploadedFile();
                  uploadFile(files[0]);
                }}
              />
              <div className={styles.delete_button} onClick={handleDetele}>
                <DeleteIcon fill={colors['dark-10']} />
              </div>
            </div>
          </div>
          <div>
            <span className="d-block" style={{ color: colors['dark-8'] }}>
              {t('STATUS')}
            </span>
            <div>
              {uploadedSong?.size && (
                <>
                  <span className="bold">{t('Size')}</span>{' '}
                  <span>{getFileSizeInMegabytes(uploadedSong.size) + ' mb'}</span>
                </>
              )}
            </div>
            <span className="bold d-block">{t('Upload Status')}</span>
            {isLoading ? (
              <div>
                <div>
                  <span>
                    {getProgressInMegabytes(loadingProgress, uploadedSong?.size)}/
                    {getFileSizeInMegabytes(uploadedSong.size)}
                    mb
                  </span>
                </div>
                <Progress
                  percent={loadingProgress || 0}
                  status={isLoading ? 'active' : 'normal'}
                  strokeColor={{ from: '#c98c4b', to: colors['orange'] }}
                />
              </div>
            ) : (
              <>
                {!hasError && (song?.key || isUploadSuccess || song?.isUploaded) ? (
                  <span className={styles.success}>{t('Success')}</span>
                ) : (
                  <span className={styles.error}>{t('Failed')}</span>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <SongUploadBox
          onUpload={(files) => uploadFile(files[0])}
          fileTypes={ALLOWED_MUSIC_FILETYPES.join(',')}
          multiple={false}
        />
      )}
    </>
  );
}
