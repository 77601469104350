import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import { ReactComponent as SucessStatus } from 'icons/status-succeed.svg';
import { ReactComponent as FailedStatus } from 'icons/status-failed.svg';
import { ReactComponent as PendingStatus } from 'icons/status-pending.svg';
import { useTranslation } from 'react-i18next';
import styles from './requestStatusField.module.scss';

const RequestStatusField = ({ status }: { status: string }) => {
  const { t } = useTranslation('common');
  switch (Number(status)) {
    case 1:
      return (
        <div className="d-flex f-align-center gap-05">
          <span className={combineClassNames(styles.statusText, styles.success)}>
            {t('Approved')}
          </span>
          <SucessStatus width={18} height={18} />
        </div>
      );
    // -1 in content change requests, and 2 in profile, both means rejected
    case -1:
    case 2:
      return (
        <div className="d-flex f-align-center gap-05">
          <span className={combineClassNames(styles.statusText, styles.failed)}>
            {t('Rejected')}
          </span>
          <FailedStatus width={18} height={18} />
        </div>
      );
    case 0:
      return (
        <div className="d-flex f-align-center gap-05">
          <span className={combineClassNames(styles.statusText, styles.pending)}>
            {t('Pending')}
          </span>
          <PendingStatus width={18} height={18} />
        </div>
      );
  }
  return <> {status} </>;
};

export default RequestStatusField;
