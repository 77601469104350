export default class Cookies {
  static set = (name: string, value: string, days = 1, secure = false) => {
    let expires;
    if (days > 0) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    } else if (days < 0) {
      expires = '; expires=' + new Date(0).toUTCString();
    } else expires = '';
    let securestring = '';
    const domain = `; path=/; domain=.${
      window.location.href.indexOf('angha.me') === -1 ? 'anghami.com' : 'angha.me'
    };`;
    if (secure) {
      securestring = '; secure=true';
    }
    document.cookie = name + '=' + value + expires + securestring + domain;
  };

  static get = (name: string) => {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  static remove = (name: string) => {
    Cookies.set(name, '', -1);
    document.cookie = `${name}=; path=/; expires=${new Date(0).toUTCString()};`;
  };
}

export const setLanguageCookies = (lang: string) => {
  ['c-language', 'c-lang'].forEach((cookieName) => {
    Cookies.set(cookieName, lang, 30);
  });
};

export const setCustomCookie = (name: string, value: string, days: number) => {
  // TODO: remove the remove line, we need it now to clear previous creators.anghami cookies
  Cookies.remove(name);
  Cookies.set(name, value, days, !window?.location.href.includes('angha.me'));
};
export const deleteCookie = (cookieName) => {
  Cookies.remove(cookieName);
};
const authCookieNames = [
  'isLoggedIn',
  'justLoggedIn',
  'dashapi_access_token',
  'dashapi_refresh_token',
  'artist_has_uploads',
  'is_podcaster',
  'show_nps',
  'user',
  'anid',
  'userType',
  'userStatus',
  'userEmail',
  'userRoles',
  'artworklocation',
  'artist',
  'artistid',
  'artisthandle',
  'artistcoverart',
  'profilepic',
  'fbprofilepic',
  'fbid',
  'fullname',
  'isOwnerWhiteListed',
  'socketsessionid',
  'hasPodcasts',
  'ownerid',
  'appsid',
  'appsidsave'
];

const COOKIE_LIFE_IN_DAYS = 30;

export const saveDashboardAuthCookies = (authenticate) => {
  setCustomCookie('isLoggedIn', 'true', COOKIE_LIFE_IN_DAYS);
  setCustomCookie('justLoggedIn', 'true', COOKIE_LIFE_IN_DAYS);
  setCustomCookie('artist_has_uploads', authenticate.artist_has_uploads || '', COOKIE_LIFE_IN_DAYS);
  setCustomCookie(
    'dashapi_access_token',
    authenticate.dashapi_access_token_pair
      ? authenticate.dashapi_access_token_pair.access_token
      : '',
    COOKIE_LIFE_IN_DAYS
  );
  setCustomCookie(
    'dashapi_refresh_token',
    authenticate.dashapi_access_token_pair ? authenticate.dashapi_access_token_pair.refresh : '',
    COOKIE_LIFE_IN_DAYS
  );
  setCustomCookie('is_podcaster', authenticate.is_podcaster || '', COOKIE_LIFE_IN_DAYS);
  setCustomCookie('show_nps', authenticate.show_nps || '', COOKIE_LIFE_IN_DAYS);
  setCustomCookie('user', authenticate.user ? authenticate.user : '', COOKIE_LIFE_IN_DAYS);
  setCustomCookie('anid', authenticate.anid ? authenticate.anid : '', COOKIE_LIFE_IN_DAYS);
  setCustomCookie(
    'userType',
    authenticate.d_typeid ? authenticate.d_typeid : '',
    COOKIE_LIFE_IN_DAYS
  );
  setCustomCookie(
    'userStatus',
    authenticate.d_statusid ? authenticate.d_statusid : '',
    COOKIE_LIFE_IN_DAYS
  );
  setCustomCookie(
    'userEmail',
    authenticate.d_email ? authenticate.d_email : '',
    COOKIE_LIFE_IN_DAYS
  );
  setCustomCookie(
    'userRoles',
    authenticate.d_roles ? authenticate.d_roles : '',
    COOKIE_LIFE_IN_DAYS
  );
  setCustomCookie(
    'artworklocation',
    authenticate.artworklocation ? authenticate.artworklocation : '',
    COOKIE_LIFE_IN_DAYS
  );
  setCustomCookie(
    'artist',
    authenticate.d_artist.name ? authenticate.d_artist.name : '',
    COOKIE_LIFE_IN_DAYS
  );
  setCustomCookie(
    'artistid',
    authenticate.d_artist.id ? authenticate.d_artist.id : '',
    COOKIE_LIFE_IN_DAYS
  );
  setCustomCookie(
    'artisthandle',
    authenticate.d_artist.handle ? authenticate.d_artist.handle : '',
    COOKIE_LIFE_IN_DAYS
  );
  setCustomCookie(
    'artistcoverart',
    authenticate.d_artist.coverart ? authenticate.d_artist.coverart : '',
    COOKIE_LIFE_IN_DAYS
  );
  setCustomCookie(
    'profilepic',
    authenticate.profilepic ? authenticate.profilepic : '',
    COOKIE_LIFE_IN_DAYS
  );
  setCustomCookie(
    'fbprofilepic',
    authenticate.fbprofilepic ? authenticate.fbprofilepic : '',
    COOKIE_LIFE_IN_DAYS
  );
  setCustomCookie('fbid', authenticate.fbid ? authenticate.fbid : '', COOKIE_LIFE_IN_DAYS);
  setCustomCookie('fullname', authenticate.d_name ? authenticate.d_name : '', COOKIE_LIFE_IN_DAYS);
  setCustomCookie(
    'isOwnerWhiteListed',
    authenticate.isOwnerWhiteListed ? authenticate.isOwnerWhiteListed : '',
    COOKIE_LIFE_IN_DAYS
  );
  setCustomCookie('socketsessionid', authenticate.socketsessionid, COOKIE_LIFE_IN_DAYS);
  setCustomCookie('appsid', authenticate.socketsessionid, COOKIE_LIFE_IN_DAYS);
  setCustomCookie(
    'hasPodcasts',
    authenticate.hasPodcasts ? authenticate.hasPodcasts : '',
    COOKIE_LIFE_IN_DAYS
  );
  setCustomCookie('ownerid', authenticate.ownerid ? authenticate.ownerid : '', COOKIE_LIFE_IN_DAYS);
};

export const removeDashboardAuthCookies = () => {
  setCustomCookie('isLoggedIn', 'false', 0);
  document.cookie = '';
  authCookieNames.forEach((cookieName) => {
    deleteCookie(cookieName);
  });
};
