import React from 'react';
import { SONG_IMAGE_PLACEHOLDER } from 'constants/releases.constants';
import { colorDarkenLighten } from 'helpers/color-darken-lighten';
import styles from './listChart.module.scss';
import { combineClassNames } from 'helpers/styling-helper';
import { IChartItemValue } from '@anghami/neogateway/dist/endpoints/getAnalyticsSections';
import { IEnhancedSectionData } from '../../interfaces';

import SectionItemHeader from '../SectionItemHeader/sectionItemHeader';
import { useTranslation } from 'react-i18next';
import { useTrackVisibility } from 'react-intersection-observer-hook';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import NoData from 'components/Reusable/NoData';
import Dialog from 'components/Reusable/Dialog';
import { getCoverArtImage } from 'helpers/releases-helper';
import LinkItem from 'components/Reusable/LinkItem';

interface IListChartItemProps extends IChartItemValue {
  color: string;
  title: string;
}

const ListChartItem = ({ extras, value, color, title, label }: IListChartItemProps) => {
  const [ref, { isVisible, wasEverVisible }] = useTrackVisibility();
  return (
    <div className={styles.listItemContainer} ref={ref}>
      <div
        className={combineClassNames(
          'd-flex f-align-center f-justify-start gap-05',
          styles.listInfo
        )}
      >
        {extras?.coverart_id && (
          <img
            src={getCoverArtImage(extras?.coverart_id)}
            alt="song-cover"
            onError={(e) => ((e.target as any).src = SONG_IMAGE_PLACEHOLDER)}
          />
        )}
        {extras?.link ? (
          <LinkItem
            customClassName={styles.listItemLinkText}
            text={title}
            link={extras?.link}
            newTab={true}
          />
        ) : (
          <span>{title}</span>
        )}
      </div>
      <div className="d-flex f-align-center">
        <span className={styles.listBar}>
          <span
            className={styles.inner}
            style={{
              width: wasEverVisible || isVisible ? `${value}%` : 0,
              backgroundColor: color
            }}
          />
        </span>
        <span>{label}</span>
      </div>
    </div>
  );
};

interface IListChartProps extends IEnhancedSectionData {
  limit?: number;
  showAll?: () => void;
  closeDialog?: () => void;
}

const ItemsList = ({
  title,
  data,
  color,
  limit,
  showAll,
  closeDialog,
  allTimeData
}: IListChartProps) => {
  const { t } = useTranslation('common');
  const showMoreButton =
    limit && data.length > limit ? { label: t('View all'), onClick: showAll } : null;
  return (
    <>
      <div
        className={combineClassNames(
          'd-flex f-justify-between f-align-center',
          styles.stickyHeader
        )}
      >
        <SectionItemHeader title={title} button={showMoreButton} allTimeData={allTimeData} />
        {closeDialog && (
          <CloseIcon width={15} height={15} className={styles.closeIcon} onClick={closeDialog} />
        )}
      </div>
      <div
        className={combineClassNames('f-column', !limit && 'overflow-scroll', 'position-relative')}
        style={{ minWidth: '20rem', padding: '0rem 1rem 1rem 1rem' }}
      >
        {data.length === 0 ? (
          <NoData allTimeData={allTimeData} />
        ) : (
          data
            .slice(0, limit)
            ?.map((row, index) => (
              <ListChartItem
                key={row.key + '-' + index}
                {...row}
                title={row.key}
                color={colorDarkenLighten(color, index, data.length)}
              />
            ))
        )}
      </div>
    </>
  );
};

export default function ListChart(props: IListChartProps) {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  return (
    <>
      <ItemsList {...props} limit={4} showAll={() => setIsDialogOpen(true)} />
      <Dialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        customClassName={styles.customDialog}
      >
        <ItemsList {...props} closeDialog={() => setIsDialogOpen(false)} />
      </Dialog>
    </>
  );
}
