import React from 'react';
import MoreOutlined from '@ant-design/icons/MoreOutlined';
import { Popover } from 'antd';
import styles from './actionsMenu.module.scss';
import ActionItem from './actionItem';
import { IActionMenuProps } from './actionsMenu';
import { combineClassNames } from 'helpers/styling-helper';

const ActionsList = ({ actions, onActionClick }: IActionMenuProps) => {
  return (
    <div className={styles.actionList}>
      {actions.map((action) => (
        <ActionItem
          key={action}
          action={action}
          onActionClick={onActionClick}
          customClassName={styles.actionItemList}
        />
      ))}
    </div>
  );
};

export default function ActionSheet({ actions, onActionClick }) {
  const [isPopOverOpen, setIsPopOverOpen] = React.useState(false);
  const handlePopOverChange = (open: boolean) => {
    setIsPopOverOpen(open);
  };
  const handleActionClick = (e) => {
    setIsPopOverOpen(false);
    onActionClick(e);
  };

  return (
    <Popover
      content={<ActionsList actions={actions} onActionClick={handleActionClick} />}
      placement="bottom"
      trigger="click"
      open={isPopOverOpen}
      onOpenChange={handlePopOverChange}
      showArrow={false}
      className="info-table-cell"
    >
      <div
        className={combineClassNames(
          'd-flex f-justify-center f-align-center',
          styles.moreIconContainer
        )}
      >
        <MoreOutlined className={styles.moreIcon} />
      </div>
    </Popover>
  );
}
