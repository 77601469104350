import React from 'react';
import styles from './introduceYourVoice.module.scss';
import { Link } from 'react-router-dom';
import Button from 'components/Reusable/Button';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArtistsIcon } from 'icons/artists-icon-landing.svg';
import { ReactComponent as PodcastersIcon } from 'icons/podcasters-icon-landing.svg';
import { ReactComponent as LabelsIcon } from 'icons/labels-icon-landing.svg';

const Card = ({ data }) => (
  <div className={styles.cardContainer}>
    <div className={styles.cardImageContainer}>{data.image}</div>
    <div className={styles.cardContent}>
      <h2 className={styles.cardTitle}>{data.title}</h2>
      <p>{data.subtitle}</p>
    </div>
  </div>
);

const BG_IMAGE = 'assets/images/introduce-your-voice-bg.webp';

function IntroduceYourVoice() {
  const { t, i18n } = useTranslation();
  const cardsData = [
    {
      id: 0,
      title: `${t('creators.landing.section3.artists.title', 'For Artists')}`,
      subtitle: `${t(
        'creators.landing.section3.artists.description',
        'Manage and upload your releases for free, get featured in playlists and access your royalties.'
      )}`,
      image: <ArtistsIcon />
    },
    {
      id: 1,
      title: `${t('creators.landing.section3.podcasters.title', 'For Podcasters')}`,
      subtitle: `${t(
        'creators.landing.section3.podcasters.description',
        'Upload your podcast episodes with ease or add your RSS feed and manage your profile'
      )}`,
      image: <PodcastersIcon />
    },
    {
      id: 2,
      title: `${t('creators.landing.section3.labels.title', 'For Labels')}`,
      subtitle: `${t(
        'creators.landing.section3.labels.description',
        'Manage your catalog, access stats and financial reports and track your growth'
      )}`,
      image: <LabelsIcon />
    }
  ];

  const mainSectionData = {
    title: t(
      'creators.landing.section2.title',
      'Join Now to Make Some Noise: The World is Listening'
    ),
    cta: t('creators.landing.cta.claim', 'Claim your profile'),
    splitedSubtitle: t(
      'creators.landing.cta.alreadyauser.login',
      'Already have an account? Log in now'
    ).split(i18n.language === 'ar' ? '؟' : '?')
  };

  return (
    <div className={styles.sectionContainer}>
      <div
        className={styles.titleSection}
        style={{
          backgroundImage: `url(${BG_IMAGE})`
        }}
      >
        <div className={styles.sectionTitle}> {mainSectionData.title}</div>
        <div className={styles.mainButtonSection}>
          <Link
            to={'/claim'}
            className="d-flex w-100 f-justify-center"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Button label={mainSectionData.cta} customClasses={[styles.sectionButton]} />
          </Link>
          <b>
            {mainSectionData.splitedSubtitle[0]}{' '}
            <Link to="/login">
              <u>{mainSectionData.splitedSubtitle[1]}.</u>
            </Link>
          </b>
        </div>
      </div>
      <div className={styles.cardsContainer}>
        {cardsData.map((card) => (
          <Card key={card.id} data={card} />
        ))}
        <div className={styles.secondaryButtonSection}>
          <Link
            to={'/claim'}
            className="d-flex w-100 f-justify-center"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Button
              label={`${t('creators.landing.cta.claim', 'Claim your profile')}`}
              customClasses={[styles.sectionButton]}
            />
          </Link>
          <b>
            {`${
              t(
                'creators.landing.cta.alreadyauser.login',
                'Already have an account? Log in now'
              ).split('?')[0]
            }? `}
            <Link to="/login">
              <u>
                {`${
                  t(
                    'creators.landing.cta.alreadyauser.login',
                    'Already have an account? Log in now'
                  ).split('?')[1]
                }.`}
              </u>
            </Link>
          </b>
        </div>
      </div>
    </div>
  );
}

export default IntroduceYourVoice;
