import {
  RELEASE_STATUSES,
  RELEASE_TYPES
} from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import { toastContext } from 'components/contexts/toast/toast.context';
import Button from 'components/Reusable/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useDeleteRelease from './useDeleteRelease';
import styles from './deleteLibraryReleaseDialog.module.scss';
import { ReactComponent as LoaderIcon } from 'icons/loaders/tail-spin.svg';
interface IDeleteLibraryReleaseDialogProps {
  closeDialog?: () => void;
  releaseName: string;
  releaseId: string;
  albumId: string;
  releaseStatus: RELEASE_STATUSES;
  releaseType: RELEASE_TYPES;
}

export default function DeleteLibraryReleaseDialog({
  closeDialog,
  releaseId,
  releaseName,
  releaseType,
  albumId,
  releaseStatus
}: IDeleteLibraryReleaseDialogProps) {
  const { t } = useTranslation('common');
  const { openToast } = React.useContext(toastContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const handleError = (message: string) => {
    openToast(message, 'error');
    setIsLoading(false);
    closeDialog();
  };
  const handleSuccess = () => {
    openToast(t('Release Deleted'), 'success');
    setIsLoading(false);
    closeDialog();
  };
  const deleteRelease = useDeleteRelease({
    releaseId,
    releaseStatus,
    albumId,
    releaseType,
    onError: handleError,
    onSuccess: handleSuccess
  });
  return (
    <div className="f-column f-justify-center f-align-center text-align-center">
      <h2>{t('delete confirmation').replace('%@', `"${releaseName}"`)}</h2>
      <div className={'d-flex f-align-center f-space-around gap-1'}>
        <Button
          label={t('delete')}
          onSubmit={() => {
            setIsLoading(true);
            deleteRelease();
          }}
          size={'md'}
          customClasses={[styles.deleteButton]}
          icon={isLoading ? <LoaderIcon width={15} height={15} /> : null}
        />
        <Button
          label={t('Cancel')}
          onSubmit={closeDialog}
          size={'md'}
          customClasses={[styles.cancelButton]}
        />
      </div>
    </div>
  );
}
