import { userContext } from 'components/contexts/auth/auth.context';
import Button from 'components/Reusable/Button';
import Form from 'components/Reusable/Form';
import { combineClassNames } from 'helpers/styling-helper';
import {
  IAlbumSong,
  IFormFields,
  ISection,
  IUploadedFile
} from 'interfaces/formsComponents.interface';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../EditReleaseDialog/editReleaseDialog.module.scss';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as ErrorStarIcon } from 'icons/error-star.svg';
import SongUploader from 'components/CreateRelease/SongUploader';
import UploadCoverArt from 'components/CreateRelease/UploadCoverArt';
import {
  extractValuesFromApiData,
  validateFormFields,
  getFormSectionsAndFields,
  getUpdatedFormFields,
  getSongsWithKeysIds
} from 'helpers/create-release-helper';
import { convertFormFieldsToValues, convertFormValuesToFields } from 'helpers/form-helper';
import {
  RELEASE_TYPES,
  OLD_RELEASE_ITEM_KEYS as RK,
  IReleaseItemOld,
  RELEASE_STATUSES
} from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import MultiSongUploader from 'components/CreateRelease/MultiSongUploader';
import useGetReleaseDetails from 'hooks/useGetReleaseDetails';
import { useMutation, useQueryClient } from 'react-query';
import {
  postAlbumSongEndpoint,
  postAlbumUploadEndpoint,
  postEditSongEndpoint,
  postSingleUploadEndpoint,
  reorderAlbumSongs,
  replaceSong,
  updateAlbumInfoEndpoint,
  updateSongInfoEndpoint
} from 'helpers/http-helper';
import useCheckForSongReplaceRequest from './useCheckForSongReplaceRequest';
import useCheckForCoverartReplaceRequest from './useCheckForCoverartReplaceRequest';
import { PendingRequestDiscard, LoaderFullScreen } from './helperComponents';
import { toastContext } from 'components/contexts/toast/toast.context';
import {
  CHANGES_ERROR_TYPE,
  IChangeError,
  IChangesError,
  IEditReleaseDialogProps,
  STEPS
} from './editLibraryReleaseDialog.interfaces';
import { compareArraysScalar } from 'helpers/utils-helper';
import {
  getIsPodcastFromReleaseType,
  getIsReleaseSingle,
  getIsReleaseSongInsideAlbum
} from 'helpers/releases-helper';
import { CONTENT_TYPES, LIBRARY_TYPE } from '@anghami/neogateway/dist/endpoints/getUserLibrary';
import { useNavigate } from 'react-router-dom';
import { GET_RELEASE_DETAILS } from 'constants/reactQueryKeys';
import { logGeneralErrorEvent } from 'helpers/analytics-helper';

export const HeaderMessage = ({ error, onClick }: { error: IChangeError; onClick: () => void }) => (
  <div
    className={combineClassNames(
      styles.header_message,
      error?.message && styles.showing,
      styles[error?.type]
    )}
    onClick={onClick}
  >
    <span>{error?.message}</span>
  </div>
);

export default function EditLibraryReleaseDialog({
  closeDialog,
  config,
  release_id,
  album_id,
  release_status,
  release_type
}: IEditReleaseDialogProps) {
  const { t } = useTranslation();
  const [selectedSection, setSelectedSection] = React.useState(0);
  const { user, userType } = React.useContext(userContext);
  const { openToast } = React.useContext(toastContext);
  const [formFields, setFormFields] = React.useState<IFormFields>(null);
  const [formSections, setFormSections] = React.useState<ISection[]>(null);
  const [coverArt, setCoverArt] = React.useState<IUploadedFile>(null);
  const [uploadedSongs, setUploadedSongs] = React.useState<IAlbumSong[]>(null);
  const [uploadedSongFile, setUploadedSongFile] = React.useState<IUploadedFile>(null);
  const [lastUploadedSongs, setLastUploadedSongs] = React.useState<string[]>([]);
  const [didFormChange, setDidFormChange] = React.useState(false);
  const [didCoverArtChange, setDidCoverArtChange] = React.useState(false);
  const [didSongsChange, setDidSongsChange] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [stepsErrors, setStepsErrors] = React.useState<IChangesError>({
    form: null,
    coverart: null,
    songs: null
  });
  const [isSingle, setIsSingle] = React.useState(false);
  const [isPodcast, setIsPodcast] = React.useState(false);
  const [albumInfo, setAlbumInfo] = React.useState(null);
  const reactQueryClient = useQueryClient();
  const [songReplaceId, setSongReplaceId] = React.useState<string>(null);
  const [isDiscardingPreviousSongRequest, setIsDiscardingPreviousSongRequest] =
    React.useState(false);
  const [isDiscardingPreviousCoverartRequest, setIsDiscardingPreviousCoverartRequest] =
    React.useState(false);
  const navigate = useNavigate();
  const setStepsError = (type: STEPS, errorMessage: string, isWarning = false) => {
    setStepsErrors({
      ...stepsErrors,
      [type]: {
        message: errorMessage,
        type: isWarning ? CHANGES_ERROR_TYPE.warning : CHANGES_ERROR_TYPE.error
      }
    });
  };
  const handleMutationsSettle = (type: STEPS, data, error, refetchLibrary = false) => {
    setIsLoading(false);
    if (!data || error) {
      logGeneralErrorEvent(error || 'no data', 'edit release in library-' + type);
      setStepsError(type, t('something-went-wrong-try-again'));
      return;
    }
    if (data.error) {
      /* when replacing song, it needs a minute to finish encoding, an error  
        "Encoding is still pending, please try again" will be returned code: 115,
          we want to allow the user to try again after this happen */
      const isFailedCausePendingSongEnconding = type === STEPS.songs && data.error.code === 115;
      if (isFailedCausePendingSongEnconding) {
        setDidSongsChange(true);
      }
      setStepsError(
        type,
        data?.error?.message || t('something-went-wrong-try-again'),
        isFailedCausePendingSongEnconding
      );
      return;
    }
    // refetch all queries depending on this release_id
    reactQueryClient.refetchQueries({
      queryKey: [GET_RELEASE_DETAILS, release_type, release_id],
      exact: false
    });
    const contentType = getIsPodcastFromReleaseType(release_type)
      ? CONTENT_TYPES.podcast
      : CONTENT_TYPES.music;
    if (getIsReleaseSongInsideAlbum(release_type)) {
      reactQueryClient.refetchQueries({
        queryKey: [
          user.anid,
          contentType,
          'library',
          release_status === RELEASE_STATUSES.DRAFT ? LIBRARY_TYPE.draft : LIBRARY_TYPE.live,
          album_id
        ],
        exact: false
      });
    }
    if (release_status === RELEASE_STATUSES.DRAFT) {
      reactQueryClient.refetchQueries({
        queryKey: [user.anid, contentType, 'library', LIBRARY_TYPE.draft],
        exact: false
      });
      return;
    }
    if (refetchLibrary) {
      reactQueryClient.refetchQueries({
        queryKey: [user.anid, contentType, 'library'],
        exact: false
      });
    }
  };
  const formMutation = useMutation(
    (formValues: IReleaseItemOld) =>
      updateSongInfoEndpoint(release_id, album_id, user?.d_artist?.id, formValues),
    {
      onSettled: (data, error) => handleMutationsSettle(STEPS.form, data, error, true)
    }
  );
  const liveAlbumMutation = useMutation(
    (formValues: IReleaseItemOld) =>
      updateAlbumInfoEndpoint(album_id, user?.d_artist?.id, {
        ...formValues,
        ispodcast: isPodcast
      }),
    {
      onSettled: (data, error) => handleMutationsSettle(STEPS.form, data, error, true)
    }
  );
  const coverArtMutation = useMutation(
    (key: string) =>
      postEditSongEndpoint(release_id, key, isDiscardingPreviousCoverartRequest, true),
    {
      onSettled: (data, error) => handleMutationsSettle(STEPS.coverart, data, error, true)
    }
  );
  const songMutation = useMutation(
    (key: string) => replaceSong(release_id, key, isDiscardingPreviousSongRequest, songReplaceId),
    {
      onSettled: (data, error) => handleMutationsSettle(STEPS.songs, data, error, true)
    }
  );
  const reorderSongsMutation = useMutation(
    (newOrder: string[]) => reorderAlbumSongs(release_id, newOrder),
    {
      onSettled: (data, error) => handleMutationsSettle(STEPS.songs, data, error)
    }
  );
  const updatePendingRelease = useMutation(
    ({ formValues, isAlbum }: { formValues: any; isAlbum: boolean }) => {
      if (!formValues) return;
      if (isAlbum) {
        return postAlbumUploadEndpoint({ ...formValues, albumid: album_id, ispodcast: isPodcast });
      }
      return postSingleUploadEndpoint({ ...formValues, albumid: album_id }, isPodcast);
    },
    {
      onSettled: (data, error) => handleMutationsSettle(STEPS.form, data, error, true)
    }
  );
  const updateInsideAlbumRelease = useMutation(
    ({ formValues }: { formValues: any }) => {
      if (!formValues) return;
      return postAlbumSongEndpoint({ ...formValues, albumid: album_id, ispodcast: isPodcast });
    },
    {
      onSettled: (data, error) => handleMutationsSettle(STEPS.form, data, error, true)
    }
  );

  const { data, isLoading: gettingReleaseDetails } = useGetReleaseDetails({
    release_id,
    release_type,
    onError: closeDialog
  });
  React.useEffect(() => {
    if (!data) return;
    if (data?.error) {
      openToast(data.error.message || t('something-went-wrong-try-again'), 'error');
      closeDialog();
      return;
    }
    fillFormFields(data.data, release_type);
  }, [data]);
  const songChangeRequestRes = useCheckForSongReplaceRequest({
    release_id,
    release_status,
    release_type
  });
  const coverartChangeRequestRes = useCheckForCoverartReplaceRequest({
    release_id,
    release_status,
    release_type
  });

  const fillFormFields = (data: IReleaseItemOld, release_type: RELEASE_TYPES) => {
    const { formSections, formFields: newFormFields } = getFormSectionsAndFields({
      releaseType: release_type,
      releaseStatus: release_status,
      accountType: userType,
      isEdit: true,
      data: {
        languageId: Number(data?.languageid),
        albumType: data?.albumtype,
        hideShowId: true
      }
    });
    setFormSections(formSections);
    setFormFields(formFields);
    const isSingle = getIsReleaseSingle(release_type);
    if (!data) return;
    setIsPodcast(!!data[RK.ispodcast] || getIsPodcastFromReleaseType(data?.releasetype));
    const languageid = Number(data[RK.languageid] || 2);
    const { formValues, coverArt, songs, singleUploadedSong } = extractValuesFromApiData(data);
    const newFormValues = {
      // Fill user (artist) info
      languageid,
      owner: user?.owner_name,
      artist_name: user?.user,
      ...formValues
    };
    setCoverArt(coverArt);
    if (isSingle) {
      setUploadedSongFile(singleUploadedSong);
    } else {
      setUploadedSongs(getSongsWithKeysIds(songs));
      setAlbumInfo({
        albumid: formValues.albumid,
        coverArt: coverArt,
        releasedate: formValues.releasedate,
        releasetime: formValues.releasetime,
        ispodcast: isPodcast,
        languageid: formValues.languageid,
        artistsValue: formValues.artists,
        genreValue: formValues.genre,
        categoryValue: formValues.category
      });
    }
    setIsSingle(isSingle);
    setFormFields(convertFormValuesToFields(newFormValues, newFormFields));
  };

  const handleFormDataChange = (newFormFields: IFormFields, fieldName: string) => {
    setDidFormChange(true);
    const tempFormFields: IFormFields = getUpdatedFormFields(newFormFields, fieldName);
    setFormFields(tempFormFields);
  };

  const handleUploadedSongsUpdate = (uploadedSongs: IAlbumSong[]) => {
    setDidSongsChange(true);
    setUploadedSongs(uploadedSongs);
  };

  const handleSongsReorder = () => {
    const songsIds = uploadedSongs.map((song) => song.songid)?.filter((songid) => songid) || [];
    if (songsIds.length <= 1) return;
    if (lastUploadedSongs.length === 0) {
      setLastUploadedSongs(songsIds);
      return;
    }
    if (compareArraysScalar(songsIds, lastUploadedSongs)) return;
    setDidSongsChange(true);
    if (release_status === RELEASE_STATUSES.LIVE) {
      reorderSongsMutation.mutateAsync(songsIds).then((res) => {
        if (!res || res.error) return;
        setLastUploadedSongs(songsIds);
      });
      return;
    }
    setLastUploadedSongs(songsIds);
  };

  const handleUpdateUploadedSong = (uploaded: IUploadedFile) => {
    setDidSongsChange(true);
    setUploadedSongFile(uploaded);
    if (!uploaded?.key) return;
    // send song to api to start deconding, and api will return a replace id
    songMutation.mutateAsync(uploaded.key).then((res) => {
      setSongReplaceId(res.replaceid);
    });
  };

  const handleUpdateCoverArt = (uploaded: IUploadedFile) => {
    setDidCoverArtChange(true);
    setCoverArt(uploaded);
  };

  const validateForm = () => {
    const { isValid, updatedFormFields, lastErrorMessage } = validateFormFields(formFields);
    if (!isValid) {
      setFormFields(updatedFormFields);
      setStepsError(STEPS.form, lastErrorMessage || t('Form validation fields required'));
    } else {
      setStepsError(STEPS.form, null);
    }
    return isValid;
  };

  const validateCoverArt = () => {
    if (!coverArt?.key) {
      setStepsError(STEPS.coverart, t('missing coverart'));
      return false;
    }
    setStepsError(STEPS.coverart, null);
    return true;
  };

  const validateSongs = () => {
    if ((isSingle && !uploadedSongFile) || (!isSingle && uploadedSongs.length === 0)) {
      setStepsError(STEPS.songs, t('You need to upload a file to proceed'));
      return false;
    }
    setStepsError(STEPS.songs, null);
    return true;
  };

  const handleLiveSingleSubmit = () => {
    const mutations = [];
    const doChangesExist = didFormChange || didCoverArtChange || didSongsChange;
    if (didFormChange && validateForm()) {
      const apiValues = convertFormFieldsToValues(formFields);
      console.log('Form Changed success', apiValues);
      const mutation = () => formMutation.mutateAsync(apiValues as IReleaseItemOld);
      mutations.push(mutation());
      setDidFormChange(false);
    }
    if (didCoverArtChange && validateCoverArt()) {
      console.log('Cover art changed success');
      const mutation = () => coverArtMutation.mutateAsync(coverArt?.key);
      mutations.push(mutation());
      setDidCoverArtChange(false);
    }
    if (didSongsChange && validateSongs()) {
      console.log('Songs changed success');
      const mutation = () => songMutation.mutateAsync(uploadedSongFile.key);
      mutations.push(mutation());
      setDidSongsChange(false);
    }
    if (mutations.length === 0) {
      setIsLoading(false);
      return;
    }
    Promise.all(mutations)
      .then((res) => {
        const needsVerification = isSingle;
        if (doChangesExist && res.length === 0) return;
        if (res?.filter((res) => res.error).length === 0) {
          if (needsVerification) {
            openToast(t('Change request created successfully'), 'success');
            navigate('changerequests/songs');
            closeDialog();
            return;
          }
          openToast(t('Changes Synced'), 'success');
          closeDialog();
        }
      })
      .catch((err) => {
        console.log('---- All endpoints called error', err);
      });
  };
  const handleLiveAlbumSubmit = () => {
    const doChangesExist = didFormChange || didCoverArtChange;
    if ((didFormChange && validateForm()) || (didCoverArtChange && validateCoverArt())) {
      const apiValues = convertFormFieldsToValues(formFields);
      liveAlbumMutation
        .mutateAsync({
          ...apiValues,
          ...(didCoverArtChange && { coverkey: coverArt.key })
        } as IReleaseItemOld)
        .then((res) => {
          if (doChangesExist && (!res || res.error)) return;
          openToast(t('Changes Synced'), 'success');
          closeDialog();
        })
        .catch((err) => {
          console.log('---- All endpoints called error', err);
        });
      return;
    }
    setIsLoading(false);
  };

  const handleOtherSingleSubmit = () => {
    if (
      (didFormChange && !validateForm()) ||
      (didCoverArtChange && !validateCoverArt()) ||
      (didSongsChange && !validateSongs)
    ) {
      setIsLoading(false);
      return;
    }
    const apiValues = {
      ...convertFormFieldsToValues(formFields),
      songid: release_id,
      albumid: album_id,
      stage: 3,
      ispodcast: isPodcast,
      tracknb: data?.data['tracknb'],
      [RK.coverkey]: coverArt.key,
      [RK.audiokey]: uploadedSongFile.key,
      [RK.filename]: uploadedSongFile.name,
      [RK.filesize]: uploadedSongFile.size
    };
    // if it's song or episode (inside an album) use postAlbumSong instead of postSongUpload
    const mutation = getIsReleaseSongInsideAlbum(release_type)
      ? updateInsideAlbumRelease
      : updatePendingRelease;
    mutation
      .mutateAsync({ formValues: apiValues, isAlbum: false })
      .then((res) => {
        if (!res || res.error) return;
        openToast(res.message || t('Changes Synced'), 'success');
        closeDialog();
      })
      .catch((err) => {
        console.log('---- Editing pending release error', err);
      });
  };
  const handlePendingAlbumSubmit = () => {
    if (
      (didFormChange && !validateForm()) ||
      (didCoverArtChange && !validateCoverArt()) ||
      (didSongsChange && !validateSongs)
    ) {
      setIsLoading(false);
      return;
    }
    const apiValues = {
      ...convertFormFieldsToValues(formFields),
      albumid: album_id,
      stage: 3,
      [RK.coverkey]: coverArt.key,
      [RK.songs]: { allsongs: uploadedSongs.map((s) => s.songid) }
    };
    updatePendingRelease
      .mutateAsync({ formValues: apiValues, isAlbum: true })
      .then((res) => {
        if (!res || res.error) return;
        openToast(res.message || t('Changes Synced'), 'success');
        closeDialog();
      })
      .catch((err) => {
        console.log('---- Editing pending release error', err);
      });
  };

  const onSubmit = () => {
    setIsLoading(true);
    if (!isSingle) {
      if (release_status === RELEASE_STATUSES.PENDING_APPROVAL) {
        handlePendingAlbumSubmit();
        return;
      }
      if (release_status === RELEASE_STATUSES.LIVE) {
        handleLiveAlbumSubmit();
        return;
      }
      setIsLoading(false);
      return;
    }
    if (release_status == RELEASE_STATUSES.LIVE) {
      handleLiveSingleSubmit();
      return;
    }
    if (
      release_status === RELEASE_STATUSES.PENDING_APPROVAL ||
      release_status === RELEASE_STATUSES.DRAFT
    ) {
      handleOtherSingleSubmit();
      return;
    }
    setIsLoading(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <div
          className={combineClassNames(
            styles.sidebar_item,
            selectedSection === 0 && styles.selected
          )}
          onClick={() => setSelectedSection(0)}
        >
          {t('General Information')}
          {stepsErrors?.form && <ErrorStarIcon />}
        </div>
        {config?.hideUploadCoverArt || (
          <div
            className={combineClassNames(
              styles.sidebar_item,
              selectedSection === 1 && styles.selected
            )}
            onClick={() => setSelectedSection(1)}
          >
            {t('Cover')}
            {stepsErrors?.coverart && <ErrorStarIcon />}
          </div>
        )}
        {config?.hideUploadSong || (
          <div
            className={combineClassNames(
              styles.sidebar_item,
              selectedSection === 2 && styles.selected
            )}
            onClick={() => setSelectedSection(2)}
          >
            {isSingle ? t('Audio File') : t('Audio Files')}
            {stepsErrors?.songs && <ErrorStarIcon />}
          </div>
        )}
      </div>
      <div className={styles.main}>
        <div className={styles.close_button} onClick={closeDialog}>
          <CloseIcon width={12} />
        </div>
        <div className={styles.section_container}>
          {isLoading || gettingReleaseDetails ? (
            <LoaderFullScreen />
          ) : (
            <>
              {selectedSection === 0 ? (
                <>
                  <HeaderMessage
                    error={stepsErrors.form}
                    onClick={() => setStepsError(STEPS.form, null)}
                  />
                  <div style={stepsErrors.form && { paddingTop: '2rem' }}>
                    {formSections && formFields && (
                      <Form
                        id={`edit-release-form-${release_id}`}
                        setFormValues={handleFormDataChange}
                        formValues={formFields}
                        sections={formSections}
                      />
                    )}
                  </div>
                </>
              ) : selectedSection === 1 ? (
                <div
                  className={combineClassNames('w-100 h-100 f-column', styles.coverArt_container)}
                >
                  <HeaderMessage
                    error={stepsErrors.coverart}
                    onClick={() => setStepsError(STEPS.coverart, null)}
                  />
                  <div style={stepsErrors.coverart && { paddingTop: '3rem' }}>
                    <PendingRequestDiscard
                      {...coverartChangeRequestRes}
                      isDiscarded={isDiscardingPreviousCoverartRequest}
                      discardRequest={() => setIsDiscardingPreviousCoverartRequest(true)}
                    >
                      <UploadCoverArt
                        onChange={handleUpdateCoverArt}
                        value={coverArt}
                        isInplace={true}
                        config={{
                          hideStatus:
                            data?.data?.coverkey === coverArt?.key ||
                            data?.data?.coverurl === coverArt?.key
                        }}
                      />
                    </PendingRequestDiscard>
                  </div>
                </div>
              ) : (
                <>
                  <HeaderMessage
                    error={stepsErrors.songs}
                    onClick={() => setStepsError(STEPS.songs, null)}
                  />
                  <div style={{ paddingTop: stepsErrors.songs ? '4.5rem' : '1.5rem' }}>
                    {uploadedSongs?.length > 0 ? (
                      <MultiSongUploader
                        songs={uploadedSongs}
                        onChange={handleUploadedSongsUpdate}
                        onReorder={handleSongsReorder}
                        isFromEdit={true}
                        albumInfo={albumInfo}
                        title={t('Edit your files')}
                      />
                    ) : (
                      <PendingRequestDiscard
                        {...songChangeRequestRes}
                        isDiscarded={isDiscardingPreviousSongRequest}
                        discardRequest={() => setIsDiscardingPreviousSongRequest(true)}
                      >
                        <SongUploader song={uploadedSongFile} onChange={handleUpdateUploadedSong} />
                      </PendingRequestDiscard>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div className={styles.submit_button_container}>
          <Button
            label={t('Submit')}
            size={'md'}
            customClasses={[styles.submit_button]}
            onSubmit={onSubmit}
            disabled={
              (selectedSection === 0 && !didFormChange) ||
              (selectedSection === 1 && !didCoverArtChange) ||
              (selectedSection === 2 && !didSongsChange)
            }
          ></Button>
        </div>
      </div>
    </div>
  );
}
