import React from 'react';
import { combineClassNames } from 'helpers/styling-helper';
import styles from './RssReleaseInfoCell.module.scss';

interface IInfoTableCellProps {
  name: string;
  coverart?: string;
  description: string;
  imagePlaceHolder?: string;
}

export default function RssReleaseInfoCell({
  name,
  coverart,
  description,
  imagePlaceHolder
}: IInfoTableCellProps) {
  return (
    <div className={combineClassNames('d-flex f-align-center', styles.infoTableCell)}>
      {coverart && (
        <img
          src={coverart}
          alt={name}
          onError={(e) => ((e.target as any).src = imagePlaceHolder || coverart)}
        />
      )}
      <div className={combineClassNames('f-column h-100 f-grow-1', styles.textAndActions)}>
        <div className="f-column f-justify-center f-grow-1">
          {name && <h4 className={styles.ellipsisOverflow}>{name}</h4>}
          {description && <p>{description}</p>}
        </div>
      </div>
    </div>
  );
}
