import {
  RELEASE_STATUSES,
  RELEASE_TYPES
} from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import { checkForExistingSongReplaceRequest } from 'helpers/http-helper';
import { getIsPodcastFromReleaseType, getIsReleaseSingle } from 'helpers/releases-helper';
import { useQuery } from 'react-query';

interface IUseCheckForSongReplaceRequestProps {
  release_id: string;
  release_status: RELEASE_STATUSES;
  release_type: RELEASE_TYPES;

  onError?: (err: unknown) => void;
  onSuccess?: (data: any) => void;
}
const useCheckForSongReplaceRequest = ({
  release_id,
  release_status,
  release_type,
  onSuccess,
  onError
}: IUseCheckForSongReplaceRequestProps) =>
  useQuery(
    ['checkForExistingSongReplaceRequest', release_id],
    async () => {
      const { existing_request } = await checkForExistingSongReplaceRequest(release_id);
      return existing_request;
    },
    {
      onError: (err) => {
        console.log(`🔴 ~ checkForExistingSongReplaceRequest for ${release_id} failed`, err);
        onError && onError(err);
      },
      ...(onSuccess && { onSuccess }),
      enabled:
        getIsReleaseSingle(release_type) &&
        !getIsPodcastFromReleaseType(release_type) &&
        release_status === RELEASE_STATUSES.LIVE
    }
  );

export default useCheckForSongReplaceRequest;
