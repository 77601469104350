import { periodContext } from 'components/Analytics/context/selectedPeriod.context';
import Button from 'components/Reusable/Button';
import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import styles from './sectionItemHeader.module.scss';

interface ISectionItemHeaderProps {
  title: string;
  allTimeData?: boolean;
  button?: {
    label: string;
    onClick: () => void;
  };
}
export default function SectionItemHeader({ title, button, allTimeData }: ISectionItemHeaderProps) {
  const { selectedPeriod } = React.useContext(periodContext);
  return (
    <div
      className={combineClassNames(
        'w-100 d-flex f-align-start f-justify-between no-select',
        styles.headerContainer
      )}
    >
      <div className="f-1 d-flex f-column f-justify-start">
        <h4 className="">{title}</h4>
        {!allTimeData && <p> {selectedPeriod.graphName} </p>}
      </div>
      {button && (
        <div className="f-1 d-flex f-justify-end">
          <Button
            label={button.label}
            size="sm"
            customClasses={['default']}
            onSubmit={button.onClick}
          />
        </div>
      )}
    </div>
  );
}
