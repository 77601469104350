import { combineClassNames } from 'helpers/styling-helper';
import React, { useEffect } from 'react';
import styles from './dialog.module.scss';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
interface IDialogProps {
  isOpen: boolean;
  onClose?: () => void;
  isBackDrop?: boolean;
  children: React.ReactNode;
  customClassName?: string;
  hasCloseIcon?: boolean;
}

export default function Dialog({
  children,
  isOpen,
  onClose,
  isBackDrop,
  customClassName,
  hasCloseIcon
}: IDialogProps) {
  const [openAnimation, setOpenAnimation] = React.useState(false);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setOpenAnimation(true);
      }, 10);
    } else {
      setTimeout(() => {
        setOpenAnimation(false);
      }, 10);
    }
  }, [isOpen]);

  return isOpen ? (
    <div className={styles.container} onClick={isBackDrop ? onClose : null}>
      <div
        id="dialog-wrapper"
        className={combineClassNames(styles.dialog, customClassName, openAnimation && styles.show)}
        onClick={(e) => e.stopPropagation()}
      >
        {hasCloseIcon && (
          <div className={styles.closeIcon} onClick={onClose}>
            <CloseIcon />
          </div>
        )}

        {children}
      </div>
    </div>
  ) : (
    <></>
  );
}
