import { RELEASE_STATUSES } from '@anghami/neogateway/dist/endpoints/getreleasedetails';

export type DataType = {
  SongID?: string;
  ArtistID?: string;
  AlbumID?: string;
  Name?: string;
  NameAr?: string;
  Status?: RELEASE_STATUSES;
  ReleaseDate?: string;
  Duration?: string;
  CoverArtID?: string;
  AddedOn?: string;
  is_owner?: string;
};

export type MetaType = {
  current_page?: number;
  from?: number;
  last_page?: number;
  path?: string;
  per_page?: number;
  to?: number;
  total?: number;
};
export type TableData = {
  meta: MetaType;
  links?: {
    first?: string;
    last?: string;
    next?: string;
    prev?: string;
  };
  data: DataType[];
};

export interface ITab {
  label: string;
  type: 'single' | 'album' | 'video' | 'show' | 'artist' | 'episode';
  selectedCount?: number;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
}

export type TableColumnType = {
  title: string;
  width: string;
  dataIndex: string;
  key: string;
  fixed?: string;
  render: (value: any, data: any) => JSX.Element;
};

export const columnCellsWidth = {
  release_info: '25rem',
  release_type: '12rem',
  video_content: '12rem',
  release_status: '10rem',
  release_date: '13rem',
  date_submitted: '13rem',
  collab_artists: '20rem',
  featuring_artists: '20rem',
  release_id: '12rem'
};
