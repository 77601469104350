import { IToastProps } from 'components/Reusable/Toast/toast';
import React from 'react';

export interface IToastContext {
  showToast: (toastData: IToastProps) => void;
  openToast: (title: string, type: IToastProps['type'], subtitle?: string) => void;
  closeToast: () => void;
}

// used to pass the reload method to the table cells
export const toastContext = React.createContext<IToastContext>({
  showToast: () => null,
  openToast: () => null,
  closeToast: () => null
});
