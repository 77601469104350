import React from 'react';
import AppRoutes from 'pages/routes';
import AppSetupWrapper from 'components/AppSetupWrapper/appSetupWrapper';
import LandingPage from 'pages/Landing/LandingPage';
import { useLocation } from 'react-router-dom';
import 'antd/dist/reset.css';
import './App.scss';
import { useCookies } from 'react-cookie';

function App() {
  const location = useLocation();
  const [cookies] = useCookies(['isLoggedIn', 'c-lang']);
  React.useLayoutEffect(() => {
    document.documentElement.lang = cookies['c-lang'] || 'en';
  });

  if (location.pathname === '/' && cookies?.isLoggedIn !== 'true') {
    return <LandingPage />;
  }
  return (
    <AppSetupWrapper>
      <AppRoutes />
    </AppSetupWrapper>
  );
}

export default App;
