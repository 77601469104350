import { environment } from 'env/environment';

export const ALLOWED_MUSIC_FILETYPES = ['.mp3', '.wav', '.mpeg', '.x-wav'];
export const ALLOWED_IMAGE_FILETYPES = ['.jpg', '.jpeg', '.png'];
export const ALLOWED_VIDEOS_FILETYPES = ['.mp4', '.mov'];

export const ARTIST_IMAGE_PLACEHOLDER = `${environment.cdn_url}/web/assets/img/artist-placeholder.png`;
export const ALBUM_IMAGE_PLACEHOLDER = `${environment.cdn_url}/web/assets/img/album-placeholder.png`;
export const PLAYLIST_IMAGE_PLACEHOLDER = `${environment.cdn_url}/web/assets/img/playlist-placeholder.png`;
export const SONG_IMAGE_PLACEHOLDER = `${environment.cdn_url}/web/assets/img/song-placeholder.png`;
export const PROFILE_IMAGE_PLACEHOLDER = `${environment.cdn_url}/web/assets/img/placeholders/profile-placeholder.jpg`;
export const GENERIC_PROFILE_IMAGE = `${environment.cdn_url}/web/assets/img/placeholders/profile-placeholder.jpg`;
