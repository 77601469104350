import React from 'react';
import { userContext } from 'components/contexts/auth/auth.context';
import { useNavigate } from 'react-router-dom';

export default function PermissionGuard({ children, permission }) {
  const { permissions } = React.useContext(userContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!permissions) return;
    if (!permissions[permission]) {
      navigate('-1');
    }
  }, [permissions]);

  return children;
}
