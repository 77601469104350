import React from 'react';
import {
  LineChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
  Tooltip
} from 'recharts';
import SectionItemHeader from '../SectionItemHeader/sectionItemHeader';
import NoData from 'components/Reusable/NoData';
import CustomToolTip from '../CustomToolTip';
import { IEnhancedSectionData } from '../../interfaces';
import { chartTickFormatter } from 'components/Analytics/helpers/charts-helper';

const AnalyticsLineChart = ({ data, title, unit, allTimeData }: IEnhancedSectionData) => {
  const [hasSecondLine, setHasSecondLine] = React.useState(false);
  React.useEffect(() => {
    setHasSecondLine(data.some((i) => i.value2));
  }, [data]);

  return (
    <div className="f-column" style={{ width: '100%', height: '25rem' }}>
      <SectionItemHeader title={title} allTimeData={allTimeData} />
      {data.length === 0 ? (
        <NoData allTimeData={allTimeData} />
      ) : (
        <div className="f-grow-1">
          <ResponsiveContainer height="100%" width="98%">
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="key" />
              <YAxis
                type="number"
                domain={['auto', 'auto']}
                padding={{ bottom: 20, top: 20 }}
                tickFormatter={chartTickFormatter}
              />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#945cff"
                strokeWidth={3}
                unit={unit}
                dot={{
                  r: 5,
                  stroke: '#945cff',
                  fill: '#945cff',
                  strokeWidth: 3
                }}
              />
              <Tooltip content={<CustomToolTip />} />
              {hasSecondLine && (
                <Line
                  type="monotone"
                  dataKey="value2"
                  stroke="#4a3276"
                  unit={unit}
                  strokeWidth={3}
                  dot={{
                    r: 5,
                    stroke: '#4a3276',
                    fill: '#4a3276',
                    strokeWidth: 3
                  }}
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default AnalyticsLineChart;
