import { userContext } from 'components/contexts/auth/auth.context';
import Button from 'components/Reusable/Button';
import Form from 'components/Reusable/Form';
import { combineClassNames } from 'helpers/styling-helper';
import {
  IAlbumSong,
  ICreateSingleFormValues,
  IFormFields,
  ISection,
  IUploadedFile
} from 'interfaces/formsComponents.interface';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './editReleaseDialog.module.scss';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import SongUploader from 'components/CreateRelease/SongUploader';
import UploadCoverArt from 'components/CreateRelease/UploadCoverArt';
import {
  getFormSectionsAndFields,
  getUpdatedFormFields,
  validateFormFields
} from 'helpers/create-release-helper';
import { convertFormValuesToFields } from 'helpers/form-helper';
import { RELEASE_TYPES } from '@anghami/neogateway/dist/endpoints/getreleasedetails';

interface IEditReleaseConfigProps {
  hideUploadCoverArt?: boolean;
  hideUploadSong?: boolean;
  //TODO: add form type and extract form fields to a seperate file
}
interface IEditReleaseDialogProps {
  songInfo: IAlbumSong;
  closeDialog?: () => void;
  onSave: (songInfo: IAlbumSong) => void;
  config?: IEditReleaseConfigProps;
}

export default function EditReleaseDialog({
  songInfo,
  closeDialog,
  onSave,
  config
}: IEditReleaseDialogProps) {
  const { t } = useTranslation('common');
  const [selectedSection, setSelectedSection] = React.useState(0);
  const { user, userType } = useContext(userContext);
  const [formValues, setFormValues] = React.useState<IAlbumSong>(songInfo);
  const [formFields, setFormFields] = React.useState<IFormFields>(null);
  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const [headerMessage, setHeaderMessage] = React.useState(null);
  const [formSections, setFormSections] = React.useState<ISection[]>(null);
  const fillFormFields = (data: IAlbumSong) => {
    const { formSections, formFields: newFormFields } = getFormSectionsAndFields({
      releaseType: data?.ispodcast ? RELEASE_TYPES.Episode : RELEASE_TYPES.Song,
      data: {
        languageId: data?.languageid,
        albumLanguageId: data.albumLanguageId,
        albumType: data?.albumtype,
        hideShowId: true
      },
      accountType: userType,
      isEdit: true
    });
    setFormSections(formSections);
    setFormFields(newFormFields);
    if (!data) return;
    let newFormValues: ICreateSingleFormValues = {
      // Fill user (artist) info
      owner: user?.owner_name,
      artist_name: user?.user,
      // default to international
      languageid: data.languageid == 0 ? 2 : data.languageid,
      releasedate: data.releasedate || data.albumReleaseDate || null,
      releasetime: data.releasetime || data.albumReleaseDate || null,
      coverkey: data.coverkey || data.albumCoverArt?.key || '',
      uploadedCoverArt: data.uploadedCoverArt?.src ? data.uploadedCoverArt : data.albumCoverArt,
      ispodcast: data.ispodcast
    };
    newFormValues = {
      ...data,
      ...newFormValues
    };
    setFormValues(newFormValues);
    setFormFields(convertFormValuesToFields(newFormValues, newFormFields));
  };
  React.useEffect(() => {
    // Fill form fields values from props
    fillFormFields(songInfo);
  }, [songInfo]);
  const handleFormDataChange = (newFormFields: IFormFields, fieldName: string) => {
    setIsFormChanged(true);
    const tempFormFields: IFormFields = getUpdatedFormFields(newFormFields, fieldName);
    setFormFields(tempFormFields);
    setFormValues({
      ...formValues,
      [fieldName]: tempFormFields[fieldName].value,
      ...(tempFormFields[fieldName].extraValueParam && {
        [tempFormFields[fieldName].extraValueParam]: tempFormFields[fieldName].extraValue
      })
    });
  };
  const onUpdateUploadedFile = (uploaded: IUploadedFile) => {
    setFormValues({
      ...formValues,
      uploadedSong: uploaded,
      audiokey: uploaded?.key || null,
      filename: uploaded?.name || null,
      filesize: uploaded?.size || null
    });
  };
  const onUpdateCoverArt = (uploaded: IUploadedFile) => {
    setFormValues({
      ...formValues,
      uploadedCoverArt: uploaded,
      coverkey: uploaded.key
    });
  };
  const validateForm = () => {
    if (!formValues) return false;
    if (!formValues.audiokey) {
      setHeaderMessage(t('You need to upload a file to proceed'));
      return false;
    }
    // only check form when the user change any fields
    if (isFormChanged) {
      const { isValid, updatedFormFields, lastErrorMessage } = validateFormFields(formFields);
      if (!isValid) {
        setFormFields(updatedFormFields);
        setHeaderMessage(lastErrorMessage || t('Form validation fields required'));
      } else {
        setHeaderMessage(null);
      }
      return isValid;
    }
    return true;
  };
  const onSubmit = () => {
    if (validateForm()) {
      onSave(formValues);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <div
          className={combineClassNames(
            styles.sidebar_item,
            selectedSection === 0 && styles.selected
          )}
          onClick={() => setSelectedSection(0)}
        >
          {t('General Information')}
        </div>
        {config?.hideUploadCoverArt || (
          <div
            className={combineClassNames(
              styles.sidebar_item,
              selectedSection === 1 && styles.selected
            )}
            onClick={() => setSelectedSection(1)}
          >
            {t('Cover')}
          </div>
        )}
        {config?.hideUploadSong || (
          <div
            className={combineClassNames(
              styles.sidebar_item,
              selectedSection === 2 && styles.selected
            )}
            onClick={() => setSelectedSection(2)}
          >
            {t('Audio File')}
          </div>
        )}
      </div>
      <div className={styles.main}>
        <div className={styles.close_button} onClick={closeDialog}>
          <CloseIcon width={12} />
        </div>
        <div
          className={combineClassNames(styles.header_message, headerMessage && styles.showing)}
          onClick={() => setHeaderMessage(null)}
        >
          <span>{headerMessage}</span>
        </div>
        <div className={styles.section_container}>
          {selectedSection === 0 ? (
            formSections &&
            formFields && (
              <>
                <Form
                  id={`edit-release-form-${songInfo?.id}`}
                  setFormValues={handleFormDataChange}
                  formValues={formFields}
                  sections={formSections}
                />
              </>
            )
          ) : selectedSection === 1 ? (
            <div className={combineClassNames('w-100 h-100 f-column', styles.coverArt_container)}>
              <UploadCoverArt
                onChange={onUpdateCoverArt}
                value={formValues.uploadedCoverArt}
                isInplace={true}
              />
            </div>
          ) : (
            <div className={styles.song_uploader_container}>
              <SongUploader song={formValues?.uploadedSong} onChange={onUpdateUploadedFile} />
            </div>
          )}
        </div>
        <div className={styles.submit_button_container}>
          <Button
            label={'Submit'}
            size={'md'}
            customClasses={[styles.submit_button]}
            onSubmit={onSubmit}
          ></Button>
        </div>
      </div>
    </div>
  );
}
