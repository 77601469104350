import { IOption } from './../interfaces/formsComponents.interface';
export const allcountrieslist: IOption[] = [
  {
    value: 'AF',
    label: 'Afghanistan'
  },
  {
    value: 'AX',
    label: 'Aland Islands'
  },
  {
    value: 'AL',
    label: 'Albania'
  },
  {
    value: 'DZ',
    label: 'Algeria'
  },
  {
    value: 'AS',
    label: 'American Samoa'
  },
  {
    value: 'AD',
    label: 'Andorra'
  },
  {
    value: 'AO',
    label: 'Angola'
  },
  {
    value: 'AI',
    label: 'Anguilla'
  },
  {
    value: 'AQ',
    label: 'Antarctica'
  },
  {
    value: 'AG',
    label: 'Antigua and Barbuda'
  },
  {
    value: 'AR',
    label: 'Argentina'
  },
  {
    value: 'AM',
    label: 'Armenia'
  },
  {
    value: 'AW',
    label: 'Aruba'
  },
  {
    value: 'AU',
    label: 'Australia'
  },
  {
    value: 'AT',
    label: 'Austria'
  },
  {
    value: 'AZ',
    label: 'Azerbaijan'
  },
  {
    value: 'BS',
    label: 'Bahamas'
  },
  {
    value: 'BH',
    label: 'Bahrain'
  },
  {
    value: 'BD',
    label: 'Bangladesh'
  },
  {
    value: 'BB',
    label: 'Barbados'
  },
  {
    value: 'BY',
    label: 'Belarus'
  },
  {
    value: 'BE',
    label: 'Belgium'
  },
  {
    value: 'BZ',
    label: 'Belize'
  },
  {
    value: 'BJ',
    label: 'Benin'
  },
  {
    value: 'BM',
    label: 'Bermuda'
  },
  {
    value: 'BT',
    label: 'Bhutan'
  },
  {
    value: 'BO',
    label: 'Bolivia'
  },
  {
    value: 'BQ',
    label: 'Bonaire, Sint Eustatius and Saba'
  },
  {
    value: 'BA',
    label: 'Bosnia and Herzegovina'
  },
  {
    value: 'BW',
    label: 'Botswana'
  },
  {
    value: 'BV',
    label: 'Bouvet Island'
  },
  {
    value: 'BR',
    label: 'Brazil'
  },
  {
    value: 'IO',
    label: 'British Indian Ocean Territory'
  },
  {
    value: 'BN',
    label: 'Brunei'
  },
  {
    value: 'BG',
    label: 'Bulgaria'
  },
  {
    value: 'BF',
    label: 'Burkina Faso'
  },
  {
    value: 'BI',
    label: 'Burundi'
  },
  {
    value: 'KH',
    label: 'Cambodia'
  },
  {
    value: 'CM',
    label: 'Cameroon'
  },
  {
    value: 'CA',
    label: 'Canada'
  },
  {
    value: 'CV',
    label: 'Cape Verde'
  },
  {
    value: 'KY',
    label: 'Cayman Islands'
  },
  {
    value: 'CF',
    label: 'Central African Republic'
  },
  {
    value: 'TD',
    label: 'Chad'
  },
  {
    value: 'CL',
    label: 'Chile'
  },
  {
    value: 'CN',
    label: 'China'
  },
  {
    value: 'CX',
    label: 'Christmas Island'
  },
  {
    value: 'CC',
    label: 'Cocos (Keeling) Islands'
  },
  {
    value: 'CO',
    label: 'Colombia'
  },
  {
    value: 'KM',
    label: 'Comoros'
  },
  {
    value: 'CG',
    label: 'Congo'
  },
  {
    value: 'CK',
    label: 'Cook Islands'
  },
  {
    value: 'CR',
    label: 'Costa Rica'
  },
  {
    value: 'CI',
    label: "Cote d'ivoire (Ivory Coast)"
  },
  {
    value: 'HR',
    label: 'Croatia'
  },
  {
    value: 'CU',
    label: 'Cuba'
  },
  {
    value: 'CW',
    label: 'Curacao'
  },
  {
    value: 'CY',
    label: 'Cyprus'
  },
  {
    value: 'CZ',
    label: 'Czech Republic'
  },
  {
    value: 'CD',
    label: 'Democratic Republic of the Congo'
  },
  {
    value: 'DK',
    label: 'Denmark'
  },
  {
    value: 'DJ',
    label: 'Djibouti'
  },
  {
    value: 'DM',
    label: 'Dominica'
  },
  {
    value: 'DO',
    label: 'Dominican Republic'
  },
  {
    value: 'EC',
    label: 'Ecuador'
  },
  {
    value: 'EG',
    label: 'Egypt'
  },
  {
    value: 'SV',
    label: 'El Salvador'
  },
  {
    value: 'GQ',
    label: 'Equatorial Guinea'
  },
  {
    value: 'ER',
    label: 'Eritrea'
  },
  {
    value: 'EE',
    label: 'Estonia'
  },
  {
    value: 'ET',
    label: 'Ethiopia'
  },
  {
    value: 'FK',
    label: 'Falkland Islands (Malvinas)'
  },
  {
    value: 'FO',
    label: 'Faroe Islands'
  },
  {
    value: 'FJ',
    label: 'Fiji'
  },
  {
    value: 'FI',
    label: 'Finland'
  },
  {
    value: 'FR',
    label: 'France'
  },
  {
    value: 'GF',
    label: 'French Guiana'
  },
  {
    value: 'PF',
    label: 'French Polynesia'
  },
  {
    value: 'TF',
    label: 'French Southern Territories'
  },
  {
    value: 'GA',
    label: 'Gabon'
  },
  {
    value: 'GM',
    label: 'Gambia'
  },
  {
    value: 'GE',
    label: 'Georgia'
  },
  {
    value: 'DE',
    label: 'Germany'
  },
  {
    value: 'GH',
    label: 'Ghana'
  },
  {
    value: 'GI',
    label: 'Gibraltar'
  },
  {
    value: 'GR',
    label: 'Greece'
  },
  {
    value: 'GL',
    label: 'Greenland'
  },
  {
    value: 'GD',
    label: 'Grenada'
  },
  {
    value: 'GP',
    label: 'Guadaloupe'
  },
  {
    value: 'GU',
    label: 'Guam'
  },
  {
    value: 'GT',
    label: 'Guatemala'
  },
  {
    value: 'GG',
    label: 'Guernsey'
  },
  {
    value: 'GN',
    label: 'Guinea'
  },
  {
    value: 'GW',
    label: 'Guinea-Bissau'
  },
  {
    value: 'GY',
    label: 'Guyana'
  },
  {
    value: 'HT',
    label: 'Haiti'
  },
  {
    value: 'HM',
    label: 'Heard Island and McDonald Islands'
  },
  {
    value: 'HN',
    label: 'Honduras'
  },
  {
    value: 'HK',
    label: 'Hong Kong'
  },
  {
    value: 'HU',
    label: 'Hungary'
  },
  {
    value: 'IS',
    label: 'Iceland'
  },
  {
    value: 'IN',
    label: 'India'
  },
  {
    value: 'ID',
    label: 'Indonesia'
  },
  {
    value: 'IR',
    label: 'Iran'
  },
  {
    value: 'IQ',
    label: 'Iraq'
  },
  {
    value: 'IE',
    label: 'Ireland'
  },
  {
    value: 'IM',
    label: 'Isle of Man'
  },
  {
    value: 'IT',
    label: 'Italy'
  },
  {
    value: 'JM',
    label: 'Jamaica'
  },
  {
    value: 'JP',
    label: 'Japan'
  },
  {
    value: 'JE',
    label: 'Jersey'
  },
  {
    value: 'JO',
    label: 'Jordan'
  },
  {
    value: 'KZ',
    label: 'Kazakhstan'
  },
  {
    value: 'KE',
    label: 'Kenya'
  },
  {
    value: 'KI',
    label: 'Kiribati'
  },
  {
    value: 'XK',
    label: 'Kosovo'
  },
  {
    value: 'KW',
    label: 'Kuwait'
  },
  {
    value: 'KG',
    label: 'Kyrgyzstan'
  },
  {
    value: 'LA',
    label: 'Laos'
  },
  {
    value: 'LV',
    label: 'Latvia'
  },
  {
    value: 'LB',
    label: 'Lebanon'
  },
  {
    value: 'LS',
    label: 'Lesotho'
  },
  {
    value: 'LR',
    label: 'Liberia'
  },
  {
    value: 'LY',
    label: 'Libya'
  },
  {
    value: 'LI',
    label: 'Liechtenstein'
  },
  {
    value: 'LT',
    label: 'Lithuania'
  },
  {
    value: 'LU',
    label: 'Luxembourg'
  },
  {
    value: 'MO',
    label: 'Macao'
  },
  {
    value: 'MK',
    label: 'Macedonia'
  },
  {
    value: 'MG',
    label: 'Madagascar'
  },
  {
    value: 'MW',
    label: 'Malawi'
  },
  {
    value: 'MY',
    label: 'Malaysia'
  },
  {
    value: 'MV',
    label: 'Maldives'
  },
  {
    value: 'ML',
    label: 'Mali'
  },
  {
    value: 'MT',
    label: 'Malta'
  },
  {
    value: 'MH',
    label: 'Marshall Islands'
  },
  {
    value: 'MQ',
    label: 'Martinique'
  },
  {
    value: 'MR',
    label: 'Mauritania'
  },
  {
    value: 'MU',
    label: 'Mauritius'
  },
  {
    value: 'YT',
    label: 'Mayotte'
  },
  {
    value: 'MX',
    label: 'Mexico'
  },
  {
    value: 'FM',
    label: 'Micronesia'
  },
  {
    value: 'MD',
    label: 'Moldava'
  },
  {
    value: 'MC',
    label: 'Monaco'
  },
  {
    value: 'MN',
    label: 'Mongolia'
  },
  {
    value: 'ME',
    label: 'Montenegro'
  },
  {
    value: 'MS',
    label: 'Montserrat'
  },
  {
    value: 'MA',
    label: 'Morocco'
  },
  {
    value: 'MZ',
    label: 'Mozambique'
  },
  {
    value: 'MM',
    label: 'Myanmar (Burma)'
  },
  {
    value: 'NA',
    label: 'Namibia'
  },
  {
    value: 'NR',
    label: 'Nauru'
  },
  {
    value: 'NP',
    label: 'Nepal'
  },
  {
    value: 'NL',
    label: 'Netherlands'
  },
  {
    value: 'NC',
    label: 'New Caledonia'
  },
  {
    value: 'NZ',
    label: 'New Zealand'
  },
  {
    value: 'NI',
    label: 'Nicaragua'
  },
  {
    value: 'NE',
    label: 'Niger'
  },
  {
    value: 'NG',
    label: 'Nigeria'
  },
  {
    value: 'NU',
    label: 'Niue'
  },
  {
    value: 'NF',
    label: 'Norfolk Island'
  },
  {
    value: 'KP',
    label: 'North Korea'
  },
  {
    value: 'MP',
    label: 'Northern Mariana Islands'
  },
  {
    value: 'NO',
    label: 'Norway'
  },
  {
    value: 'OM',
    label: 'Oman'
  },
  {
    value: 'PK',
    label: 'Pakistan'
  },
  {
    value: 'PW',
    label: 'Palau'
  },
  {
    value: 'PS',
    label: 'Palestine'
  },
  {
    value: 'PA',
    label: 'Panama'
  },
  {
    value: 'PG',
    label: 'Papua New Guinea'
  },
  {
    value: 'PY',
    label: 'Paraguay'
  },
  {
    value: 'PE',
    label: 'Peru'
  },
  {
    value: 'PH',
    label: 'Phillipines'
  },
  {
    value: 'PN',
    label: 'Pitcairn'
  },
  {
    value: 'PL',
    label: 'Poland'
  },
  {
    value: 'PT',
    label: 'Portugal'
  },
  {
    value: 'PR',
    label: 'Puerto Rico'
  },
  {
    value: 'QA',
    label: 'Qatar'
  },
  {
    value: 'RE',
    label: 'Reunion'
  },
  {
    value: 'RO',
    label: 'Romania'
  },
  {
    value: 'RU',
    label: 'Russia'
  },
  {
    value: 'RW',
    label: 'Rwanda'
  },
  {
    value: 'BL',
    label: 'Saint Barthelemy'
  },
  {
    value: 'SH',
    label: 'Saint Helena'
  },
  {
    value: 'KN',
    label: 'Saint Kitts and Nevis'
  },
  {
    value: 'LC',
    label: 'Saint Lucia'
  },
  {
    value: 'MF',
    label: 'Saint Martin'
  },
  {
    value: 'PM',
    label: 'Saint Pierre and Miquelon'
  },
  {
    value: 'VC',
    label: 'Saint Vincent and the Grenadines'
  },
  {
    value: 'WS',
    label: 'Samoa'
  },
  {
    value: 'SM',
    label: 'San Marino'
  },
  {
    value: 'ST',
    label: 'Sao Tome and Principe'
  },
  {
    value: 'SA',
    label: 'Saudi Arabia'
  },
  {
    value: 'SN',
    label: 'Senegal'
  },
  {
    value: 'RS',
    label: 'Serbia'
  },
  {
    value: 'SC',
    label: 'Seychelles'
  },
  {
    value: 'SL',
    label: 'Sierra Leone'
  },
  {
    value: 'SG',
    label: 'Singapore'
  },
  {
    value: 'SX',
    label: 'Sint Maarten'
  },
  {
    value: 'SK',
    label: 'Slovakia'
  },
  {
    value: 'SI',
    label: 'Slovenia'
  },
  {
    value: 'SB',
    label: 'Solomon Islands'
  },
  {
    value: 'SO',
    label: 'Somalia'
  },
  {
    value: 'ZA',
    label: 'South Africa'
  },
  {
    value: 'GS',
    label: 'South Georgia and the South Sandwich Islands'
  },
  {
    value: 'KR',
    label: 'South Korea'
  },
  {
    value: 'SS',
    label: 'South Sudan'
  },
  {
    value: 'ES',
    label: 'Spain'
  },
  {
    value: 'LK',
    label: 'Sri Lanka'
  },
  {
    value: 'SD',
    label: 'Sudan'
  },
  {
    value: 'SR',
    label: 'Suriname'
  },
  {
    value: 'SJ',
    label: 'Svalbard and Jan Mayen'
  },
  {
    value: 'SZ',
    label: 'Swaziland'
  },
  {
    value: 'SE',
    label: 'Sweden'
  },
  {
    value: 'CH',
    label: 'Switzerland'
  },
  {
    value: 'SY',
    label: 'Syria'
  },
  {
    value: 'TW',
    label: 'Taiwan'
  },
  {
    value: 'TJ',
    label: 'Tajikistan'
  },
  {
    value: 'TZ',
    label: 'Tanzania'
  },
  {
    value: 'TH',
    label: 'Thailand'
  },
  {
    value: 'TL',
    label: 'Timor-Leste (East Timor)'
  },
  {
    value: 'TG',
    label: 'Togo'
  },
  {
    value: 'TK',
    label: 'Tokelau'
  },
  {
    value: 'TO',
    label: 'Tonga'
  },
  {
    value: 'TT',
    label: 'Trinidad and Tobago'
  },
  {
    value: 'TN',
    label: 'Tunisia'
  },
  {
    value: 'TR',
    label: 'Turkey'
  },
  {
    value: 'TM',
    label: 'Turkmenistan'
  },
  {
    value: 'TC',
    label: 'Turks and Caicos Islands'
  },
  {
    value: 'TV',
    label: 'Tuvalu'
  },
  {
    value: 'UG',
    label: 'Uganda'
  },
  {
    value: 'UA',
    label: 'Ukraine'
  },
  {
    value: 'AE',
    label: 'United Arab Emirates'
  },
  {
    value: 'GB',
    label: 'United Kingdom'
  },
  {
    value: 'US',
    label: 'United States'
  },
  {
    value: 'UM',
    label: 'United States Minor Outlying Islands'
  },
  {
    value: '??',
    label: 'Unknown'
  },
  {
    value: 'A1',
    label: 'Unknown'
  },
  {
    value: 'A1',
    label: 'Unknown'
  },
  {
    value: 'UY',
    label: 'Uruguay'
  },
  {
    value: 'UZ',
    label: 'Uzbekistan'
  },
  {
    value: 'VU',
    label: 'Vanuatu'
  },
  {
    value: 'VA',
    label: 'Vatican City'
  },
  {
    value: 'VE',
    label: 'Venezuela'
  },
  {
    value: 'VN',
    label: 'Vietnam'
  },
  {
    value: 'VG',
    label: 'Virgin Islands, British'
  },
  {
    value: 'VI',
    label: 'Virgin Islands, US'
  },
  {
    value: 'WF',
    label: 'Wallis and Futuna'
  },
  {
    value: 'EH',
    label: 'Western Sahara'
  },
  {
    value: 'YE',
    label: 'Yemen'
  },
  {
    value: 'ZM',
    label: 'Zambia'
  },
  {
    value: 'ZW',
    label: 'Zimbabwe'
  }
];

export const allcountriesIso2list = [
  'AF',
  'AX',
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AQ',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BQ',
  'BA',
  'BW',
  'BV',
  'BR',
  'IO',
  'BN',
  'BG',
  'BF',
  'BI',
  'KH',
  'CM',
  'CA',
  'CV',
  'KY',
  'CF',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CG',
  'CK',
  'CR',
  'CI',
  'HR',
  'CU',
  'CW',
  'CY',
  'CZ',
  'CD',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'FR',
  'GF',
  'PF',
  'TF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GG',
  'GN',
  'GW',
  'GY',
  'HT',
  'HM',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IM',
  'IT',
  'JM',
  'JP',
  'JE',
  'JO',
  'KZ',
  'KE',
  'KI',
  'XK',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MK',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'ME',
  'MS',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NU',
  'NF',
  'KP',
  'MP',
  'NO',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'PR',
  'QA',
  'RE',
  'RO',
  'RU',
  'RW',
  'BL',
  'SH',
  'KN',
  'LC',
  'MF',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SX',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'GS',
  'KR',
  'SS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SJ',
  'SZ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TT',
  'TN',
  'TR',
  'TM',
  'TC',
  'TV',
  'UG',
  'UA',
  'AE',
  'GB',
  'US',
  'UM',
  '??',
  'A1',
  'A1',
  'UY',
  'UZ',
  'VU',
  'VA',
  'VE',
  'VN',
  'VG',
  'VI',
  'US',
  'WF',
  'EH',
  'YE',
  'ZM',
  'ZW'
];

export const allregions = {
  gcc: ['SA', 'KW', 'OM', 'BH', 'QA', 'YE'],
  egypt: ['EG', 'SS', 'SD'],
  levant: ['LB', 'SY', 'JO', 'PS'],
  maghreb: ['DZ', 'TN', 'MA', 'LY'],
  na: ['EG', 'SS', 'SD']
};

export const countriescode = {
  BD: '880',
  BE: '32',
  BF: '226',
  BG: '359',
  BA: '387',
  BB: '+1-246',
  WF: '681',
  BL: '590',
  BM: '+1-441',
  BN: '673',
  BO: '591',
  BH: '973',
  BI: '257',
  BJ: '229',
  BT: '975',
  JM: '+1-876',
  BV: '',
  BW: '267',
  WS: '685',
  BQ: '599',
  BR: '55',
  BS: '+1-242',
  JE: '+44-1534',
  BY: '375',
  BZ: '501',
  RU: '7',
  RW: '250',
  RS: '381',
  TL: '670',
  RE: '262',
  TM: '993',
  TJ: '992',
  RO: '40',
  TK: '690',
  GW: '245',
  GU: '+1-671',
  GT: '502',
  GS: '',
  GR: '30',
  GQ: '240',
  GP: '590',
  JP: '81',
  GY: '592',
  GG: '+44-1481',
  GF: '594',
  GE: '995',
  GD: '+1-473',
  GB: '44',
  GA: '241',
  SV: '503',
  GN: '224',
  GM: '220',
  GL: '299',
  GI: '350',
  GH: '233',
  OM: '968',
  TN: '216',
  JO: '962',
  HR: '385',
  HT: '509',
  HU: '36',
  HK: '852',
  HN: '504',
  HM: ' ',
  VE: '58',
  PR: '+1-787 and 1-939',
  PS: '970',
  PW: '680',
  PT: '351',
  SJ: '47',
  PY: '595',
  IQ: '964',
  PA: '507',
  PF: '689',
  PG: '675',
  PE: '51',
  PK: '92',
  PH: '63',
  PN: '870',
  PL: '48',
  PM: '508',
  ZM: '260',
  EH: '212',
  EE: '372',
  EG: '20',
  ZA: '27',
  EC: '593',
  IT: '39',
  VN: '84',
  SB: '677',
  ET: '251',
  SO: '252',
  ZW: '263',
  SA: '966',
  ES: '34',
  ER: '291',
  ME: '382',
  MD: '373',
  MG: '261',
  MF: '590',
  MA: '212',
  MC: '377',
  UZ: '998',
  MM: '95',
  ML: '223',
  MO: '853',
  MN: '976',
  MH: '692',
  MK: '389',
  MU: '230',
  MT: '356',
  MW: '265',
  MV: '960',
  MQ: '596',
  MP: '+1-670',
  MS: '+1-664',
  MR: '222',
  IM: '+44-1624',
  UG: '256',
  TZ: '255',
  MY: '60',
  MX: '52',
  IL: '972',
  FR: '33',
  IO: '246',
  SH: '290',
  FI: '358',
  FJ: '679',
  FK: '500',
  FM: '691',
  FO: '298',
  NI: '505',
  NL: '31',
  NO: '47',
  NA: '264',
  VU: '678',
  NC: '687',
  NE: '227',
  NF: '672',
  NG: '234',
  NZ: '64',
  NP: '977',
  NR: '674',
  NU: '683',
  CK: '682',
  XK: '',
  CI: '225',
  CH: '41',
  CO: '57',
  CN: '86',
  CM: '237',
  CL: '56',
  CC: '61',
  CA: '1',
  CG: '242',
  CF: '236',
  CD: '243',
  CZ: '420',
  CY: '357',
  CX: '61',
  CR: '506',
  CW: '599',
  CV: '238',
  CU: '53',
  SZ: '268',
  SY: '963',
  SX: '599',
  KG: '996',
  KE: '254',
  SS: '211',
  SR: '597',
  KI: '686',
  KH: '855',
  KN: '+1-869',
  KM: '269',
  ST: '239',
  SK: '421',
  KR: '82',
  SI: '386',
  KP: '850',
  KW: '965',
  SN: '221',
  SM: '378',
  SL: '232',
  SC: '248',
  KZ: '7',
  KY: '+1-345',
  SG: '65',
  SE: '46',
  SD: '249',
  DO: '+1-809 and 1-829',
  DM: '+1-767',
  DJ: '253',
  DK: '45',
  VG: '+1-284',
  DE: '49',
  YE: '967',
  DZ: '213',
  US: '1',
  UY: '598',
  YT: '262',
  UM: '1',
  LB: '961',
  LC: '+1-758',
  LA: '856',
  TV: '688',
  TW: '886',
  TT: '+1-868',
  TR: '90',
  LK: '94',
  LI: '423',
  LV: '371',
  TO: '676',
  LT: '370',
  LU: '352',
  LR: '231',
  LS: '266',
  TH: '66',
  TF: '',
  TG: '228',
  TD: '235',
  TC: '+1-649',
  LY: '218',
  VA: '379',
  VC: '+1-784',
  AE: '971',
  AD: '376',
  AG: '+1-268',
  AF: '93',
  AI: '+1-264',
  VI: '+1-340',
  IS: '354',
  IR: '98',
  AM: '374',
  AL: '355',
  AO: '244',
  AQ: '',
  AS: '+1-684',
  AR: '54',
  AU: '61',
  AT: '43',
  AW: '297',
  IN: '91',
  AX: '+358-18',
  AZ: '994',
  IE: '353',
  ID: '62',
  UA: '380',
  QA: '974',
  MZ: '258'
};

export const countriesbycode = {
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  WF: 'Wallis and Futuna',
  BL: 'Saint Barthelemy',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BT: 'Bhutan',
  JM: 'Jamaica',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  WS: 'Samoa',
  BQ: 'Bonaire, Saint Eustatius and Saba ',
  BR: 'Brazil',
  BS: 'Bahamas',
  JE: 'Jersey',
  BY: 'Belarus',
  BZ: 'Belize',
  RU: 'Russia',
  RW: 'Rwanda',
  RS: 'Serbia',
  TL: 'East Timor',
  RE: 'Reunion',
  TM: 'Turkmenistan',
  TJ: 'Tajikistan',
  RO: 'Romania',
  TK: 'Tokelau',
  GW: 'Guinea-Bissau',
  GU: 'Guam',
  GT: 'Guatemala',
  GS: 'South Georgia and the South Sandwich Islands',
  GR: 'Greece',
  GQ: 'Equatorial Guinea',
  GP: 'Guadeloupe',
  JP: 'Japan',
  GY: 'Guyana',
  GG: 'Guernsey',
  GF: 'French Guiana',
  GE: 'Georgia',
  GD: 'Grenada',
  GB: 'United Kingdom',
  GA: 'Gabon',
  SV: 'El Salvador',
  GN: 'Guinea',
  GM: 'Gambia',
  GL: 'Greenland',
  GI: 'Gibraltar',
  GH: 'Ghana',
  OM: 'Oman',
  TN: 'Tunisia',
  JO: 'Jordan',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  HK: 'Hong Kong',
  HN: 'Honduras',
  HM: 'Heard Island and McDonald Islands',
  VE: 'Venezuela',
  PR: 'Puerto Rico',
  PS: 'Palestinian Territory',
  PW: 'Palau',
  PT: 'Portugal',
  SJ: 'Svalbard and Jan Mayen',
  PY: 'Paraguay',
  IQ: 'Iraq',
  PA: 'Panama',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PE: 'Peru',
  PK: 'Pakistan',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PM: 'Saint Pierre and Miquelon',
  ZM: 'Zambia',
  EH: 'Western Sahara',
  EE: 'Estonia',
  EG: 'Egypt',
  ZA: 'South Africa',
  EC: 'Ecuador',
  IT: 'Italy',
  VN: 'Vietnam',
  SB: 'Solomon Islands',
  ET: 'Ethiopia',
  SO: 'Somalia',
  ZW: 'Zimbabwe',
  SA: 'Saudi Arabia',
  ES: 'Spain',
  ER: 'Eritrea',
  ME: 'Montenegro',
  MD: 'Moldova',
  MG: 'Madagascar',
  MF: 'Saint Martin',
  MA: 'Morocco',
  MC: 'Monaco',
  UZ: 'Uzbekistan',
  MM: 'Myanmar',
  ML: 'Mali',
  MO: 'Macao',
  MN: 'Mongolia',
  MH: 'Marshall Islands',
  MK: 'Macedonia',
  MU: 'Mauritius',
  MT: 'Malta',
  MW: 'Malawi',
  MV: 'Maldives',
  MQ: 'Martinique',
  MP: 'Northern Mariana Islands',
  MS: 'Montserrat',
  MR: 'Mauritania',
  IM: 'Isle of Man',
  UG: 'Uganda',
  TZ: 'Tanzania',
  MY: 'Malaysia',
  MX: 'Mexico',
  IL: 'Israel',
  FR: 'France',
  IO: 'British Indian Ocean Territory',
  SH: 'Saint Helena',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falkland Islands',
  FM: 'Micronesia',
  FO: 'Faroe Islands',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NA: 'Namibia',
  VU: 'Vanuatu',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NZ: 'New Zealand',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  CK: 'Cook Islands',
  XK: 'Kosovo',
  CI: 'Ivory Coast',
  CH: 'Switzerland',
  CO: 'Colombia',
  CN: 'China',
  CM: 'Cameroon',
  CL: 'Chile',
  CC: 'Cocos Islands',
  CA: 'Canada',
  CG: 'Republic of the Congo',
  CF: 'Central African Republic',
  CD: 'Democratic Republic of the Congo',
  CZ: 'Czech Republic',
  CY: 'Cyprus',
  CX: 'Christmas Island',
  CR: 'Costa Rica',
  CW: 'Curacao',
  CV: 'Cape Verde',
  CU: 'Cuba',
  SZ: 'Swaziland',
  SY: 'Syria',
  SX: 'Sint Maarten',
  KG: 'Kyrgyzstan',
  KE: 'Kenya',
  SS: 'South Sudan',
  SR: 'Suriname',
  KI: 'Kiribati',
  KH: 'Cambodia',
  KN: 'Saint Kitts and Nevis',
  KM: 'Comoros',
  ST: 'Sao Tome and Principe',
  SK: 'Slovakia',
  KR: 'South Korea',
  SI: 'Slovenia',
  KP: 'North Korea',
  KW: 'Kuwait',
  SN: 'Senegal',
  SM: 'San Marino',
  SL: 'Sierra Leone',
  SC: 'Seychelles',
  KZ: 'Kazakhstan',
  KY: 'Cayman Islands',
  SG: 'Singapore',
  SE: 'Sweden',
  SD: 'Sudan',
  DO: 'Dominican Republic',
  DM: 'Dominica',
  DJ: 'Djibouti',
  DK: 'Denmark',
  VG: 'British Virgin Islands',
  DE: 'Germany',
  YE: 'Yemen',
  DZ: 'Algeria',
  US: 'United States',
  UY: 'Uruguay',
  YT: 'Mayotte',
  UM: 'United States Minor Outlying Islands',
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LA: 'Laos',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TT: 'Trinidad and Tobago',
  TR: 'Turkey',
  LK: 'Sri Lanka',
  LI: 'Liechtenstein',
  LV: 'Latvia',
  TO: 'Tonga',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LR: 'Liberia',
  LS: 'Lesotho',
  TH: 'Thailand',
  TF: 'French Southern Territories',
  TG: 'Togo',
  TD: 'Chad',
  TC: 'Turks and Caicos Islands',
  LY: 'Libya',
  VA: 'Vatican',
  VC: 'Saint Vincent and the Grenadines',
  AE: 'United Arab Emirates',
  AD: 'Andorra',
  AG: 'Antigua and Barbuda',
  AF: 'Afghanistan',
  AI: 'Anguilla',
  VI: 'U.S. Virgin Islands',
  IS: 'Iceland',
  IR: 'Iran',
  AM: 'Armenia',
  AL: 'Albania',
  AO: 'Angola',
  AQ: 'Antarctica',
  AS: 'American Samoa',
  AR: 'Argentina',
  AU: 'Australia',
  AT: 'Austria',
  AW: 'Aruba',
  IN: 'India',
  AX: 'Aland Islands',
  AZ: 'Azerbaijan',
  IE: 'Ireland',
  ID: 'Indonesia',
  UA: 'Ukraine',
  QA: 'Qatar',
  MZ: 'Mozambique'
};
