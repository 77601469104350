import { gateway } from 'helpers/gateway-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import getFeaturedItems, {
  IFeaturedItem,
  IGetFeaturedItemsResponse
} from '@anghami/neogateway/dist/endpoints/getFeaturedItems';
import { userContext } from 'components/contexts/auth/auth.context';
import { loaderContext } from 'components/contexts/loader/loader.context';
import FeaturedCategory from 'components/Featured/FeaturedCategory';
import { FeaturedTypes } from 'components/Featured/Interfaces/featured.interface';
import Button from 'components/Reusable/Button';
import { toastContext } from 'components/contexts/toast/toast.context';
import { setPageTitle } from 'helpers/url-helper';
import { logGeneralErrorEvent } from 'helpers/analytics-helper';
import { combineClassNames } from 'helpers/styling-helper';
import { useWindowDimensions } from 'hooks/useWindowDimensions';

const FEATURED_ITEMS_LIMIT = 5;

export default function FeaturedPage() {
  const { t } = useTranslation('common');
  const { user } = React.useContext(userContext);
  const { setIsLoading } = React.useContext(loaderContext);
  const { openToast } = React.useContext(toastContext);
  const [featuredItems, setFeaturedItems] =
    React.useState<IGetFeaturedItemsResponse['response']>(null);
  const [canAddMoreItems, setCanAddMoreItems] = React.useState<boolean>(true);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = React.useState(true);
  const { isMobile } = useWindowDimensions();
  const { data, isLoading } = useQuery([user?.anid, 'user-featured-filled'], async () => {
    const { data } = await gateway.callEndpoint(getFeaturedItems, {
      operation: 'fill',
      artistid: user?.anid
    });
    return data.response;
  });

  const featuredChanges = useMutation(
    () => {
      return gateway.callEndpoint(getFeaturedItems, {
        operation: 'update',
        artistid: user?.anid,
        ...(featuredItems.featured_albums && {
          featured_albums: featuredItems.featured_albums.filter(Boolean).map((i) => i.id)
        }),
        ...(featuredItems.featured_playlists && {
          featured_playlists: featuredItems.featured_playlists.filter(Boolean).map((i) => i.id)
        }),
        ...(featuredItems.featured_songs && {
          featured_songs: featuredItems.featured_songs.filter(Boolean).map((i) => i.id)
        })
      });
    },
    {
      onSettled: (data, error) => {
        if (!data?.data || error) {
          logGeneralErrorEvent(error, 'update featured items');
          openToast(t('something-went-wrong-try-again'), 'error');
          return;
        }
        if (data.data.error) {
          openToast(data.data.error.message || t('something-went-wrong-try-again'), 'error');
          return;
        }
        openToast(t('Changes Synced'), 'success');
      }
    }
  );

  React.useEffect(() => {
    setPageTitle('Featured');
  }, []);

  React.useEffect(() => {
    setIsLoading(isLoading);
    return () => {
      setIsLoading(false);
    };
  }, [isLoading]);

  React.useEffect(() => {
    if (!data) return;
    setFeaturedItems(data);
  }, [data]);

  React.useEffect(() => {
    if (!featuredItems) return;
    setCanAddMoreItems(
      FEATURED_ITEMS_LIMIT >
        featuredItems.featured_albums?.length +
          featuredItems.featured_playlists?.length +
          featuredItems.featured_songs?.length
    );
  }, [featuredItems]);

  React.useEffect(() => {
    if (canAddMoreItems) return;
    openToast(t('You reached the limit of featured items'), 'warning');
  }, [canAddMoreItems]);

  const handleSave = () => {
    featuredChanges.mutate();
  };

  const handleItemsChange = (type: FeaturedTypes, items: IFeaturedItem[]) => {
    setFeaturedItems({
      ...featuredItems,
      ...(type === FeaturedTypes.albums && { featured_albums: items }),
      ...(type === FeaturedTypes.playlists && { featured_playlists: items }),
      ...(type === FeaturedTypes.songs && { featured_songs: items })
    });
    setIsSaveButtonDisabled(false);
  };

  return (
    <div className="p-2 f-column h-100">
      <div className="d-flex f-align-center f-justify-between">
        <div>
          <h1>{t('Featured On')}</h1>
          <p>{t('Customize your Anghami artists profile with content that you like')}</p>
        </div>
        <Button
          label={t('Save')}
          size="md"
          customClasses={['purpleFilled']}
          onSubmit={handleSave}
          disabled={isSaveButtonDisabled}
        />
      </div>
      {featuredItems && (
        <div
          className={combineClassNames(
            'd-flex f-grow-1 gap-1 overflow-scroll',
            isMobile && ' f-column'
          )}
        >
          <FeaturedCategory
            type={FeaturedTypes.playlists}
            title={t('Playlists')}
            filledItems={featuredItems.featured_playlists || []}
            onItemsUpdate={handleItemsChange}
            isAddDisabled={!canAddMoreItems}
          />
          <FeaturedCategory
            type={FeaturedTypes.albums}
            title={t('Albums')}
            filledItems={featuredItems.featured_albums || []}
            onItemsUpdate={handleItemsChange}
            isAddDisabled={!canAddMoreItems}
          />
          <FeaturedCategory
            type={FeaturedTypes.songs}
            title={t('Songs')}
            filledItems={featuredItems.featured_songs || []}
            onItemsUpdate={handleItemsChange}
            isAddDisabled={!canAddMoreItems}
          />
        </div>
      )}
    </div>
  );
}
