import { gateway } from 'helpers/gateway-helper';
import React from 'react';
import { useQuery } from 'react-query';
import commonStyles from '../crCommon.module.scss';
import getPendingSongsRequests, {
  ChangeRequestsStatus
} from '@anghami/neogateway/dist/endpoints/getPendingSongsRequests';
import { BeatLoader } from 'components/Reusable/Loaders';
import SongRequestItem from '../SongRequestItem';
import { useTranslation } from 'react-i18next';
import Pagination from 'antd/es/pagination';

export default function SongsRequests({
  selectedFilter = ChangeRequestsStatus.all,
  parentElement
}: {
  selectedFilter?: ChangeRequestsStatus;
  parentElement: HTMLElement;
}) {
  const { t } = useTranslation('common');
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState(50);
  const { data, isLoading, error, isFetching } = useQuery(
    ['songs-requests', currentPage, pageLimit, selectedFilter],
    async () => {
      const { data } = await gateway.callEndpoint(getPendingSongsRequests, {
        source: 'mychangerequest',
        status: selectedFilter,
        output: 'jsonhp',
        offset: currentPage,
        limit: pageLimit
      });
      return data;
    }
  );
  React.useEffect(() => {
    if (!parentElement) return;
    setTimeout(() => {
      parentElement?.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'nearest' });
    }, 200);
  }, [data]);

  const handlePaginationChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageLimit(pageSize);
  };
  if (isLoading || isFetching) {
    return (
      <div className="w-100 h-100 f-center" style={{ paddingTop: '2rem' }}>
        <BeatLoader />
      </div>
    );
  }
  if (!data || error) {
    return <div style={{ padding: '2rem' }}>{t('something-went-wrong-try-again')}</div>;
  }
  if (data.error) {
    return <div style={{ padding: '2rem' }}>{data.error.message || t('Something went wrong')}</div>;
  }
  if (Number(data.count) === 0 || !data.requests || data.requests?.length === 0) {
    return <div style={{ padding: '2rem' }}>{t('No pending requests')}</div>;
  }
  return (
    <div className={commonStyles.container}>
      {data?.requests?.map((req, index) => (
        <SongRequestItem key={req.id + '-' + index} request={req} />
      ))}
      <Pagination
        defaultCurrent={currentPage}
        total={Number(data.count)}
        pageSize={pageLimit}
        onChange={handlePaginationChange}
        hideOnSinglePage={true}
      />
    </div>
  );
}
