import React from 'react';
import Select from 'antd/lib/select';
import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import { getAnalyticsPeriods, IPeriodValue } from '../constants/analytics.contants';
import styles from './periodSelector.module.scss';
import { TimePeriods } from '@anghami/neogateway/dist/endpoints/getAnalyticsData';
import { combineClassNames } from 'helpers/styling-helper';
import DateRangePickerDialog from 'components/Reusable/DateRangePickerDialog';
import { subDays } from 'date-fns/esm';

const { Option } = Select;

interface IPeriodSelectorProps {
  value: IPeriodValue;
  onChange: (value: IPeriodValue) => void;
}

export default function PeriodSelector({ value, onChange }: IPeriodSelectorProps) {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const handlePeriodChange = (IPeriodValue: any) => {
    if (IPeriodValue !== TimePeriods.CUSTOM) {
      onChange(getAnalyticsPeriods()[IPeriodValue]);
    } else {
      setIsModalVisible(true);
    }
  };

  const handleModalSubmit = (from: string, to: string) => {
    const IPeriodValue = { from, to };
    onChange({ ...getAnalyticsPeriods()[TimePeriods.CUSTOM], ...IPeriodValue });
  };

  const disableDates = (current: Date) => {
    if (!current) return false;
    return (
      new Date().getTime() < current.getTime() ||
      new Date('2013-01-01').getTime() > current.getTime()
    );
  };

  return (
    <div>
      <Select
        value={value}
        bordered={false}
        className={styles.selector}
        dropdownStyle={{ borderRadius: '6px' }}
        onSelect={handlePeriodChange}
      >
        {Object.keys(getAnalyticsPeriods()).map((periodKey) => {
          const IPeriodValue = getAnalyticsPeriods()[periodKey];
          return (
            <Option value={periodKey} key={periodKey}>
              <div
                className={combineClassNames(
                  'd-flex f-justify-start f-align-center gap-1',
                  styles.selectorOption
                )}
              >
                <CalendarOutlined style={{ color: '#A1A5AC' }} />
                <span className={styles.optionText}>{IPeriodValue.displayname}</span>
              </div>
            </Option>
          );
        })}
      </Select>
      <DateRangePickerDialog
        isOpen={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onChange={handleModalSubmit}
        defaultFrom={value?.from || subDays(new Date(), 30).toString()}
        defaultTo={value?.to || subDays(new Date(), 1).toString()}
        disableDates={disableDates}
      />
    </div>
  );
}
