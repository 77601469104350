import React from 'react';
import Button from 'components/Reusable/Button';
import { ReactComponent as HelpIcon } from 'icons/help.svg';
import { useTranslation } from 'react-i18next';
import { SidemenuContext } from './sidemenu.context';

export default function SidemenuNeedHelpButton() {
  const { t } = useTranslation();
  const { isMinimized, isMobile } = React.useContext(SidemenuContext);
  return (
    <Button
      label={!isMinimized || isMobile ? t('Need help getting started?') : ''}
      withBackground={'#f8f8f8'}
      icon={<HelpIcon height={13} width={13} />}
      customStyles={{ fontSize: '0.8rem' }}
      customClasses={['default', 'sm', 'centered', 'w-100']}
      onSubmit={() => {
        window.location.href =
          'https://support.anghami.com/hc/en-us/categories/360000029987--Artist-Connect';
      }}
    />
  );
}
