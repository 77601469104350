import Button from 'components/Reusable/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function RssEpisodesCell({
  episodesCount,
  feedId,
  feedName
}: {
  episodesCount: string;
  feedId: string;
  feedName: string;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(Object.entries({ feedName })).toString();
  return (
    <div className="d-flex f-align-center f-justify-between w-100">
      <span>{episodesCount}</span>
      <Button
        label={t('view')}
        size="xsm"
        customClasses={['default']}
        onSubmit={() => navigate('/library/rss/feed/' + feedId + '?' + queryParams)}
      />
    </div>
  );
}
