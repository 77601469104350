import { IReportResponse } from '@anghami/neogateway/dist/endpoints/getOwnersReports';
import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import { tableTypeTitleMap } from '../constants';
import { getTableColumns, getTableData } from '../helpers';
import { TablesTypes } from '../interfaces';
import styles from './reportsTable.module.scss';
import Table from 'antd/lib/table';

export default function ReportsTable({
  tableData,
  type,
  extras
}: {
  tableData: IReportResponse;
  type?: TablesTypes;
  extras: { ownerName?: string; groupOwnerName?: string };
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [columns, setColumns] = React.useState(null);
  const tableRef = React.useRef();

  React.useEffect(() => {
    if (!tableData) return;
    const customDateKeys = Object.keys(tableData).filter((k) => k !== 'TOTAL');
    setData(getTableData(tableData, type, customDateKeys, extras));
    setColumns(getTableColumns(type, customDateKeys));
    setIsLoading(false);
  }, [tableData]);

  if (!data || data.length === 0) return <></>;

  return (
    <div className={combineClassNames('mt-1', styles.tableContainer)}>
      <h2 className="mb-1">{type ? tableTypeTitleMap[type] : data[0].report_month}</h2>
      {isLoading || !data ? (
        <span>loading</span>
      ) : (
        <Table
          bordered={true}
          rowKey={'key'}
          ref={tableRef}
          pagination={false}
          columns={columns}
          dataSource={data}
        />
      )}
    </div>
  );
}
