import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './landingContainer.module.scss';
import { environment } from 'env/environment';
import { updateSidemenuCSSVariables } from '../sidemenu/sidemenu.helper';

function LandingContainerLoader() {
  const { t } = useTranslation();
  React.useEffect(() => {
    updateSidemenuCSSVariables(false);
  }, []);
  return (
    <div className={styles.mainContainer}>
      <div className={combineClassNames('f-column p-2  f-1 f-grow', styles.backgroundSection)}>
        <img
          src={`${environment.creators_assets_cdn}/images/anghami_creators_black.png`}
          className={styles.pageLogo}
          alt="Artist-Dashboard logo"
        />
        <h1 className={styles.pageTitle}>
          {t('Welcome to Anghami for')} <span>{t('Creators')}</span>
        </h1>
        <div
          className={combineClassNames(
            'w-100 d-flex f-justify-center',
            styles.desktopLanguageSelector
          )}
        ></div>
      </div>
      <div className={combineClassNames(styles.outletSection)}>
        <div className="h-100"></div>
      </div>
    </div>
  );
}

export default LandingContainerLoader;
