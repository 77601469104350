import React from 'react';
import { combineClassNames } from 'helpers/styling-helper';
import styles from './stepsBar.module.scss';
import { ReactComponent as TickIcon } from 'icons/tick.svg';

interface IStepsBarProps {
  steps: { value: string }[];
  stepIndex: number;
  stepClicked?: (stepIndex: number) => void;
}
export default function StepsBar({ steps, stepIndex, stepClicked }: IStepsBarProps) {
  return (
    <div className={combineClassNames(styles.stepsWrapper, 'd-flex', 'f-align-center')}>
      <div className={combineClassNames('d-flex', 'f-align-center', styles.stepsWrapper_container)}>
        {steps.map((step, index) => {
          const isClickable = stepClicked && index <= stepIndex;
          return (
            <div
              key={step.value + index}
              className={combineClassNames(
                'f-column',
                'f-align-center',
                styles.stepItem,
                index <= stepIndex && styles.completed
              )}
              style={{ cursor: isClickable ? 'pointer' : 'default' }}
              onClick={() => {
                isClickable && stepClicked(index);
              }}
            >
              <div className={styles.stepValue}>{step?.value}</div>
              <div
                className={combineClassNames(
                  styles.stepIcon,
                  'd-flex',
                  'f-align-center',
                  (index === stepIndex || index < stepIndex) && styles.filled
                )}
              >
                {index < stepIndex ? (
                  <TickIcon fill={'#fff'} className={styles.tickIcon} />
                ) : (
                  <span className={styles.step_text}>{index + 1}</span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
