import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IRequest } from '@anghami/neogateway/dist/endpoints/getPendingSongsRequests';
import styles from './songRequestItem.module.scss';
import RequestStatusField from '../RequestStatusField';
import LinkItem from 'components/Reusable/LinkItem';

const SongRequestItem = ({ request }: { request: IRequest }) => {
  const { t } = useTranslation('common');
  return (
    <div className={combineClassNames('d-flex', styles.songItemContainer)}>
      <div className={combineClassNames('f-1', styles.section)}>
        <div className={styles.firstSectionItem}>
          <h3>{t('Request ID')}</h3>
          <p>{request.id}</p>
        </div>
        <div className={styles.firstSectionItem}>
          <h3>{t('Requested on')}</h3>
          <p>{request.createdon}</p>
        </div>
        <div className={styles.firstSectionItem}>
          <h3>{t('Artist ID')}</h3>
          <LinkItem
            link={`https://play.anghami.com/artist/${request.artistid}`}
            text={request.artistid}
            newTab={true}
          />
        </div>
        <div className={styles.firstSectionItem}>
          <h3>{t('Owner')}</h3>
          <p>{request.ownerid}</p>
        </div>
        <div className={styles.firstSectionItem}>
          <h3>{t('Status')}</h3>
          <RequestStatusField status={request.status} />
        </div>
      </div>
      <div className={combineClassNames('f-1 f-column gap-1', styles.section)}>
        <div>
          <div className="d-flex f-align-center gap-05 mb-1">
            <h3 className={styles.uppercaseHeader}>{t('Album')}</h3>
            <LinkItem
              link={`https://play.anghami.com/album/${request.albumid}`}
              text={request.albumid}
              customClassName={styles.titleLink}
              newTab={true}
            />
          </div>
          <div
            className={combineClassNames('d-flex f-align-center gap-2', styles.secondSectionItem)}
          >
            <h4>{t('Name')}</h4>
            <div>
              <p className={styles.primaryText}>{request.albumname}</p>
              <p className={styles.secondaryText}>{request.oldvalues?.albumname}</p>
            </div>
          </div>
          <div
            className={combineClassNames('d-flex f-align-center gap-2', styles.secondSectionItem)}
          >
            <h4>{t('Name Ar')}</h4>
            <div>
              <p className={styles.primaryText}>{request.albumnameAr}</p>
              <p className={styles.secondaryText}>{request.oldvalues?.albumnameAr}</p>
            </div>
          </div>
          <div
            className={combineClassNames('d-flex f-align-center gap-2', styles.secondSectionItem)}
          >
            <h4>{t('Type')}</h4>
            <div>
              <p className={styles.primaryText}>{request.albumtype}</p>
              <p className={styles.secondaryText}>{request.oldvalues?.albumtype}</p>
            </div>
          </div>
        </div>
        <div>
          <div className="d-flex f-align-center gap-05 mb-1">
            <h3 className={styles.uppercaseHeader}>{t('Song')}</h3>
            <LinkItem
              link={`https://play.anghami.com/song/${request.songid}`}
              text={request.songid}
              customClassName={styles.titleLink}
              newTab={true}
            />
          </div>
          <div
            className={combineClassNames('d-flex f-align-center gap-2', styles.secondSectionItem)}
          >
            <h4>{t('Name')}</h4>
            <div>
              <p className={styles.primaryText}>{request.Name}</p>
              <p className={styles.secondaryText}>{request.oldvalues?.Name}</p>
            </div>
          </div>
          <div
            className={combineClassNames('d-flex f-align-center gap-2', styles.secondSectionItem)}
          >
            <h4>{t('Name Ar')}</h4>
            <div>
              <p className={styles.primaryText}>{request.NameAr}</p>
              <p className={styles.secondaryText}>{request.oldvalues?.NameAr}</p>
            </div>
          </div>
          <div
            className={combineClassNames('d-flex f-align-center gap-2', styles.secondSectionItem)}
          >
            <h4>{t('Language')}</h4>
            <div>
              <p className={styles.primaryText}>{request.Language}</p>
            </div>
          </div>
          <div
            className={combineClassNames('d-flex f-align-center gap-2', styles.secondSectionItem)}
          >
            <h4>{t('Genre')}</h4>
            <div>
              <p className={styles.primaryText}>{request.Genre}</p>
              <p className={styles.secondaryText}>{request.oldvalues?.Genre}</p>
            </div>
          </div>
          <div
            className={combineClassNames('d-flex f-align-center gap-2', styles.secondSectionItem)}
          >
            <h4>{t('Lyrics')}</h4>
            <div>
              <p className={styles.primaryText}>{request.lyrics}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SongRequestItem;
