import React, { useEffect, useState } from 'react';
import styles from './perksOfAnghami.module.scss';
import { combineClassNames } from 'helpers/styling-helper';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { useTranslation } from 'react-i18next';
import { environment } from 'env/environment';

const Card = (props) => (
  <div className={styles.cardContainer} style={{ ...props.style }}>
    <img src={props.imgUrl} alt={props.alt || 'Image'} />
    <div className={styles.cardContent}>
      <h2>{props.title}</h2>
      <p>{props.content}</p>
    </div>
  </div>
);

const MAX_WIDTH_MOBILE = 550;
const CARDS_PER_PAGE_DESKTOP = 2;
const CARDS_PER_PAGE_DESKTOP_LIMIT = 3;
const CARDS_PER_PAGE_MOBILE = 1;

function PerksOfAnghami() {
  const { t } = useTranslation();
  const cardsData = [
    {
      id: 0,
      title: `${t(
        'creators.landing.section1.subtitle.reach',
        'Connect with the Region’s Biggest Audience'
      )}`,
      content: `${t(
        'creators.landing.section1.subtitle.reach.description',
        'Our tools will help you build your audience, connect with them and reach new potential fans.'
      )}`,
      imgUrl: `${environment.creators_assets_cdn}/images/anghami_perks_1.webp`
    },
    {
      id: 1,
      title: `${t('creators.landing.section1.subtitle.upload', 'Upload Music & Content for FREE')}`,
      content: `${t(
        'creators.landing.section1.subtitle.upload.description',
        'Pay 0% Fees and get 100% Royalties.'
      )}`,
      imgUrl: `${environment.creators_assets_cdn}/images/anghami_perks_2.webp`
    }
  ];

  const cardsNumber = cardsData.length;

  const [start, setStart] = useState(0);
  const [cardsPerPage, setCardsPerPage] = useState(CARDS_PER_PAGE_DESKTOP);
  const [iterator, setIterator] = useState(Array.from({ length: cardsPerPage }, (x, i) => i));
  const [showArrow, setShowArrow] = useState(false);

  const { width } = useWindowDimensions();

  const scrollForward = () => {
    setStart((start + cardsPerPage) % cardsNumber);
  };

  const scrollBackward = () => {
    if (start < cardsPerPage) {
      setStart(cardsNumber - cardsPerPage + start);
    } else {
      setStart((start - cardsPerPage) % cardsNumber);
    }
  };

  useEffect(() => {
    if (width > MAX_WIDTH_MOBILE) {
      const interval = setInterval(() => scrollForward(), 5000);
      return () => clearInterval(interval);
    }
  });

  useEffect(() => {
    if (width < MAX_WIDTH_MOBILE) {
      setShowArrow(false);
      setCardsPerPage(CARDS_PER_PAGE_MOBILE);
      setIterator([0, 1]);
    } else {
      setShowArrow(cardsData.length > CARDS_PER_PAGE_DESKTOP);
      setCardsPerPage(CARDS_PER_PAGE_DESKTOP);
      setIterator(Array.from({ length: CARDS_PER_PAGE_DESKTOP }, (x, i) => i));
    }
  }, [width]);

  return (
    <div
      className={combineClassNames(
        'd-flex',
        'f-column',
        'f-justify-center',
        'f-align-center',
        styles.sectionContainer
      )}
    >
      <div className={styles.sectionTitle}> {`${t('creators.landing.section1.title')}`}</div>
      {width > MAX_WIDTH_MOBILE ? (
        <div className="d-flex f-justify-center f-align-center w-100">
          {showArrow && (
            <div className={styles.arrowContainer} onClick={scrollBackward}>
              <img
                src={`${environment.creators_assets_cdn}/images/arrow_icon.svg`}
                alt="left_arrow"
              />
            </div>
          )}
          <div
            className={combineClassNames('d-flex', 'f-justify-center', 'f-align-stretch', 'gap-1')}
            style={{
              width: showArrow ? '80%' : '100%'
            }}
          >
            {iterator.map((v) => (
              <Card
                style={{
                  width: cardsPerPage < CARDS_PER_PAGE_DESKTOP_LIMIT ? '50%' : '30%'
                }}
                key={cardsData[(start + v) % cardsNumber].id}
                id={cardsData[(start + v) % cardsNumber].id}
                title={cardsData[(start + v) % cardsNumber].title}
                content={cardsData[(start + v) % cardsNumber].content}
                imgUrl={cardsData[(start + v) % cardsNumber].imgUrl}
              />
            ))}{' '}
          </div>
          {showArrow && (
            <div className={styles.arrowContainer} onClick={scrollForward}>
              <img
                style={{ transform: 'rotate(180deg)' }}
                src={`${environment.creators_assets_cdn}/images/arrow_icon.svg`}
                alt="right_arrow"
              />
            </div>
          )}
        </div>
      ) : (
        <div className={styles.mobileCardsContainer}>
          {cardsData.map((v) => (
            <Card key={v.id} id={v.id} title={v.title} content={v.content} imgUrl={v.imgUrl} />
          ))}
        </div>
      )}
    </div>
  );
}

export default PerksOfAnghami;
