import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './sidemenuBetaOptout.module.scss';
import { userContext } from 'components/contexts/auth/auth.context';
import ExitBetaButton from './exitBetaButton';

export default function SidemenuBetaOptout() {
  const { t } = useTranslation();
  const { user } = React.useContext(userContext);

  return (
    <div className="w-100 f-column gap-05">
      {!user?.hide_opt_out && (
        <div className={combineClassNames(styles.betaSection)}>
          <div>{t('Go back to the original platform')}</div>
          <ExitBetaButton />
        </div>
      )}
    </div>
  );
}
