import { userContext } from 'components/contexts/auth/auth.context';
import Button from 'components/Reusable/Button';
import { gateway } from 'helpers/gateway-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import styles from './profile.module.scss';
import getDashboardProfile from '@anghami/neogateway/dist/endpoints/getDashboardProfile';
import ProfilePreview from 'components/Profile/ProfilePreview';
import ProfileEdit from 'components/Profile/ProfileEdit';
import updateDashboardProfile, {
  IUpdateDashboardProfileResponse
} from '@anghami/neogateway/dist/endpoints/updateDashboardProfile';
import { loaderContext } from 'components/contexts/loader/loader.context';
import { toastContext } from 'components/contexts/toast/toast.context';
import { IUploadedFile } from 'interfaces/formsComponents.interface';
import { environment } from 'env/environment';
import { IProfileFormValues } from 'components/Profile/interfaces';
import { useNavigate } from 'react-router-dom';
import { setPageTitle } from 'helpers/url-helper';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { combineClassNames } from 'helpers/styling-helper';
import { AccountTypeEnums } from 'interfaces/auth.interface';
import { getCoverArtImage } from 'helpers/releases-helper';
import { getErrorCode, getErrorMessage } from 'helpers/api-helper';
import { logGeneralErrorEvent } from 'helpers/analytics-helper';

/*
  Dear future dev
  I'm genuinely sorry if you have to deal with this file, I tried my best to clean it up,
  left some comments around, for reference check artistInfoController.js on old dashboard repo
*/

export default function ProfilePage() {
  const { user, userType } = React.useContext(userContext);
  const { setIsLoading } = React.useContext(loaderContext);
  const { openToast } = React.useContext(toastContext);
  const [isDiscardBannerShowing, setIsDiscardBannerShowing] = React.useState(false);
  const [discardPendingRequest, setDiscardPendingRequest] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [formValues, setFormValues] = React.useState<IProfileFormValues>({});
  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const [isCoverartChanged, setIsCoverartChanged] = React.useState(false);
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(['profile-data'], async () => {
    const { data } = await gateway.callEndpoint(getDashboardProfile, {
      artistId: Number(user.anid)
    });
    if (!data || data.error) {
      return null;
    }
    const userData = {
      ...data?.response,
      fbdata: false,
      artistlanguage: data.response.artistlanguage || '1',
      profilepic:
        data.response.coverartid === 'FB' && data.response.facebookdata.profile_picture
          ? data.response.facebookdata.profile_picture
          : data.response.coverartid
          ? getCoverArtImage(data.response.coverartid, 296)
          : 'https://web.anghcdn.co/assets/img/userholder.jpg',
      ...(Object.keys(data.response.facebookdata).length > 0 && {
        facebook: data.response.facebookdata.username || data.response.facebook,
        fbdata: true
      }),
      accountid: user.account_id,
      accountname: user.account_name
    };

    return userData;
  });
  const logErrorToAmplitude = (data: IUpdateDashboardProfileResponse, error: Error) => {
    if (error) {
      logGeneralErrorEvent(
        error?.message || new Error('No information provided'),
        'update profile'
      );
      return;
    }
    if (!data) {
      logGeneralErrorEvent(new Error('no data was returned'), 'update profile');
      return;
    }
    if (data.error) {
      logGeneralErrorEvent(
        new Error(
          `API-ERROR: CODE:${getErrorCode(data.error)} MESSAGE:${getErrorMessage(data.error)}`
        ),
        'update profile',
        formValues
      );
      return;
    }
    if (!data?.response) {
      logGeneralErrorEvent(new Error('data returned but no reponse'), 'update profile');
      return;
    }
  };
  const mutation = useMutation(
    () => {
      // this was here because we were able to disable en and ar bio in old dash, and it might return
      const [enBio, deleteEnBio] = formValues.en_bio ? [formValues.en_bio, false] : ['', true];
      const [arBio, deleteArBio] = formValues.ar_bio ? [formValues.ar_bio, false] : ['', true];

      // default 1 means do not delete, check enum column in respective table in the db
      const deleteBios = deleteEnBio && deleteArBio ? 4 : deleteEnBio ? 3 : deleteArBio ? 2 : 1;

      // compare previous value of show on cover, api return '1' or '0', and I should send 'show' | '0' | '1'
      let showOnCover =
        data?.showoncover === formValues?.showoncover
          ? '-1'
          : formValues.showoncover
          ? 'show'
          : '0';
      // although dash has no admin, but keeping this in case later added
      if (userType === AccountTypeEnums.ADMIN) {
        showOnCover = formValues?.showoncover ? 'show' : '';
      }
      const params = {
        ...formValues,
        // remove uploaded file cause it's only needed client side
        uploadedImage: '',
        // if the user selects fb clear all values added by uploaded image
        ...(formValues?.showoncover === '1' && {
          key: '',
          bucket: '',
          useuploadedcover: ''
        }),
        showoncover: showOnCover,
        facebook: formValues.facebook || '',
        twitter: formValues.twitter || '',
        instagram: formValues.instagram || '',
        phone: formValues.phone || '',
        artistid: user.anid,
        artistname: formValues.artistname || '',
        artistnamear: formValues?.artistnamearabic || data.artistnamearabic,
        artistlanguage: formValues.artistlanguage || '1',
        useuploadedcover: formValues.useuploadedcover ? 'yes' : '',
        verification_status: formValues.verification_status,
        delete_bio: deleteBios,
        en_bio: enBio,
        ar_bio: arBio,
        // this feature is not implemented, but in case we add it later, replace bios with fb biography
        ...(formValues.importBio && {
          en_bio: formValues.facebookdata?.biography,
          ar_bio: formValues.facebookdata?.biography
        }),
        discard_existing: discardPendingRequest
      };
      return gateway.callEndpoint(updateDashboardProfile, params);
    },
    {
      onSettled: ({ data }, error: Error) => {
        setIsEditing(false);
        setIsLoading(false);
        if (error || !data || data.error || !data?.response) {
          logErrorToAmplitude(data, error);
          openToast(getErrorMessage(data?.error, t('Something went wrong')), 'error');
          return;
        }
        openToast(t('Change request created succefully'), 'success');
        navigate('/changerequests');
      }
    }
  );

  React.useEffect(() => {
    setPageTitle('Profile');
  }, []);

  React.useEffect(() => {
    setIsLoading(isLoading);
    return () => {
      setIsLoading(false);
    };
  }, [isLoading]);

  React.useEffect(() => {
    if (!data) return;
    setFormValues(data);
  }, [data]);

  const handleSave = () => {
    setIsLoading(true);
    mutation.mutate();
  };
  const hadnleDiscard = () => {
    setFormValues({});
    setIsEditing(false);
  };

  const handleEditClick = () => {
    if (formValues.has_pending_request) {
      setIsDiscardBannerShowing(true);
      return;
    }
    setIsEditing(true);
  };

  const handleProfileImageUpdate = (uploadedImage: IUploadedFile) => {
    if (!uploadedImage.isUploaded || !uploadedImage.key) return;
    setIsCoverartChanged(true);
    setFormValues({
      ...formValues,
      // both showoncover and coverartid might show that we're using image from fb, so we clear them on image upload
      showoncover: '0',
      coverartid: '',
      key: uploadedImage.key,
      bucket: environment.upload_s3_bucket,
      uploadedImage: uploadedImage,
      useuploadedcover: 'yes'
    });
  };

  const handleProfileImageError = (errorMessage: string) => {
    openToast(errorMessage, 'error');
    setIsCoverartChanged(false);
  };

  const handleDiscardRequest = () => {
    setDiscardPendingRequest(true);
    setIsDiscardBannerShowing(false);
    setIsEditing(true);
  };

  return (
    <>
      {
        <div
          className={combineClassNames(
            'd-flex f-align-center f-justify-between',
            styles.pendingRequestBanner,
            isDiscardBannerShowing && styles.showing
          )}
        >
          <span>{t('You already have a change request')}</span>
          <div className="d-flex f-align-center gap-1">
            <Button
              label={t('Discard Request and Edit')}
              size="sm"
              customClasses={[styles.discardButton]}
              onSubmit={handleDiscardRequest}
            />
            <CloseIcon
              className={styles.closeButton}
              onClick={() => setIsDiscardBannerShowing(false)}
            />
          </div>
        </div>
      }
      <div className={styles.pageContainer}>
        <div className="d-flex f-align-center f-justify-between mb-1">
          <h1>{t('Your Profile')}</h1>
          {isEditing ? (
            <div className="d-flex f-align-center gap-1">
              <Button
                label={t('Save')}
                size="md"
                customClasses={['purpleFilled']}
                onSubmit={handleSave}
                disabled={!isFormChanged && !isCoverartChanged}
              />
              <Button
                label={t('Discard')}
                size="md"
                withBackground="#fff"
                customClasses={['purple']}
                onSubmit={hadnleDiscard}
              />
            </div>
          ) : (
            <Button
              label={t('Edit')}
              size="md"
              customClasses={['purpleFilled']}
              onSubmit={handleEditClick}
            />
          )}
        </div>
        {data ? (
          isEditing ? (
            <ProfileEdit
              profileData={formValues}
              onUpdate={function (fieldName: string, value: any): void {
                setIsFormChanged(true);
                setFormValues({
                  ...formValues,
                  [fieldName]: value
                });
              }}
              onProfileImageChange={handleProfileImageUpdate}
              onProfileImageError={handleProfileImageError}
            />
          ) : (
            <ProfilePreview profileData={data} />
          )
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
