import { getScrollableParent, getDialogParent } from 'helpers/dom-helper';
import React from 'react';

/**
 * @description Hook that closes the modal when the user clicks outside of it, or scroll the nearest scrollable parent.
 * @params {boolean} defaultValue - The default value of the hook.
 * @returns {boolean} isOpen - Whether the modal is open or not.
 * @returns {function} setIsOpen - Function to set the isOpen state.
 * @returns {React.RefObject} ref - Ref of the component.
 **/
export default function useCloseOnScrollAndClickOutside({
  defaultValue
}: {
  defaultValue: boolean;
}): [boolean, (boolean) => void, React.RefObject<HTMLDivElement>] {
  const [isOpen, setIsOpen] = React.useState(defaultValue);
  const ref = React.useRef();

  React.useEffect(() => {
    const scrollableElement = getScrollableParent(ref.current);
    const closeDropDown = (event) => {
      if (ref.current && !(ref.current as any).contains(event.target)) {
        setIsOpen(false);
      }
    };
    // dialogs have stopPropagation on click, to allow backdrop feature wish was breaking this logic
    const dialogParent = getDialogParent(ref.current);
    if (dialogParent) {
      dialogParent.addEventListener('click', closeDropDown);
    }
    scrollableElement.addEventListener('scroll', closeDropDown);
    document.addEventListener('click', closeDropDown);
    return () => {
      scrollableElement.removeEventListener('scroll', closeDropDown);
      document.removeEventListener('click', closeDropDown);
      if (dialogParent) {
        dialogParent.removeEventListener('click', closeDropDown);
      }
    };
  }, [ref]);
  return [isOpen, setIsOpen, ref];
}
