import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import common from '../common.module.scss';
import styles from './circularDropDown.module.scss';
import { ReactComponent as DropDownIcon } from 'icons/dropdown.svg';
import WithLabelWrapper from '../WithLabelWrapper';
import colors from 'styles/partials/_colors_exports.module.scss';
import { IOption } from 'interfaces/formsComponents.interface';
import { IInputWithLabel } from 'interfaces/formsComponents.interface';
import useCloseOnScrollAndClickOutside from 'hooks/useCloseOnScrollAndClickOutside';
import { useTranslation } from 'react-i18next';

export interface ICircularDropDownProps
  extends Omit<IInputWithLabel, 'value' | 'onChange' | 'defaultValue'> {
  customClassName?: string;
  options?: IOption[];
  onChange: (value: IOption) => void;
  placeholder?: string;
  value: IOption;
  error?: string;
  disabled?: boolean;
  defaultValue?: IOption;
  customInputClass?: string;
  hideArrow?: boolean;
}

export default function CircularDropDown(props: ICircularDropDownProps) {
  const { t } = useTranslation();
  const { onChange, options, value, error, customInputClass } = props;
  const [showingDropDown, setShowingDropDown, ref] = useCloseOnScrollAndClickOutside({
    defaultValue: false
  });

  const handleChange = (value: IOption) => {
    setShowingDropDown(false);
    onChange(value);
  };

  const swithShowingDropDown = (e) => {
    e.stopPropagation();
    setShowingDropDown(!showingDropDown);
  };

  return (
    <div ref={ref}>
      <WithLabelWrapper {...props}>
        <div
          className={combineClassNames(
            common.input,
            customInputClass,
            styles.circular_dropdown,
            error && common.error
          )}
        >
          <span className="d-block cursor-pointer no-select" onClick={swithShowingDropDown}>
            {t(value?.label)}
          </span>
          <div className={styles.dropDownIcon} onClick={swithShowingDropDown}>
            <DropDownIcon
              fill={colors['dark-1']}
              className={combineClassNames(
                styles.icon,
                showingDropDown && options?.length !== 0 && styles.open
              )}
            ></DropDownIcon>
          </div>
          {showingDropDown && (
            <div className={styles.selectContainer}>
              {options.map((option, index) => (
                <div
                  key={option.value + index}
                  className={combineClassNames(
                    styles.selectItem,
                    value?.value === option.value && styles.selected
                  )}
                  onClick={() => handleChange(option)}
                >
                  {t(option?.label)}
                </div>
              ))}
            </div>
          )}
        </div>
      </WithLabelWrapper>
    </div>
  );
}
