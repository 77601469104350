import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  convertAlbumSongObjectToSongInfo,
  getFileSizeInMegabytes,
  getProgressInMegabytes
} from 'helpers/create-release-helper';
import { IAlbumSong, UploaderFileTypes } from 'interfaces/formsComponents.interface';
import useFileUploader from 'hooks/useFileUploader';
import SongPreview from 'components/CreateRelease/SongPreview';
import FileUploadButton from 'components/CreateRelease/FileUploadButton';
import Button from 'components/Reusable/Button';
import { combineClassNames } from 'helpers/styling-helper';
import { ReactComponent as RetryIcon } from 'icons/retry.svg';
import { ReactComponent as EditIcon } from 'icons/edit.svg';
import { ReactComponent as AddIcon } from 'icons/add-sm.svg';
import { ReactComponent as ArrowIcon } from 'icons/arrow-left.svg';
import { ReactComponent as ReoderIcon } from 'icons/reorder.svg';
import SongInfoPreview from '../SongInfoPreview';
import EditReleaseDialog from 'components/Dialogs/EditReleaseDialog';
import { useMutation, useQueryClient } from 'react-query';
import { autoDeleteRelease, postAlbumSongEndpoint } from 'helpers/http-helper';
import { toastContext } from 'components/contexts/toast/toast.context';
import { ALLOWED_MUSIC_FILETYPES } from 'constants/releases.constants';
import DeleteWithConfirmation from 'components/Reusable/DeleteWithConfirmation';
import Tooltip from 'antd/es/tooltip';
import { ReactComponent as InfoIcon } from 'icons/info.svg';
import { dialogsContext } from 'components/contexts/dialogs/toast/dialogs.context';
import {
  RELEASE_STATUSES,
  RELEASE_TYPES
} from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import { GET_RELEASE_DETAILS } from 'constants/reactQueryKeys';
import { logGeneralErrorEvent } from 'helpers/analytics-helper';
import Progress from 'antd/es/progress';
import styles from './uploadedSongBox.module.scss';
import colors from 'styles/partials/_colors_exports.module.scss';

interface IUploadedSongBoxProps {
  index: number;
  song: IAlbumSong;
  onDelete: (song: IAlbumSong) => void;
  onUpdate: (song: IAlbumSong) => void;
  setCollapsed: (_shouldCollapse: boolean) => void;
  isEditDisabled: boolean;
  collapsed?: boolean;
  canUpload?: boolean;
  isOrderingDisabled?: boolean;
  canDelete?: boolean;
}

export default function UploadedSongBox({
  index,
  song,
  onDelete,
  onUpdate,
  setCollapsed,
  collapsed,
  isEditDisabled,
  isOrderingDisabled,
  canUpload,
  canDelete
}: IUploadedSongBoxProps) {
  const { t } = useTranslation('common');
  const { showDialog, closeDialog } = React.useContext(dialogsContext);
  const reactQueryClient = useQueryClient();
  const [hasError, setHasError] = React.useState(false);
  const [errorCause, setErrorCause] = React.useState(null);
  const { openToast } = useContext(toastContext);
  const mutation = useMutation(
    (song: IAlbumSong) => {
      if (!song?.audiokey) return;
      return postAlbumSongEndpoint(song);
    },
    {
      retry: 3,
      mutationKey: 'postAlbumSongEndpoint' + song.id || song.songid,
      onError: (err, variables) => {
        logGeneralErrorEvent(err, 'uploading song UploadSongBox', { variables });
        openToast(t('Something went wrong'), 'error');
        setHasError(true);
      },
      onSuccess: (data, variables) => {
        if (!data) return;
        setHasError(false);
        if (data.length === 0 && variables.delete) {
          handleSongDelete();
          return;
        }
        if (data.error?.message) {
          openToast(data.error.message, 'error');
          setHasError(true);
          setErrorCause(data.error.code === 107 ? data.error.message : null);
          return;
        }
        if (!data.songid) {
          logGeneralErrorEvent(
            'songid not returned in postAlbumSong',
            'uploading song UploadSongBox',
            {
              apiResponse: data
            }
          );
          setHasError(true);
          setErrorCause(song.ispodcast ? t('adding-to-show-failed') : t('adding-to-album-failed'));
          return;
        }
        onUpdate({ ...song, ...variables, songid: data.songid });
        if (data.message) {
          openToast(data.message, 'success');
          return;
        }
      }
    }
  );
  const handleSongDelete = () => {
    reactQueryClient.refetchQueries({
      queryKey: [
        GET_RELEASE_DETAILS,
        song.ispodcast ? RELEASE_TYPES.Show : RELEASE_TYPES.Album,
        song.albumid
      ],
      exact: false
    });
    openToast(t('Song Deleted'), 'success');
  };
  const autoDeleteSongMutation = useMutation(autoDeleteRelease, {
    onSettled: (data, error) => {
      if (!data || error || data.error) {
        openToast(data?.error?.message || t('something-went-wrong-try-again'), 'error');
        return;
      }
      handleSongDelete();
    }
  });

  React.useEffect(() => {
    if (!canUpload) return;
    if (!song || !song.uploadedSong || song.uploadedSong.isUploaded || song.songid) return;
    uploadFile(song.uploadedSong);
  }, [canUpload]);

  const {
    uploadFile,
    clearUploadedFile,
    uploadedFile: uploadedSong,
    loadingProgress,
    isLoading
  } = useFileUploader({
    fileType: UploaderFileTypes.audio,
    onSuccess: async (uploaded) => {
      const newSongObject = {
        ...song,
        uploadedSong: uploaded,
        audiokey: uploaded.key
      };
      mutation.mutate(newSongObject);
    },
    onError: (err) => {
      console.error('🔴 ~ file: uploadedSongBox.tsx ~ line 49 ~ err', err);
      setHasError(true);
      logGeneralErrorEvent(err, 'uploading album song');
      onUpdate({ ...song, uploadedSong: { ...song?.uploadedSong, key: null } });
    },
    initialFile: song?.uploadedSong || null
  });

  const handleEdit = (updatedSong: IAlbumSong) => {
    mutation.mutate(updatedSong);
    closeDialog();
    onUpdate(updatedSong);
  };

  const handleDelete = () => {
    if (!canDelete) {
      openToast(t('cannot_delete_last_item_song'), 'warning');
      return;
    }
    if (song.status === RELEASE_STATUSES.LIVE || song.status === RELEASE_STATUSES.PENDING_RELEASE) {
      autoDeleteSongMutation.mutate(song.songid);
    } else {
      mutation.mutate({ ...song, delete: true });
    }
    clearUploadedFile();
    onDelete(song);
  };

  const handleRetry = () => {
    if (uploadedSong?.isUploaded) {
      mutation.mutate(song);
      return;
    }
    uploadFile(uploadedSong);
  };

  const showEditDialog = () => {
    showDialog({
      dialog: (
        <EditReleaseDialog
          onSave={handleEdit}
          songInfo={song}
          // until api support editing podcast coverart, we should hide it
          config={{ hideUploadCoverArt: false }}
        />
      ),
      backdrop: false,
      customClassName: styles.dialog
    });
  };
  return (
    <>
      <div className="d-flex">
        {!isOrderingDisabled && <ReoderIcon className={styles.reorder_icon} />}
        <div
          className={combineClassNames(
            styles.uploaded_song_box,
            collapsed && styles.collapsed,
            hasError
              ? styles.sync_error
              : !song.audiokey && !song.songid
              ? styles.sync_pending
              : null,
            isEditDisabled && 'f-justify-center'
          )}
        >
          <div className="d-flex f-align-center f-justify-between">
            <div
              className={combineClassNames(
                'd-flex f-align-center f-justify-start',
                styles.song_preview_part,
                !isEditDisabled && styles.fullbuttons,
                !song?.songname && styles.withCompleteButton
              )}
            >
              <span className={styles.song_index}>{index}</span>
              <SongPreview
                song={uploadedSong}
                customClass={styles.song_preview}
                onMouseDown={(e) => e.stopPropagation()}
              />
            </div>
            <div
              className={combineClassNames('d-flex f-align-center', styles.actions_part)}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <DeleteWithConfirmation onDelete={handleDelete} />
              {isEditDisabled ? (
                <></>
              ) : (
                <>
                  <FileUploadButton
                    customClass={styles.changeFile_button}
                    label={t('Change File')}
                    customIcon={<RetryIcon />}
                    fileTypes={ALLOWED_MUSIC_FILETYPES.join(',')}
                    onUpload={(files) => {
                      clearUploadedFile();
                      uploadFile(files[0]);
                    }}
                  />
                  {song.songid &&
                    (song.songname ? (
                      <Button
                        label={t('Edit info')}
                        customClasses={[styles.add_info_button]}
                        icon={<EditIcon fill={colors['light-10']} />}
                        onSubmit={showEditDialog}
                      />
                    ) : (
                      <Button
                        label={t('Complete Info')}
                        customClasses={[styles.add_info_button, styles.complete_info]}
                        icon={<AddIcon fill={colors['light-10']} />}
                        onSubmit={showEditDialog}
                      />
                    ))}
                  {isOrderingDisabled && (
                    <ArrowIcon
                      className={combineClassNames(
                        styles.arrow_icon,
                        !collapsed && styles.collapsed
                      )}
                      onClick={() => setCollapsed(!collapsed)}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          {!isEditDisabled && (
            <div className={combineClassNames('d-flex', styles.info_preview_section)}>
              <div className={styles.info_column}>
                <span className={styles.info_column_title}>{t('STATUS')}</span>
                {song.uploadedSong?.size && (
                  <div>
                    <span className="bold">{t('Size')}</span>{' '}
                    <span>{getFileSizeInMegabytes(song.uploadedSong.size) + ' mb'}</span>
                  </div>
                )}
                <span className="bold d-block">{t('Upload Status')}</span>
                {isLoading ? (
                  <div>
                    <span>
                      {getProgressInMegabytes(loadingProgress, song.uploadedSong?.size)}/
                      {getFileSizeInMegabytes(song.uploadedSong.size)}
                      mb
                    </span>
                    <Progress
                      percent={loadingProgress || 0}
                      status={isLoading ? 'active' : 'normal'}
                      strokeColor={{ from: '#c98c4b', to: colors['orange'] }}
                    />
                  </div>
                ) : (
                  <>
                    {hasError ? (
                      <div
                        className="d-flex f-align-center gap-05"
                        onMouseDown={(e) => e.stopPropagation()}
                      >
                        <span className={styles.error}>{t('Failed')}</span>
                        {errorCause ? (
                          <Tooltip title={<span>{errorCause}</span>}>
                            <InfoIcon fill={colors['dark-10']} width={25} height={25} />
                          </Tooltip>
                        ) : (
                          <div
                            className="d-flex f-align-center cursor-pointer"
                            onClick={handleRetry}
                          >
                            <span>{t('Retry')}</span>
                            <RetryIcon fill={colors['dark-10']} width={25} height={25} />
                          </div>
                        )}
                      </div>
                    ) : song?.uploadedSong.isUploaded || canUpload ? (
                      song.songid ? (
                        <span className={styles.success}>{t('Success')}</span>
                      ) : (
                        <span style={{ color: colors['orange'] }}>
                          {song.ispodcast ? t('adding-to-show') : t('adding-to-album')}
                        </span>
                      )
                    ) : (
                      <div>
                        <span style={{ color: colors['orange'] }}>{t('Pending')}</span>
                      </div>
                    )}
                  </>
                )}
              </div>
              <SongInfoPreview
                songInfo={
                  Object.keys(song).length > 2
                    ? convertAlbumSongObjectToSongInfo(song, !!song.ispodcast)
                    : []
                }
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
