import { IUploadedFile, UploaderFileTypes } from 'interfaces/formsComponents.interface';
import {
  ALLOWED_IMAGE_FILETYPES,
  ALLOWED_MUSIC_FILETYPES,
  ALLOWED_VIDEOS_FILETYPES
} from 'constants/releases.constants';

export const dataURIToBlob = (uf: IUploadedFile) => {
  const binary = atob(uf.src ? String(uf.src)?.split(',')[1] : '');
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: uf.type.replace('x-wav', 'wav') });
};

export const createFile = async (uf: IUploadedFile) => {
  try {
    const response = await fetch(uf.src);
    const data = await response.blob();
    const metadata = {
      type: uf.type
    };
    const file = new File([data], uf.name, metadata);
    return file;
  } catch (err) {
    const file = dataURIToBlob(uf);
    return file;
  }
};

const ALL_ALLOWED_TYPES = [
  ...ALLOWED_MUSIC_FILETYPES,
  ...ALLOWED_IMAGE_FILETYPES,
  ...ALLOWED_VIDEOS_FILETYPES
];
export const getFileExtension = (uf: IUploadedFile, fileType: UploaderFileTypes) => {
  if (!uf) return;
  const filename = uf.name ? String(uf.name) : '';
  const typeFromName = filename.split('.').pop();
  if (typeFromName && ALL_ALLOWED_TYPES.indexOf('.' + typeFromName) > -1) {
    return typeFromName;
  }
  return uf.type?.replace(fileType + '/', '');
};

export const generateKey = (userid: string, uf: IUploadedFile, fileType: UploaderFileTypes) => {
  const cname = `${fileType === 'audio' ? 'audio/' : ''}${
    Date.now() + Math.floor(Math.random() * 1000 + 1)
  }`;
  const fileLocation = userid + '/' + cname + '.' + getFileExtension(uf, fileType);
  const key = 'content/' + fileLocation;
  return key;
};
