import Button from 'components/Reusable/Button';
import TextInput from 'components/Reusable/TextInput';
import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './youtubeUploader.module.scss';
import { ReactComponent as DeleteIcon } from 'icons/delete.svg';
import { ReactComponent as RetryIcon } from 'icons/retry.svg';
const KEY = 'AIzaSyCW8HX4XO2ly-FFBEHMg353MhQCdkZZz4M';
interface Thumbnails {
  url: string;
  width: number;
  height: number;
}
interface IVideoMetas {
  categoryId: string;
  channelId: string;
  channelTitle: string;
  description: string;
  liveBroadcastContent: string;
  localized: { title: string; description: string };
  publishedAt: string;
  tags: string[];
  thumbnails: { default: Thumbnails; medium: Thumbnails; high: Thumbnails };
  title: string;
}

interface IYoutubeUploadProps {
  customInputStyle: string;
  value: string;
  onUpdate: (string) => void;
}
export default function YoutubeUploader({
  customInputStyle,
  value,
  onUpdate
}: IYoutubeUploadProps) {
  const { t } = useTranslation('common');
  const [youtubeLink, setYoutubeLink] = React.useState(value || '');
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [videoMetas, setVideoMetas] = React.useState<IVideoMetas>(null);
  const youtube_parser = function (url) {
    //eslint-disable-next-line no-useless-escape
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  };
  const handleOnProceed = () => {
    if (!youtubeLink) return;
    const youtubeId = youtube_parser(youtubeLink);
    if (!youtubeId) {
      setError(t('Invalid youtube link'));
      return;
    }
    setError('');
    // setYoutubeId(youtubeId);
    setIsLoading(true);
    fetchVideoMetas(youtubeId)
      .then((videoMetas) => {
        setIsLoading(false);
        setVideoMetas(videoMetas);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err?.message || t('something-went-wrong-try-again'));
        console.log('🔴 ~ fetching video metas error', err);
      });
  };
  const fetchVideoMetas = (videoId) => {
    const endpoint = `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${KEY}&rand=${Math.floor(
      Math.random() * 1000 + 1
    )}`;
    return fetch(endpoint)
      .then((res) => res.json())
      .then((data) => {
        if (!data) {
          throw new Error(t('something-went-wrong-try-again'));
        }
        if (data?.error?.code === 403) {
          throw new Error(t('service-unavailable'));
        }
        if (!data.items || data.items.length === 0) {
          throw new Error(t('Video not found'));
        }
        return data.items[0].snippet;
      })
      .catch((err) => {
        console.error('unable to fetch youtube link in upload', err);
      });
  };

  React.useEffect(() => {
    if (value && !videoMetas) {
      handleOnProceed();
    }
  }, [value]);

  return (
    <div className={styles.main_container}>
      {value && videoMetas ? (
        <div
          className={combineClassNames(
            'd-flex f-align-center f-justify-between',
            styles.youtubeMetas_container_main
          )}
        >
          <div className="d-flex f-align-center h-100">
            <img
              src={videoMetas?.thumbnails.default.url}
              width={videoMetas?.thumbnails.default.width}
              height={videoMetas?.thumbnails.default.height}
              alt="thumbnail"
            />
            <div
              className={combineClassNames(
                'f-column h-100 f-justify-between',
                styles.text_container
              )}
            >
              <span className="bold">{videoMetas?.title}</span>
              <div className="f-column">
                <span className={combineClassNames('uppercase', styles.grey_text)}>
                  {t('link')}
                </span>
                <span className={styles.grey_text}>{youtubeLink}</span>
              </div>
            </div>
          </div>
          <div className={combineClassNames('d-flex f-align-start h-100')}>
            <Button
              label="Change Link"
              icon={<RetryIcon width={25} height={25} />}
              customClasses={[styles.change_button]}
              onSubmit={() => {
                setVideoMetas(null);
                setYoutubeLink('');
                onUpdate(null);
              }}
            />
            <div
              className={styles.delete_button}
              onClick={() => {
                setVideoMetas(null);
                setYoutubeLink('');
                onUpdate(null);
              }}
            >
              <DeleteIcon />
            </div>
          </div>
        </div>
      ) : (
        <>
          {' '}
          <div className={combineClassNames('d-flex f-align-start', styles.input_container)}>
            <TextInput
              label={t('Upload with Youtube Link')}
              value={youtubeLink}
              onChange={(e) => setYoutubeLink(e.target.value)}
              customInputClass={customInputStyle}
              disabled={false}
              error={error}
              showErrorText={true}
            ></TextInput>
            <Button
              label={t('Proceed')}
              customClasses={[styles.main_button, styles.proceed_button]}
              disabled={youtubeLink?.trim()?.length === 0}
              onSubmit={handleOnProceed}
            />
          </div>
          {videoMetas && (
            <>
              {isLoading ? (
                'is Loading ...'
              ) : (
                <div
                  className={combineClassNames(
                    'd-flex f-align-center',
                    styles.youtubeMetas_container
                  )}
                >
                  <img
                    src={videoMetas?.thumbnails.default.url}
                    width={videoMetas?.thumbnails.default.width}
                    height={videoMetas?.thumbnails.default.height}
                    alt="thumbnail"
                  />
                  <div className="f-column">
                    <span className="bold">{videoMetas.title}</span>
                    <span>{youtubeLink}</span>
                    <div className={'d-flex f-align-center'}>
                      <Button
                        label="Use audio from video"
                        customClasses={[styles.main_button]}
                        onSubmit={() => onUpdate(youtubeLink)}
                      />
                      <Button
                        label="Cancel"
                        customClasses={[styles.clear_button]}
                        onSubmit={() => {
                          setVideoMetas(null);
                          setYoutubeLink('');
                          onUpdate(null);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
