const scope = 'app.containers.HomePage';
export const getSignupForm = (t: (key: string) => string) => ({
  userType: {
    type: 'radio',
    label: t(`${scope}.form.userType`),
    name: 'usertype',
    data: {
      options: [
        {
          label: t(`${scope}.form.userType.artist`),
          value: 2
        },
        {
          label: t(`${scope}.form.userType.podcaster`),
          value: 5
        },
        {
          label: t(`${scope}.form.userType.labelAndAggregator`),
          value: 3
        }
      ]
    }
  },
  firstName: {
    type: 'text',
    label: t(`${scope}.form.firstName`),
    name: 'firstname',
    autocomplete: 'given-name',
    data: {
      placeholder: t(`${scope}.form.inputPlaceHolder`).replace(
        '{label}',
        t(`${scope}.form.firstName`)
      )
    }
  },
  lastName: {
    type: 'text',
    label: t(`${scope}.form.lastName`),
    name: 'lastname',
    autocomplete: 'family-name',
    data: {
      placeholder: t(`${scope}.form.inputPlaceHolder`).replace(
        '{label}',
        t(`${scope}.form.lastName`)
      )
    }
  },
  email: {
    type: 'email',
    label: t(`${scope}.form.yourEmail`),
    name: 'email',
    id: 'email',
    autocomplete: 'email',
    data: {
      placeholder: t(`${scope}.form.inputPlaceHolder`).replace(
        '{label}',
        t(`${scope}.form.yourEmail`)
      )
    }
  },
  phoneNumber: {
    type: 'phoneNumber',
    label: t(`${scope}.form.phoneNumber`),
    name: 'phone',
    autocomplete: 'off',
    data: {
      placeholder: t(`${scope}.form.inputPlaceHolder`).replace(
        '{label}',
        t(`${scope}.form.phoneNumber`)
      )
    }
  },
  password: {
    type: 'password',
    label: t(`${scope}.form.yourPassword`),
    name: 'password',
    autocomplete: 'new-password',
    data: {
      placeholder: t(`${scope}.form.inputPlaceHolder`).replace(
        '{label}',
        t(`${scope}.form.yourPassword`)
      )
    }
  },
  confirmPassword: {
    type: 'password',
    label: t(`${scope}.form.confirmPassword`),
    name: 'confirmpassword',
    autocomplete: 'new-password',
    data: {
      placeholder: t(`${scope}.form.inputPlaceHolder`).replace(
        '{label}',
        t(`${scope}.form.confirmPassword`)
      )
    }
  },
  musicLanguage: {
    type: 'radio',
    label: t(`${scope}.form.musiclanguage`),
    name: 'musiclanguage',
    data: {
      options: [
        { label: t(`${scope}.form.musiclanguage.arabic`), value: 1 },
        {
          label: t(`${scope}.form.musiclanguage.international`),
          value: 2
        }
      ]
    }
  },
  isExistingLabel: {
    type: 'radio',
    label: t(`${scope}.form.isExistingLabel`),
    name: 'isexistinglabel',
    data: {
      options: [
        { label: t(`${scope}.form.isExistingLabel.yes`), value: 1 },
        { label: t(`${scope}.form.isExistingLabel.no`), value: 2 }
      ]
    }
  },
  artistName: {
    type: 'text',
    label: t(`${scope}.form.artistName`),
    name: 'artistname',
    data: {
      placeholder: t(`${scope}.form.inputPlaceHolder`).replace(
        '{label}',
        t(`${scope}.form.artistName`)
      )
    }
  },
  artistNameAr: {
    type: 'text',
    label: t(`${scope}.form.artistNameAr`),
    name: 'artistnamear',
    data: {
      placeholder: t(`${scope}.form.inputPlaceHolder`).replace(
        '{label}',
        t(`${scope}.form.artistNameAr`)
      )
    }
  }
});
