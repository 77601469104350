import React from 'react';

export const SidemenuContext = React.createContext<{
  isMobile: boolean;
  isMobileExpanded: boolean;
  setIsMobileExpanded: (val: boolean) => void;
  isMinimized: boolean;
  setIsMinimized: (val: boolean) => void;
}>({
  isMobile: false,
  isMobileExpanded: false,
  setIsMobileExpanded: () => null,
  isMinimized: false,
  setIsMinimized: () => null
});
