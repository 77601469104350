import React from 'react';
import MultiSelect from 'components/Reusable/MultiSelect';
import { IMultiSelectProps } from 'components/Reusable/MultiSelect/multiSelect';
import { useQuery } from 'react-query';
import { gateway } from 'helpers/gateway-helper';
import getGenres from '@anghami/neogateway/dist/endpoints/getGenres';

interface IGenreSelectProps extends IMultiSelectProps {
  languageid?: 0 | 1 | 2;
}
export default function GenresSelect(props: IGenreSelectProps) {
  const { data: genres } = useQuery(
    ['genreDropDown', props.languageid || 1],
    async () => {
      const { data } = await gateway.callEndpoint(getGenres, {
        langid: props.languageid || 1
      });
      if (data?.genres?.length > 0) {
        return data?.genres.map((genre) => ({ label: genre, value: genre }));
      }
      throw new Error('Something went wrong, please try again');
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: 3,
      staleTime: Infinity
    }
  );

  return <MultiSelect {...props} options={genres} hideArrow={false} limit={2} />;
}
