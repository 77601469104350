import { combineClassNames } from 'helpers/styling-helper';
import React, { ReactElement } from 'react';
import common from '../common.module.scss';
import styles from '../MultiSelect/multiSelect.module.scss';
import { ReactComponent as DropDownIcon } from 'icons/dropdown.svg';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import WithLabelWrapper from '../WithLabelWrapper';
import colors from 'styles/partials/_colors_exports.module.scss';
import { IOption } from 'interfaces/formsComponents.interface';
import { IInputWithLabel } from 'interfaces/formsComponents.interface';
import useCloseOnScrollAndClickOutside from 'hooks/useCloseOnScrollAndClickOutside';

export interface IDropDownProps
  extends Omit<IInputWithLabel, 'value' | 'onChange' | 'defaultValue'> {
  customClassName?: string;
  options?: IOption[];
  onChange: (value: IOption) => void;
  placeholder?: string;
  value?: IOption;
  error?: string;
  disabled?: boolean;
  defaultValue?: IOption;
  customInputClass?: string;
  hideArrow?: boolean;
  onSearchQuery?: (query: string) => void;
  icon?: ReactElement;
  clearOnSelect?: boolean;
  allowClear?: boolean;
}

export default function DropDown(props: IDropDownProps) {
  const {
    onChange,
    options,
    placeholder,
    value,
    error,
    customInputClass,
    disabled,
    onSearchQuery,
    customClassName,
    icon,
    clearOnSelect,
    allowClear = true
  } = props;
  const [selected, setSelected] = React.useState(value || null);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [showingDropDown, setShowingDropDown, ref] = useCloseOnScrollAndClickOutside({
    defaultValue: false
  });

  React.useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleChange = (value: IOption) => {
    setShowingDropDown(false);
    onChange(value);
    if (clearOnSelect) {
      setSearchQuery('');
      setSelected(null);
      return;
    }
    setSelected(value);
  };

  const searchOption = (option: IOption, query: string) => {
    return String(option.label + option.value)
      .toLowerCase()
      .includes(query?.toLowerCase());
  };

  const swithShowingDropDown = (e) => {
    e.stopPropagation();
    setShowingDropDown(!showingDropDown);
  };

  return (
    <div ref={ref} className={customClassName}>
      <WithLabelWrapper {...props}>
        <div
          className={combineClassNames(
            common.input,
            customInputClass,
            styles.multiSelect,
            error && common.error,
            disabled && common.disabled
          )}
        >
          {!clearOnSelect && selected?.value ? (
            <span
              className="w-100 h-100"
              onClick={() => {
                setShowingDropDown(true);
              }}
            >
              {selected.label || options.find((opt) => opt.value === selected.value)?.label}
            </span>
          ) : (
            <input
              type={'text'}
              className={styles.hidden_input}
              onChange={(e) => {
                setShowingDropDown(true);
                onSearchQuery ? onSearchQuery(e.target.value) : setSearchQuery(e.target.value);
              }}
              onClick={(e) => {
                e.stopPropagation();
                setShowingDropDown(true);
              }}
              value={searchQuery}
              placeholder={placeholder}
              disabled={disabled}
            ></input>
          )}
          <div className={combineClassNames('d-flex gap-05 f-align-center', styles.dropDownIcon)}>
            {allowClear && !disabled && (
              <CloseIcon
                width={12}
                height={12}
                fill={colors['dark-10']}
                className={combineClassNames(styles.closeIcon, !selected?.value && styles.hidden)}
                onClick={() => handleChange(null)}
              />
            )}

            {!disabled &&
              (icon ? (
                icon
              ) : (
                <DropDownIcon
                  fill={colors['dark-10']}
                  className={combineClassNames(
                    styles.icon,
                    showingDropDown && options?.length !== 0 && styles.open
                  )}
                  onClick={swithShowingDropDown}
                ></DropDownIcon>
              ))}
          </div>
          {!disabled &&
            showingDropDown &&
            options?.filter((opt) => !searchQuery || searchOption(opt, searchQuery)).length > 0 && (
              <div className={styles.selectContainer}>
                {options
                  .filter((opt) => !searchQuery || searchOption(opt, searchQuery))
                  .map((option, index) => (
                    <div
                      key={option.value + index}
                      className={combineClassNames(
                        styles.selectItem,
                        selected?.value === option.value && styles.selected
                      )}
                      onClick={() => handleChange(option)}
                    >
                      {option.label}
                    </div>
                  ))}
              </div>
            )}
        </div>
      </WithLabelWrapper>
    </div>
  );
}
