import { GO_TO_TAB } from 'constants/amplitudeEvents';
import { logAmplitudeEvent } from 'helpers/analytics-helper';

export const logGoToTab = (name: string, url: string) => {
  logAmplitudeEvent(GO_TO_TAB, {
    tab_name: name,
    tab_url: url
  });
};

export const updateSidemenuCSSVariables = (isShowing, isMobile = false, isMinimized = false) => {
  document.documentElement.style.setProperty(
    '--sidemenu-width',
    isShowing && !isMobile ? (isMinimized ? '5rem' : '19rem') : '0rem'
  );
  document.documentElement.style.setProperty('--sidemenu-height', isMobile ? '7rem' : '0rem');
};
