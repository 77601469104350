import WithLabelWrapper from 'components/Reusable/WithLabelWrapper';
import { IInputWithLabel } from 'interfaces/formsComponents.interface';
import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import common from '../common.module.scss';

interface TextInputProps extends IInputWithLabel {
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  placeholder?: string;
  error?: string;
  customInputClass?: string;
  required?: boolean;
}

export default function TextInput(props: TextInputProps) {
  const {
    placeholder,
    onChange,
    onBlur,
    onKeyDown,
    value,
    error,
    disabled,
    customInputClass,
    required,
    pattern,
    id,
    name,
    autoComplete
  } = props;
  return (
    <WithLabelWrapper {...props}>
      <input
        className={combineClassNames(
          common.input,
          error ? common.error : '',
          customInputClass || ''
        )}
        type={'text'}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        value={value || ''}
        disabled={disabled}
        required={required}
        pattern={pattern}
        id={id}
        name={name}
        autoComplete={autoComplete}
      />
    </WithLabelWrapper>
  );
}
