// TODO: rename to container or convert it to HOC
import getUserLibrary, {
  CONTENT_TYPES,
  LIBRARY_TYPE
} from '@anghami/neogateway/dist/endpoints/getUserLibrary';
import { toastContext } from 'components/contexts/toast/toast.context';
import { userContext } from 'components/contexts/auth/auth.context';
import DataTable from 'components/Reusable/Table';
import { gateway } from 'helpers/gateway-helper';
import React from 'react';
import { useInfiniteQuery } from 'react-query';
import { IArtistInfo } from '../Context/library.context';
import { libraryTableColumns } from '../Columns';
import { RELEASE_TYPES } from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import { useTranslation } from 'react-i18next';
import { AccountTypeEnums } from 'interfaces/auth.interface';
import { useWindowDimensions } from 'hooks/useWindowDimensions';

interface IReleaseTableHOCProps {
  contentType: CONTENT_TYPES;
  isDraft: boolean;
  artistInfo?: IArtistInfo;
  albumId?: string;
}

export default function ReleasesTableHOC({
  contentType,
  isDraft,
  artistInfo,
  albumId
}: IReleaseTableHOCProps) {
  const { openToast } = React.useContext(toastContext);
  const { user, userType } = React.useContext(userContext);
  const { t } = useTranslation();
  const [mainConfChange, setMainConfChange] = React.useState(false);
  const { isMobile } = useWindowDimensions();
  const key = [
    user?.anid,
    contentType,
    'library',
    isDraft ? LIBRARY_TYPE.draft : LIBRARY_TYPE.live,
    albumId,
    artistInfo?.artist_id || 'noartist'
  ];
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery(
      key,
      async ({ pageParam = 1 }) => {
        const { data } = await gateway.callEndpoint(getUserLibrary, {
          content_type: contentType,
          library_type: isDraft ? LIBRARY_TYPE.draft : LIBRARY_TYPE.live,
          release_type: RELEASE_TYPES.ALL,
          output: 'jsonhp',
          album_id: albumId,
          page: pageParam,
          ...(artistInfo?.artist_id && { artist_id: artistInfo?.artist_id })
        });
        if (!data || data.error) {
          openToast(data?.error?.message || 'Error fetching library', 'error');
          return [];
        }
        setMainConfChange(false);
        return data.data;
      },
      {
        getNextPageParam: (lastPage, allPages) =>
          lastPage.length === 0 ? undefined : allPages.length + 1,
        keepPreviousData: true,
        enabled:
          userType !== AccountTypeEnums.LABEL ||
          !(userType === AccountTypeEnums.LABEL && !artistInfo?.artist_id)
      }
    );
  React.useEffect(() => {
    setMainConfChange(true);
  }, [albumId]);

  return (
    <>
      <DataTable
        isLoading={isLoading || (mainConfChange && isFetching)}
        data={[
          ...(data?.pages
            .flat()
            .map((i) => ({ ...i, key: (i.song_id || i.album_id) + i.release_name })) || [])
        ]}
        tableColumns={libraryTableColumns(t, isMobile)}
        onFetch={hasNextPage && fetchNextPage}
        isInfiniteLoading={isFetchingNextPage}
        rowSelection={false}
        tableId={'library-' + albumId}
      />
    </>
  );
}
