import React from 'react';
import styles from './createReleaseDialog.module.scss';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as SongVisual } from 'icons/song-visual.svg';
import { ReactComponent as PodcastVisual } from 'icons/podcast-visual.svg';
import { ReactComponent as TickIcon } from 'icons/tick.svg';
import { combineClassNames } from 'helpers/styling-helper';
import Button from 'components/Reusable/Button';
import { userContext } from 'components/contexts/auth/auth.context';
import { AccountTypeEnums } from 'interfaces/auth.interface';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AlbumVisual from 'icons/album-visual';
import ShowVisual from 'icons/show-visual';
import colors from 'styles/partials/_colors_exports.module.scss';
import ArtistSelect from 'components/CreateRelease/ArtistSelect';
import OwnShowsSelect from 'components/CreateRelease/OwnShowsSelect';
import { getIsPodcastFromReleaseType } from 'helpers/releases-helper';
import { RELEASE_TYPES } from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import { logAmplitudeEvent } from 'helpers/analytics-helper';
import { SELECT_RELEASE_TYPE } from 'constants/amplitudeEvents';
import { generateGuid } from 'helpers/utils-helper';
import CheckBox from 'components/Reusable/CheckBox';
import { environment } from 'env/environment';
import OwnAlbumsSelect from 'components/CreateRelease/OwnAlbumsSelect';
import {
  IQueryParamFormObject,
  ISelectedAlbumToAddTo
} from 'components/CreateRelease/create.interface';
import { IReleaseItem } from '@anghami/neogateway/dist/endpoints/getUserLibrary';
import { encodeQueryParams } from 'helpers/url-helper';

interface ICreateReleaseDialogProps {
  closeDialog?: () => void;
}

export default function CreateReleaseDialog({ closeDialog }: ICreateReleaseDialogProps) {
  const [selectedArtists, setSelectedArtists] = React.useState([]);
  const [releaseType, setReleaseType] = React.useState<RELEASE_TYPES>(null);
  const [releaseTypes, setReleaseTypes] = React.useState([]);
  const [selectedAlbumToAddTo, setSelectedAlbumToAddTo] =
    React.useState<ISelectedAlbumToAddTo>(null);
  const { t } = useTranslation();
  const { userType, permissions, user } = React.useContext(userContext);
  const [isRssChecked, setIsRssChecked] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    setReleaseTypes([
      {
        label: t('Single'),
        value: RELEASE_TYPES.Single,
        Icon: SongVisual,
        enabled: permissions?.upload_single
      },
      {
        label: t('Album'),
        value: RELEASE_TYPES.Album,
        Icon: AlbumVisual,
        double: true,
        enabled: permissions?.upload_album
      },
      {
        label: t('Episode'),
        value: RELEASE_TYPES.Episode,
        Icon: PodcastVisual,
        enabled: permissions?.upload_episode
      },
      {
        label: t('Show'),
        value: RELEASE_TYPES.Show,
        Icon: ShowVisual,
        double: true,
        enabled: permissions?.upload_show
      }
    ]);
  }, [permissions]);

  React.useEffect(() => {
    if (!releaseTypes || releaseTypes.length === 0) return;
    setReleaseType(releaseTypes.find((rt) => rt.enabled)?.value);
  }, [releaseTypes]);

  React.useEffect(() => {
    setSelectedAlbumToAddTo(null);
  }, [releaseType]);

  const onProceed = () => {
    logAmplitudeEvent(SELECT_RELEASE_TYPE, {
      content_type: releaseType,
      artist_id: user?.d_artist?.id,
      album_id: selectedAlbumToAddTo?.albumToAddToId
    });
    const formObject: IQueryParamFormObject =
      selectedArtists?.length > 0 || selectedAlbumToAddTo?.albumToAddToId
        ? {
            ...(selectedArtists?.length > 0 && {
              artistsValue: selectedArtists,
              artists: selectedArtists.map((a) => a.value)
            }),
            ...(selectedAlbumToAddTo?.albumToAddToId && selectedAlbumToAddTo)
          }
        : null;
    const form = formObject ? JSON.stringify(formObject) : null;
    const encodedForm = encodeQueryParams(form);
    navigate(
      `/create/${releaseType}${releaseType === RELEASE_TYPES.Show && isRssChecked ? '/rss' : ''}?${
        form ? `form=${encodedForm}&` : ''
      }${
        selectedAlbumToAddTo?.albumToAddToId ? 'albumid=' + selectedAlbumToAddTo.albumToAddToId : ''
      }`,
      {
        state: {
          keyProp: generateGuid()
        }
      }
    );
    closeDialog();
  };

  const handleAlbumChange = (selectedAlbum: IReleaseItem, isPodcast = false) => {
    if (!selectedAlbum?.album_id) {
      setSelectedAlbumToAddTo(null);
      return;
    }
    setSelectedAlbumToAddTo({
      albumToAddToId: selectedAlbum.album_id,
      albumToAddToIdValue: { value: selectedAlbum.album_id, label: selectedAlbum.release_name },
      isaddingtoexistingalbum: true,
      isPodcast
    });
  };

  return (
    <div className={styles.create_release_dialog}>
      <div className={styles.dialog_header}>
        <h2>{t('Define your releases')}</h2>
        <CloseIcon className={styles.back_icon} onClick={closeDialog} />
      </div>
      {/* Labels should select which artist this release belongs to */}
      {userType === AccountTypeEnums.LABEL && (
        <div className={styles.select_input_container}>
          <ArtistSelect
            value={selectedArtists}
            onChange={(e) => setSelectedArtists(e)}
            label={
              getIsPodcastFromReleaseType(releaseType)
                ? t('Choose a Podcaster')
                : t('Choose an artist')
            }
            placeholder={
              getIsPodcastFromReleaseType(releaseType)
                ? t('Type and select podcasters')
                : t('Type and select artists')
            }
            limit={1}
          ></ArtistSelect>
        </div>
      )}
      <div
        className={combineClassNames(
          styles.release_type_selector,
          releaseTypes?.length > 2 && styles.more_then_two
        )}
      >
        {releaseTypes
          ?.filter((i) => i.enabled)
          .map(({ Icon, label, value }) => (
            <div className="w-100" key={value}>
              <div
                className={combineClassNames(
                  styles.release_type_selector_item,
                  releaseType === value && styles.selected
                )}
                onClick={() => setReleaseType(value)}
              >
                <div className="d-flex f-align-center">
                  <Icon className={styles.visual_icon} secondaryclass={styles.visual_icon_second} />
                  <span>{label}</span>
                </div>
                {releaseType === value && <TickIcon />}
              </div>
              {releaseType === RELEASE_TYPES.Show && value === RELEASE_TYPES.Show && (
                <div className="d-flex mt-05 f-align-center m-start-05">
                  <CheckBox
                    label={t('Upload as RSS feed')}
                    isChecked={isRssChecked}
                    onChange={(isCheked) => {
                      setIsRssChecked(isCheked);
                    }}
                  />
                  <img
                    src={`${environment.creators_assets_cdn}/images/rss.svg`}
                    style={{ width: 17, height: 17 }}
                    alt="rss-icon"
                  />
                </div>
              )}
            </div>
          ))}
      </div>
      {releaseType === RELEASE_TYPES.Episode && (
        <div className={styles.ownshow_selector_container}>
          <OwnShowsSelect
            label={t('Choose the existing show for this episode if any')}
            description={t('Add to podcast description')}
            placeholder={t('Select your show')}
            returnFullObject={true}
            onChange={(e) => handleAlbumChange(e, true)}
          />
        </div>
      )}
      {releaseType === RELEASE_TYPES.Single && (
        <div className={styles.ownshow_selector_container}>
          <OwnAlbumsSelect
            label={t('Choose the existing album for this song if any')}
            description={t('Add to Album description')}
            placeholder={t('Select your album')}
            returnFullObject={true}
            onChange={handleAlbumChange}
          />
        </div>
      )}

      <div className="d-flex w-100 f-justify-center">
        <Button
          onSubmit={onProceed}
          label={t('Proceed')}
          withBackground={colors['branding-purple']}
          customClasses={[styles.proceed_button]}
          // labels are forced to select an artist to add release to
          disabled={
            !releaseType ||
            (userType === AccountTypeEnums.LABEL && selectedArtists.length === 0) ||
            (releaseType === RELEASE_TYPES.Episode && !selectedAlbumToAddTo?.albumToAddToId)
          }
        ></Button>
      </div>
    </div>
  );
}
