import React from 'react';
import { ReactComponent as PendingIcon } from 'icons/status-pending.svg';
import { ReactComponent as FailedIcon } from 'icons/status-failed.svg';
import { ReactComponent as SucceededIcon } from 'icons/status-succeed.svg';
import { ReactComponent as DraftIcon } from 'icons/status-draft.svg';
import { ReactComponent as ProcessingIcon } from 'icons/status-processing.svg';
import colors from 'styles/partials/_colors_exports.module.scss';
import styles from './statusTableCell.module.scss';
import { RELEASE_STATUSES } from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import { useTranslation } from 'react-i18next';

export default function StatusTableCell({ status }: { status: string }) {
  const { t } = useTranslation('common');

  const StatusComponents = {
    [RELEASE_STATUSES.PENDING_RELEASE]: {
      label: t('Pending Release'),
      color: colors['orange'],
      icon: PendingIcon
    },
    [RELEASE_STATUSES.LIVE]: {
      label: t('Live'),
      color: colors['success-color'],
      icon: SucceededIcon
    },
    [RELEASE_STATUSES.NOT_LIVE]: {
      label: t('Not Live'),
      color: colors['failure-color'],
      icon: FailedIcon
    },
    [RELEASE_STATUSES.BLOCKED]: {
      label: t('Blocked'),
      color: colors['failure-color'],
      icon: FailedIcon
    },
    [RELEASE_STATUSES.PENDING_APPROVAL]: {
      label: t('Pending Approval'),
      color: colors['orange'],
      icon: ProcessingIcon
    },
    [RELEASE_STATUSES.TAKENDOWN]: {
      label: t('Taken Down'),
      color: colors['failure-color'],
      icon: FailedIcon
    },
    [RELEASE_STATUSES.MERGED]: {
      label: t('Taken Down'),
      color: colors['failure-color'],
      icon: FailedIcon
    },
    [RELEASE_STATUSES.REJECTED]: {
      label: t('Rejected'),
      color: colors['failure-color'],
      icon: FailedIcon
    },
    [RELEASE_STATUSES.AUTO_REJECTED]: {
      label: t('Auto Rejected'),
      color: colors['failure-color'],
      icon: FailedIcon
    },
    [RELEASE_STATUSES.BLOCKED_88]: {
      label: t('Blocked'),
      color: colors['failure-color'],
      icon: FailedIcon
    },
    [RELEASE_STATUSES.FAILED_PROCESSING]: {
      label: t('Failed To Process'),
      color: colors['failure-color'],
      icon: FailedIcon
    },
    [RELEASE_STATUSES.REJECTED_171]: {
      label: t('Rejected 171'),
      color: colors['failure-color'],
      icon: FailedIcon
    },
    [RELEASE_STATUSES.DRAFT]: {
      label: t('Draft'),
      color: colors['dark-1'],
      icon: DraftIcon
    },
    [RELEASE_STATUSES.DELETED_DRAFT]: {
      label: t('Deleted Draft'),
      color: colors['pastel-red'],
      icon: () => <FailedIcon fill={colors['pastel-red']} />
    },
    [RELEASE_STATUSES.ARTIST_TAKEN_DOWN]: {
      label: t('Taken Down'),
      color: colors['failure-color'],
      icon: FailedIcon
    },
    [RELEASE_STATUSES.PROCESSING]: {
      label: t('Processing'),
      color: colors['orange'],
      icon: ProcessingIcon
    }
  };
  const statusContent = StatusComponents[status];
  if (!statusContent) {
    return <div className={styles.statusTableCell}>{status}</div>;
  }
  return (
    <div className={styles.statusTableCell} style={{ color: statusContent.color }}>
      <span>{statusContent.label}</span>
      <statusContent.icon width="16" height="16" />
    </div>
  );
}
