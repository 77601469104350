import React from 'react';
import {
  RELEASE_STATUSES,
  RELEASE_TYPES
} from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import { CONTENT_TYPES, LIBRARY_TYPE } from '@anghami/neogateway/dist/endpoints/getUserLibrary';
import { userContext } from 'components/contexts/auth/auth.context';
import {
  autoDeleteRelease,
  postAlbumSongEndpoint,
  postAlbumUploadEndpoint,
  postSingleUploadEndpoint
} from 'helpers/http-helper';
import {
  getIsPodcastFromReleaseType,
  getIsReleaseAlbum,
  getIsReleaseSongInsideAlbum
} from 'helpers/releases-helper';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { logGeneralErrorEvent } from 'helpers/analytics-helper';

export interface IUseDeleteReleaseProps {
  releaseId: string;
  albumId: string;
  releaseStatus: RELEASE_STATUSES;
  releaseType: RELEASE_TYPES;
  onSuccess: () => void;
  onError: (message: string) => void;
}

export default function useDeleteRelease({
  releaseId,
  albumId,
  releaseStatus,
  releaseType,
  onSuccess,
  onError
}: IUseDeleteReleaseProps) {
  const { t } = useTranslation('common');
  const { user } = React.useContext(userContext);
  const reactQueryClient = useQueryClient();
  const handleMutationSettle = (data, error) => {
    if (!data || error) {
      onError(t('something-went-wrong-try-again'));
      logGeneralErrorEvent(error, 'useDeleteRelease on settle');
      return;
    }
    if (data.error) {
      onError(data?.error?.message || t('something-went-wrong-try-again'));
      return;
    }
    const contentType = getIsPodcastFromReleaseType(releaseType)
      ? CONTENT_TYPES.podcast
      : CONTENT_TYPES.music;
    if (getIsReleaseSongInsideAlbum(releaseType)) {
      reactQueryClient.refetchQueries({
        queryKey: [
          user?.anid,
          contentType,
          'library',
          releaseStatus === RELEASE_STATUSES.DRAFT ? LIBRARY_TYPE.draft : LIBRARY_TYPE.live,
          albumId
        ],
        exact: false
      });
    }
    reactQueryClient.refetchQueries({
      queryKey: [user?.anid, contentType, 'library'],
      exact: false
    });
    onSuccess();
  };
  const deleteliveReleaseMutation = useMutation(
    ({ releaseId, isAlbum }: { releaseId: string; isAlbum?: boolean }) =>
      autoDeleteRelease(releaseId, isAlbum),
    {
      onSettled: handleMutationSettle
    }
  );
  const deletePendingAlbumMutation = useMutation(
    (releaseId: string) =>
      postAlbumUploadEndpoint(
        {
          albumid: releaseId,
          delete: true
        },
        getIsPodcastFromReleaseType(releaseType)
      ),
    {
      onSettled: handleMutationSettle
    }
  );
  const deleteSingleMutation = useMutation(
    (releaseId: string) =>
      postSingleUploadEndpoint(
        {
          songid: releaseId,
          albumid: albumId,
          delete: true
        },
        getIsPodcastFromReleaseType(releaseType)
      ),
    {
      onSettled: handleMutationSettle
    }
  );
  const deleteSongMutation = useMutation(
    (releaseId: string) =>
      postAlbumSongEndpoint({
        songid: releaseId,
        albumid: albumId,
        ispodcast: getIsPodcastFromReleaseType(releaseType) ? 1 : 0,
        delete: true
      }),
    {
      onSettled: handleMutationSettle
    }
  );

  const deleteRelease = () => {
    const isAlbum = getIsReleaseAlbum(releaseType);
    if (
      releaseStatus === RELEASE_STATUSES.LIVE ||
      releaseStatus === RELEASE_STATUSES.PENDING_RELEASE
    ) {
      deleteliveReleaseMutation.mutate({ releaseId, isAlbum });
      return;
    }
    if (isAlbum) {
      deletePendingAlbumMutation.mutate(releaseId);
      return;
    }
    if (getIsReleaseSongInsideAlbum(releaseType)) {
      deleteSongMutation.mutate(releaseId);
      return;
    }
    deleteSingleMutation.mutate(releaseId);
  };

  return deleteRelease;
}
