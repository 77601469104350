import DateRangePickerDialog from 'components/Reusable/DateRangePickerDialog';
import React from 'react';
import styles from './reportsRangeSelector.module.scss';
import { parse } from 'date-fns';
import { API_DATE_FORMAT } from '../constants';

interface IReportRangeSelectorProps {
  from?: string;
  to?: string;
  onChange: (from?: string, to?: string) => void;
  dateFormat?: string;
  dateLimit?: string;
}

export default function ReportRangeSelector({
  from,
  to,
  onChange,
  dateFormat,
  dateLimit
}: IReportRangeSelectorProps) {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const disableDates = (current: Date) => {
    if (!current) return false;
    return (
      parse(dateLimit, API_DATE_FORMAT, new Date()).getTime() < current.getTime() ||
      new Date('2013-01-01').getTime() > current.getTime()
    );
  };

  return (
    <>
      <div
        className={styles.dateContainer}
        onClick={() => setIsModalVisible(true)}
      >{`${from} - ${to}`}</div>
      <DateRangePickerDialog
        isOpen={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onChange={onChange}
        dateFormat={dateFormat}
        defaultFrom={parse(from, dateFormat, new Date()).toISOString()}
        defaultTo={parse(to, dateFormat, new Date()).toISOString()}
        picker="month"
        disableDates={disableDates}
      />
    </>
  );
}
