import getAnalyticsData, { TimePeriods } from '@anghami/neogateway/dist/endpoints/getAnalyticsData';
import {
  ChartTypes,
  ChartsIds,
  AnalyticsType
} from '@anghami/neogateway/dist/endpoints/getAnalyticsSections';
import { ReactComponent as Loader } from 'icons/loaders/bars.svg';
import { ReactComponent as RetryIcon } from 'icons/retry.svg';
import { gateway } from 'helpers/gateway-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  ListChart,
  AnalyticsAreaChart,
  AnalyticsLineChart,
  PercentageChart,
  TableChart,
  SummaryChart
} from '../charts';
import { periodContext } from '../context/selectedPeriod.context';
import {
  enhanceLineChartData,
  enhanceListChartData,
  enhancePercentageChart,
  enhanceTableChartData
} from '../helpers/charts-helper';
import colors from 'styles/partials/_colors_exports.module.scss';
import styles from './sectionItem.module.scss';
import SectionItemHeader from '../charts/SectionItemHeader/sectionItemHeader';

interface ISectionItemProps {
  chartType: ChartTypes;
  chartId: ChartsIds;
  analyticsType: AnalyticsType;
  allTimeData?: boolean;
  title?: string;
  id: string;
}

export default function SectionItem({
  id,
  chartType,
  chartId,
  title,
  analyticsType,
  allTimeData
}: ISectionItemProps) {
  const { selectedPeriod } = React.useContext(periodContext);
  const { t } = useTranslation('common');
  const { data, isLoading, error, refetch } = useQuery(
    [
      'analytics-data',
      chartId,
      analyticsType,
      id,
      !allTimeData && selectedPeriod.value,
      ...(!allTimeData && selectedPeriod.value === TimePeriods.CUSTOM
        ? [selectedPeriod.from, selectedPeriod.to]
        : [])
    ],
    async () => {
      const { data: res } = await gateway.callEndpoint(getAnalyticsData, {
        ...(id && { id }),
        chart_id: chartId,
        analytics_type: analyticsType,
        ...(!allTimeData && {
          time_period: selectedPeriod.value,
          ...(selectedPeriod.value === TimePeriods.CUSTOM && {
            start_date: selectedPeriod.from,
            end_date: selectedPeriod.to
          })
        }),
        output: 'jsonhp'
      });
      if (!res || res.error) {
        throw new Error(res?.error?.message || t('something-went-wrong-try-again'));
      }
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );
  if (isLoading) {
    return (
      <div className="w-100 h-100 d-flex f-align-center f-justify-center position-relative p-1">
        <Loader fill={colors['branding-purple']} width="30" height="30" />
      </div>
    );
  }
  if (error) {
    return (
      <div className="w-100 h-100 f-column">
        <SectionItemHeader title={title} allTimeData={allTimeData} />
        <div className="d-flex f-align-center f-justify-center f-grow-1">
          {(error as any).message && typeof (error as any).message === 'string'
            ? (error as any).message
            : t('something-went-wrong-try-again')}
          <RetryIcon className={styles.retryButton} onClick={() => refetch()} />
        </div>
      </div>
    );
  }
  switch (chartType) {
    case ChartTypes.listChart: {
      return (
        <ListChart color={'#44E6B0'} {...enhanceListChartData(data)} allTimeData={allTimeData} />
      );
    }
    case ChartTypes.lineChart: {
      return <AnalyticsLineChart {...enhanceLineChartData(data)} allTimeData={allTimeData} />;
    }
    case ChartTypes.areaChart: {
      return <AnalyticsAreaChart {...enhanceLineChartData(data)} allTimeData={allTimeData} />;
    }
    case ChartTypes.percentageChart: {
      return <PercentageChart {...enhancePercentageChart(data)} allTimeData={allTimeData} />;
    }
    case ChartTypes.table: {
      return <TableChart {...enhanceTableChartData(data)} allTimeData={allTimeData} />;
    }
    case ChartTypes.summary: {
      return <SummaryChart {...data} allTimeData={allTimeData} />;
    }
    default: {
      return <div>Section type not supported</div>;
    }
  }
}
