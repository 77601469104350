import React from 'react';
import { combineClassNames } from 'helpers/styling-helper';
import styles from './songInfoPreview.module.scss';
import { ISongInfoPreviewItem } from 'interfaces/formsComponents.interface';
import { useTranslation } from 'react-i18next';

interface ISongInfoPreviewProps {
  songInfo: ISongInfoPreviewItem[];
}

export default function SongInfoPreview({ songInfo }: ISongInfoPreviewProps) {
  const { t } = useTranslation('common');
  return (
    <>
      {songInfo.map((info) => (
        <div key={info.title} className={styles.info_column}>
          <span className={styles.info_column_title}> {info.title}</span>
          {info.value.map((infoValue, index) => (
            <div key={infoValue.title + infoValue.value + index} className={styles.one_info_field}>
              {infoValue.title && (
                <span className={styles.info_column_subtitle}>{infoValue.title}</span>
              )}
              {infoValue.value ? (
                <span className={styles.indo_column_value}>{infoValue.value}</span>
              ) : (
                <span className={combineClassNames(styles.indo_column_value, styles.error)}>
                  {t('Missing')}
                </span>
              )}
            </div>
          ))}
        </div>
      ))}
    </>
  );
}
