import Button from 'components/Reusable/Button';
import ProgressBar from 'components/Reusable/ProgressBar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import colors from 'styles/partials/_colors_exports.module.scss';
import { ReactComponent as SuccessIcon } from 'icons/status-succeed.svg';
import { ReactComponent as RetryIcon } from 'icons/retry.svg';
import { combineClassNames } from 'helpers/styling-helper';
import styles from './videoUpload.module.scss';

export default function VideoUploadProgress({
  isLoading,
  isUploadSuccess,
  loadingProgress,
  handleRetry
}: {
  isLoading: boolean;
  isUploadSuccess: boolean;
  loadingProgress: number;
  handleRetry: () => void;
}) {
  const { t } = useTranslation();
  return (
    <div className={combineClassNames('w-100', styles.videoProgressContainer)}>
      <div className="d-flex f-align-center f-justify-between mb-1">
        {isLoading ? (
          <>
            <h3>{t('uploading')}</h3>
            <Button
              label={t('Retry')}
              icon={<RetryIcon width={20} height={20} />}
              onSubmit={handleRetry}
              customClasses={[styles.retryButton, styles.whilePending]}
            />
          </>
        ) : isUploadSuccess ? (
          <>
            <h3>{t('Uploaded')}</h3>
            <SuccessIcon className={styles.successIcon} />
          </>
        ) : (
          <>
            <h3>{t('Upload Failed')}</h3>
            <Button
              label={t('Retry')}
              icon={<RetryIcon width={20} height={20} />}
              onSubmit={handleRetry}
              customClasses={[styles.retryButton]}
            />
          </>
        )}
      </div>
      <ProgressBar
        progress={loadingProgress || 0}
        backgroundColor={colors['light-3']}
        progressColor={colors['dark-3']}
      />
    </div>
  );
}
