import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './noData.module.scss';
import Empty from 'antd/lib/empty';

export default function NoData({
  allTimeData,
  customMessage
}: {
  allTimeData?: boolean;
  customMessage?: string;
}) {
  const { t } = useTranslation('common');
  return (
    <div className="w-100 h-100 f-center f-column no-select">
      <Empty description={false} />
      <span className={styles.noDataText}>
        {customMessage
          ? customMessage
          : allTimeData
          ? t('No data')
          : t('No data in the selected period')}
      </span>
    </div>
  );
}
