import React from 'react';
import { gateway } from 'helpers/gateway-helper';
import DropDown from 'components/Reusable/DropDown';
import { IDropDownProps } from 'components/Reusable/DropDown/dropDown';
import getAlbumTypes from '@anghami/neogateway/dist/endpoints/getAlbumTypes';
import { useQuery } from 'react-query';
import { RELEASE_TYPES } from '@anghami/neogateway/dist/endpoints/getreleasedetails';

export default function AlbumTypesSelect(props: IDropDownProps & { releasetype?: RELEASE_TYPES }) {
  const { data } = useQuery(
    ['albumTypeDropDown', props.releasetype],
    async () => {
      const { data } = await gateway.callEndpoint(getAlbumTypes, {
        release_type: props.releasetype || RELEASE_TYPES.Single
      });
      if (data?.types?.length > 0) {
        return data?.types.map((type) => ({ label: type?.description, value: type?.id }));
      } else {
        throw new Error('Something went wrong, please try again');
      }
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: 3,
      staleTime: Infinity
    }
  );

  React.useEffect(() => {
    if (!props.value || !data) return;
    const findValue = data?.find((item) => item.value === props.value.value);
    if (findValue) {
      if (findValue.label !== props.value?.label) {
        props.onChange(findValue);
        return;
      }
      return;
    }
    props.onChange(null);
  }, [data, props.value]);

  return <DropDown {...props} options={data || []} />;
}
