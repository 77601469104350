import React from 'react';
import { environment } from 'env/environment';
import styles from './artistItem.module.scss';
import { combineClassNames } from 'helpers/styling-helper';

interface IProps {
  ArtistArt?: string;
  name?: string;
  selectArtist?: any;
  half?: boolean;
}

export default function ArtistItem({ ArtistArt, name, selectArtist, half }: IProps) {
  return (
    <div
      className={combineClassNames(styles.container, half && styles.half)}
      onClick={selectArtist}
    >
      <img
        alt="album-cover"
        className="album-cover"
        src={`${environment.artwork_cdn}/?id=${ArtistArt}&size=64`}
        style={{ alignSelf: 'center' }}
      />
      <span>{name}</span>
    </div>
  );
}
