import { AccountTypeEnums, IPermissions, IAuthenticateRes } from 'interfaces/auth.interface';
import React from 'react';

export interface Auth {
  user: IAuthenticateRes;
  setUser: (user: IAuthenticateRes) => void;
  loggedIn: boolean;
  setLoggedIn: (user: any) => void;
  logout: () => void;
  reauth: (callback?: () => void) => void;
  handleAuthSuccess: (authObject: IAuthenticateRes) => void;
  permissions: IPermissions;
  setPermissions: (p: string[]) => void;
  userType: AccountTypeEnums;
}

const userContext = React.createContext<Auth>({
  user: null,
  setUser: () => null,
  loggedIn: false,
  setLoggedIn: () => null,
  logout: () => null,
  reauth: () => null,
  handleAuthSuccess: () => null,
  permissions: null,
  setPermissions: () => null,
  userType: null
});

const useAuth = () => React.useContext(userContext);

export { userContext, useAuth };
