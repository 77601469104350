import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/Reusable/Button';
import { IUploadedFile, UploaderFileTypes } from 'interfaces/formsComponents.interface';
import useFileUploader from 'hooks/useFileUploader';
import { useMutation, useQueryClient } from 'react-query';
import { gateway } from 'helpers/gateway-helper';
import optionsVideoEncodingQueue from '@anghami/neogateway/dist/endpoints/optionsVideoEncodingQueue';
import { environment } from 'env/environment';
import { toastContext } from 'components/contexts/toast/toast.context';
import {
  CONTENT_TYPES,
  IReleaseItem,
  LIBRARY_TYPE
} from '@anghami/neogateway/dist/endpoints/getUserLibrary';
import { getCoverArtImage } from 'helpers/releases-helper';
import {
  UploadedVideo,
  VideoUploadHeader,
  VideoPlayer,
  VideoUploadProgress,
  VideoUploadBox
} from 'components/VideoUpload';
import { userContext } from 'components/contexts/auth/auth.context';

interface IUploadVideoDialogProps {
  closeDialog?: () => void;
  songId?: string;
  video?: IReleaseItem['video'];
  isPodcast?: boolean;
}

const postVideo = async (songId: string, key: string, releaseDate: Date) =>
  gateway
    .callEndpoint(optionsVideoEncodingQueue, {
      option: 'insert',
      params: {
        bucket: environment.upload_s3_bucket,
        path: key,
        songid: songId,
        videoreleasedate: (releaseDate || new Date()).getTime().toString()
      },
      output: 'jsonhp'
    })
    .then((res) => res?.data || null);

export default function UploadVideoDialog({
  songId,
  video,
  closeDialog,
  isPodcast
}: IUploadVideoDialogProps) {
  const { t } = useTranslation('common');
  const { openToast } = React.useContext(toastContext);
  const videoRef: { current: HTMLVideoElement } = React.useRef();
  const canvasRef: { current: HTMLCanvasElement } = React.useRef();
  const [videoMetas, setVideoMetas] = React.useState<IUploadedFile>(null);
  const [videoReleaseDate, setVideoReleaseDate] = React.useState<Date>(new Date());
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const { user } = React.useContext(userContext);
  const {
    uploadFile,
    clearUploadedFile,
    uploadedFile,
    loadingProgress,
    isLoading,
    isUploadSuccess
  } = useFileUploader({
    fileType: UploaderFileTypes.video,
    onSuccess: (uploadedFile) => {
      console.log('upload success', uploadedFile);
    },
    onError: (err: any) => {
      console.error('upload error', err);
    }
  });
  const reactQueryClient = useQueryClient();
  const mutation = useMutation(() => postVideo(songId, uploadedFile?.key, videoReleaseDate), {
    retry: 3,
    onSettled: (data, error) => {
      if (!data || error || data.error) {
        console.error('error posting uploaded video', error || data.error);
        openToast(data?.error?.message || t('Something went wrong, try again'), 'error');
        return;
      }
      openToast(t('video added success'), 'success');
      reactQueryClient.refetchQueries({
        queryKey: [
          user?.anid,
          isPodcast ? CONTENT_TYPES.podcast : CONTENT_TYPES.music,
          'library',
          LIBRARY_TYPE.live
        ],
        exact: false
      });
      closeDialog();
    }
  });

  React.useEffect(() => {
    if (!video || typeof video.status === 'undefined') return;
    setStep(2);
    setVideoReleaseDate(new Date(video?.release_date));
  }, [video]);

  React.useEffect(() => {
    if (!videoMetas?.src || !videoRef.current) return;
    videoRef.current.onpause = () => {
      setIsPlaying(false);
    };
    videoRef.current.onplay = () => {
      setIsPlaying(true);
    };
  }, [videoMetas?.src]);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files.item(0);
      const objectUrl = URL.createObjectURL(file);
      setVideoMetas({
        ...videoMetas,
        name: file.name,
        type: file.type,
        src: objectUrl
      });
    }
  };

  const handlePlayPause = () => {
    if (videoRef?.current?.paused) {
      videoRef.current.play();
      return;
    }
    videoRef?.current?.pause();
  };

  const changeVideo = () => {
    clearUploadedFile();
    setVideoMetas(null);
    setStep(0);
  };

  const handleNextButton = () => {
    if (step === 2) {
      if (!videoMetas?.src) {
        closeDialog();
        return;
      }
      mutation.mutate();
      return;
    }
    if (step === 0) {
      uploadFile(videoMetas);
    }
    setStep(step + 1);
  };

  const handleRetry = () => {
    clearUploadedFile();
    uploadFile(videoMetas);
  };

  const handleBackButton = () => {
    if (step === 0) {
      closeDialog();
      return;
    }
    if (step === 2 && !videoMetas?.src) {
      closeDialog();
      return;
    }
    setStep(step - 1);
  };

  return (
    <div className={'f-column p-2'}>
      <VideoUploadHeader
        handleBackButton={handleBackButton}
        handleNextButton={handleNextButton}
        nextButtonLabel={step === 0 ? t('Upload') : step === 1 ? t('Next') : t('Done')}
        isNextButtonDisabled={
          isLoading || (step === 0 && !videoMetas?.name) || (step === 1 && !uploadedFile?.key)
        }
        status={video?.status}
        isLastStep={step === 2}
      />
      <div className={'f-column f-align-center w-100 gap-1'}>
        {step === 2 ? (
          videoMetas?.src ? (
            <UploadedVideo
              changeVideo={changeVideo}
              videoRef={videoRef}
              name={videoMetas.name}
              src={videoMetas?.src}
              releaseDate={videoReleaseDate}
              setReleaseDate={setVideoReleaseDate}
            />
          ) : (
            <UploadedVideo
              changeVideo={changeVideo}
              videoRef={videoRef}
              name={video.id}
              src={video?.thumbnail_id ? getCoverArtImage(video.thumbnail_id) : video.url}
              isImage={!!video.thumbnail_id}
              releaseDate={videoReleaseDate}
              setReleaseDate={setVideoReleaseDate}
              isPreview={true}
            />
          )
        ) : videoMetas?.src ? (
          <div className="f-column f-align-center">
            <VideoPlayer
              handlePlayPause={handlePlayPause}
              src={videoMetas?.src}
              isPlaying={isPlaying}
              videoRef={videoRef}
            />
            {step === 0 ? (
              <Button
                label={t('Change Video')}
                customClasses={['default']}
                size="md"
                onSubmit={changeVideo}
              ></Button>
            ) : (
              <VideoUploadProgress
                isLoading={isLoading}
                isUploadSuccess={isUploadSuccess}
                loadingProgress={loadingProgress}
                handleRetry={handleRetry}
              />
            )}
          </div>
        ) : (
          <VideoUploadBox onSelectFile={onSelectFile} />
        )}
      </div>
      <canvas style={{ display: 'none' }} ref={canvasRef}></canvas>
    </div>
  );
}
