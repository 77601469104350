/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import styles from './videoUpload.module.scss';
import { ReactComponent as PlayFilled } from 'icons/play-filled.svg';
import { ReactComponent as PauseFilled } from 'icons/pause-filled.svg';

export default function VideoPlayer({ isPlaying, videoRef, src, handlePlayPause }) {
  return (
    <div className={styles.videoContainer}>
      <video className={styles.previewVideo} ref={videoRef} src={src}></video>
      <div className={styles.controlsOverlay}>
        <div className={styles.playpausebutton} onClick={handlePlayPause}>
          {isPlaying ? (
            <PauseFilled width={60} height={60} />
          ) : (
            <PlayFilled width={60} height={60} />
          )}
        </div>
      </div>
    </div>
  );
}
