import { dialogsContext } from 'components/contexts/dialogs/toast/dialogs.context';
import EditLibraryReleaseDialog from 'components/Dialogs/EditLibraryReleaseDialog';
import React from 'react';
import {
  AVAILABLE_ACTIONS,
  RELEASE_STATUSES,
  RELEASE_TYPES
} from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import { useNavigate } from 'react-router-dom';
import UploadVideoDialog from 'components/Dialogs/UploadVideoDialog';
import {
  getIsPodcastFromReleaseType,
  getIsReleaseAlbum,
  getIsReleaseSongInsideAlbum,
  getReleaseLink,
  getSimplifiedReleaseTypes
} from 'helpers/releases-helper';
import DeleteLibraryReleaseDialog from 'components/Dialogs/DeleteLibraryReleaseDialog';
import ShareDialog, { SHARE_TYPES } from 'components/Dialogs/ShareDialog/shareDialog';
import { logAmplitudeEvent } from 'helpers/analytics-helper';
import { CLICK_LIBRARY_ACTION_BUTTON } from 'constants/amplitudeEvents';
import styles from './infoTableCell.module.scss';

interface IuseHandleActionsProps {
  releaseId: string;
  releaseType: RELEASE_TYPES;
  releaseStatus: RELEASE_STATUSES;
  releaseName: string;
  albumId: string;
  coverart: string;
}

export default function useHandleActions({
  releaseId,
  releaseStatus,
  releaseType,
  releaseName,
  coverart,
  albumId
}: IuseHandleActionsProps): (action: AVAILABLE_ACTIONS) => void {
  const { showDialog } = React.useContext(dialogsContext);
  const navigate = useNavigate();
  const showEditReleaseDialog = () => {
    showDialog({
      dialog: (
        <EditLibraryReleaseDialog
          release_id={releaseId}
          release_status={releaseStatus}
          release_type={releaseType}
          album_id={albumId}
          config={{
            hideUploadCoverArt: false
          }}
        ></EditLibraryReleaseDialog>
      ),
      backdrop: true,
      customClassName: styles.editDialog
    });
  };

  const redirectToEditingDraft = () => {
    const isAlbum = getIsReleaseAlbum(releaseType);
    navigate(
      `/create/${getSimplifiedReleaseTypes(releaseType)}?albumid=${albumId}${
        !isAlbum ? `&songid=${releaseId}` : ''
      }`
    );
  };

  const showUploadVideoDialog = () => {
    showDialog({
      dialog: (
        <UploadVideoDialog
          songId={releaseId}
          isPodcast={getIsPodcastFromReleaseType(releaseType)}
        ></UploadVideoDialog>
      ),
      backdrop: true,
      customClassName: styles.videoUploadDialog
    });
  };

  const showDeleteDialog = () => {
    showDialog({
      dialog: (
        <DeleteLibraryReleaseDialog
          releaseName={releaseName}
          releaseId={releaseId}
          albumId={albumId}
          releaseStatus={releaseStatus}
          releaseType={releaseType}
        ></DeleteLibraryReleaseDialog>
      ),
      backdrop: true,
      customClassName: styles.deleteDialog
    });
  };

  const showShareDialog = () => {
    showDialog({
      dialog: (
        <ShareDialog
          shareData={{
            image: coverart,
            text: releaseName,
            link: getReleaseLink(releaseType, releaseId)
          }}
          allowedShare={[SHARE_TYPES.COPY, SHARE_TYPES.FB, SHARE_TYPES.TWTR, SHARE_TYPES.TLGRM]}
        ></ShareDialog>
      ),
      backdrop: true,
      customClassName: styles.shareDialog
    });
  };

  const showAnalytics = () => {
    let type;
    switch (releaseType) {
      case RELEASE_TYPES.Single:
        type = RELEASE_TYPES.Song;
        break;
      case RELEASE_TYPES.Single_Episode:
        type = RELEASE_TYPES.Episode;
        break;
      default:
        type = releaseType;
        break;
    }
    navigate(`/analytics/${type}/${releaseId}`);
  };

  const manageRSS = () => {
    navigate('/library/rss');
  };

  const handleActionClick = (action: AVAILABLE_ACTIONS) => {
    logAmplitudeEvent(CLICK_LIBRARY_ACTION_BUTTON, {
      name: action
    });
    switch (action) {
      case AVAILABLE_ACTIONS.EDIT_RELEASE_DIALOG: {
        showEditReleaseDialog();
        break;
      }
      case AVAILABLE_ACTIONS.EDIT_RELEASE_PAGE: {
        redirectToEditingDraft();
        break;
      }
      case AVAILABLE_ACTIONS.EDIT: {
        const isSongInsideAlbum = getIsReleaseSongInsideAlbum(releaseType);
        if (
          releaseStatus == RELEASE_STATUSES.LIVE ||
          releaseStatus == RELEASE_STATUSES.PENDING_APPROVAL ||
          isSongInsideAlbum
        ) {
          showEditReleaseDialog();
          return;
        }
        if (releaseStatus == RELEASE_STATUSES.DRAFT) {
          redirectToEditingDraft();
          return;
        }
        break;
      }
      case AVAILABLE_ACTIONS.ADD_VIDEO: {
        showUploadVideoDialog();
        break;
      }
      case AVAILABLE_ACTIONS.TAKEDOWN:
      case AVAILABLE_ACTIONS.DELETE: {
        showDeleteDialog();
        break;
      }
      case AVAILABLE_ACTIONS.SHARE: {
        showShareDialog();
        break;
      }
      case AVAILABLE_ACTIONS.VIEW_ANALYTICS: {
        showAnalytics();
        break;
      }
      case AVAILABLE_ACTIONS.MANAGE_RSS: {
        manageRSS();
        break;
      }

      default: {
        console.log('----- not handled yet');
      }
    }
  };

  return handleActionClick;
}
