export const environment = {
  prod_api_endpoint: 'https://dashapi.anghami.com/gateway.php',
  staging_api_endpoint: 'https://dashstaging.anghami.com/gateway.php',
  staging_local_api_endpoint: 'http://dashapi-staging.angha.me/gateway.php',
  creators_koussa_local_api_endpoint: 'http://koussa-creators.anghami.com',
  creators_koussa_prod_api_endpoint: 'https://koussa-creators.anghami.com',
  secure_staging_local_api_endpoint: 'https://dashapi-staging.angha.me/gateway.php',
  lab_api_endpoint: 'http://lab.anghami.com/gateway.php',
  main_images_cdn_url: 'https://web.anghcdn.co/_next/static/images',
  local_api_endpoint: 'http://dashapi.angha.me/gateway.php',
  server_api_endpoint: 'http://web2api.anghami.com/gateway.php',
  ganalyticsKey: 'UA-38735485-18',
  gtagKey: 'UA-125682597-1',
  fbkKey: '355354294849193',
  fbSDK: '299953330100883',
  amplitudeKey: 'ccf346f1cd6b93ab16eca9f8987da0ca',
  raygun: '22rYc42HZ5sxBzlgxPc4Q',
  cdn_url: 'https://web.anghcdn.co',
  artwork_cdn: 'https://artwork.anghcdn.co',
  web_assets_cdn_url: 'https://web.anghcdn.co/artists-dashboard/_next/static/assets',
  creators_assets_cdn: 'https://web.anghcdn.co/creators/assets',
  dashboard_s3_bucket: 'https://s3.eu-west-1.amazonaws.com/anghami.dashboard',
  upload_s3_bucket: 'anghami.dashboard',
  general_s3_token: 'C5E386CD1A2A2F1DD93841AD811F7',
  zendesk_key: '55844177-5758-496c-bcbd-a00c02ea54d4'
};
