import { gateway } from 'helpers/gateway-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import getOwnersReport from '@anghami/neogateway/dist/endpoints/getOwnersReports';
import { format, parse, subMonths } from 'date-fns';
import ReportRangeSelector from 'components/Reports/ReportsRangeSelector';
import DropDown from 'components/Reusable/DropDown';
import { IOption } from 'interfaces/formsComponents.interface';
import styles from './reports.module.scss';
import { combineClassNames } from 'helpers/styling-helper';
import ReportsSummary from 'components/Reports/ReportsSummary';
import { Skeleton } from 'antd';
import ReportsTable from 'components/Reports/ReportsTable/reportsTable';
import { TablesTypes } from 'components/Reports/interfaces';
import NoData from 'components/Reusable/NoData';
import { userContext } from 'components/contexts/auth/auth.context';
import { setPageTitle } from 'helpers/url-helper';
import { API_DATE_FORMAT, DISPLAY_DATE_FORMAT } from 'components/Reports/constants';
import SignContractDialog from 'components/Dialogs/SignContractDialog';
import { VIEW_CONTRACT_SIGNING } from 'constants/amplitudeEvents';
import { logAmplitudeEvent } from 'helpers/analytics-helper';
import { dialogsContext } from 'components/contexts/dialogs/toast/dialogs.context';
import useUserConditions from 'hooks/useUserConditions';
import WarningOutlined from '@ant-design/icons/lib/icons/WarningOutlined';
import ExitBetaButton from 'components/Landing/sidemenu/exitBetaButton';
import { AccountTypeEnums } from 'interfaces/auth.interface';

export default function ReportsPage() {
  const { t } = useTranslation('common');
  const [dateRange, setDateRange] = React.useState<{ from: string; to: string }>(null);
  const [groupOwners, setGroupOwners] = React.useState<IOption>(null);
  const [isLabelAccountReports, setIsLabelAccountReports] = React.useState<boolean>(false);
  const { user, userType } = React.useContext(userContext);
  const { shouldSignAgreement } = useUserConditions();
  const { showDialog } = React.useContext(dialogsContext);
  const { data, isLoading, refetch } = useQuery(
    [user?.anid, 'reports', dateRange?.from, dateRange?.to, groupOwners?.value],
    async () => {
      const formatedFrom = dateRange?.from
        ? format(parse(dateRange.from, DISPLAY_DATE_FORMAT, new Date()), API_DATE_FORMAT)
        : null;
      const formatedTo = dateRange?.to
        ? format(parse(dateRange.to, DISPLAY_DATE_FORMAT, new Date()), API_DATE_FORMAT)
        : null;
      const { data } = await gateway.callEndpoint(getOwnersReport, {
        output: 'jsonhp',
        ...(dateRange && { from: formatedFrom, to: formatedTo }),
        ...(groupOwners && { grpownerid: Number(groupOwners.value) })
      });
      return data;
    }
  );
  React.useEffect(() => {
    if (!data) return;
    if (data.error) return;
    if (!dateRange?.from && !dateRange?.to) {
      const date = parse(data.dateLimit, 'yyyy-MM-dd', new Date());
      const dateMinusMonth = subMonths(date, 1);
      const formattedFrom = format(dateMinusMonth, DISPLAY_DATE_FORMAT);
      const formattedTo = format(date, DISPLAY_DATE_FORMAT);
      setDateRange({
        from: formattedFrom,
        to: formattedTo
      });
    }
    setIsLabelAccountReports(
      !!data.response?.TOTAL?.all_streams_free || !!data.response?.TOTAL?.all_streams_telco
    );
    if (!data.groupowners || groupOwners?.value) return;
    setGroupOwners(data.groupowners.map((go) => ({ value: String(go.id), label: go.text }))[0]);
  }, [data]);

  React.useEffect(() => {
    setPageTitle('Reports');
    if (shouldSignAgreement) {
      showSignContractDialog();
    }
  }, [shouldSignAgreement]);

  const showSignContractDialog = () => {
    logAmplitudeEvent(VIEW_CONTRACT_SIGNING, {});
    showDialog({
      dialog: <SignContractDialog onSuccessFn={() => refetch()} />,
      backdrop: true
    });
  };

  const handleDateRangeChange = (from: string, to: string) => {
    setDateRange({ from, to });
  };

  const handleGroupOwnersChange = (value: IOption) => {
    setGroupOwners(value);
  };
  return (
    <div className="p-2">
      <div className="d-flex f-align-center f-justify-between">
        <h1>{t('Financial Report')}</h1>
        {dateRange && (
          <div className="d-flex f-align-center gap-1">
            <span className="bold hide-on-mobile">{t('Date')}</span>
            <ReportRangeSelector
              from={dateRange.from}
              to={dateRange.to}
              onChange={handleDateRangeChange}
              dateLimit={data?.dateLimit}
              dateFormat={DISPLAY_DATE_FORMAT}
            />
          </div>
        )}
      </div>
      <div>
        <div
          className={combineClassNames('d-flex f-align-center gap-1', styles.accountGroupContainer)}
        >
          {data?.groupowners && (
            <>
              <span className="bold">{t('Account')}</span>
              <DropDown
                customClassName={styles.groupOwnerDropdown}
                onChange={handleGroupOwnersChange}
                options={data.groupowners.map((go) => ({ value: String(go.id), label: go.text }))}
                value={groupOwners}
              />
            </>
          )}
        </div>
        {userType === AccountTypeEnums.LABEL && (
          <div
            className={combineClassNames(
              'd-flex gap-1 f-align-center f-justify-between p-1',
              styles.labelDislaimer
            )}
          >
            <WarningOutlined style={{ fontSize: '2.5rem' }} />
            <span className="d-block f-1">
              {t('you-are-on-beta-and-you-might-have-some-missing-information-on-this-page')}
            </span>
            <ExitBetaButton />
          </div>
        )}
        {!data || isLoading ? (
          <Skeleton style={{ marginTop: '1rem' }} />
        ) : data.error || Number(data.response) === -1 ? (
          <div className="d-flex f-grow f-align-center f-justify-center pt-1 mt-1">
            <NoData customMessage={String(data.error?.message || data.error || t('No data'))} />
          </div>
        ) : (
          <div className="f-column h-100 gap-2">
            <ReportsSummary summary={data.summary} dateRange={dateRange} />
            {isLabelAccountReports ? (
              Object.values(TablesTypes).map((type) => (
                <div key={type} className="f-1">
                  <ReportsTable
                    tableData={data?.response}
                    type={type}
                    extras={{ ownerName: data?.realownername, groupOwnerName: data?.owner }}
                  />
                </div>
              ))
            ) : (
              <ReportsTable
                tableData={data?.response}
                extras={{ ownerName: data?.realownername, groupOwnerName: data?.owner }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
