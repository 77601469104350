export const isMobile = () => {
  if (typeof window === 'undefined' || typeof navigator === 'undefined') {
    return false;
  }

  return /Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent);
};

export const imageURLtoFile: (imageURL: string, fileName: string) => Promise<File> = async (
  imageURL: string,
  fileName: string
) => {
  const blob = await imageURLtoBlob(imageURL);
  const file = new File([blob], `${fileName}.jpg`, { type: blob.type });
  return file;
};

export const imageURLtoBlob: (imageURL: string) => Promise<Blob> = async (imageURL: string) => {
  const response = await fetch(imageURL);
  // here image is url/location of image
  const blob = await response.blob();
  return blob;
};

export const shareFB = (text: string, link: string): void => {
  window.open(
    `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      link
    )}&quote=${encodeURIComponent(text)}`,
    'facebook-popup',
    'height=350,width=600'
  );
};

export const shareWhatsapp = (text: string): void => {
  if (isMobile()) {
    window.open(`whatsapp://send?text=${encodeURIComponent(text)}`, '_blank');
  } else {
    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`, '_blank');
  }
};

export const shareTwitter = (text: string, link: string, hashtag: string): void => {
  window.open(
    `http://twitter.com/share?url=${encodeURIComponent(link)}&text=${encodeURIComponent(
      text
    )}&counturl=${encodeURIComponent(link)}${hashtag ? `&hashtags=${hashtag}` : ''}`,
    '_blank'
  );
};

export const shareEmail = (text: string, link: string, title: string): void => {
  window.open(
    'mailto:' +
      '?subject=' +
      encodeURIComponent(title) +
      '&body=' +
      encodeURIComponent(link) +
      encodeURIComponent('\n' + text),
    '_blank'
  );
};

export const shareLine = (text: string, link: string): void => {
  window.open(
    `https://lineit.line.me/share/ui?url=${encodeURIComponent(link)}&text=${encodeURIComponent(
      text
    )}`,
    '_blank'
  );
};

export const shareSkype = (text: string, link: string): void => {
  window.open(
    'https://web.skype.com/share' +
      '?url=' +
      encodeURIComponent(link) +
      '&text=' +
      encodeURIComponent(text),
    '_blank'
  );
};

export const shareViber = (text: string): void => {
  window.open('viber://forward?text=' + encodeURIComponent(text), '_blank');
};

export const shareTelegram = (text: string): void => {
  window.open('https://telegram.me/share/url?url=' + encodeURIComponent(text), '_blank');
};

export const shareReddit = (text: string, link: string, title: string): void => {
  window.open(
    'https://reddit.com/submit?url=' +
      encodeURIComponent(link) +
      '&title=' +
      encodeURIComponent(title),
    '_blank'
  );
};

export const shareLinkedIn = (text: string, link: string, title: string): void => {
  window.open(
    'https://www.linkedin.com/shareArticle' +
      '?mini=true' +
      '&url=' +
      encodeURIComponent(link) +
      '&title=' +
      encodeURIComponent(title) +
      '&summary=' +
      encodeURIComponent(text),
    '_blank'
  );
};

export const sharePintrest = (text: string, link: string): void => {
  window.open('http://pinterest.com/pin/create/link/?url=' + encodeURIComponent(link), '_blank');
};

export const shareTumblr = (text: string, link: string, title: string): void => {
  window.open(
    'https://www.tumblr.com/widgets/share/tool' +
      '?canonicalUrl=' +
      encodeURIComponent(link) +
      '&title=' +
      encodeURIComponent(title) +
      '&caption=' +
      encodeURIComponent(text) +
      '&tags=',
    '_blank'
  );
};

export const saveImage = async (shareImageURL: string, fileName: string) => {
  const blobImage = await imageURLtoFile(shareImageURL, fileName);
  const link = document.createElement('a');
  const blobUrl = URL.createObjectURL(blobImage);
  link.href = blobUrl;
  link.download = `${fileName}.jpg`;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
