import React from 'react';
import {
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Area,
  AreaChart,
  Tooltip
} from 'recharts';
import SectionItemHeader from '../SectionItemHeader/sectionItemHeader';
import NoData from 'components/Reusable/NoData';
import CustomToolTip from '../CustomToolTip';
import { IEnhancedSectionData } from '../../interfaces';
import pSBC from 'shade-blend-color';
import { chartTickFormatter } from 'components/Analytics/helpers/charts-helper';

const AnalyticsAreaChart = ({ data, title, unit, color, allTimeData }: IEnhancedSectionData) => {
  const [hasSecondLine, setHasSecondLine] = React.useState(false);
  const firstColor = color || '#945cff';
  const [secondColor, setSecondColor] = React.useState('#4a3276');
  React.useEffect(() => {
    setHasSecondLine(data.some((i) => i.value2));
    setSecondColor(pSBC(-0.6, color || '#945cff'));
  }, [data]);
  return (
    <div style={{ width: '100%', padding: '1rem', height: '25rem' }}>
      <SectionItemHeader title={title} allTimeData={allTimeData} />
      {data.length == 0 ? (
        <NoData allTimeData={allTimeData} />
      ) : (
        <div className="f-grow-1">
          <ResponsiveContainer height="100%" width="100%">
            <AreaChart data={data}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={firstColor} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={firstColor} stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={secondColor} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={secondColor} stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="key" />
              <YAxis type="number" domain={['auto', 'auto']} tickFormatter={chartTickFormatter} />
              <Area
                type="monotone"
                dataKey="value"
                stroke={firstColor}
                fillOpacity={1}
                fill="url(#colorUv)"
                strokeWidth={3}
                unit={unit}
                dot={{
                  r: 5,
                  stroke: color || '#945cff',
                  fill: color || '#945cff',
                  strokeWidth: 3
                }}
              />
              <Tooltip content={<CustomToolTip />} />
              {hasSecondLine && (
                <Area
                  type="monotone"
                  dataKey="value2"
                  stroke={secondColor}
                  fillOpacity={1}
                  fill="url(#colorPv)"
                  strokeWidth={3}
                  unit={unit}
                  dot={{
                    r: 5,
                    stroke: secondColor,
                    fill: secondColor,
                    strokeWidth: 3
                  }}
                />
              )}
            </AreaChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default AnalyticsAreaChart;
