import MultiSelect from 'components/Reusable/MultiSelect';
import { IMultiSelectProps } from 'components/Reusable/MultiSelect/multiSelect';
import React from 'react';
import { gateway } from 'helpers/gateway-helper';
import searchEdge from '@anghami/neogateway/dist/endpoints/searchEdge';

export default function ArtistSelect(props: IMultiSelectProps) {
  const [artists, setArtists] = React.useState([]);
  let debounceTimout = null;
  const searchArtists = (query: string) => {
    clearTimeout(debounceTimout);
    debounceTimout = setTimeout(() => {
      gateway
        .callEndpoint(searchEdge, {
          q: query?.toLowerCase(),
          dashboard: true,
          searchtype: 'artists'
        })
        .then((res) => {
          if (!res || !res.data) return;
          setArtists(
            res.data.map((artist) => ({ label: artist?._value, value: artist?._attributes.id }))
          );
        });
    }, 500);
  };
  return (
    <MultiSelect {...props} options={artists} onSearchQuery={searchArtists} hideArrow={true} />
  );
}
