import LanguageSelector from 'components/LanguageSelector';
import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './landingContainer.module.scss';
import { environment } from 'env/environment';
import { BeatLoader } from 'components/Reusable/Loaders';
import { Outlet } from 'react-router-dom';
import { updateSidemenuCSSVariables } from '../sidemenu/sidemenu.helper';

function LandingContainer() {
  const { t } = useTranslation();
  React.useEffect(() => {
    updateSidemenuCSSVariables(false);
  }, []);
  return (
    <div className={styles.mainContainer}>
      <div className={combineClassNames('f-column p-2  f-1 f-grow', styles.backgroundSection)}>
        <img
          src={`${environment.creators_assets_cdn}/images/anghami_creators_black.png`}
          className={styles.pageLogo}
          alt="Artist-Dashboard logo"
        />
        <h1 className={styles.pageTitle}>
          {t('Welcome to Anghami for')} <span>{t('Creators')}</span>
        </h1>
        <div
          className={combineClassNames(
            'w-100 d-flex f-justify-center',
            styles.desktopLanguageSelector
          )}
        >
          <LanguageSelector />
        </div>
      </div>
      <div className={combineClassNames(styles.outletSection)}>
        <div className="h-100">
          <React.Suspense
            fallback={
              <div className="w-100 h-100 f-center">
                <BeatLoader></BeatLoader>
              </div>
            }
          >
            <Outlet />
          </React.Suspense>
        </div>
      </div>
      <div className={combineClassNames('w-100 f-justify-center', styles.mobileLanguageSelector)}>
        <LanguageSelector />
      </div>
    </div>
  );
}

export default LandingContainer;
