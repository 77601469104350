import React from 'react';
import ArtistsBenefits from 'components/Landing/frames/ArtistsBenefits';
import DownloadPlaybook from 'components/Landing/frames/DownloadPlaybook';
import DownloadSection from 'components/Landing/frames/DownloadSection';
import IntroduceYourVoice from 'components/Landing/frames/IntroduceYourVoice';
import LandingHeroSection from 'components/Landing/frames/LandingHeroSection';
import PerksOfAnghami from 'components/Landing/frames/PerksOfAnghami';
import LandingHeader from 'components/Landing/LandingHeader';
import styles from './landingPage.module.scss';

export default function LandingPage() {
  return (
    <div className={styles.childrenContainer}>
      <LandingHeader />
      <div className={styles.bodyContainer}>
        <LandingHeroSection />
        <PerksOfAnghami />
        <IntroduceYourVoice />
        <DownloadPlaybook />
        {/* <ArtistsWhoMadeIt /> */}
        <ArtistsBenefits />
        <DownloadSection />
      </div>
      {/* TODO: Footer to be updated */}
      {/* <Footer /> */}
    </div>
  );
}
