import React from 'react';
import styles from './reportsSummary.module.scss';
import { ISummary } from '@anghami/neogateway/dist/endpoints/getOwnersReports';
import { useTranslation } from 'react-i18next';
import { format, parse } from 'date-fns';
import { formatCurrency, formatDecimal, shortenNumbers } from 'helpers/numbers-helper';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { DISPLAY_DATE_FORMAT } from '../constants';

interface IReportsSummaryProps {
  summary: Partial<ISummary>;
  dateRange?: { from: string; to: string };
}
export default function ReportsSummary({ summary, dateRange }: IReportsSummaryProps) {
  const { t } = useTranslation('common');
  const { isMobile } = useWindowDimensions();
  const [dateRangeString, setDateRangeString] = React.useState('');
  React.useEffect(() => {
    if (!dateRange) return;
    setDateRangeString(
      `From ${format(parse(dateRange.from, DISPLAY_DATE_FORMAT, new Date()), 'MMM')} To ${format(
        parse(dateRange.to, DISPLAY_DATE_FORMAT, new Date()),
        'MMM'
      )}`
    );
  }, [dateRange]);
  return (
    <div className={styles.reportsSummaryContainer}>
      {summary && (
        <>
          <div className={styles.mainSection}>
            <h3>{t('Total net revenue')}</h3>
            <p>{dateRangeString}</p>
            <span>{formatCurrency(summary.owner_net_revenue || 0, 'USD')}</span>
          </div>
          <div
            className={styles.secondSection}
            style={
              Object.keys(summary).length > 2
                ? { justifyContent: 'space-around', gap: 'unset', paddingInlineStart: '1rem' }
                : {}
            }
          >
            {(summary.label_streams || summary.label_streams === 0) && (
              <div className={styles.section}>
                <h3>{t('Account Total Plays')}</h3>
                <p>{dateRangeString}</p>
                <span>
                  {isMobile
                    ? shortenNumbers(summary.label_streams)
                    : formatDecimal(summary.label_streams)}
                </span>
              </div>
            )}
            {(summary.all_streams || summary.all_streams === 0) && (
              <div className={styles.section}>
                <h3>{t('Total Plays')}</h3>
                <p>{dateRangeString}</p>
                <span>
                  {isMobile
                    ? shortenNumbers(summary.all_streams)
                    : formatDecimal(summary.all_streams)}
                </span>
              </div>
            )}
            {(summary.market_share || summary.market_share === 0) && (
              <div className={styles.section}>
                <h3>{t('Market Share')}</h3>
                <p>{dateRangeString}</p>
                <span>{summary.market_share + '%'}</span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
