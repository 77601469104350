import { IFormField } from './../interfaces/formsComponents.interface';
import {
  IAlbumSong,
  ICreateAlbumFormValues,
  ICreateSingleFormValues,
  ISongInfoPreviewItem,
  IUploadedFile
} from 'interfaces/formsComponents.interface';

import { FormFieldTypes, IFormFields, ISection } from 'interfaces/formsComponents.interface';
import i18n from 'i18n';
import {
  IReleaseItemOld,
  RELEASE_STATUSES,
  RELEASE_TYPES,
  OLD_RELEASE_ITEM_KEYS as RK
} from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import { getIsReleaseAlbum, getIsReleaseSongInsideAlbum } from './releases-helper';
import { AccountTypeEnums } from 'interfaces/auth.interface';
import { getFormFieldsFilteredByFormSections } from './form-helper';
import { v4 as uuidv4 } from 'uuid';
const { t } = i18n;

/* 
 FORM Sections HELPERS
 -------------------
*/

/* 
  - Category instead of genre for podcast
  - hide collab and featuring on podcast inside a show
  - Hide albumtype when adding a song to an album
  - show language selector only when mixed is 0 (mixed)
*/

const getSingleFormSections = (): ISection[] => [
  {
    title: null,
    data: [['albumtoaddto'], ['coverkey'], ['albumtype'], ['languageid']]
  },
  {
    title: t('General Information'),
    data: [
      ['songname', 'songnamear'],
      // ['artist_name', 'owner'],
      // ['featuringartistids', 'artists'],
      ['genre'],
      ['isrc', 'upc'],
      ['releasedate', 'releasetime']
    ]
  },
  {
    title: t('Additional Information'),
    data: [['explicit'], ['lyrics']]
  }
];

const getSongFormSections = (): ISection[] => [
  {
    title: null,
    data: [['albumtoaddto'], ['coverkey'], ['languageid']]
  },
  {
    title: t('General Information'),
    data: [
      ['songname', 'songnamear'],
      // ['artist_name', 'owner'],
      // ['featuringartistids', 'artists'],
      ['genre'],
      ['isrc'],
      ['releasedate', 'releasetime']
    ]
  },
  {
    title: t('Additional Information'),
    data: [['explicit'], ['lyrics']]
  }
];

const getEpisodeFormSections = (): ISection[] => [
  {
    title: null,
    data: [['showid'], ['coverkey'], ['languageid']]
  },
  {
    title: t('General Information'),
    data: [
      ['songname', 'songnamear'],
      ['description', 'descriptionar'],
      // ['featuringartistids', 'artists'],
      // hiding category for episode inside a show, as it will inherit it and it's read only, and it will save a query from api
      // ['category'],
      ['releasedate', 'releasetime']
    ]
  },
  {
    title: t('Additional Information'),
    data: [['explicit']]
  }
];

const getSingleEpisodeFormSections = (): ISection[] => [
  {
    title: null,
    data: [['showid'], ['coverkey'], ['albumtype'], ['languageid']]
  },
  {
    title: t('General Information'),
    data: [
      ['songname', 'songnamear'],
      ['description', 'descriptionar'],
      // ['featuringartistids', 'artists'],
      ['category'],
      ['releasedate', 'releasetime']
    ]
  },
  {
    title: t('Additional Information'),
    data: [['explicit']]
  }
];

const getAlbumFormSections = (): ISection[] => [
  {
    title: null,
    data: [['coverkey'], ['albumtype'], ['languageid']]
  },
  {
    title: t('General Information'),
    data: [
      ['albumname', 'albumnamear'],
      // ['artist_name', 'owner'],
      // ['artists'],
      ['genre'],
      ['upc'],
      ['releasedate', 'releasetime']
    ]
  },
  {
    title: t('Additional Information'),
    data: [['explicit']]
  }
];

const getShowFormSections = (): ISection[] => [
  {
    title: null,
    data: [['coverkey'], ['albumtype'], ['languageid']]
  },
  {
    title: t('General Information'),
    data: [
      ['albumname', 'albumnamear'],
      ['description', 'descriptionar'],
      // ['artists'],
      ['category'],
      ['releasedate', 'releasetime']
    ]
  },
  {
    title: t('Additional Information'),
    data: [['explicit']]
  }
];

const getRSSShowFormSections = (): ISection[] => [
  {
    title: '',
    data: [['rssLink'], ['languageid'], ['category']]
  }
];

/* 
 FORM FIELDS HELPERS
 -------------------
*/
const getSingleFormFields = (isSingle = false): IFormFields => ({
  coverkey: {
    type: FormFieldTypes.UPLOAD_COVER_ART,
    label: t('Release Cover'),
    name: 'coverkey',
    extraValueParam: 'uploadedCoverArt',
    value: '',
    required: true
  },
  albumtype: {
    type: FormFieldTypes.ALBUM_TYPE_SELECT,
    label: t('Type'),
    name: 'albumtype',
    extraValueParam: 'albumTypeValue',
    value: null,
    required: isSingle,
    releasetype: RELEASE_TYPES.Single,
    placeholder: t('album type placeholder')
  },
  languageid: {
    type: FormFieldTypes.RADIO_BUTTON,
    label: t('Language'),
    labelDescription: 'single language description',
    value: null,
    name: 'languageid',
    required: true,
    options: [
      { label: t('Arabic'), value: '1' },
      { label: t('International'), value: '2' }
    ]
  },
  songname: {
    type: FormFieldTypes.INPUT_FIELD,
    label: t('Name'),
    name: 'songname',
    value: '',
    placeholder: t('Song Name'),
    required: true
  },
  songnamear: {
    type: FormFieldTypes.INPUT_FIELD,
    label: t('Arabic Name'),
    name: 'songnamear',
    regexValidation: /[\u0600-\u06FF]/,
    regexValidationMessage: t('Release name should be in Arabic'),
    value: '',
    placeholder: t('Arabic Song Name'),
    required: false,
    isHidden: true
  },
  artist_name: {
    type: FormFieldTypes.INPUT_FIELD,
    label: t('Artist Name'),
    name: 'artist_name',
    value: '',
    placeholder: t('Artist Name'),
    required: true,
    disabled: true
  },
  owner: {
    type: FormFieldTypes.INPUT_FIELD,
    label: t('Owner'),
    name: 'owner',
    value: '',
    placeholder: t('Owner'),
    required: false,
    disabled: true
  },
  featuringartistids: {
    type: FormFieldTypes.ARTISTS_SELECT,
    label: t('Featuring Artists'),
    name: 'featuringartistids',
    extraValueParam: 'featuringartistidsValue',
    value: [],
    placeholder: t('Type and select artists')
  },
  artists: {
    type: FormFieldTypes.ARTISTS_SELECT,
    label: t('Collaborating Artists'),
    name: 'artists',
    extraValueParam: 'artistsValue',
    value: [],
    placeholder: t('Type and select artists')
  },
  genre: {
    type: FormFieldTypes.GENRE_SELECT,
    label: t('Genre'),
    name: 'genre',
    extraValueParam: 'genreValue',
    value: null,
    placeholder: t('music genre placeholder'),
    required: true,
    languageid: 2
  },
  isrc: {
    type: FormFieldTypes.INPUT_FIELD,
    label: t('ISRC'),
    name: 'isrc',
    value: '',
    placeholder: 'AA/XXX/00/1111'
  },
  upc: {
    type: FormFieldTypes.INPUT_FIELD,
    label: t('UPC'),
    name: 'upc',
    value: '',
    placeholder: '000000000000',
    disabled: true
  },
  releasedate: {
    type: FormFieldTypes.DATE_INPUT,
    label: t('Release Date'),
    name: 'releasedate',
    value: '',
    placeholder: 'YYYY/MM/DD',
    required: true
  },
  releasetime: {
    type: FormFieldTypes.TIME_INPUT,
    label: t('Time'),
    name: 'releasetime',
    value: '',
    placeholder: 'hh:mm:ss',
    required: true
  },
  explicit: {
    type: FormFieldTypes.MONO_SELECT_CHECKBOX,
    label: t('Restrictions'),
    itemLabel: t('Explicit'),
    name: 'explicit',
    value: null,
    placeholder: ''
  },
  lyrics: {
    type: FormFieldTypes.TEXT_AREA,
    label: t('Lyrics'),
    name: 'lyrics',
    value: '',
    placeholder: t('Add lyrics here')
  },
  albumtoaddto: {
    type: FormFieldTypes.ALBUM_TO_ADD_TO_SELECT,
    label: t('Album name'),
    name: 'albumtoaddto',
    extraValueParam: 'albumtoaddtoValue',
    labelDescription: t('Add to Album description'),
    value: null,
    required: false,
    placeholder: t('Select album to add to')
  }
});
const getEpisodeFormFields = (isSingle = false): IFormFields => {
  const singleFormFields = getSingleFormFields(isSingle);
  return {
    ...singleFormFields,
    albumtype: {
      ...singleFormFields.albumtype,
      releasetype: RELEASE_TYPES.Episode
    },
    songname: {
      ...singleFormFields.songname,
      placeholder: t('Episode Name')
    },
    songnamear: {
      ...singleFormFields.songnamear,
      regexValidationMessage: t('Release name should be in Arabic'),
      placeholder: t('Arabic Episode Name')
    },
    showid: {
      type: FormFieldTypes.OWN_SHOWS_SELECT,
      label: t('Podcast name'),
      name: 'showid',
      extraValueParam: 'showidValue',
      value: null,
      required: true,
      labelDescription: t('Add to podcast description'),
      placeholder: t('Select show to add to')
    },
    description: {
      type: FormFieldTypes.TEXT_AREA,
      label: t('Description'),
      name: 'description',
      value: '',
      placeholder: t('Add description here')
    },
    descriptionar: {
      type: FormFieldTypes.TEXT_AREA,
      label: t('Arabic Description'),
      name: 'descriptionar',
      value: '',
      placeholder: t('Add arabic description here'),
      isHidden: true
    },
    category: {
      type: FormFieldTypes.CATEGORY_SELECT,
      label: t('Category'),
      name: 'category',
      extraValueParam: 'categoryValue',
      placeholder: t('select podcast category'),
      value: null,
      required: isSingle,
      albumtype: 5
    },
    artists: {
      ...singleFormFields.artists,
      label: t('Collaborating Podcasters'),
      placeholder: t('Type and select podcasters')
    },
    featuringartistids: {
      ...singleFormFields.featuringartistids,
      label: t('Featuring Podcasters'),
      placeholder: t('Type and select podcasters')
    },
    genre: null
  };
};
const getAlbumFormFields = (): IFormFields => ({
  coverkey: {
    type: FormFieldTypes.UPLOAD_COVER_ART,
    label: t('Release Cover'),
    name: 'coverkey',
    extraValueParam: 'uploadedCoverArt',
    value: '',
    required: true
  },
  albumtype: {
    type: FormFieldTypes.ALBUM_TYPE_SELECT,
    label: t('Type'),
    name: 'albumtype',
    extraValueParam: 'albumTypeValue',
    value: null,
    required: true,
    releasetype: RELEASE_TYPES.Album,
    placeholder: t('album type placeholder')
  },
  languageid: {
    type: FormFieldTypes.RADIO_BUTTON,
    label: t('Language'),
    labelDescription: t('Select music language'),
    value: null,
    name: 'languageid',
    options: [
      { label: t('Arabic'), value: '1' },
      { label: t('International'), value: '2' },
      { label: t('Mixed'), value: '0' }
    ]
  },
  albumname: {
    type: FormFieldTypes.INPUT_FIELD,
    label: t('Name'),
    name: 'albumname',
    value: '',
    placeholder: t('Album Name'),
    required: true
  },
  albumnamear: {
    type: FormFieldTypes.INPUT_FIELD,
    label: t('Name in Arabic'),
    name: 'albumnamear',
    regexValidation: /[\u0600-\u06FF]/,
    regexValidationMessage: t('Release name should be in Arabic'),
    value: '',
    placeholder: t('Arabic Album Name'),
    required: false,
    isHidden: true
  },
  artist_name: {
    type: FormFieldTypes.INPUT_FIELD,
    label: t('Artist Name'),
    name: 'artist_name',
    value: '',
    placeholder: t('Artist Name'),
    required: false,
    disabled: true
  },
  owner: {
    type: FormFieldTypes.INPUT_FIELD,
    label: t('Owner'),
    name: 'owner',
    value: '',
    placeholder: t('Owner'),
    required: false,
    disabled: true
  },
  artists: {
    type: FormFieldTypes.ARTISTS_SELECT,
    label: t('Collaborating Artists'),
    name: 'artists',
    extraValueParam: 'artistsValue',
    value: [],
    placeholder: t('Type and select artists')
  },
  genre: {
    type: FormFieldTypes.GENRE_SELECT,
    additionalInfo: {
      text: t('Album Genre Info')
    },
    label: t('Genre'),
    name: 'genre',
    extraValueParam: 'genreValue',
    value: null,
    placeholder: t('music genre placeholder'),
    required: true,
    languageid: 2
  },
  upc: {
    type: FormFieldTypes.INPUT_FIELD,
    label: t('UPC'),
    name: 'upc',
    value: '',
    placeholder: '000000000000',
    disabled: true
  },
  releasedate: {
    type: FormFieldTypes.DATE_INPUT,
    label: t('Release Date'),
    name: 'releasedate',
    value: '',
    placeholder: 'YYYY/MM/DD',
    required: true
  },
  releasetime: {
    type: FormFieldTypes.TIME_INPUT,
    label: t('Time'),
    name: 'releasetime',
    value: '',
    placeholder: 'hh:mm:ss',
    required: true
  },
  explicit: {
    type: FormFieldTypes.MONO_SELECT_CHECKBOX,
    label: t('Restrictions'),
    itemLabel: t('Explicit'),
    name: 'explicit',
    value: null,
    placeholder: ''
  }
});
const getShowFormFields = (): IFormFields => {
  const albumFormFields = getAlbumFormFields();
  return {
    ...albumFormFields,
    languageid: {
      ...albumFormFields.languageid,
      labelDescription: t('Select show language')
    },
    albumname: {
      ...albumFormFields.albumname,
      placeholder: t('Show Name')
    },
    albumnamear: {
      ...albumFormFields.albumnamear,
      placeholder: t('Arabic Show Name')
    },
    description: {
      type: FormFieldTypes.TEXT_AREA,
      label: t('Description'),
      name: 'description',
      value: '',
      placeholder: t('Add description here')
    },
    descriptionar: {
      type: FormFieldTypes.TEXT_AREA,
      label: t('Arabic Description'),
      name: 'descriptionar',
      value: '',
      placeholder: t('Add arabic description here'),
      isHidden: true
    },
    category: {
      type: FormFieldTypes.CATEGORY_SELECT,
      label: t('Category'),
      name: 'category',
      extraValueParam: 'categoryValue',
      value: null,
      placeholder: t('select podcast category'),
      required: true,
      albumtype: 5
    },
    artists: {
      ...albumFormFields.artists,
      label: t('Collaborating Podcasters'),
      placeholder: t('Type and select podcasters')
    },
    albumtype: {
      ...albumFormFields.albumtype,
      releasetype: RELEASE_TYPES.Show
    },
    genre: null
  };
};
const getRSSShowFormFields = (): IFormFields => ({
  rssLink: {
    type: FormFieldTypes.INPUT_FIELD,
    label: t('Add podcast feed URL'),
    name: 'rssLink',
    value: '',
    placeholder: t('RSS link placeholder'),
    required: true
  },
  languageid: {
    type: FormFieldTypes.RADIO_BUTTON,
    label: t('Language'),
    labelDescription: t('Select music language'),
    value: null,
    name: 'languageid',
    extraLabel:
      'If the track contains both arabic and international languages, please select Arabic',
    options: [
      { label: t('Arabic'), value: '1' },
      { label: t('International'), value: '2' },
      { label: t('Mixed'), value: '0' }
    ]
  },
  category: {
    type: FormFieldTypes.CATEGORY_SELECT,
    label: t('Category'),
    name: 'category',
    extraValueParam: 'categoryValue',
    value: null,
    placeholder: t('select podcast category'),
    required: true,
    albumtype: 5
  }
});

const getReleaseTypeFormFields = (release_type: RELEASE_TYPES) =>
  ({
    [RELEASE_TYPES.Album]: getAlbumFormFields(),
    [RELEASE_TYPES.Show]: getShowFormFields(),
    [RELEASE_TYPES.Single]: getSingleFormFields(true),
    [RELEASE_TYPES.Episode]: getEpisodeFormFields(),
    [RELEASE_TYPES.Song]: getSingleFormFields(),
    [RELEASE_TYPES.Single_Episode]: getEpisodeFormFields(true)
  }[release_type]);

const getFormSections = (release_type: RELEASE_TYPES): ISection[] =>
  ({
    [RELEASE_TYPES.Album]: getAlbumFormSections(),
    [RELEASE_TYPES.Show]: getShowFormSections(),
    [RELEASE_TYPES.Single]: getSingleFormSections(),
    [RELEASE_TYPES.Single_Episode]: getSingleEpisodeFormSections(),
    [RELEASE_TYPES.Episode]: getEpisodeFormSections(),
    [RELEASE_TYPES.Song]: getSongFormSections()
  }[release_type]);

const getFormFields = (configs: IFormConfigs): IFormFields => {
  const isAlbum = getIsReleaseAlbum(configs.releaseType);
  const isSongInsideAlbum = getIsReleaseSongInsideAlbum(configs.releaseType);
  const isLive = configs.releaseStatus === RELEASE_STATUSES.LIVE;
  const isLabel = configs.accountType === AccountTypeEnums.LABEL;
  const initialFormFields = getReleaseTypeFormFields(configs.releaseType);
  // song inside an album languageid is inherited from album, but it might change only if it's mixed (0)
  const songLanguageId = configs?.data
    ? (isSongInsideAlbum &&
      configs.data.albumLanguageId &&
      Number(configs.data.albumLanguageId) !== 0
        ? configs.data.albumLanguageId
        : configs.data.languageId) || 2
    : 2;
  return {
    ...initialFormFields,
    coverkey: configs.isEdit ? null : initialFormFields.coverkey,
    languageid: initialFormFields.languageid
      ? {
          ...initialFormFields.languageid,
          value: isSongInsideAlbum ? songLanguageId : configs.data?.languageId || 2,
          // hide when it's a songd inside album and the album language is fixed
          isHidden: isSongInsideAlbum && Number(configs.data?.albumLanguageId) !== 0
        }
      : null,
    albumnamear: initialFormFields.albumnamear
      ? {
          ...initialFormFields.albumnamear,
          required: configs.data?.languageId != 2,
          isHidden: configs.data?.languageId == 2
        }
      : null,
    songnamear: initialFormFields.songnamear
      ? {
          ...initialFormFields.songnamear,
          required: songLanguageId == 1,
          isHidden: songLanguageId != 1
        }
      : null,
    descriptionar: initialFormFields.descriptionar
      ? {
          ...initialFormFields.descriptionar,
          isHidden: isSongInsideAlbum ? songLanguageId === 2 : configs.data?.languageId == 2
        }
      : null,
    artist_name: initialFormFields.artist_name
      ? {
          ...initialFormFields.artist_name,
          // required for singles and episodes
          required: !isAlbum,
          disabled: true
        }
      : null,
    owner: initialFormFields.owner
      ? {
          ...initialFormFields.owner,
          required: false,
          disabled: true
        }
      : null,
    artists: initialFormFields.artists
      ? {
          ...initialFormFields.artists,
          disabled: configs.isEdit && isLive,
          required: isLabel && !(configs.isEdit && isLive),
          getApiValue: (formField: IFormField) =>
            formField.value?.map((artist) => ({ id: artist, isnew: false }))
        }
      : null,
    genre: initialFormFields.genre
      ? {
          ...initialFormFields.genre,
          additionalInfo: isAlbum
            ? {
                text: t('Album Genre Info')
              }
            : null,
          value: null,
          required: true,
          languageid: isSongInsideAlbum ? songLanguageId : configs.data?.languageId || 2
        }
      : null,
    upc: initialFormFields.upc
      ? {
          ...initialFormFields.upc,
          isHidden: !isLabel,
          disabled: !isLabel && isLive
        }
      : null,
    isrc: initialFormFields.isrc
      ? {
          ...initialFormFields.isrc,
          isHidden: !isLabel,
          disabled: !isLabel && isLive
        }
      : null,
    releasedate: {
      ...initialFormFields.releasedate,
      required: true,
      getApiValue: (formField: IFormField) => formField.value?.getTime()
    },
    releasetime: {
      ...initialFormFields.releasetime,
      required: true,
      notSentToApi: true
    },
    category: initialFormFields.category
      ? {
          ...initialFormFields.category,
          required: true,
          albumtype: configs.data?.albumType || 5,
          getApiValue: (formField: IFormField) => formField.extraValue
        }
      : null,
    albumtype: initialFormFields.albumtype
      ? {
          ...initialFormFields.albumtype,
          value: null,
          required: true,
          release_type: configs.releaseType
        }
      : null,
    showid: configs.data?.hideShowId ? null : initialFormFields.showid,
    albumtoaddto: { ...initialFormFields.albumtoaddto, disabled: true }
  };
};

export const getUpdatedFormFields = (
  newFormFields: IFormFields,
  fieldName: string
): IFormFields => {
  const tempFormFields: IFormFields = {
    ...newFormFields,
    [fieldName]: { ...newFormFields[fieldName], error: null }
  };
  if (fieldName === RK.languageid) {
    if (tempFormFields[RK.songnamear]) {
      tempFormFields[RK.songnamear] = {
        ...newFormFields[RK.songnamear],
        ...(newFormFields[RK.languageid].value == 1
          ? { isHidden: false, required: true }
          : { isHidden: true, required: false })
      };
    } else {
      tempFormFields[RK.albumnamear] = {
        ...newFormFields[RK.albumnamear],
        // arabic fields are requied when album language is mixed (we default for arabic)
        ...(newFormFields[RK.languageid].value != 2
          ? { isHidden: false, required: true }
          : { isHidden: true, required: false })
      };
    }
    if (tempFormFields[RK.descriptionar]) {
      tempFormFields[RK.descriptionar] = {
        ...newFormFields[RK.descriptionar],
        isHidden: newFormFields[RK.languageid].value == 2
      };
    } else if (tempFormFields[RK.genre]) {
      tempFormFields[RK.genre] = {
        ...tempFormFields[RK.genre],
        [RK.languageid]: newFormFields[RK.languageid].value,
        value: '',
        extraValue: null
      };
    }
  } else if (fieldName === RK.albumtype && tempFormFields[RK.category]) {
    tempFormFields[RK.category] = {
      ...tempFormFields[RK.category],
      [RK.albumtype]: newFormFields[RK.albumtype].value,
      value: '',
      extraValue: null
    };
  } else if (
    (fieldName === RK.releasedate && newFormFields[RK.releasetime]?.value) ||
    (fieldName === RK.releasetime && newFormFields[RK.releasedate].value)
  ) {
    const date = new Date(newFormFields[RK.releasedate].value);
    const time = new Date(newFormFields[RK.releasetime]?.value);
    tempFormFields[RK.releasedate].value = new Date(
      date.setHours(time.getHours(), time.getMinutes(), time.getSeconds())
    );
  }
  return tempFormFields;
};
export interface IFormConfigs {
  releaseType: RELEASE_TYPES;
  isEdit?: boolean;
  releaseStatus?: RELEASE_STATUSES;
  accountType?: AccountTypeEnums;
  data?: {
    languageId?: number;
    albumType?: number;
    hideShowId?: boolean;
    albumLanguageId?: number;
  };
}
export const getFormSectionsAndFields = (
  configs: IFormConfigs
): { formSections: ISection[]; formFields: IFormFields } => {
  const formSections = getFormSections(configs.releaseType);
  const formFields = getFormFieldsFilteredByFormSections(getFormFields(configs), formSections);
  return { formSections, formFields };
};

export const getRssShowFormSectionsAndFields = () => {
  const formSections = getRSSShowFormSections();
  const formFields = getFormFieldsFilteredByFormSections(getRSSShowFormFields(), formSections);
  return { formSections, formFields };
};

export const extractSingleFormValuesFromApiData = (data, isPodcast) => ({
  ...data,
  languageid: data.languageid || 2,
  ispodcast: isPodcast,
  uploadedCoverArt: {
    src: data.coverurl,
    key: data.coverkey,
    isUploaded: !!data.coverkey || !!data.coverurl
  },
  ...(data.isyoutubeupload
    ? { youtubeLink: data.audiourl, audioonly: true }
    : {
        uploadedSong: {
          src: data.audiourl,
          key: data.audiokey,
          name: data.filename || data.songname || data.songnamear || data.songid || 'uploaded Song',
          size: data.filesize
        }
      }),
  // empty arabic fields when language is not arabic,
  // cause api set arabic fields to match english ones when language is not arabic and this cause issue in regex validation
  songnamear: data.languageid == 1 ? data.songnamear : '',
  descriptionar: data.languageid == 1 ? data.descriptionar : '',
  // api won't return releasetime in getreleasedetails
  releasedate: data.releasedate ? new Date(Number(data.releasedate)) : null,
  ...(data.releasetype === RELEASE_TYPES.Episode && { showid: data.albumid }),
  releasetime: data.releasedate ? new Date(Number(data.releasedate)) : null,
  ...(data.albumtype && { albumTypeValue: data.albumtype, albumtype: data.albumtype?.value }),
  ...(data.genre && { genre: data.genre.value, genreValue: data.genre }),
  ...(data.category && { category: data.category.id, categoryValue: data.category }),
  ...(data.featuringartistids && {
    featuringartistidsValue: data.featuringartistids,
    featuringartistids: data.featuringartistids?.map((artist) => artist.value)
  }),
  ...(data.artists && {
    artistsValue: data.artists,
    artists: data.artists?.map((artist) => artist.value)
  }),
  ...(data.albumid &&
    data.releasetype === RELEASE_TYPES.Song && {
      albumtoaddto: data.albumid,
      albumtoaddtoValue: { value: data.albumid }
    })
});

export const extractAlbumFormValuesFromApiData = (
  data,
  isPodcast
): [ICreateAlbumFormValues, IAlbumSong[]] => {
  const newFormValues = {
    ...data,
    ispodcast: isPodcast,
    uploadedCoverArt: {
      src: data.coverurl,
      key: data.coverkey,
      isUploaded: !!data.coverkey || !!data.coverurl
    },
    // releasedate and time are returned as timestamps
    releasedate: new Date(Number(data.releasedate)),
    releasetime: data.releasetime ? new Date(Number(data.releasetime)) : data.releasedate,
    ...(data.albumtype && { albumTypeValue: data.albumtype, albumtype: data.albumtype?.value }),
    ...(data.genre && { genreValue: data.genre, genre: data.genre?.value }),
    ...(data.category && { categoryValue: data.category, category: data.category?.id }),
    ...(data.featuringartistids && {
      featuringartistidsValue: data.featuringartistids,
      featuringartistids: data.featuringartistids?.map((artist) => artist.value)
    }),
    ...(data.artists && {
      artistsValue: data.artists,
      artists: data.artists?.map((ar) => ar.value)
    }),
    songs: { allsongs: data.songs?.map((song) => song.songid) || null },
    albumnamear: data.languageid != 2 ? data.albumnamear : '',
    descriptionar: data.languageid != 2 ? data.descriptionar : ''
    // empty arabic fields when language is not arabic,
    // cause api set arabic fields to match english ones when language is not arabic and this cause issue in regex validation
  };
  return [
    newFormValues,
    data.songs.map((song) => {
      const audioFile: IUploadedFile = {
        src: song.audiourl,
        key: song.audiokey,
        name: song.filename || song.songname || song.songnamear || song.songid,
        size: song.filesize,
        isUploaded: !!song.audiokey || !!song.audiourl
      };
      return {
        ...extractSingleFormValuesFromApiData(song, isPodcast),
        id: song.songid,
        uploadedSong: audioFile
      };
    })
  ];
};

export const extractValuesFromApiData = (
  data: IReleaseItemOld
): {
  formValues: any;
  coverArt: IUploadedFile;
  songs: IAlbumSong[];
  singleUploadedSong: IUploadedFile;
} => {
  const languageid = Number(data.languageid || 2);
  const coverArt: IUploadedFile = {
    src: data.coverurl,
    key: data.coverkey,
    name: data.songname || data.songnamear || 'uploaded coverArt',
    isUploaded: !!data.coverkey || !!data.coverurl
  };
  let songs: IAlbumSong[] = null;
  let singleUploadedSong: IUploadedFile = null;
  if (data?.songs?.length > 0) {
    songs = data.songs.map((song) => {
      const audioFile: IUploadedFile = {
        src: song.audiourl,
        key: song.audiokey,
        name: song.songname || song.songnamear || song.filename || song.songid,
        size: song.filesize,
        isUploaded: !!song.audiokey || !!song.audiourl
      };
      return {
        ...extractSingleFormValuesFromApiData(song, !!song.ispodcast),
        id: song.songid,
        uploadedSong: audioFile
      };
    });
  } else {
    singleUploadedSong = {
      src: data.audiourl,
      key: data.audiokey,
      name: data.songname || data.songnamear || data.filename || data.songid || 'uploaded Song',
      size: data.filesize,
      isUploaded: !!data.audiokey || !!data.audiourl
    };
  }

  const formValues = {
    ...data,
    languageid: languageid,
    // empty arabic fields when language is not arabic,
    // cause api set arabic fields to match english ones when language is not arabic and this cause issue in regex validation
    songnamear: languageid !== 2 ? data.songnamear : '',
    descriptionar: languageid !== 2 ? data.descriptionar : '',
    albumnamear: languageid !== 2 ? data.albumnamear : '',
    releasedate: new Date(Number(data.releasedate)),
    releasetime: data.releasetime
      ? new Date(Number(data.releasetime))
      : new Date(Number(data.releasedate)),
    ...(data.albumtype && { albumTypeValue: data.albumtype, albumtype: data.albumtype?.value }),
    ...(data.genre && { genreValue: data.genre, genre: data.genre?.value }),
    ...(data.category && { categoryValue: data.category, category: data.category?.id }),
    ...(data.featuringartists && {
      featuringartistidsValue: data.featuringartists,
      featuringartistids: data.featuringartists?.map((artist) => artist.value)
    }),
    ...(data.artists && {
      artistsValue: data.artists,
      artists: data.artists?.map((ar) => ar.value)
    }),
    ...(data.albumid &&
      data.releasetype === RELEASE_TYPES.Song && {
        albumtoaddto: data.albumid,
        albumtoaddtoValue: { value: data.albumid }
      })
  };
  return { formValues, coverArt, songs, singleUploadedSong };
};

export const validateFormFields = (
  formFields: IFormFields
): {
  isValid: boolean;
  missingFields: string[];
  updatedFormFields: IFormFields;
  lastErrorMessage: string;
} => {
  const missingFields = [];
  let isValid = true;
  let lastErrorMessage = null;
  Object.keys(formFields).forEach((field) => {
    if (
      formFields[field]?.required &&
      (!formFields[field].value || formFields[field].value.length === 0)
    ) {
      isValid = false;
      formFields[field].error = t('field is required');
      missingFields.push(field);
      console.log('🟠 ~ missing field', field);
      lastErrorMessage = t('Form validation fields required');
    } else if (
      formFields[field]?.value &&
      formFields[field].regexValidation &&
      !formFields[field].regexValidation?.test(formFields[field].value)
    ) {
      isValid = false;
      formFields[field].error = formFields[field].regexValidationMessage;
      lastErrorMessage = formFields[field].error;
      console.log('🟠 ~ regex fail field', field);
    } else {
      formFields[field].error = null;
    }
  });
  return { isValid, missingFields, updatedFormFields: formFields, lastErrorMessage };
};

export const getProgressInMegabytes = (progress, fileSize = 1) => {
  return Number(((progress / 100) * fileSize) / 1000000).toFixed(2);
};

export const convertAlbumSongObjectToSongInfo = (
  song: IAlbumSong,
  isPodcast = false
): ISongInfoPreviewItem[] => {
  return isPodcast
    ? [
        {
          title: t('Episode Name'),
          value: [
            { title: t('English'), value: song.songname },
            { title: t('Arabic'), value: song.songnamear }
          ]
        },
        {
          title: t('Language'),
          value: [
            {
              value:
                song.languageid == 1
                  ? t('Arabic')
                  : song.languageid == 2
                  ? t('English')
                  : t('Mixed')
            }
          ]
        },
        {
          title: t('Description'),
          value: [{ value: song.description }]
        },
        {
          title: t('Arabic Description'),
          value: [{ value: song.descriptionar }]
        },
        {
          title: t('Category'),
          value: [{ value: song.categoryValue?.name }]
        }
      ]
    : [
        {
          title: t('Song Name'),
          value: [
            { title: t('English'), value: song.songname },
            { title: t('Arabic'), value: song.songnamear }
          ]
        },
        {
          title: t('Song Language'),
          value: [
            {
              value:
                song.languageid == 1
                  ? t('Arabic')
                  : song.languageid == 2
                  ? t('English')
                  : t('Mixed')
            }
          ]
        },
        {
          title: t('Genre'),
          value: [{ value: song.genre }]
        },
        {
          title: t('ISRC'),
          value: [{ value: String(song.isrc || '') }]
        },
        {
          title: t('Lyrics'),
          value: [
            {
              value: song.lyrics
            }
          ]
        }
      ];
};

export const convertSingleSongObjectToSongInfo = (
  song: ICreateSingleFormValues
): ISongInfoPreviewItem[] => [
  {
    title: t('Song Name'),
    value: [
      { title: t('English'), value: song.songname },
      { title: t('Arabic'), value: song.songnamear }
    ]
  },
  {
    title: t('Artist'),
    value: [{ value: song.artist_name }]
  },
  {
    title: t('Genre'),
    value: [{ value: song.genre }]
  },
  {
    title: t('ISRC'),
    value: [{ value: String(song.isrc || '') }]
  },
  {
    title: t('Lyrics'),
    value: [
      {
        value: song.lyrics
      }
    ]
  }
];

export const getFileSizeInMegabytes = (fileSize) => {
  return Number(fileSize / 1000000).toFixed(2);
};

export const getSongsWithKeysIds = (songs: IAlbumSong[]) =>
  songs.map((s) => ({ ...s, id: s.id || uuidv4() }));
