import { IReleaseItem } from '@anghami/neogateway/dist/endpoints/getUserLibrary';
import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import styles from './artistsRoster.module.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface CharSplitArtists {
  [key: string]: IReleaseItem[];
}

const splitArtistsByFirstChar = (artists: IReleaseItem[]): CharSplitArtists => {
  const firstCharSplitArtists = {};
  artists.forEach((artist) => {
    const firstChar = artist.artist_name.charAt(0);
    firstCharSplitArtists[firstChar] = firstCharSplitArtists[firstChar]
      ? [...firstCharSplitArtists[firstChar], artist]
      : [artist];
  });
  return firstCharSplitArtists;
};

const AristRosterItem = ({ artist }: { artist: IReleaseItem }) => {
  const [isShowingPopOver, setIsShowingPopOver] = React.useState(false);
  const [coordinates, setCoordinates] = React.useState({ x: 0, y: 0 });
  const { t } = useTranslation();
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!artist.artist_id) return;
    setIsShowingPopOver(true);
    setCoordinates({ x: e.clientX, y: e.clientY });
  };
  const queryParams = new URLSearchParams(Object.entries(artist)).toString();

  return (
    <div
      className={styles.artist_item}
      key={artist.artist_id}
      onClick={handleClick}
      onMouseLeave={() => setIsShowingPopOver(false)}
    >
      {artist.artist_name}
      {isShowingPopOver && (
        <div className={styles.popoverMenu} style={{ top: coordinates.y, left: coordinates.x }}>
          <Link to={'/library/?' + queryParams}>{t('Library')}</Link>
          <Link to={'/analytics/artist/' + artist.artist_id}>{t('Analytics')}</Link>
        </div>
      )}
    </div>
  );
};
interface IArtistsRosterProps {
  artists: IReleaseItem[];
}
export default function ArtistsRoster({ artists }: IArtistsRosterProps) {
  const [splitArtists, setSplitArtists] = React.useState({});
  React.useEffect(() => {
    if (!artists || artists.length === 0) return;
    setSplitArtists(splitArtistsByFirstChar(artists));
  }, [artists]);
  return (
    <div className={combineClassNames('f-column', 'f-wrap', styles.roster_container)}>
      {Object.keys(splitArtists)
        .sort()
        .map((key) => (
          <div key={key} className={styles.roster_item}>
            <h1>{key}</h1>
            {splitArtists[key].map((artist: IReleaseItem) => (
              <AristRosterItem key={artist.artist_id} artist={artist} />
            ))}
          </div>
        ))}
    </div>
  );
}
