import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import styles from './sidemenuUserContainer.module.scss';
import { DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { GENERIC_PROFILE_IMAGE } from 'constants/releases.constants';
import { getCoverArtImage } from 'helpers/releases-helper';
import { AccountTypeEnums } from 'interfaces/auth.interface';
import { useTranslation } from 'react-i18next';
import { userContext } from 'components/contexts/auth/auth.context';
import { useNavigate } from 'react-router-dom';
import useCloseOnScrollAndClickOutside from 'hooks/useCloseOnScrollAndClickOutside';
import { ReactComponent as MenuIcon } from 'icons/menu.svg';
import { ReactComponent as CloseIcon } from 'icons/close-landing.svg';
import { environment } from 'env/environment';
import { SidemenuContext } from './sidemenu.context';
import { useQuery } from 'react-query';
import { gateway } from 'helpers/gateway-helper';
import downloadContract from '@anghami/neogateway/dist/endpoints/downloadContract';

const SidemenuLogo = ({
  isSideMenuMinimized,
  isMobile
}: {
  isSideMenuMinimized: boolean;
  isMobile: boolean;
}) => {
  return !isSideMenuMinimized || isMobile ? (
    <img
      src={`${environment.creators_assets_cdn}/images/anghami_creators_black.png`}
      className={combineClassNames(
        styles.artist_logo,
        isSideMenuMinimized && styles.isMinimized,
        isMobile && styles.isMobile
      )}
      alt="Artist-Dashboard logo"
    />
  ) : (
    <img
      src={`${environment.creators_assets_cdn}/images/pink_logo_alone.svg`}
      className={combineClassNames(
        styles.artist_logo_small,
        isSideMenuMinimized && styles.isMinimized
      )}
      alt="Artist-Dashboard logo"
    />
  );
};

export default function SidemenuLogoAndUserContainer() {
  const { isMinimized, isMobile, isMobileExpanded, setIsMobileExpanded } =
    React.useContext(SidemenuContext);
  const { t, i18n } = useTranslation();
  const { user, userType, permissions, logout } = React.useContext(userContext);
  const [showingProfileDropDown, setShowingProfileDropDown, ref] = useCloseOnScrollAndClickOutside({
    defaultValue: false
  });
  const navigate = useNavigate();
  const { data: agreementLink, isLoading: agreementLoading } = useQuery(
    [user?.anid, 'signed-agreement'],
    async () => {
      const { data } = await gateway.callEndpoint(downloadContract, {
        geturl: 1,
        output: 'jsonhp'
      });
      if (!data || data.error) {
        throw new Error(data?.error?.message || t('unable to get contract'));
      }
      return data.url;
    }
  );

  return (
    <div
      className={combineClassNames(
        'w-100',
        styles.sidemenu_user_container,
        isMobile ? 'd-flex f-align-center f-justify-between' : 'f-column f-align-start gap-1'
      )}
    >
      <SidemenuLogo isSideMenuMinimized={isMinimized} isMobile={isMobile} />
      <div className={combineClassNames('d-flex f-align-center gap-1', !isMobile && 'w-100')}>
        <div
          className={combineClassNames(
            'd-flex',
            'f-align-center',
            'f-justify-between',
            styles.user_wrapper,
            isMobile && styles.isMobile,
            isMinimized && !isMobile && styles.isMinimized
          )}
          role="button"
          tabIndex={0}
          onKeyDown={null}
          onClick={(e) => {
            e.stopPropagation();
            setShowingProfileDropDown(!showingProfileDropDown);
          }}
        >
          <div className={combineClassNames('d-flex f-align-center f-justify-center')}>
            {/* labels don't have a profile pic */}
            {(userType === AccountTypeEnums.ARTIST || userType === AccountTypeEnums.PODCASTER) && (
              <img
                src={
                  user.artist_cover_art_id
                    ? getCoverArtImage(user.artist_cover_art_id)
                    : GENERIC_PROFILE_IMAGE
                }
                alt="User Profile"
                className={styles.user_img}
                onError={(e) => ((e.target as any).src = GENERIC_PROFILE_IMAGE)}
              />
            )}
            <div className={combineClassNames(styles.username)}>
              &nbsp;{isMobile ? user?.user.split(' ')[0] : user?.user}
            </div>
          </div>
          <div className={isMinimized ? 'hidden' : null}>
            {showingProfileDropDown ? (
              <DownOutlined />
            ) : i18n?.language === 'ar' ? (
              <LeftOutlined />
            ) : (
              <RightOutlined />
            )}
          </div>
          <div
            className={combineClassNames(
              styles.dropdown_content,
              showingProfileDropDown ? styles.toggled_dropdown : '',
              isMinimized && !isMobile && styles.fixedDropDown,
              isMobile && styles.fixed
            )}
            ref={ref}
          >
            {permissions?.manage_artist_profile && (
              <div
                className={styles.dropdown_item}
                onClick={() => {
                  navigate('/profile');
                }}
                role="button"
                tabIndex={0}
                onKeyDown={null}
              >
                {t('Edit Profile')}
              </div>
            )}
            {user.has_contract && (
              <a
                className={styles.linkContainer}
                href={agreementLink}
                target="_blank"
                rel="noreferrer"
                download
              >
                <div
                  className={agreementLoading ? styles.disabled : styles.dropdown_item}
                  role="button"
                  tabIndex={0}
                  onKeyDown={null}
                >
                  {t('download_contract')}
                </div>
              </a>
            )}
            <div
              className={styles.dropdown_item}
              onClick={logout}
              role="button"
              tabIndex={0}
              onKeyDown={null}
            >
              {t('Logout')}
            </div>
          </div>
        </div>
        {isMobile ? (
          <div
            className={combineClassNames('position-relative', styles.toggleIconContainer)}
            onClick={() => setIsMobileExpanded(!isMobileExpanded)}
          >
            <CloseIcon
              className={combineClassNames(styles.toggleIcon, !isMobileExpanded && styles.hidden)}
            />
            <MenuIcon
              className={combineClassNames(styles.toggleIcon, isMobileExpanded && styles.hidden)}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
