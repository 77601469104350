import React from 'react';
import styles from './unverifiedEmail.module.scss';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { gateway } from 'helpers/gateway-helper';
import postResendVerificationEmail from '@anghami/neogateway/dist/endpoints/postResendVerificationEmail';
import EmailStatusImage from '../EmailStatusImage';
import { getErrorMessage } from 'helpers/api-helper';
import useLocalStorage from 'hooks/useLocalStorage';
import { combineClassNames } from 'helpers/styling-helper';

const RESEND_COUNTDOWN = 30;

export default function UnverifiedEmail() {
  const { t } = useTranslation();
  const intervalRef = React.useRef<number>();
  const [count, setCount] = React.useState(RESEND_COUNTDOWN);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const location = useLocation();
  const email = location.state?.email || '';
  const isEmailSentBeforeRedirect = location.state?.isEmailSentBeforeRedirect || false;
  const [timeSent, setTimeSent] = useLocalStorage('resend_email_time_sent', 1);
  const [canResend, setCanResend] = React.useState<boolean>(true);

  const { mutate: resendEmail, isLoading } = useMutation({
    mutationFn: () => gateway.callEndpoint(postResendVerificationEmail, { email }),
    onSuccess: ({ data }) => {
      if (data.error || !data.success) {
        setErrorMessage(getErrorMessage(data.error, t('something-went-wrong-try-again')));
        startTimer();
        return;
      }
      setTimeSent(timeSent + 1);
      startTimer(RESEND_COUNTDOWN * (timeSent + 1));
    },
    onError: () => {
      setErrorMessage(t('something-went-wrong-try-again'));
      showResendButton();
      window.clearInterval(intervalRef.current);
    }
  });

  const startTimer = (count: number = RESEND_COUNTDOWN * timeSent) => {
    setCount(count);
    setCanResend(false);
  };

  const showResendButton = () => {
    setCount(RESEND_COUNTDOWN * timeSent);
    setCanResend(true);
    window.clearInterval(intervalRef.current);
  };

  React.useEffect(() => {
    if (!isEmailSentBeforeRedirect) return;
    startTimer();
  }, [isEmailSentBeforeRedirect]);

  React.useEffect(() => {
    if (canResend) return;
    if (count <= 0) {
      showResendButton();
      window.clearInterval(intervalRef.current);
      return;
    }
    intervalRef.current = window.setTimeout(() => {
      setCount((count) => count - 1);
    }, 1000);
    return () => {
      window.clearTimeout(intervalRef.current);
    };
  }, [count, canResend]);

  if (!email) return <Navigate to="/login" />;

  return (
    <div className={styles.container}>
      <h1>{t('Verify your email')}</h1>
      <EmailStatusImage status={errorMessage ? 'error' : 'pending'} />
      {errorMessage ? (
        <p className={styles.errorMessage}>{errorMessage}</p>
      ) : (
        <>
          <p>
            {t('we-sent-you-an-email-verification').split('%@')[0]}
            <b>{email}</b>
            {t('we-sent-you-an-email-verification').split('%@')[1]}
          </p>
          <p>
            {t('didnt-receive-your-email')}{' '}
            {canResend ? (
              <span
                className={combineClassNames(styles.resendButton, isLoading && styles.isDisabled)}
                onClick={() => (isLoading ? '' : resendEmail())}
              >
                {t('Resend')}
              </span>
            ) : (
              t('Resend in') + ' ' + count + ' ' + t('seconds')
            )}
          </p>
        </>
      )}
    </div>
  );
}
