import { ReactComponent as SingleIcon } from 'icons/single-filled.svg';
import { ReactComponent as AlbumIcon } from 'icons/album-filled.svg';
import { ReactComponent as VideoIcon } from 'icons/video-filled.svg';
import { ReactComponent as PodcastIcon } from 'icons/podcast-filled.svg';
import { ReactComponent as ArtistIcon } from 'icons/artist-filled.svg';
import { ITab } from 'components/Library/Columns/tabledatatype';
export const LIBRARY_TAGS: { [key: string]: ITab } = {
  single: {
    label: 'Single',
    type: 'single',
    icon: SingleIcon
  },
  song: {
    label: 'Song',
    type: 'single',
    icon: SingleIcon
  },
  episode: {
    label: 'Episode',
    type: 'episode',
    icon: PodcastIcon
  },
  single_episode: {
    label: 'Single Episode',
    type: 'episode',
    icon: PodcastIcon
  },
  video: {
    label: 'Video',
    type: 'video',
    icon: VideoIcon
  },
  artist: {
    label: 'Artist',
    type: 'artist',
    icon: ArtistIcon
  },
  album: {
    label: 'Album',
    type: 'album',
    icon: AlbumIcon
  },
  show: {
    label: 'Show',
    type: 'show',
    icon: PodcastIcon
  }
};
