import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    load: 'languageOnly',
    defaultNS: 'common',
    lowerCaseLng: true,
    supportedLngs: ['en', 'ar'],
    partialBundledLanguages: true,
    ns: ['common'],
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
      lookupQuerystring: 'lng',
      lookupCookie: 'c-lang',
      lookupLocalStorage: 'cLang',
      lookupSessionStorage: 'cLang',

      // cache user language
      caches: ['localStorage']
    },
    // parseMissingKeyHandler: (key: string, defaultvalue: string) => {
    //   console.log('--- Missing key', key, defaultvalue);
    //   return `${key} (MISSING TRANSLATION)`;
    // },
    // saveMissing: true,
    // updateMissing: true,
    // missingKeyHandler: (
    //   lngs: readonly string[],
    //   ns: string,
    //   key: string,
    //   fallbackValue: string,
    //   updateMissing: boolean,
    //   options: any
    // ) => {
    //   console.log({ lngs, ns, key, fallbackValue, updateMissing, options });
    // },
    react: {
      useSuspense: false
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;
