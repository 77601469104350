import Button from 'components/Reusable/Button';
import React from 'react';
import styles from './uploadCoverArtInplace.module.scss';
import colors from 'styles/partials/_colors_exports.module.scss';
import WithLabelWrapper from 'components/Reusable/WithLabelWrapper';
import { ReactComponent as RetryIcon } from 'icons/retry.svg';
import { combineClassNames } from 'helpers/styling-helper';
import { IUploadCoverArtProps, IUploadedFile } from 'interfaces/formsComponents.interface';
import ProgressBar from 'components/Reusable/ProgressBar';
import { useTranslation } from 'react-i18next';
import CoverArtSelectAndCrop from '../CoverArtSelectAndCrop';

export default function UploadCoverArtInplace(props: IUploadCoverArtProps) {
  const { customLabelClass, uploader, value } = props;
  const [isUploadingCoverArt, setIsUploadingCoverArt] = React.useState(false);
  const [isCroppingImage, setIsCroppingImage] = React.useState(false);
  const [imageMetas, setImageMetas] = React.useState<IUploadedFile>(
    value || { type: '', name: '' }
  );

  const { t } = useTranslation('common');
  const {
    uploadFile,
    clearUploadedFile,
    uploadedFile: uploadedCoverArt,
    loadingProgress,
    isLoading,
    isUploadSuccess
  } = uploader;

  const uploadCoverArt = () => {
    setIsUploadingCoverArt(true);
    uploadFile(imageMetas);
  };
  return (
    <WithLabelWrapper
      {...props}
      customLabelClass={combineClassNames(styles.customLabel, customLabelClass)}
    >
      <div className={styles.upload_coverart_container}>
        {uploadedCoverArt?.src || isUploadingCoverArt ? (
          <>
            <img src={uploadedCoverArt?.src} alt="uploaded-coverart" className={styles.coverArt} />
            <div
              className={combineClassNames(
                'f-column f-justify-center f-align-center',
                styles.progress_button_container
              )}
            >
              {props?.config?.hideStatus ? null : isLoading ? (
                <ProgressBar progress={loadingProgress} />
              ) : (
                <div className="d-flex f-align-center" style={{ marginInlineStart: '0.5rem' }}>
                  {isUploadSuccess || uploadedCoverArt.isUploaded ? (
                    <span style={{ color: colors['success-color'] }}>{t('Upload Successful')}</span>
                  ) : (
                    <>
                      <span style={{ color: colors['failure-color'] }}>{t('Unsuccessful')}</span>
                      <div className="d-flex f-align-center" onClick={uploadCoverArt}>
                        <span style={{ color: colors['dark-10'], margin: '0em 0.5em' }}>
                          {t('Retry')}
                        </span>
                        <RetryIcon fill={colors['dark-10']} />
                      </div>
                    </>
                  )}
                </div>
              )}
              <Button
                label={t('Change cover')}
                withBackground={colors['light-6']}
                customClasses={[styles.retry_button]}
                onSubmit={() => {
                  clearUploadedFile();
                  setIsUploadingCoverArt(false);
                  setIsCroppingImage(false);
                }}
                icon={<RetryIcon width={20} />}
                disabled={isLoading}
              />
            </div>
          </>
        ) : (
          <div className={styles.crop_container}>
            <div style={{ height: 'calc(100%-3em)' }}>
              <CoverArtSelectAndCrop
                coverArt={imageMetas}
                onUploadCoverArt={setImageMetas}
                isCropState={isCroppingImage}
                setIsCropState={setIsCroppingImage}
              ></CoverArtSelectAndCrop>
            </div>
            <div className="w-100 d-flex f-justify-center" style={{ height: '3em' }}>
              {isCroppingImage && (
                <Button
                  label={t('Upload Cover')}
                  withBackground={colors['light-6']}
                  customClasses={[styles.upload_button]}
                  onSubmit={uploadCoverArt}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </WithLabelWrapper>
  );
}
