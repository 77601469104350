import React from 'react';
import styles from './sidemenu.module.scss';
import { combineClassNames } from 'helpers/styling-helper';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import SidemenuLogoAndUserContainer from './sidemenuUserContainer';
import SidemenuCreateButton from './sidemenuCreateButton';
import SidemenuNavigation from './sidemenuNavigation';
import SidemenuMinimizeButton from './sidemenuMinimizeButton';
import SidemenuBetaOptout from './sidemenuBetaOptout';
import LanguageSelector from 'components/LanguageSelector';
import SidemenuNeedHelpButton from './sidemenuNeedHelpButton';
import './override.styles.scss';
import { SidemenuContext } from './sidemenu.context';
import { updateSidemenuCSSVariables } from './sidemenu.helper';

export default function Sidemenu() {
  const [isSideMenuMinimized, setIsSideMenuMinimized] = React.useState(false);
  const [isMobileExpanded, setIsMobileExpanded] = React.useState(false);
  const { isMobile, width } = useWindowDimensions();

  React.useEffect(() => {
    return () => {
      updateSidemenuCSSVariables(false);
    };
  }, []);

  React.useEffect(() => {
    const isSmallScreen = width < 900;
    setIsSideMenuMinimized(isSmallScreen);
  }, [width]);

  React.useEffect(() => {
    updateSidemenuCSSVariables(true, isMobile, isSideMenuMinimized);
  }, [isMobile, isSideMenuMinimized]);

  return (
    <SidemenuContext.Provider
      value={{
        isMinimized: isSideMenuMinimized,
        setIsMinimized: setIsSideMenuMinimized,
        isMobile,
        isMobileExpanded,
        setIsMobileExpanded
      }}
    >
      <div
        className={combineClassNames(
          styles.sidemenu_container,
          isSideMenuMinimized && styles.toggled_sidemenu_container,
          isMobile && styles.isMobile,
          isMobileExpanded && styles.isMobileExpanded
        )}
        id="sidemenu"
      >
        {!isMobile && <SidemenuMinimizeButton />}

        <div
          className={combineClassNames(
            styles.sidemenu,
            'd-flex',
            'f-align-center',
            isMobile && styles.isMobile,
            isMobileExpanded && styles.isMobileExpanded
          )}
        >
          <div className={combineClassNames('f-column', 'f-align-start', styles.sidemenu_top)}>
            <SidemenuLogoAndUserContainer />
            {(!isMobile || isMobileExpanded) && (
              <>
                <SidemenuCreateButton />
                <SidemenuNavigation />
              </>
            )}
          </div>
          {(!isMobile || isMobileExpanded) && (
            <div className="f-column w-100 gap-05">
              <div className="d-flex w-100 f-justify-center">
                <LanguageSelector hideLabel={isSideMenuMinimized} />
              </div>
              {(!isSideMenuMinimized || (isMobile && isMobileExpanded)) && <SidemenuBetaOptout />}
              <div className={styles.sidemenu_bottom}>
                <SidemenuNeedHelpButton />
              </div>
            </div>
          )}
        </div>
      </div>
    </SidemenuContext.Provider>
  );
}
