import React from 'react';
import { ARTIST_IMAGE_PLACEHOLDER } from 'constants/releases.constants';
import { getCoverArtImage } from 'helpers/releases-helper';
import { useTranslation } from 'react-i18next';
import { IArtistInfo } from '../Context/library.context';
import styles from './artistLibraryHeader.module.scss';
import { combineClassNames } from 'helpers/styling-helper';
import { ReactComponent as ArrowIcon } from 'icons/arrow-left.svg';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Switch from 'antd/lib/switch';
import './override.scss';

export default function ArtistLibraryHeader({
  artist_name,
  cover_art_id,
  isDraft
}: IArtistInfo & { isDraft: boolean }) {
  const { t } = useTranslation('common');
  const { search } = useLocation();
  const navigate = useNavigate();
  const switchLiveAndDrafts = () => {
    navigate(`${isDraft ? '/library' : '/library/drafts'}${search}`);
  };
  return (
    <div className={combineClassNames('f-column f-justify-start', styles.mainContainer)}>
      <NavLink to={'/library/roster'}>
        <div className={combineClassNames('d-flex f-align-center', styles.backToRosterButton)}>
          <ArrowIcon />
          <span>{t('Go back to Roster')}</span>
        </div>
      </NavLink>
      <div className={combineClassNames('d-flex f-align-center', styles.artistHeaderItem)}>
        <img
          src={getCoverArtImage(cover_art_id)}
          alt="artist"
          onError={(e) => ((e.target as any).src = ARTIST_IMAGE_PLACEHOLDER)}
        />
        <h1>{t('artists library').replace('%@', artist_name)}</h1>
        <Switch
          checkedChildren={t('Live')}
          unCheckedChildren={t('Drafts')}
          checked={!isDraft}
          onChange={switchLiveAndDrafts}
        />
      </div>
    </div>
  );
}
