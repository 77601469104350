import React from 'react';
import { useQuery } from 'react-query';
import { gateway } from 'helpers/gateway-helper';
import { combineClassNames } from 'helpers/styling-helper';
import styles from './pitchPlaylists.module.scss';
import { useTranslation } from 'react-i18next';
import { loaderContext } from 'components/contexts/loader/loader.context';
import getSongsForPitching, {
  ISongForPitching
} from '@anghami/neogateway/dist/endpoints/getSongsForPitching';
import NoData from 'components/Reusable/NoData';
import { dialogsContext } from 'components/contexts/dialogs/toast/dialogs.context';
import PitchFormDialog from 'components/PitchPlaylists/PitchFormDialog';
import SongToPitch from 'components/PitchPlaylists/SongToPitch';
import { toastContext } from 'components/contexts/toast/toast.context';
import { logAmplitudeEvent } from 'helpers/analytics-helper';
import { SETUP_PITCH_PLAYLIST } from 'constants/amplitudeEvents';
import { GET_SONGS_FOR_PITCHING } from 'constants/reactQueryKeys';

export default function PitchPlaylists() {
  const { t } = useTranslation('common');
  const { setIsLoading } = React.useContext(loaderContext);
  const { showDialog } = React.useContext(dialogsContext);
  const { openToast } = React.useContext(toastContext);
  const { data: res, isLoading } = useQuery(
    [GET_SONGS_FOR_PITCHING],
    async () => {
      const { data } = await gateway.callEndpoint(getSongsForPitching, {
        section: 'all',
        output: 'jsonhp'
      });
      return data;
    },
    {
      retry: 3,
      keepPreviousData: true
    }
  );

  React.useEffect(() => {
    setIsLoading(isLoading);
    return () => {
      setIsLoading(false);
    };
  }, [isLoading]);

  const handleItemClick = (item: Partial<ISongForPitching>) => {
    if (res?.remaining_pitches_nbr === 0) {
      openToast(t('You have reached the maximum number of pitch requests'), 'warning');
      return;
    }
    logAmplitudeEvent(SETUP_PITCH_PLAYLIST, { objectid: item.id });
    showDialog({
      dialog: <PitchFormDialog selectedSong={item} />,
      backdrop: true,
      customClassName: styles.formDialog
    });
  };

  return (
    <div className="p-2">
      <div className="header-1">
        <h1>{t('Pitch Playlist')}</h1>
        <p>{t('pitch-playlist-description-line-1')}</p>
        <p>{t('pitch-playlist-description-line-2')}</p>
      </div>
      {res?.data?.length > 0 ? (
        <>
          <div className="header-2">
            <div>
              <h2>{t('Pick a song to pitch')}</h2>
              <span>
                {t('pitch requests remaining').replace(
                  '%@',
                  `${res.remaining_pitches_nbr}/${res.maximum_pitches_nbr}`
                )}
              </span>
            </div>
            <p>
              {t('You can only select one song among the most recent releases in your Library')}
            </p>
          </div>
          <div className={combineClassNames('d-flex f-wrap', styles.songsContainer)}>
            {res.data.map((p, index) => (
              <SongToPitch key={p.id + index} song={p} handleClick={handleItemClick} />
            ))}
          </div>
        </>
      ) : (
        !isLoading && (
          <NoData
            customMessage={res?.error?.message || t('there-are-no-eligible-songs-for-pitching')}
          />
        )
      )}
    </div>
  );
}
