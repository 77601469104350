import React from 'react';
import { IFeaturedItem } from '@anghami/neogateway/dist/endpoints/getFeaturedItems';
import SearchFeaturedInput from '../SearchFeaturedInput';
import styles from './featuredCategory.module.scss';
import { ReactComponent as DeleteIcon } from 'icons/delete.svg';
import { getCoverArtImage } from 'helpers/releases-helper';
import {
  ALBUM_IMAGE_PLACEHOLDER,
  PLAYLIST_IMAGE_PLACEHOLDER,
  SONG_IMAGE_PLACEHOLDER
} from 'constants/releases.constants';
import { FeaturedTypes } from '../Interfaces/featured.interface';
import { combineClassNames } from 'helpers/styling-helper';

interface IFeaturedCategoryProps {
  type: FeaturedTypes;
  title: string;
  filledItems: IFeaturedItem[];
  onItemsUpdate: (type: FeaturedTypes, items: IFeaturedItem[]) => void;
  isAddDisabled: boolean;
}

const PlaceHoldersMap = {
  [FeaturedTypes.albums]: ALBUM_IMAGE_PLACEHOLDER,
  [FeaturedTypes.playlists]: PLAYLIST_IMAGE_PLACEHOLDER,
  [FeaturedTypes.songs]: SONG_IMAGE_PLACEHOLDER
};

export default function FeaturedCategory({
  title,
  type,
  filledItems,
  onItemsUpdate,
  isAddDisabled
}: IFeaturedCategoryProps) {
  const handleOnChange = (item: IFeaturedItem) => {
    onItemsUpdate(type, [...filledItems, item]);
  };

  const handleDelete = (itemId: string) => {
    onItemsUpdate(type, filledItems?.filter((i) => Boolean(i) && i.id !== itemId) || []);
  };
  return (
    <div className="p-1">
      <h3 className="mb-1">{title}</h3>
      <div className={styles.searchInput}>
        <SearchFeaturedInput
          value={null}
          onChange={handleOnChange}
          type={type}
          selectedItemsIds={filledItems.filter(Boolean).map((i) => i.id)}
          disabled={isAddDisabled}
        />
      </div>
      <div className="f-column gap-05">
        {filledItems.filter(Boolean).map((item) => (
          <div key={item.id} className="d-flex f-align-center gap-05">
            <div className={combineClassNames('d-flex f-align-center', styles.featuredItem)}>
              <img
                alt={'featured-item-cover'}
                src={item.coverart ? getCoverArtImage(item.coverart) : PlaceHoldersMap[type]}
              />
              <span>{item.name}</span>
            </div>
            <DeleteIcon
              width={22}
              height={22}
              className={styles.deleteIcon}
              onClick={() => handleDelete(item.id)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
