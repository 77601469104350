// import { getQueryStringValueFromURL } from 'helpers/url-helper';
import { getQueryStringValueFromURL } from 'helpers/url-helper';
import { AccountTypeEnums } from 'interfaces/auth.interface';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { userContext } from './auth.context';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { updateSidemenuCSSVariables } from 'components/Landing/sidemenu/sidemenu.helper';
interface IProps {
  children: any;
  isLanding?: boolean;
  disable?: boolean;
}

export default function AuthGuard({ children, isLanding, disable }: IProps) {
  const { loggedIn, userType } = React.useContext(userContext);
  const { isMobile } = useWindowDimensions();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (disable) {
      return;
    }
    const queryRedirect = decodeURIComponent(
      getQueryStringValueFromURL('redirect')?.replace('/?redirect=', '')
    );
    if (isLanding && loggedIn) {
      updateSidemenuCSSVariables(true, isMobile, false);
      const mainPage = userType === AccountTypeEnums.LABEL ? '/library/roster' : '/library';
      navigate(queryRedirect || mainPage);
      return;
    }
    if (!isLanding && !loggedIn) {
      updateSidemenuCSSVariables(false);
      navigate(
        `/login?redirect=${encodeURIComponent(
          window.location.href.replace(window.location.origin, '')
        )}`
      );
      return;
    }
  }, [loggedIn]);
  //TODO: add loading splash screen until logged in
  if (loggedIn || isLanding) {
    return <>{children}</>;
  }
  return <></>;
}
