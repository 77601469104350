/* eslint-disable @typescript-eslint/no-unused-vars */
import Modal from 'antd/lib/modal';
import RangePicker from 'components/Reusable/RangePicker';
import { differenceInCalendarDays, format, subDays } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './dateRangePickerDialog.module.scss';

interface IDateRangePickerDialogProps {
  dateFormat?: string;
  isOpen?: boolean;
  onChange: (from?: string, to?: string) => void;
  onClose: () => void;
  defaultFrom?: string;
  defaultTo?: string;
  picker?: 'week' | 'month' | 'quarter' | 'year';
  disableDates?: (date: Date) => boolean;
  daysLimit?: number;
}

export default function DateRangePickerDialog({
  defaultFrom,
  defaultTo,
  onChange,
  isOpen,
  onClose,
  picker,
  disableDates,
  dateFormat = 'yyyy-MM-dd',
  daysLimit = 365
}: IDateRangePickerDialogProps) {
  const { t } = useTranslation('common');
  const [momentRange, setMomentRange] = React.useState<Date[]>([]);
  const [rangeValue, setRangeValue] = React.useState<[Date, Date]>();
  const [timePickerError, setTimePickerError] = React.useState('');
  React.useEffect(() => {
    setTimePickerError(null);
    setRangeValue([new Date(defaultFrom), new Date(defaultTo)]);
  }, [isOpen]);

  const handleModalSubmit = () => {
    onChange(
      format(new Date(momentRange[0] || ''), dateFormat),
      format(new Date(momentRange[1] || ''), dateFormat)
    );
    onClose();
  };

  const handleClose = () => {
    setTimePickerError(null);
    setMomentRange(null);
    setRangeValue(null);
    onClose();
  };

  const defaultFilteredDates = (current) => subDays(new Date(), 1) < current;
  const filteredDates = disableDates || defaultFilteredDates;

  const handleCustomRange = (inputDates: [Date, Date]) => {
    setRangeValue(inputDates);
    if (inputDates?.length === 2) {
      const dates = inputDates.map((d) => new Date(d));
      if (differenceInCalendarDays(dates[1], dates[0]) <= daysLimit) {
        setTimePickerError(null);
        setMomentRange(dates);
        return;
      }
      setTimePickerError(t('The date range cannot span over a period of one year'));
      return;
    }
    setTimePickerError(t('Please enter a date range'));
  };

  return (
    <Modal
      title={t('Custom Date Range Selector')}
      open={isOpen}
      onOk={handleModalSubmit}
      onCancel={handleClose}
      maskClosable={false}
      okText={t('Submit')}
      cancelText={t('Cancel')}
      okButtonProps={{ shape: 'round', disabled: !!timePickerError || !momentRange?.length }}
      cancelButtonProps={{ shape: 'round' }}
    >
      <RangePicker
        onChange={handleCustomRange}
        disabledDate={filteredDates}
        style={{
          borderRadius: '6px'
        }}
        value={rangeValue}
        defaultValue={[new Date(defaultFrom), new Date(defaultTo)]}
        defaultPickerValue={[new Date(defaultFrom), new Date(defaultTo)]}
        picker={picker || undefined}
        status={timePickerError ? 'error' : ''}
      />
      <h3 className={styles.errorMessage}>{timePickerError}</h3>
    </Modal>
  );
}
