import React from 'react';

export default function RssCategoriesCell({ categories }: { categories: string }) {
  return (
    <div>
      {categories?.split(',').map((item) => (
        <div key={'categories-cell-items-' + item}>{item}</div>
      ))}
    </div>
  );
}
