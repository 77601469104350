import React from 'react';
import SongObject from 'components/CreateRelease/SongObject';
import { userContext } from 'components/contexts/auth/auth.context';
import SongPreview from 'components/CreateRelease/SongPreview';
import EditButton from 'components/CreateRelease/EditButton';
import styles from './create.module.scss';
import {
  IAlbumSong,
  ICreateAlbumFormValues,
  IFormFields,
  ISection
} from 'interfaces/formsComponents.interface';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Form from 'components/Reusable/Form';
import { gateway } from 'helpers/gateway-helper';
import ReleaseHeader from 'components/Landing/headers/release';
import StepsBar from 'components/Reusable/StepsBar';
import colors from 'styles/partials/_colors_exports.module.scss';
import { combineClassNames } from 'helpers/styling-helper';
import getReleaseDetails from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import { convertFormValuesToFields } from 'helpers/form-helper';
import {
  convertAlbumSongObjectToSongInfo,
  extractAlbumFormValuesFromApiData,
  validateFormFields,
  getFormSectionsAndFields,
  getUpdatedFormFields,
  getSongsWithKeysIds
} from 'helpers/create-release-helper';
import SongInfoPreview from 'components/CreateRelease/SongInfoPreview';
import MultiSongUploader from 'components/CreateRelease/MultiSongUploader';
import { useMutation, useQueryClient } from 'react-query';
import { postAlbumUploadEndpoint } from 'helpers/http-helper';
import { compareArraysScalar } from 'helpers/utils-helper';
import { toastContext } from 'components/contexts/toast/toast.context';
import { loaderContext } from 'components/contexts/loader/loader.context';
import { RELEASE_TYPES } from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import { getEncodedQueryParam, setPageTitle } from 'helpers/url-helper';
import { CONTENT_TYPES, LIBRARY_TYPE } from '@anghami/neogateway/dist/endpoints/getUserLibrary';
import { logAmplitudeEvent, logGeneralErrorEvent } from 'helpers/analytics-helper';
import { SAVE_AND_PROCEED, SAVE_AS_DRAFT, SUBMIT } from 'constants/amplitudeEvents';
import ReleaseDateTimePreview from 'components/CreateRelease/ReleaseDateTimePreview';
import { AccountTypeEnums } from 'interfaces/auth.interface';
import Modal from 'antd/es/modal';

export default function CreateAlbumReleasePage() {
  const { user, userType } = React.useContext(userContext);
  const { isLoading, setIsLoading } = React.useContext(loaderContext);
  const { openToast, closeToast } = React.useContext(toastContext);
  const [uploadedSongs, setUploadedSongs] = React.useState<IAlbumSong[]>([]);
  const { t } = useTranslation('common');
  const [formValues, setFormValues] = React.useState<ICreateAlbumFormValues>({});
  const [formFields, setFormFields] = React.useState<IFormFields>(null);
  const [formSections, setFormSections] = React.useState<ISection[]>(null);
  const [step, setStep] = React.useState<number>(0);
  const [lastUploadedSongs, setLastUploadedSongs] = React.useState<string[]>([]);
  const [areAllSongsUploaded, setAreAllSongsUploaded] = React.useState(false);
  const [doResetOnProceed, setDoResetOnProceed] = React.useState<boolean>(false);
  const [isStepConfirmationModalShowing, setIsStepConfirmationModalShowing] =
    React.useState<boolean>(false);
  const [goingToStepIndex, setGoingToStepIndex] = React.useState<number>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const albumIdQP = searchParams.get('albumid');
  const location = useLocation();
  const keyProp = (location?.state as { keyProp: string })?.keyProp;
  const reactQueryClient = useQueryClient();
  const containerRef: React.LegacyRef<HTMLDivElement> = React.useRef();

  const mutation = useMutation(
    (formValues: any) => {
      if (!formValues) return;
      setAreAllSongsUploaded(false);
      return postAlbumUploadEndpoint(formValues);
    },
    {
      retry: 3,
      mutationKey: 'postAlbumUploadEndpoint',
      onError: (err: any) => {
        setIsLoading(false);
        console.log('🔴 ~ PostAlbumSong Error --', err);
        openToast(err?.message || t('Something went wrong'), 'error');
        logAmplitudeEvent(SAVE_AND_PROCEED, {
          step: step,
          status: 'fail',
          error_message: err?.message || err
        });
      },
      onSuccess: (data, variables) => {
        setIsLoading(false);
        if (!data) return;
        if (data.error?.message) {
          openToast(data.error.message, 'error');
          logAmplitudeEvent(SAVE_AND_PROCEED, {
            step: step,
            status: 'fail',
            error_message: data.error.message,
            error_code: data.error.code
          });
          return;
        }
        if (data.message) {
          openToast(data.message, data.albumid ? 'success' : 'warning');
        }
        if (step === 2) {
          if (variables.saveasdraft) {
            handleSaveAsDraftRedirection();
            return;
          }
          reactQueryClient.refetchQueries({
            queryKey: [user?.anid, CONTENT_TYPES.music, 'library', LIBRARY_TYPE.live],
            exact: false
          });
          logAmplitudeEvent(SUBMIT, {
            content_id: formValues.albumid,
            artist_id: user.d_artist.id,
            owner_id: user.ownerid,
            status: 'success'
          });
          navigate('/library');
          return;
        }
        if (data.albumid) {
          if (doResetOnProceed) {
            getRelease(data.albumid);
          }
          setFormValues({ ...formValues, albumid: data.albumid });
          setSearchParams(
            { albumid: data.albumid },
            {
              state: { keyProp: keyProp }
            }
          );
          if (!variables.proceed) return;
          logAmplitudeEvent(SAVE_AND_PROCEED, {
            step: step,
            status: 'success'
          });
          setStep(step + 1);
        }
      },
      onSettled: () => {
        setIsLoading(false);
      }
    }
  );

  React.useEffect(() => {
    if (containerRef?.current) {
      containerRef.current.scrollIntoView();
    }
    if (step > 2) {
      setStep(2);
      return;
    }
    if (step < 0) {
      setStep(0);
      return;
    }
  }, [step]);

  const checkIfAllSongsAreUploaded = (songs: IAlbumSong[]): boolean => {
    return !songs.some((song) => !song.songid);
  };

  const fillFormFields = (data) => {
    const queryParamsForm = getEncodedQueryParam(searchParams.get('form'));
    let newFormValues: ICreateAlbumFormValues = {
      // Fill user (artist) info
      owner: user?.owner_name,
      artist_name: user?.user,
      // default to international
      languageid: 2,
      ...queryParamsForm
    };
    if (data) {
      const [albumObject, songs] = extractAlbumFormValuesFromApiData(data, 0);
      newFormValues = { ...newFormValues, ...albumObject };
      setUploadedSongs(getSongsWithKeysIds(songs));
      setStep(data.stage ? Number(data.stage) - 1 : 0);
      setAreAllSongsUploaded(albumObject.albumid && checkIfAllSongsAreUploaded(songs));
    }
    const { formSections, formFields: newFormFields } = getFormSectionsAndFields({
      releaseType: RELEASE_TYPES.Album,
      accountType: userType,
      data: {
        languageId: newFormValues.languageid
      }
    });
    setFormSections(formSections);
    setFormValues(newFormValues);
    setFormFields(convertFormValuesToFields(newFormValues, newFormFields));
  };

  const resetForm = () => {
    if (!userType) return;
    const { formFields, formSections } = getFormSectionsAndFields({
      releaseType: RELEASE_TYPES.Album
    });
    setFormFields(formFields);
    setFormSections(formSections);
    setUploadedSongs([]);
    setFormValues({});
    setStep(0);
    setLastUploadedSongs([]);
    fillFormFields(null);
    setAreAllSongsUploaded(false);
  };

  React.useEffect(resetForm, [keyProp, userType]);

  const getRelease = (releaseId) => {
    setIsLoading(true);
    gateway
      .callEndpoint(getReleaseDetails, {
        release_id: releaseId,
        release_type: RELEASE_TYPES.Album,
        sid: user?.socketsessionid,
        output: 'jsonhp'
      })
      .then(({ data }) => {
        setIsLoading(false);
        if (!data) return;
        if (data?.error) {
          openToast(data.error.message, 'error');
          // 403 is when I don't own the release, redirect to library
          if (data.error.code === 403) {
            navigate('/library');
          }
          return;
        }
        fillFormFields({ ...data?.data, albumid: albumIdQP } || null);
      })
      .catch((err) => {
        console.log('🔴 ~ getReleaseDetails error ~ err', err);
        setIsLoading(false);
        logGeneralErrorEvent(err, 'create album, getReleaseDetails error');
        openToast(t('something-went-wrong-try-again'), 'error');
        fillFormFields(null);
      });
  };

  React.useEffect(() => {
    setPageTitle('Upload Album');
    if (!albumIdQP) {
      fillFormFields(null);
      return;
    }
    getRelease(albumIdQP);
  }, []);

  React.useEffect(() => {
    setAreAllSongsUploaded(checkIfAllSongsAreUploaded(uploadedSongs));
  }, [uploadedSongs]);

  const handleSongsReorder = () => {
    const songsIds = uploadedSongs.map((song) => song.songid)?.filter((songid) => songid) || [];
    if (songsIds.length <= 1) return;
    if (lastUploadedSongs.length === 0) {
      setLastUploadedSongs(songsIds);
      return;
    }
    if (compareArraysScalar(songsIds, lastUploadedSongs)) return;
    const req = {
      ...formValues,
      stage: step + 1,
      songs: {
        allsongs: songsIds
      }
    };
    mutation.mutate(req);
    setLastUploadedSongs(songsIds);
  };

  const postAlbumValues = () => {
    const req = {
      ...formValues,
      stage: step + 1,
      songs: {
        allsongs: uploadedSongs.map((s) => s.songid)
      },
      proceed: true
    };
    mutation.mutate(req);
  };

  const validateForm = () => {
    if (!formValues) return false;
    if (step === 0) {
      const { missingFields, isValid, updatedFormFields, lastErrorMessage } =
        validateFormFields(formFields);
      if (!isValid) {
        setFormFields(updatedFormFields);
        if (missingFields.length === 1 && missingFields[0] === 'coverkey') {
          openToast(t('missing coverart'), 'error');
        } else {
          openToast(lastErrorMessage || t('Form validation fields required'), 'error');
        }
      } else {
        closeToast();
      }
      return isValid;
    } else if (step === 1) {
      if (uploadedSongs.length === 0) {
        openToast(t('You need to upload a file to proceed'), 'error');
        return false;
      }
      return true;
    } else {
      return true;
    }
  };

  const onProceedAction = () => {
    setIsLoading(true);
    const isValid = validateForm();
    if (!isValid) {
      setIsLoading(false);
      return;
    }
    postAlbumValues();
  };

  const handleSaveAsDraftRedirection = () => {
    logAmplitudeEvent(SAVE_AS_DRAFT, {
      content_id: formValues.albumid,
      artist_id: user?.d_artist?.id,
      owner_id: user.ownerid,
      status: 'success'
    });
    openToast(t('Draft saved'), 'warning');
    reactQueryClient.refetchQueries({
      queryKey: [user?.anid, CONTENT_TYPES.music, 'library', LIBRARY_TYPE.draft],
      exact: false
    });
    navigate('/library/drafts');
  };

  const saveAsDraft = () => {
    // final step
    if (step === 2) {
      mutation.mutate({ ...formValues, stage: step + 1, saveasdraft: true });
      return;
    }
    handleSaveAsDraftRedirection();
  };

  const handleFormDataChange = (newFormFields: IFormFields, fieldName: string) => {
    const tempFormFields: IFormFields = getUpdatedFormFields(newFormFields, fieldName);
    setFormFields(tempFormFields);
    setFormValues({
      ...formValues,
      [fieldName]: tempFormFields[fieldName].value,
      ...(tempFormFields[fieldName].extraValueParam && {
        [tempFormFields[fieldName].extraValueParam]: tempFormFields[fieldName].extraValue
      })
    });
  };

  const handleStepClick = (stepIndex: number) => {
    // if returning to step 0 (I only allow going backward) and there's uploaded songs notify user
    if (stepIndex === 0 && uploadedSongs.length > 0) {
      setGoingToStepIndex(stepIndex);
      setIsStepConfirmationModalShowing(true);
      return;
    }
    setStep(stepIndex);
  };

  const handleStepConfirmationOk = () => {
    setIsStepConfirmationModalShowing(false);
    setDoResetOnProceed(true);
    setStep(goingToStepIndex);
  };

  return (
    <>
      <div
        ref={containerRef}
        className={combineClassNames(styles.main_container, 'f-column f-grow-1')}
      >
        <div className={styles.fixed_header}>
          <ReleaseHeader
            title={t('Create a release')}
            description={t('Album release')}
            actionButtons={
              step === 2 || (step === 1 && formValues.albumid)
                ? [
                    {
                      label: step === 2 ? t('Submit') : t('Proceed'),
                      withBackground: colors['branding-purple'],
                      size: 'lg',
                      disabled: !formValues || isLoading,
                      onSubmit: onProceedAction
                    },
                    {
                      label: t('Save as draft'),
                      size: 'lg',
                      customClasses: ['purple', 'f-center', styles.save_as_draft_button],
                      onSubmit: saveAsDraft,
                      disabled: !areAllSongsUploaded
                    }
                  ]
                : [
                    {
                      label: t('Proceed'),
                      withBackground: colors['branding-purple'],
                      size: 'lg',
                      disabled: !formValues || isLoading,
                      onSubmit: onProceedAction
                    }
                  ]
            }
          />
          <StepsBar
            steps={[
              { value: t('Fill Info') },
              { value: t('Upload Content') },
              { value: t('Review') }
            ]}
            stepIndex={step}
            stepClicked={handleStepClick}
          />
        </div>
        {formSections && formFields ? (
          step === 0 ? (
            <Form
              id={`album-form-${formValues?.albumid}`}
              customClasses={{ container: styles.form_container }}
              sections={formSections}
              formValues={formFields}
              setFormValues={handleFormDataChange}
            />
          ) : step === 1 ? (
            <div className={styles.upload_song_container}>
              <SongObject
                songInfo={{
                  uploadedCoverArt: formFields['coverkey']?.extraValue,
                  name: formFields['albumname']?.value,
                  artist: formValues?.artist_name,
                  releaseDate: formFields['releasedate']?.value
                }}
                onEdit={() => setStep(0)}
              />
              <div className={styles.songUploader_container}>
                <MultiSongUploader
                  title={t('Upload your files')}
                  albumInfo={{
                    coverArt: formFields['coverkey']?.extraValue,
                    albumid: formValues?.albumid,
                    releasedate: formValues?.releasedate,
                    releasetime: formValues?.releasetime,
                    ispodcast: 0,
                    languageid: formValues?.languageid,
                    artistsValue: formValues?.artistsValue,
                    genreValue: formValues?.genreValue,
                    albumtype: formValues?.albumtype
                  }}
                  songs={uploadedSongs}
                  onChange={setUploadedSongs}
                  onReorder={handleSongsReorder}
                />
              </div>
            </div>
          ) : (
            <div className={styles.preview_section}>
              <div>
                <div className="d-flex f-align-center">
                  <h3>{t('Cover')}</h3>
                  <EditButton onClick={() => setStep(0)} customClass={styles.edit_button} />
                </div>
                <div className="d-flex">
                  <img
                    src={formFields['coverkey']?.extraValue?.src}
                    className={styles.coverArt_preview}
                    alt="coverart-preview"
                  />
                  <span className={styles.coverArt_preview_text}>
                    {formFields['coverkey']?.extraValue?.name || formValues?.albumname}
                  </span>
                </div>
              </div>
              <div>
                <div className="d-flex f-align-center">
                  <h3>{t('Album Information')}</h3>
                  <EditButton onClick={() => setStep(0)} customClass={styles.edit_button} />
                </div>
                <div className={styles.preview_form}>
                  <div className={styles.preview_item}>
                    <span className="bold">{t('Name')}</span>
                    <span>{formValues.albumname}</span>
                  </div>
                  {formValues.albumnamear && (
                    <div className={styles.preview_item}>
                      <span className="bold">{t('Arabic Name')}</span>
                      <span>{formValues.albumnamear}</span>
                    </div>
                  )}
                  <div className={styles.preview_item}>
                    <span className="bold">{t('Genre')}</span>
                    <span>{formValues.genre || 'N/A'}</span>
                  </div>
                  <div className={styles.preview_item}>
                    <span className="bold">{t('Release Date')}</span>
                    <ReleaseDateTimePreview
                      releaseDate={formValues?.releasedate}
                      releaseTime={formValues?.releasetime}
                    />
                  </div>
                  {formValues?.owner && (
                    <div className={styles.preview_item}>
                      <span className="bold">{t('Owner')}</span>
                      <span>{formValues.owner}</span>
                    </div>
                  )}
                  <div className={styles.preview_item}>
                    <span className="bold">{t('Artist Name')}</span>
                    <span>
                      {userType === AccountTypeEnums.LABEL
                        ? formValues?.artistsValue?.map((a) => a.label).join(', ')
                        : formValues.artist_name}
                    </span>
                  </div>
                  <div className={styles.preview_item}>
                    <span className="bold">{t('UPC')}</span>
                    <span>{formValues.upc || 'N/A'}</span>
                  </div>
                </div>
              </div>
              <div className="d-flex f-align-center">
                <h3>{t('Track File')}</h3>
                <EditButton onClick={() => setStep(1)} customClass={styles.edit_button} />
              </div>
              {uploadedSongs?.map((song, index) => (
                <div
                  className={combineClassNames('f-column', styles.preview_song_container)}
                  key={song.id + index}
                >
                  <div className={combineClassNames('d-flex f-align-center')}>
                    <span className={styles.preview_index}>{index + 1}</span>
                    <SongPreview key={song.id} song={song.uploadedSong} />
                  </div>
                  <div className="d-flex">
                    <SongInfoPreview
                      key={song.id + index}
                      songInfo={convertAlbumSongObjectToSongInfo(song)}
                    />
                  </div>
                </div>
              ))}
            </div>
          )
        ) : null}
      </div>
      <Modal
        open={isStepConfirmationModalShowing}
        title={t('before-you-leave-you-should-know')}
        okText={t('Yes-sure')}
        cancelText={t('Cancel')}
        onCancel={() => setIsStepConfirmationModalShowing(false)}
        onOk={handleStepConfirmationOk}
      >
        <p>
          {t('Changing album information might reset your progress and clear your uploaded songs')}
        </p>
      </Modal>
    </>
  );
}
