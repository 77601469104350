import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import Button from 'components/Reusable/Button';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './library.module.scss';
import './override.styles.scss';
import { gateway } from 'helpers/gateway-helper';
import { useQuery } from 'react-query';
import { toastContext } from 'components/contexts/toast/toast.context';
import { loaderContext } from 'components/contexts/loader/loader.context';
import { dialogsContext } from 'components/contexts/dialogs/toast/dialogs.context';
import CreateReleaseDialog from 'components/Dialogs/CreateReleaseDialog';
import { userContext } from 'components/contexts/auth/auth.context';
import getUserLibrary, {
  CONTENT_TYPES,
  LIBRARY_TYPE
} from '@anghami/neogateway/dist/endpoints/getUserLibrary';
import ArtistsRoster from 'components/Library/ArtistsRoster/artistsRoster';
import SearchInput from 'components/Reusable/SearchInput';
import { RELEASE_TYPES } from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import { setPageTitle } from 'helpers/url-helper';
import { logAmplitudeEvent } from 'helpers/analytics-helper';
import { CLICKED_ON_CREATE_RELEASE, VIEW_CONTRACT_SIGNING } from 'constants/amplitudeEvents';
import SignContractDialog from 'components/Dialogs/SignContractDialog';
import useUserConditions from 'hooks/useUserConditions';

export default function LibraryRoster() {
  const { t } = useTranslation('common');
  const { openToast } = React.useContext(toastContext);
  const { setIsLoading } = React.useContext(loaderContext);
  const { showDialog } = React.useContext(dialogsContext);
  const [searchValue, setSearchValue] = React.useState('');
  const { user } = useContext(userContext);
  const { shouldSignAgreement, canCreateRelease } = useUserConditions();
  const { isLoading, data } = useQuery(
    [user?.anid + '-library-roster'],
    async () => {
      const { data } = await gateway.callEndpoint(getUserLibrary, {
        content_type: CONTENT_TYPES.artists,
        library_type: LIBRARY_TYPE.live,
        release_type: RELEASE_TYPES.ALL,
        output: 'jsonhp'
      });
      if (!data || data.error) {
        openToast(data?.error?.message || 'Error fetching library', 'error');
        return [];
      }
      return data.data;
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  );

  React.useEffect(() => {
    setIsLoading(isLoading);
    return () => {
      setIsLoading(false);
    };
  }, [isLoading]);

  React.useEffect(() => {
    setPageTitle('Roster');
  }, []);

  const showCreateReleaseDialog = () => {
    logAmplitudeEvent(CLICKED_ON_CREATE_RELEASE, {
      source: 'library-roster'
    });
    showDialog({
      dialog: <CreateReleaseDialog />,
      backdrop: true
    });
  };

  const showSignContractDialog = () => {
    logAmplitudeEvent(VIEW_CONTRACT_SIGNING, {});
    showDialog({
      dialog: <SignContractDialog onSuccessFn={() => showCreateReleaseDialog()} />,
      backdrop: true
    });
  };

  const handleCreateAction = () => {
    if (shouldSignAgreement) {
      showSignContractDialog();
      return;
    }
    showCreateReleaseDialog();
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <h1>{t('%@ artists list').replace('%@', user?.d_name)}</h1>
        </div>
        {canCreateRelease && (
          <Button
            label={t('Create a release')}
            icon={<PlusOutlined />}
            customClasses={[styles.create_button]}
            onSubmit={handleCreateAction}
          />
        )}
      </div>
      <div className={styles.search_container}>
        <SearchInput
          value={searchValue}
          onChange={setSearchValue}
          placeholder={t('search-for-your-artists')}
        />
      </div>
      <div className={styles.roster_container}>
        <ArtistsRoster
          artists={data?.filter((artist) =>
            searchValue
              ? artist?.artist_name?.toLowerCase()?.indexOf(searchValue?.toLowerCase()) !== -1
              : true
          )}
        />
      </div>
    </div>
  );
}
