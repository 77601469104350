import { IGetManagePodcastsItem } from '@anghami/neogateway/dist/endpoints/getManagePodcasts';
import { TableColumnType } from 'components/Library/Columns/tabledatatype';
import DataTable from 'components/Reusable/Table';
import { toastContext } from 'components/contexts/toast/toast.context';
import { GET_RSS_SERVERS_Files } from 'constants/reactQueryKeys';
import managePodcasts from 'helpers/manage-podcasts-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { RssReleaseInfoCell } from '../RssTableCells';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as BackIcon } from 'icons/back-arrow.svg';
import styles from './rssTables.module.scss';
import colors from 'styles/partials/_colors_exports.module.scss';
import LinkItem from 'components/Reusable/LinkItem';
import { SONG_IMAGE_PLACEHOLDER } from 'constants/releases.constants';
import RssActiveFilter from '../RssActiveFilter';
import { getErrorCode } from 'helpers/api-helper';
import Empty from 'antd/es/empty';

const StatusCell = ({ status, realsongid }: { status: string; realsongid: string }) => {
  const { t } = useTranslation();
  switch (status) {
    case '-2':
      return <span style={{ color: colors['orange'] }}>{t('Pending')}</span>;
    case '-1':
      return <span style={{ color: colors['failure-color'] }}>{t('Error')}</span>;
    case '-3':
      return <span style={{ color: colors['failure-color'] }}>{t('Removed')}</span>;
    case '1':
      return (
        <div className="d-flex f-align-center gap-1">
          <span style={{ color: colors['success-color'] }}>{t('Live')}</span>
          <LinkItem
            text={t('Listen')}
            newTab={true}
            link={`https://play.anghami.com/song/${realsongid}`}
          ></LinkItem>
        </div>
      );
    default:
      return <></>;
  }
};
export const columns = (t: any): TableColumnType[] => [
  {
    title: t('Release Info'),
    width: '5rem',
    key: '0',
    fixed: 'left',
    dataIndex: 'title',
    render: (title: string, data: IGetManagePodcastsItem) => (
      <RssReleaseInfoCell
        name={title}
        description={data?.description}
        coverart={data?.image}
        imagePlaceHolder={SONG_IMAGE_PLACEHOLDER}
      />
    )
  },
  {
    title: t('Published on'),
    width: '4rem',
    dataIndex: 'publishdate',
    key: '2',
    render: (value: string) => <div>{value}</div>
  },
  {
    title: t('Status'),
    width: '4rem',
    dataIndex: 'status',
    key: '1',
    render: (value: string, data: IGetManagePodcastsItem) => (
      <StatusCell status={value} realsongid={data?.realsongid} />
    )
  }
];

export default function RssFeedFilesTable() {
  const [activeFilterValue, setActiveFilterValue] = React.useState<1 | 0>(1);
  const { feedId } = useParams();
  const { openToast } = React.useContext(toastContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  console.log(
    '🚀 ~ file: RssFeedFilesTable.tsx:86 ~ RssFeedFilesTable ~ searchParams:',
    searchParams.get('feedName')
  );
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
    [GET_RSS_SERVERS_Files, feedId, activeFilterValue],
    async ({ pageParam = 1 }) => {
      const data = await managePodcasts.getFiles(feedId, pageParam, activeFilterValue);
      // don't show error message when fetching the second page, or when code is 108, no feed found it will show in table
      if ((!data || data.error) && pageParam === 1 && getErrorCode(data.error) !== 108) {
        openToast(data?.error?.message || 'Error fetching feed files', 'error');
        return [];
      }
      return data?.response || [];
    },
    {
      getNextPageParam: (lastPage, allPages) =>
        !lastPage || lastPage.length === 0 ? undefined : allPages.length + 1,
      keepPreviousData: true
    }
  );
  return (
    <>
      <div className="d-flex f-align-center px-2 mb-1 gap-05">
        <BackIcon className={styles.backButton} onClick={() => navigate(-1)} />
        <h2>{searchParams.get('feedName') || t('Feed Episodes')}</h2>
        <RssActiveFilter
          selectedFilter={activeFilterValue}
          setSelectedFilter={setActiveFilterValue}
        />
      </div>
      <div className={styles.tableContainer}>
        <DataTable
          isLoading={isLoading}
          onFetch={hasNextPage && fetchNextPage}
          data={data?.pages.flat() || []}
          tableColumns={columns(t)}
          rowSelection={false}
          isInfiniteLoading={isFetchingNextPage}
          rowKey={'id'}
          className="rss-custom-table"
          locale={{
            emptyText: isLoading ? (
              t('Fetching Data')
            ) : (
              <Empty description={t('No Feed files found for this server')} />
            )
          }}
        />
      </div>
    </>
  );
}
