import getPendingArtistsRequests from '@anghami/neogateway/dist/endpoints/getPendingArtistsRequests';
import { ChangeRequestsStatus } from '@anghami/neogateway/dist/endpoints/getPendingSongsRequests';
import Pagination from 'antd/es/pagination';
import { BeatLoader } from 'components/Reusable/Loaders';
import { gateway } from 'helpers/gateway-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import ArtistRequestItem from '../ArtistRequestItem';
import commonStyles from '../crCommon.module.scss';

export default function ProfileRequests({
  selectedFilter = ChangeRequestsStatus.all,
  parentElement
}: {
  selectedFilter?: ChangeRequestsStatus;
  parentElement: HTMLElement;
}) {
  const { t } = useTranslation('common');
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState(50);
  const { data, isLoading, error, isFetching } = useQuery(
    ['profile-requests', currentPage, pageLimit, selectedFilter],
    async () => {
      const { data } = await gateway.callEndpoint(getPendingArtistsRequests, {
        source: 'mychangerequest',
        status: selectedFilter,
        output: 'jsonhp',
        offset: currentPage,
        limit: pageLimit
      });
      return data;
    }
  );

  React.useEffect(() => {
    if (!parentElement) return;
    setTimeout(() => {
      parentElement?.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'nearest' });
    }, 200);
  }, [data]);

  const handlePaginationChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageLimit(pageSize);
  };
  if (isLoading || isFetching) {
    return (
      <div className="w-100 h-100 f-center" style={{ paddingTop: '2rem' }}>
        <BeatLoader />
      </div>
    );
  }
  if (!data || error) {
    return <div style={{ padding: '2rem' }}>{t('something-went-wrong-try-again')}</div>;
  }
  if (data.error) {
    return <div style={{ padding: '2rem' }}>{data.error.message || t('Something went wrong')}</div>;
  }
  if (Number(data.count) === 0 || !data.requests || data.requests.length === 0) {
    return <div style={{ padding: '2rem' }}>{t('No pending requests')}</div>;
  }

  return (
    <div className={commonStyles.container}>
      {data?.requests?.map((req, index) => (
        <ArtistRequestItem key={req.id + '-' + index} request={req} />
      ))}
      <Pagination
        defaultCurrent={currentPage}
        total={Number(data.count)}
        pageSize={pageLimit}
        onChange={handlePaginationChange}
        hideOnSinglePage={true}
      />
    </div>
  );
}
