import React from 'react';
import { AVAILABLE_ACTIONS } from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import ShareAltOutlined from '@ant-design/icons/lib/icons/ShareAltOutlined';
import VideoCameraAddOutlined from '@ant-design/icons/lib/icons/VideoCameraAddOutlined';
import CloseSquareOutlined from '@ant-design/icons/lib/icons/CloseSquareOutlined';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import BarChartOutlined from '@ant-design/icons/lib/icons/BarChartOutlined';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import { useTranslation } from 'react-i18next';
import { IActionMenuProps } from './actionsMenu';
import styles from './actionsMenu.module.scss';
import { combineClassNames } from 'helpers/styling-helper';

const Actions = {
  [AVAILABLE_ACTIONS.EDIT]: {
    label: 'Edit',
    icon: <EditOutlined />,
    onClick: function (release: any) {
      console.log(`🚀 ~ ${this.label} CLICK`, release);
    }
  },
  [AVAILABLE_ACTIONS.EDIT_RELEASE_DIALOG]: {
    label: 'Edit',
    icon: <EditOutlined />,
    onClick: function (release: any) {
      console.log(`🚀 ~ ${this.label} CLICK`, release);
    }
  },
  [AVAILABLE_ACTIONS.EDIT_RELEASE_PAGE]: {
    label: 'Edit',
    icon: <EditOutlined />,
    onClick: function (release: any) {
      console.log(`🚀 ~ ${this.label} CLICK`, release);
    }
  },
  [AVAILABLE_ACTIONS.ADD_VIDEO]: {
    label: 'add-video',
    icon: <VideoCameraAddOutlined />,
    onClick: function (release: any) {
      console.log(`🚀 ~ ${this.label} CLICK`, release);
    }
  },
  [AVAILABLE_ACTIONS.VIEW_ANALYTICS]: {
    label: 'view-analytics',
    icon: <BarChartOutlined />,
    onClick: function (release: any) {
      console.log(`🚀 ~ ${this.label} CLICK`, release);
    }
  },
  [AVAILABLE_ACTIONS.TAKEDOWN]: {
    label: 'take-down',
    icon: <CloseSquareOutlined />,
    onClick: function (release: any) {
      console.log(`🚀 ~ ${this.label} CLICK`, release);
    }
  },
  [AVAILABLE_ACTIONS.DELETE]: {
    label: 'delete',
    icon: <DeleteOutlined />,
    onClick: function (release: any) {
      console.log(`🚀 ~ ${this.label} CLICK`, release);
    }
  },
  [AVAILABLE_ACTIONS.SHARE]: {
    label: 'Share',
    icon: <ShareAltOutlined />,
    onClick: function (release: any) {
      console.log(`🚀 ~ ${this.label} CLICK`, release);
    }
  },
  [AVAILABLE_ACTIONS.MANAGE_RSS]: {
    label: 'Manage',
    icon: <EditOutlined />,
    onClick: function (release: any) {
      console.log(`🚀 ~ ${this.label} CLICK`, release);
    }
  }
};

interface IActionItemProps {
  action: AVAILABLE_ACTIONS;
  onActionClick: IActionMenuProps['onActionClick'];
  customClassName: string;
}

export default function ActionItem({ action, onActionClick, customClassName }: IActionItemProps) {
  const { t } = useTranslation('common');
  const actionItem = Actions[action];
  if (!actionItem) {
    return <></>;
  }
  return (
    <div
      key={actionItem.label}
      className={combineClassNames(styles.actionItem, customClassName)}
      onClick={() => onActionClick(action)}
    >
      {actionItem.icon}
      <span>{t(actionItem.label)}</span>
    </div>
  );
}
