import React from 'react';
import { IArtistPromotionRequest } from '@anghami/neogateway/dist/endpoints/getArtistPromotionRequest';
import Tag from 'antd/lib/tag';
import { useTranslation } from 'react-i18next';
import colors from 'styles/partials/_colors_exports.module.scss';

const PromotionStatusField = ({ row }: { row: IArtistPromotionRequest }) => {
  const { t } = useTranslation('common');
  switch (row.status) {
    case '0': {
      if (row.dashpay === '0' && Number(row.amount) <= 0) {
        return <Tag color={colors['orange']}> {t('Pending Review')} </Tag>;
      } else if (row.dashpay == '1' && Number(row.amount) > 0) {
        <Tag color={colors['orange']}> {`${t('Pending Online Payment')} ${row.amount}`}</Tag>;
      }
      return <Tag color={colors['orange']}> {t('Pending Review')}</Tag>;
    }
    case '1':
      return <Tag color={colors['success-color']}>{t('Approved')}</Tag>;
    case '-1':
      return <Tag color={colors['failure-color']}>{t('Rejected')}</Tag>;
    case '2':
      return <Tag color={colors['main-blue']}>{t('Contacted')}</Tag>;
    default:
      return <></>;
  }
};

export default PromotionStatusField;
