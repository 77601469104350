import React from 'react';
import { t } from 'i18next';
import { FormFieldTypes, IFormField } from 'interfaces/formsComponents.interface';
// Form Components
import AlbumTypesSelect from 'components/CreateRelease/AlbumTypesSelect';
import ArtistSelect from 'components/CreateRelease/ArtistSelect';
import GenreSelect from 'components/CreateRelease/GenreSelect';
import OwnShowsSelect from 'components/CreateRelease/OwnShowsSelect';
import UploadCoverArt from 'components/CreateRelease/UploadCoverArt';
import CheckBox from '../CheckBox';
import DateInput from '../DateInput';
import RadioButton from '../RadioButton';
import TextArea from '../TextArea';
import TextInput from '../TextInput';
import TimeInput from '../TimeInput';
import WithLabelWrapper from '../WithLabelWrapper';
import CategorySelect from 'components/CreateRelease/CategorySelect';
import { combineClassNames } from 'helpers/styling-helper';
import NationalitySelect from 'components/PitchPlaylists/NationalitySelect';
import MoodsSelect from 'components/PitchPlaylists/MoodsSelect';
import GenresSelect from 'components/PitchPlaylists/GenresSelect/genresSelect';
import OwnAlbumsSelect from 'components/CreateRelease/OwnAlbumsSelect';
import styles from './form.module.scss';

const FIELDS = {
  [FormFieldTypes.INPUT_FIELD]: (field, updateValue, id) => {
    return (
      <TextInput
        {...field}
        key={id + field.name}
        onChange={(e) => updateValue(field?.name, e.target.value)}
      ></TextInput>
    );
  },
  [FormFieldTypes.TEXT_AREA]: (field, updateValue, id) => {
    return (
      <TextArea
        {...field}
        value={field.value || ''}
        key={id + field.name}
        onChange={(e) => updateValue(field?.name, e.target.value)}
      />
    );
  },
  [FormFieldTypes.DATE_INPUT]: (field, updateValue, id) => {
    return (
      <DateInput
        {...field}
        key={id + field.name}
        onChange={(val) => {
          updateValue(field?.name, val);
        }}
      />
    );
  },
  [FormFieldTypes.TIME_INPUT]: (field, updateValue, id) => {
    return (
      <TimeInput
        {...field}
        key={id + field.name}
        onChange={(val) => {
          updateValue(field?.name, val);
        }}
      />
    );
  },
  [FormFieldTypes.ARTISTS_SELECT]: (field, updateValue, id) => {
    return (
      <ArtistSelect
        {...field}
        key={id + field.name}
        onChange={(val) =>
          updateValue(
            field?.name,
            val.map((a) => a.value),
            val
          )
        }
        value={field?.extraValue}
      />
    );
  },
  [FormFieldTypes.GENRE_SELECT]: (field, updateValue, id) => {
    if (field?.isMultiSelect) {
      return (
        <GenresSelect
          {...field}
          key={id + field.name}
          onChange={(val) =>
            updateValue(
              field?.name,
              val.map((a) => a.value),
              val
            )
          }
          value={field?.extraValue}
        />
      );
    }
    return (
      <GenreSelect
        {...field}
        key={id + field.name}
        onChange={(val) => updateValue(field?.name, val?.value, val)}
        value={field?.extraValue}
      />
    );
  },
  [FormFieldTypes.CATEGORY_SELECT]: (field, updateValue, id) => {
    return (
      <CategorySelect
        {...field}
        key={id + field.name}
        onChange={(val) => updateValue(field?.name, val.id, val)}
        value={field?.extraValue}
      />
    );
  },
  [FormFieldTypes.MONO_SELECT_CHECKBOX]: (field, updateValue, id) => {
    {
      /* //TODO: update this component to be without the parent label, passed as a title */
    }
    return (
      <WithLabelWrapper
        label={t('Restrictions')}
        error={field?.error}
        extraLabel={field?.extraLabel}
        customLabelClass={field.customLabelClass}
      >
        <CheckBox
          label={t(field?.itemLabel)}
          key={id + field.name}
          isChecked={Boolean(Number(field?.value))}
          onChange={(isCheked) => {
            updateValue(field?.name, isCheked ? 1 : 0);
          }}
        />
      </WithLabelWrapper>
    );
  },
  [FormFieldTypes.RADIO_BUTTON]: (field, updateValue, id) => {
    return (
      <RadioButton
        key={id + field.name}
        label={t(field?.label)}
        description={t(field?.labelDescription)}
        error={field?.error}
        value={String(field?.value)}
        onChange={(val) => updateValue(field?.name, Number(val))}
        items={field?.options}
      ></RadioButton>
    );
  },
  [FormFieldTypes.UPLOAD_COVER_ART]: (field, updateValue, id) => {
    return (
      <UploadCoverArt
        {...field}
        key={id + field.name}
        onChange={(val) => updateValue(field?.name, val.key, val)}
        value={field?.extraValue}
      />
    );
  },
  [FormFieldTypes.ALBUM_TYPE_SELECT]: (field, updateValue, id) => {
    return (
      <AlbumTypesSelect
        {...field}
        key={id + field.name}
        onChange={(val) => updateValue(field?.name, val?.value, val)}
        value={field?.extraValue}
      />
    );
  },
  [FormFieldTypes.OWN_SHOWS_SELECT]: (field, updateValue, id) => {
    return (
      <OwnShowsSelect
        {...field}
        description={field?.labelDescription}
        key={id + field.name}
        onChange={(val) => updateValue(field?.name, val?.value, val)}
        value={field?.extraValue}
      />
    );
  },
  [FormFieldTypes.ALBUM_TO_ADD_TO_SELECT]: (field, updateValue, id) => {
    return (
      <OwnAlbumsSelect
        {...field}
        description={field?.labelDescription}
        key={id + field.name}
        onChange={(val) => updateValue(field?.name, val?.value, val)}
        value={field?.extraValue}
      />
    );
  },
  [FormFieldTypes.NATIONALITY_SELECT]: (field, updateValue, id) => {
    return (
      <NationalitySelect
        {...field}
        key={id + field.name}
        onChange={(val) => updateValue(field?.name, val?.value, val)}
        value={field?.extraValue}
      />
    );
  },
  [FormFieldTypes.MOODS_SELECT]: (field, updateValue, id) => {
    return (
      <MoodsSelect
        {...field}
        key={id + field.name}
        onChange={(val) =>
          updateValue(
            field?.name,
            val.map((a) => a.value),
            val
          )
        }
        value={field?.extraValue}
      />
    );
  }
};
interface IFormFieldProps {
  id: string;
  field: IFormField;
  onUpdate: (fieldName: string, value: any, extraValue: any) => void;
}
export default function FormField({ id, field, onUpdate }: IFormFieldProps) {
  return (
    <div className={combineClassNames(styles.form_input, field.isHidden && styles.hidden)}>
      {!field.isHidden && FIELDS[field.type](field, onUpdate, id)}
    </div>
  );
}
