import {
  RELEASE_TYPES,
  RELEASE_STATUSES
} from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import { ALBUM_IMAGE_PLACEHOLDER, SONG_IMAGE_PLACEHOLDER } from 'constants/releases.constants';
import { environment } from 'env/environment';

export const getCoverArtImage = (coverArtImage: string, size = 296) => {
  if (!coverArtImage) {
    return `${environment.artwork_cdn}/webp/img/upload-music/upload-image-song-placeholder-dark.png`;
  }
  if (isNaN(Number(coverArtImage))) {
    return decodeURIComponent(coverArtImage);
  } else {
    return `${environment.artwork_cdn}?id=${coverArtImage}&size=${size}`;
  }
};

export const getIsReleaseSingle = (releaseType: RELEASE_TYPES) =>
  releaseType !== RELEASE_TYPES.Album && releaseType !== RELEASE_TYPES.Show;
export const getIsReleaseSongInsideAlbum = (releaseType: RELEASE_TYPES) =>
  releaseType === RELEASE_TYPES.Song || releaseType === RELEASE_TYPES.Episode;
export const getIsPodcastFromReleaseType = (releaseType: RELEASE_TYPES) =>
  releaseType === RELEASE_TYPES.Episode ||
  releaseType === RELEASE_TYPES.Single_Episode ||
  releaseType === RELEASE_TYPES.Show;
export const getIsReleaseAlbum = (releaseType: RELEASE_TYPES) =>
  releaseType === RELEASE_TYPES.Album || releaseType === RELEASE_TYPES.Show;
export const getIsReleaseDown = (releaseStatus: RELEASE_STATUSES) =>
  releaseStatus === RELEASE_STATUSES.DELETED_DRAFT ||
  releaseStatus === RELEASE_STATUSES.MERGED ||
  releaseStatus === RELEASE_STATUSES.TAKENDOWN;
export const getSimplifiedReleaseTypes = (releaseType: RELEASE_TYPES) => {
  switch (releaseType) {
    case RELEASE_TYPES.Song:
      return RELEASE_TYPES.Single;
    case RELEASE_TYPES.Single_Episode:
      return RELEASE_TYPES.Episode;
    case RELEASE_TYPES.ALL:
      //default all to single
      return RELEASE_TYPES.Single;
    default:
      return releaseType;
  }
};

export const getReleaseCoverPlaceHolder = (releaseType: RELEASE_TYPES) => {
  // keeping it a switch so later we can add placeholers for podcasts
  switch (releaseType) {
    case RELEASE_TYPES.Album:
    case RELEASE_TYPES.Show:
      return ALBUM_IMAGE_PLACEHOLDER;
    default:
      return SONG_IMAGE_PLACEHOLDER;
  }
};

export const getReleaseLink = (releaseType: RELEASE_TYPES, releaseId: string) => {
  let type: string;
  switch (releaseType) {
    case RELEASE_TYPES.Show:
      type = 'podcast';
      break;
    case RELEASE_TYPES.Single_Episode:
      type = 'episode';
      break;
    case RELEASE_TYPES.Single:
      type = 'song';
      break;
    default:
      type = releaseType;
  }
  return `https://play.anghami.com/${type}/${releaseId}`;
};
