import React from 'react';
import DropDown from 'components/Reusable/DropDown';
import { useQuery } from 'react-query';
import { IInputWithLabel } from 'interfaces/formsComponents.interface';
import managePodcasts from 'helpers/manage-podcasts-helper';

interface IItem {
  name: string;
  id: string;
}
interface ICategorySelectProps
  extends Omit<IInputWithLabel, 'value' | 'onChange' | 'defaultValue'> {
  albumtype?: 0 | 1 | 2;
  onChange?: (value: IItem) => void;
  customClassName?: string;
  placeholder?: string;
  value?: IItem;
  error?: string;
  disabled?: boolean;
  defaultValue?: IItem;
  customInputClass?: string;
  hideArrow?: boolean;
  onSearchQuery?: (query: string) => void;
}

export default function CategorySelect(props: ICategorySelectProps) {
  const { data } = useQuery(
    ['genreDropDown', props.albumtype || 5],
    async () => {
      const data = await managePodcasts.getCategories(props.albumtype || 5, '');
      if (data?.response?.length > 0) {
        return data?.response.map((category) => ({ label: category.name, value: category.id }));
      } else {
        throw new Error('Something went wrong, please try again');
      }
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: 3,
      staleTime: Infinity
    }
  );

  return (
    <DropDown
      {...props}
      options={data || []}
      value={{ value: props.value?.id, label: props.value?.name }}
      defaultValue={{ value: props.defaultValue?.id, label: props.defaultValue?.name }}
      onChange={(value) => props.onChange({ name: value?.label, id: value?.value })}
    />
  );
}
