import { IUseFileUploaderReturnType } from './../hooks/useFileUploader';
import {
  RELEASE_STATUSES,
  RELEASE_TYPES
} from '@anghami/neogateway/dist/endpoints/getreleasedetails';
export interface IUploadedFile {
  src?: string;
  name?: string;
  type?: string;
  size?: number;
  key?: string;
  isUploaded?: boolean;
  file?: File;
}

export interface IOption {
  value: string;
  label: string;
}

export interface IFormField {
  type: string;
  label: string;
  labelDescription?: string;
  extraLabel?: string;
  additionalInfo?: IAdditionalInfo;
  name: string;
  extraValueParam?: any; // in case of ArtistsSelect we have to send 2 params to api
  extraValue?: any;
  value: any;
  placeholder?: string;
  required?: boolean;
  error?: string;
  options?: IOption[];
  isMultiSelect?: boolean;
  disabled?: boolean;
  isHidden?: boolean;
  regexValidation?: RegExp;
  regexValidationMessage?: string;
  // used in single radio or checkbox components
  itemLabel?: string;
  customInputClass?: string;
  customLabelClass?: string;
  showErrorText?: boolean;
  notSentToApi?: boolean;
  getApiValue?: (formField: IFormField) => any;
  //TODO: create a sub field that containes extra fields
  releasetype?: RELEASE_TYPES;
  albumtype?: number;
  languageid?: number;
}
export interface ISection {
  title: string;
  data: string[][];
}

export interface IFormFields {
  [key: string]: IFormField;
}

export interface ICreateSingleFormValues {
  genre?: string;
  releasedate?: Date;
  languageid?: number;
  albumtype?: number;
  songname?: string;
  songnamear?: string;
  lyrics?: string;
  single?: boolean;
  explicit?: 0 | 1;
  featuringartistids?: string[];
  artists?: string[];
  isrc?: number;
  ispodcast?: 0 | 1;
  bucket?: string;
  audiokey?: string;
  filesize?: number;
  filename?: string;
  coverkey?: string;
  upc?: string;
  audioonly?: boolean;
  youtubeLink?: string;
  isyoutubeupload?: boolean;
  uploadedCoverArt?: IUploadedFile;
  uploadedSong?: IUploadedFile;
  genreValue?: IOption;
  albumTypeValue?: IOption;
  featuringartistidsValue?: IOption[];
  artistsValue?: IOption[];
  artist_name?: string;
  owner?: string;
  releasetime?: Date;
  musicGenre?: string[];
  songid?: string;
  albumid?: string;
  description?: string;
  descriptionar?: string;
  showid?: string;
  showidValue?: IOption;
  isaddingtoexistingalbum?: boolean;
  delete?: boolean;
  category?: string;
  categoryValue?: { name: string; id: string };
}

export interface ICreateRSSShowFormValue {
  rssLink?: string;
  languageid?: number;
  albumid?: string;
  categoryValue?: ICategoryValue;
  category?: string;
}

export interface PostAlbumUpdateBody {
  albumname?: string;
  albumnamear?: string;
  albumtype?: number;
  description?: string;
  descriptionar?: string;
  bucket?: string;
  coverkey?: string;
  explicit?: 1 | 0;
  genre?: string;
  isalbumupload?: number;
  artists?: string[];
  languageid?: number;
  multilanguage?: boolean;
  releasedate?: Date;
  upc?: string;
  artist_name?: string;
  owner?: string;
  songs?: {
    allsongs: IAlbumSong[];
  };
}

export interface ICategoryValue {
  name: string;
  id: string;
}

export interface ICreateAlbumFormValues extends PostAlbumUpdateBody {
  artistsValue?: IOption[];
  uploadedCoverArt?: IUploadedFile;
  genreValue?: IOption;
  albumTypeValue?: IOption;
  releasetime?: Date;
  musicGenre?: string[];
  albumid?: string;
  proceed?: boolean;
  categoryValue?: ICategoryValue;
  category?: string;
  delete?: boolean;
}

export enum UploaderFileTypes {
  audio = 'audio',
  image = 'image',
  video = 'video'
}

export enum FormFieldTypes {
  TEXT_AREA = 'textarea',
  INPUT_FIELD = 'input-field',
  MULTI_SELECT_DROPDOWN = 'multi-select-dropdown',
  MONO_SELECT_DROPDOWN = 'mono-select-dropdown',
  MULTI_SELECT_CHECKBOX = 'multi-select-checkbox',
  MONO_SELECT_CHECKBOX = 'mono-select-checkbox',
  RADIO_BUTTON = 'radio-button',
  DATE_INPUT = 'date-input',
  TIME_INPUT = 'time-input',
  ARTISTS_SELECT = 'artist-select',
  GENRE_SELECT = 'genre-select',
  UPLOAD_COVER_ART = 'upload-cover-art',
  ALBUM_TYPE_SELECT = 'album-type-select',
  OWN_SHOWS_SELECT = 'own-shows-select',
  CATEGORY_SELECT = 'category-select',
  NATIONALITY_SELECT = 'nationality-select',
  MOODS_SELECT = 'moods-select',
  OWN_ALBUM_SELECT = 'own-album-select',
  ALBUM_TO_ADD_TO_SELECT = 'album-to-add-to-select'
}
export interface IAlbumSong extends Omit<ICreateSingleFormValues, 'delete'> {
  id?: string;
  tracknb?: number;
  collapsed?: boolean;
  songid?: string;
  albumId?: string;
  albumCoverArt?: IUploadedFile;
  albumReleaseDate?: Date;
  albumLanguageId?: number;
  delete?: boolean;
  status?: RELEASE_STATUSES;
}

export interface ISongInfoPreviewItem {
  title?: string;
  value?: {
    title?: string;
    value: string;
  }[];
}

export interface IFormValues {
  [key: string]: any;
}

export interface IInputWithLabel extends Partial<React.HTMLProps<HTMLInputElement>> {
  label?: string;
  extraLabel?: string;
  additionalInfo?: IAdditionalInfo;
  description?: string;
  error?: string;
  customLabelClass?: string;
  children?: React.ReactNode;
  showErrorText?: boolean;
  pattern?: string;
  id?: string;
  name?: string;
  autoComplete?: string;
}

export interface IAdditionalInfo {
  text: string;
  customClass?: string;
}

export interface ICheckBoItemxData {
  label?: string;
  value?: string;
  isChecked?: boolean;
}

export interface ICheckbox extends Omit<IInputWithLabel, 'onChange'> {
  values: string[];
  items: ICheckBoItemxData[];
  onChange?: (value: string) => void;
  error?: string;
  customInputClass?: string;
}

export interface IUploadCoverArtProps
  extends Omit<IInputWithLabel, 'value' | 'onChange' | 'onError'> {
  onChange: (uploadedImage: IUploadedFile) => void;
  onError?: (error: string) => void;
  value?: IUploadedFile;
  isInplace?: boolean;
  config?: {
    notCoverArt?: boolean;
    hideBottomInfo?: boolean;
    changeButtonText?: string;
    uploadButtonText?: string;
    title?: string;
    hideStatus?: boolean;
    maxSize?: number;
  };
  uploader?: IUseFileUploaderReturnType;
}
