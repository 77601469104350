import { dashboardAuthenticate } from '@anghami/neogateway/dist/endpoints/authentication';
import refreshAuthToken from '@anghami/neogateway/dist/endpoints/refreshAuthToken';
import { USER_LOGIN_FAIL, USER_LOGIN_SUCCESS } from 'constants/amplitudeEvents';
import { BASE_URL } from 'constants/appConstants';
import { logAmplitudeEvent, logGeneralErrorEvent, setUserData } from 'helpers/analytics-helper';
import { removeDashboardAuthCookies, saveDashboardAuthCookies } from 'helpers/cookies-helper';
import { gateway, getSID } from 'helpers/gateway-helper';
import { postLogoutWrapper } from 'helpers/http-helper';
import useLocalStorage from 'hooks/useLocalStorage';
import { AccountTypeEnums, IAuthenticateRes, IPermissions } from 'interfaces/auth.interface';
import React from 'react';
import { useCookies } from 'react-cookie';
import { QueryCache } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { userContext, Auth as IAuth } from './auth.context';
import Dialog from 'components/Reusable/Dialog';
import RejectedUserDialog from 'components/Dialogs/RejectedUserDialog';

interface IProps {
  children?: any;
  setIsConnectionLost?: (val: boolean) => void;
  userProfile?: {
    id: string;
  };
}

const createPermissionObject = (permissionsArray: string[]): { [key: string]: boolean } => {
  if (!permissionsArray) return;
  const permissions = {};
  permissionsArray.forEach((permission) => {
    const permissionKey = permission.split('-').join('_');
    permissions[permissionKey] = true;
  });
  return permissions;
};

const getAccountType = (user: IAuthenticateRes): AccountTypeEnums => {
  switch (user?.d_typeid) {
    case '1':
      return AccountTypeEnums.ADMIN;
    case '2':
      return Number(user?.is_podcaster) ? AccountTypeEnums.PODCASTER : AccountTypeEnums.ARTIST;
    case '3':
      return AccountTypeEnums.LABEL;
    default:
      return AccountTypeEnums.UNKOWN;
  }
};

const Auth = ({ children, setIsConnectionLost }: IProps) => {
  const [cookies] = useCookies(['isLoggedIn']);
  const isLoggedInCookie = cookies.isLoggedIn;
  const [loggedIn, setLoggedIn] = React.useState(isLoggedInCookie === 'true' && Boolean(getSID()));
  const [user, setUser] = useLocalStorage<IAuthenticateRes>('user', null);
  const [permissions, setPermissions] = React.useState<IPermissions>(null);
  const [userType, setUserType] = React.useState<AccountTypeEnums>(null);
  const [isUserRejected, setIsUserRejected] = React.useState(false);
  const navigate = useNavigate();

  const logApiReponse = (isSuccess: boolean, res) => {
    if (process.env.NODE_ENV !== 'development') return;
    if (isSuccess) {
      console.log(
        `🟢 ~ API CALL SUCCESS - %c${res.config?.params?.type}`,
        'color: #0edb0e; font-weight: bold;',
        {
          request: res?.config?.params,
          response: res?.data
        }
      );
      return;
    }
    console.log(
      `🔴 ~ API CALL ERROR - %c${res.config?.params?.type}`,
      'color: red; font-weight: bold;',
      {
        request: res?.config?.params,
        response: res?.data
      }
    );
  };

  // Setup gateway interceptors
  React.useEffect(() => {
    if (!gateway) return;
    gateway.customResponseInterceptor.on('api_response_success', (res: any) => {
      logApiReponse(true, res);
      setIsConnectionLost(false);
      if (
        String(res?.data?.code) === '9090' ||
        String(res?.data?.error?.code) === '9090' ||
        String(res?.data?.error?._attributes?.code) === '9090'
      ) {
        console.log(`${res.config?.params?.type} request returned 9090`);
        // user is not allowed to access new dash
        window.location.replace(BASE_URL);
      }
      if (
        res?.data?.logoff === 'true' ||
        res?.data?.error?.logoff === 'true' ||
        res?.data?.error?._attributes?.logoff === 'true'
      ) {
        console.log(`${res.config?.params?.type} request returned logoff`);
        logout();
        return;
      }
      if (
        String(res?.data?.code) === '111' ||
        String(res?.data?.error?.code) === '111' ||
        String(res?.data?.error?._attributes?.code) === '111'
      ) {
        reauth();
        return;
      }
      if (res?.data?.refresh_token || res?.data?._attributes?.refresh_token) {
        refreshJWTToken();
        return;
      }
    });
    gateway.customResponseInterceptor.on('api_response_error', (res: any) => {
      // ECONNABORTED on time out or when aborted
      if (res.code === 'ERR_NETWORK') {
        console.log('🔴 ~ Network Error');
        setIsConnectionLost(true);
        return { error: { message: 'network error' } };
      } else {
        setIsConnectionLost(false);
        logApiReponse(false, res);
      }
    });
  }, []);

  const refreshJWTToken = async () => {
    if (!user?.afc?.refresh_token) {
      logout();
      return;
    }
    const { data } = await gateway.callEndpoint(
      refreshAuthToken,
      {
        refresh_token: user.afc.refresh_token,
        grant_type: 'refresh_token'
      },
      true
    );
    if (!data?.access_token) return;
    setUser({
      ...user,
      afc: data
    });
  };

  React.useEffect(() => {
    if (!user?.afc) return;
    const currentTimestap = new Date().getTime() / 1000;
    if (currentTimestap >= user.afc.expires_at) {
      refreshJWTToken();
      return;
    }
    gateway.setTokens({
      jwtToken: user?.afc?.access_token
    });
  }, [user]);

  // logout users with no afc object, admins will get empty object
  React.useEffect(() => {
    if (!user || user.afc) return;
    logGeneralErrorEvent('', 'logged-in user with no afc');
    logout();
  }, [user]);

  const handleAuthSuccess = (authObject: IAuthenticateRes) => {
    if (!authObject) return;
    saveDashboardAuthCookies(authObject);
    // d_statusid 0 is when the user is rejected
    if (Number(authObject.d_statusid) === 0) {
      // incase it was from reauth
      navigate('/login');
      setIsUserRejected(true);
      return;
    }
    setUser(authObject);
    setUserData(authObject);
    // redirect when user is not allowerd
    if (authObject.redirect_url) {
      window.location.href = (authObject as any).redirect_url;
      return;
    }
    setLoggedIn(true);
    logAmplitudeEvent(USER_LOGIN_SUCCESS, {
      user: authObject.anid,
      hide_opt_out: authObject.hide_opt_out
    });
  };

  const reauth = (callback?: () => void) => {
    gateway
      .callEndpoint(dashboardAuthenticate, {
        reauthenticate: true
      })
      .then(({ data }) => {
        const authResponse = data;
        if (!authResponse || authResponse.error || !authResponse.authenticate) {
          // const errorCode = authResponse?.error?.code;
          console.log('reauth error', authResponse.error);
          logout();
          return;
        }
        const authObject: IAuthenticateRes =
          authResponse.authenticate._attributes || authResponse.authenticate;
        const authWithAfc: IAuthenticateRes = {
          ...authObject,
          // for now reauth is returning afc as null, and it's overiding the old one, this will default for the old one if it's null
          afc: authObject?.afc || user?.afc
        };
        handleAuthSuccess(authWithAfc);
        if (!callback) return;
        callback();
      })
      .catch((err: any) => {
        console.error('🔴 ~ Login error', err);
        logAmplitudeEvent(USER_LOGIN_FAIL, {
          error: err
        });
      });
  };

  React.useEffect(() => {
    if (isLoggedInCookie && !user?.anid) {
      reauth();
    }
  }, [isLoggedInCookie]);

  React.useEffect(() => {
    if (!user) return;
    setPermissions(createPermissionObject(user?.afc?.scopes));
    setUserType(getAccountType(user));
  }, [user]);

  const logout = () => {
    removeDashboardAuthCookies();
    setLoggedIn(false);
    setUser(null);
    setUserType(null);
    postLogoutWrapper();
    gateway?.setTokens({
      socketsessionid: ''
    });
    const queryCache = new QueryCache();
    queryCache.clear();
    navigate('/login');
  };

  const setUserHOF = (user: IAuthenticateRes) => {
    if (!user) return;
    setUser(user);
    setPermissions(createPermissionObject(user?.afc?.scopes));
    setUserType(getAccountType(user));
  };

  const auth: IAuth = {
    loggedIn,
    setLoggedIn,
    user,
    setUser: setUserHOF,
    logout,
    reauth,
    permissions,
    handleAuthSuccess,
    setPermissions: (permissions: string[]) => {
      setUser({ ...user, afc: { ...user.afc, scopes: permissions } });
      setPermissions(createPermissionObject(permissions));
    },
    userType
  };

  return (
    <>
      <userContext.Provider value={auth}>{children}</userContext.Provider>
      <Dialog isOpen={isUserRejected} isBackDrop={false}>
        <RejectedUserDialog
          closeAndLogout={() => {
            setIsUserRejected(false);
            logout();
          }}
        />
      </Dialog>
    </>
  );
};

export default Auth;
