import React from 'react';
import PhoneInput from 'components/Landing/CreateFormField/PhoneInput';
import RadioButton from 'components/Landing/CreateFormField/RadioButton';
import styles from './formField.module.scss';
import { combineClassNames } from 'helpers/styling-helper';

interface IProps {
  info?: {
    type?: string;
    data?: any;
    name?: string;
    autocomplete?: string;
    label?: string;
  };
  error?: string;
  onChange?: any;
  onBlur?: any;
  value?: any;
}

export default function FormField({ info, onChange, onBlur, error, value }: IProps) {
  let Forminput = null;
  switch (info.type) {
    case 'radio':
      Forminput = info.data.options.map((op: { label: string; value: string }) => (
        <RadioButton
          label={op.label}
          group={info.name}
          value={op.value}
          change={onChange}
          key={op.value + op.label}
          checked={value === op.value}
        />
      ));
      break;
    case 'text':
    case 'email':
    case 'password':
      Forminput = (
        <input
          type={info.type}
          placeholder={info.data.placeholder}
          onChange={onChange}
          onBlur={onBlur}
          name={info.name}
          autoComplete={info.autocomplete}
          value={value || ''}
          className={combineClassNames(styles.customTextInput, styles.large, error && styles.error)}
        />
      );
      break;
    case 'phoneNumber':
      Forminput = (
        <PhoneInput value={value} blur={blur} name={info.name} change={onChange} error={error} />
      );
      break;
    default:
      break;
  }
  return (
    <div className={styles.formFieldDiv}>
      <span className={combineClassNames(styles.fieldLabel, error && styles.error)}>
        {info.label}
        <span
          className={styles.fieldError}
          style={
            error && error.length > 22
              ? { display: 'block', marginTop: '0.4em', marginLeft: '0em' }
              : {}
          }
        >
          {error || null}
        </span>
      </span>
      <div className="d-flex mt-1">{Forminput}</div>
    </div>
  );
}
