import React, { ChangeEvent } from 'react';
import styles from './songUploadBox.module.scss';
import { ReactComponent as UploadVisual } from 'icons/upload-visual.svg';
import Button from 'components/Reusable/Button';
import { IUploadedFile } from 'interfaces/formsComponents.interface';
import { useTranslation } from 'react-i18next';
import { toastContext } from 'components/contexts/toast/toast.context';

interface SongUploadBoxProps {
  onUpload: (event: IUploadedFile[]) => void;
  multiple?: boolean;
  fileTypes?: string;
}
export default function SongUploadBox({ multiple, fileTypes, onUpload }: SongUploadBoxProps) {
  const [uploadedSongs, setUploadedSongs] = React.useState<IUploadedFile[]>([]);
  const { t } = useTranslation('common');
  const { openToast } = React.useContext(toastContext);

  React.useEffect(() => {
    if (uploadedSongs.length <= 0) return;
    onUpload(uploadedSongs);
  }, [uploadedSongs]);

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    const songs = [];
    if (e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files);
      files.forEach((file) => {
        if (
          file.type.indexOf('audio/') === -1 ||
          fileTypes.indexOf('.' + file.type.replace('audio/', '')) === -1
        ) {
          openToast(t('file type is not supported').replace('%@', file.type), 'error');
          return;
        }
        songs.push({
          type: file.type,
          name: file.name,
          size: file.size,
          file: file,
          src: URL.createObjectURL(file)
        });
        if (songs.length === files.length) {
          setUploadedSongs([...songs]);
        }
      });
    }
  };

  return (
    <div className={styles.upload_box}>
      <input multiple={multiple} type={'file'} onChange={onSelectFile} accept={fileTypes}></input>
      <>
        <UploadVisual></UploadVisual>
        <span>{t('Drag and drop or choose from your desktop')}</span>
      </>
      <Button
        customClasses={[styles.browse_button]}
        withBackground={'#C4C4C4'}
        onSubmit={null}
        label={t('Browse Desktop')}
      />
    </div>
  );
}
