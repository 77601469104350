import React from 'react';

export interface ILoaderContext {
  isLoading: boolean;
  setIsLoading: (boolean) => void;
}

// used to pass the reload method to the table cells
export const loaderContext = React.createContext<ILoaderContext>({
  setIsLoading: () => null,
  isLoading: false
});
