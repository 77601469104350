import React from 'react';
import styles from './downloadPlaybook.module.scss';
import { combineClassNames } from 'helpers/styling-helper';
import Button from 'components/Reusable/Button';
import { ReactComponent as DownloadIcon } from 'icons/download.svg';
import { useTranslation } from 'react-i18next';

const playbookLink = 'https://phoenix.anghcdn.co/artists/Artist-Playbook-web.pdf';
function DownloadPlaybook() {
  const { t } = useTranslation();
  return (
    <div className={styles.sectionContainer}>
      <div
        className={combineClassNames(
          'd-flex',
          'f-column',
          'f-justify-center',
          'f-align-center',
          styles.sectionContent
        )}
      >
        <div className={styles.sectionTitle}>{`${t(
          'creators.landing.section4.tips.title',
          'Tips & Tricks and everything in between'
        )}`}</div>

        <p>{`${t(
          'creators.landing.section4.tips.description',
          'Download the Artist Playbook and discover the full guide to your journey from launching content to promoting it and gaining market insights.'
        )}`}</p>
        <a href={playbookLink} download target="_blank" rel="noreferrer">
          <Button
            label={`${t(
              'creators.landing.section4.tips.playbookdownload.cta',
              'Download Playbook'
            )}`}
            withBackground="#FFFFFF"
            customClasses={[styles.downloadButton]}
            icon={<DownloadIcon width={20} />}
          />
        </a>
      </div>
    </div>
  );
}

export default DownloadPlaybook;
