interface IAPIError {
  _attributes?: { message?: string; code?: number };
  message?: string;
  code?: number;
}

export const getErrorMessage = (error: IAPIError, defaultMessage = '') => {
  if (!error) return defaultMessage;
  const err = error._attributes ? error._attributes.message : error.message;
  if (err?.trim().length > 1) {
    return err;
  }
  return defaultMessage;
};

export const getErrorCode = (error: IAPIError) =>
  !error ? 0 : error._attributes ? Number(error._attributes.code) : Number(error.code);
