import { IGetManagePodcastsItem } from '@anghami/neogateway/dist/endpoints/getManagePodcasts';
import { toastContext } from 'components/contexts/toast/toast.context';
import { GET_RSS_SERVERS } from 'constants/reactQueryKeys';
import { getErrorMessage } from 'helpers/api-helper';
import managePodcasts from 'helpers/manage-podcasts-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

type PartialIGetManagePodcastsItem = Partial<IGetManagePodcastsItem>;

export default function useManagePodcasts({
  onSettledCallback
}: {
  onSettledCallback?: () => void;
}) {
  const { t } = useTranslation();
  const { openToast } = React.useContext(toastContext);
  const queryClient = useQueryClient();
  const refetchMainQuery = () => {
    queryClient.refetchQueries([GET_RSS_SERVERS]);
  };
  const deactivateMutation = useMutation(
    ({ rssItem, reason }: { rssItem: PartialIGetManagePodcastsItem; reason: string }) => {
      return managePodcasts.deactivate(rssItem.id, reason);
    },
    {
      onSettled: (data, error, { rssItem }) => {
        onSettledCallback();
        if (error || data.error) {
          const errorMsg = getErrorMessage(
            data.error,
            t('could-not-deactivate-rss-feed-url-for-please-try-again').replace('%@', rssItem.name)
          );
          openToast(errorMsg, 'error');
          return;
        }
        openToast(
          (data.response as any) ||
            `[${rssItem.id}] ${rssItem.name} ${t('succefully-deactivated')}`,
          'success'
        );
        refetchMainQuery();
      }
    }
  );
  const activateMutation = useMutation(
    ({ rssItem }: { rssItem: PartialIGetManagePodcastsItem }) => {
      return managePodcasts.activate(rssItem.id);
    },
    {
      onSettled: (data, error, { rssItem }) => {
        onSettledCallback();
        if (error || data.error) {
          const errorMsg = getErrorMessage(
            data.error,
            t('could-not-activate-rss-feed-url-for-please-try-again').replace('%@', rssItem.name)
          );
          openToast(errorMsg, 'error');
          return;
        }
        openToast(
          (data.response as any) || `[${rssItem.id}] ${rssItem.name} ${t('succefully-activated')}`,
          'success'
        );
        refetchMainQuery();
      }
    }
  );
  const editMutation = useMutation(
    ({ rssItem, newUrl }: { rssItem: PartialIGetManagePodcastsItem; newUrl: string }) => {
      return managePodcasts.updateRSSFeedURL(rssItem.id, newUrl, rssItem.name);
    },
    {
      onSettled: (data, error, { rssItem, newUrl }) => {
        onSettledCallback();
        if (data.error || error) {
          const errorMsg = getErrorMessage(
            data.error,
            t('could-not-update-rss-feed-url-for-please-try-again').replace('%@', rssItem.name)
          );
          openToast(errorMsg, 'error');
          return;
        }
        openToast('[' + rssItem.id + '] ' + rssItem.name + t('succefully-updated'), 'success');
        rssItem.url = newUrl;
        refetchMainQuery();
      }
    }
  );
  const refetchMutation = useMutation(
    ({ rssItem }: { rssItem: PartialIGetManagePodcastsItem }) => {
      return managePodcasts.fetch(rssItem.id);
    },
    {
      onSettled: (data, error, { rssItem }) => {
        onSettledCallback();
        if (error || data.error) {
          const errorMsg = getErrorMessage(
            data.error,
            t('could-not-refetch-rss-feed-url-for-please-try-again').replace('%@', rssItem.name)
          );
          openToast(errorMsg, 'error');
          return;
        }
        openToast(
          (data.response as any) || `[${rssItem.id}] ${rssItem.name} ${t('succefully-refetched')}`,
          'success'
        );
        refetchMainQuery();
      }
    }
  );

  return {
    deactivateMutation,
    activateMutation,
    editMutation,
    refetchMutation
  };
}
