/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import Button from 'components/Reusable/Button';
import { combineClassNames } from 'helpers/styling-helper';
import { useTranslation } from 'react-i18next';
import styles from './videoUpload.module.scss';
import DatePicker from 'components/Reusable/CustomAntdCompnents/DatePicker';

const UploadedVideo = ({
  changeVideo,
  src,
  videoRef,
  isImage,
  releaseDate,
  setReleaseDate,
  isPreview
}: {
  changeVideo: () => void;
  src: string;
  videoRef: any;
  name: string;
  isImage?: boolean;
  releaseDate: Date;
  setReleaseDate: (date: Date) => void;
  isPreview?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <div className="w-100 h-100 m-1">
      <div className={combineClassNames('d-flex f-justify-between', styles.uploadedPreview)}>
        <div className="d-flex f-align-center gap-1">
          <div className={combineClassNames('f-center', styles.videoThumbnailContainer)}>
            {isImage ? (
              <img src={src} alt="video thumbnail" className={styles.videoThumb} />
            ) : (
              <video className={styles.videoThumb} ref={videoRef} src={src}></video>
            )}
          </div>
          <div>
            <div>
              <span className={styles.label}>{t('Release Date')}</span>
              <DatePicker
                value={releaseDate}
                onChange={setReleaseDate}
                showTime
                disabled={isPreview}
              />
            </div>
          </div>
        </div>
        <Button
          label={t('Change')}
          customClasses={['default', styles.changeVideButton]}
          size="md"
          onSubmit={changeVideo}
        ></Button>
      </div>
    </div>
  );
};

export default UploadedVideo;
