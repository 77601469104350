import React from 'react';
import styles from './songObject.module.scss';
import { combineClassNames } from 'helpers/styling-helper';
import { IUploadedFile } from 'interfaces/formsComponents.interface';
import EditButton from '../EditButton';
import { environment } from 'env/environment';
import { useTranslation } from 'react-i18next';
interface ISongObjectProps {
  songInfo: {
    uploadedCoverArt: IUploadedFile;
    name: string;
    artist: string;
    releaseDate: Date;
  };
  onEdit: () => void;
}
export default function SongObject({ songInfo, onEdit }: ISongObjectProps) {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={combineClassNames('d-flex', styles.sub_container)}>
        <img
          src={
            songInfo?.uploadedCoverArt?.src ||
            `${environment.artwork_cdn}/webp/?id=24226759&size=296`
          }
          alt="song-cover"
        />
        <div>
          <span className={styles.title}>{songInfo?.name || 'Song name not set'}</span>
          <span className={styles.artist}>{songInfo?.artist || 'Artist name not set'}</span>
          <span className={combineClassNames(styles.releasedate, 'bold')}>{t('Release date')}</span>
          <span className={styles.releasedate}>
            {songInfo?.releaseDate ? songInfo?.releaseDate?.toDateString() : 'Date is not set'}
          </span>
        </div>
      </div>
      <EditButton onClick={onEdit} />
    </div>
  );
}
