import { formatDecimal, formatCurrency } from './../../../helpers/numbers-helper';
import { formatPercentage } from 'helpers/numbers-helper';
import { TablesTypes } from '../interfaces';
import i18n from 'i18next';
const { t } = i18n;

export const keysMap: {
  key: string;
  formatFunc: (num: number, currency?: string) => string;
  label: (type?: TablesTypes) => string;
}[] = [
  { key: 'all_streams', formatFunc: formatDecimal, label: () => t('All streams') },
  { key: 'label_streams', formatFunc: formatDecimal, label: () => t('Account total plays') },
  { key: 'market_share', formatFunc: formatPercentage, label: () => t('Market share') },
  { key: 'monthly_users', formatFunc: formatDecimal, label: () => t('Monthly Users') },
  { key: 'net_revenue', formatFunc: formatCurrency, label: () => t('Ads revenue') },
  {
    key: 'owner_gross_revenue',
    formatFunc: formatCurrency,
    label: (type: TablesTypes) =>
      type === TablesTypes.telco || type === TablesTypes.premium
        ? t('Account pro-rata share of subscriptions revenue')
        : t('Account pro-rata share of ads revenue')
  },
  { key: 'owner_net_revenue', formatFunc: formatCurrency, label: () => t('Account net revenue') },
  { key: 'owner_share', formatFunc: formatPercentage, label: () => t('Account share') },
  { key: 'publishing_rights', formatFunc: formatCurrency, label: () => t('Publishing rights') },
  { key: 'revenue_pool', formatFunc: formatCurrency, label: () => t('Revenue Pool') }
];

export const columnCellsWidth = {
  label_cell: '1rem',
  number_cell: '1rem'
};

export const tableTypeTitleMap = {
  [TablesTypes.free]: t('Freemium Tier'),
  [TablesTypes.premium]: t('Premium Tier'),
  [TablesTypes.telco]: t('TELCO TIER'),
  [TablesTypes.total]: t('Total by month')
};

export const API_DATE_FORMAT = 'yyyy-MM-dd';
export const DISPLAY_DATE_FORMAT = 'yyyy/MM';
