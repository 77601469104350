import React from 'react';
import { getCountryCallingCode } from 'libphonenumber-js';
import { getCountry } from 'helpers/http-helper';
import TextInput from '../TextInput';
import styles from './phoneWithCountry.module.scss';
import WithLabelWrapper from '../WithLabelWrapper';

export interface IPhoneWithCountryValue {
  country: string;
  phone: string;
  fullPhone: string;
}

interface IPhoneWithCountryProps {
  value: IPhoneWithCountryValue;
  onChange: (val: IPhoneWithCountryValue) => void;
  placeHolder?: string;
  customClasses?: {
    country?: string;
    phone?: string;
  };
  name?: string;
  error?: string;
  label?: string;
}

const COUNTRY_CODE_REGEX = new RegExp(/^\+[0-9]{0,3}$/);
const PHONE_REGEX = new RegExp(/^[0-9]{1,15}$/);

const PhoneWithCountry = ({
  value,
  onChange,
  placeHolder,
  customClasses,
  name,
  error,
  label
}: IPhoneWithCountryProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(() => {
    if (value?.country) return;
    setIsLoading(true);
    const handleCountry = (country: any) => {
      const countryCode = `+${getCountryCallingCode(country)}`;
      onChange({
        ...value,
        country: countryCode,
        fullPhone: countryCode + value?.phone
      });
      setIsLoading(false);
      return;
    };
    getCountry(handleCountry, () => {
      setIsLoading(false);
    });
  }, []);

  const handleCountryCode = (e: React.ChangeEvent) => {
    const country = (e.target as HTMLInputElement).value;
    if (country && !COUNTRY_CODE_REGEX.test(country)) return false;
    onChange({
      ...value,
      country,
      fullPhone: country + value?.phone
    });
  };

  const handlePhoneChange = (e: React.ChangeEvent) => {
    const phone = (e.target as HTMLInputElement).value;
    if (phone && !PHONE_REGEX.test(phone)) return false;
    onChange({
      ...value,
      phone,
      fullPhone: value?.country + phone
    });
  };

  return (
    <WithLabelWrapper label={label}>
      <div className="d-flex f-align-center">
        <div className={styles.countryInput}>
          <TextInput
            placeholder={value?.country || '+000'}
            onChange={handleCountryCode}
            name="countryCode"
            error={error}
            autoComplete="off"
            id="telcountrycode"
            value={value?.country}
            disabled={isLoading}
            customInputClass={customClasses?.country}
          />
        </div>
        <div className={styles.numberInput}>
          <TextInput
            id="telnational"
            placeholder={placeHolder || '000 000'}
            onChange={handlePhoneChange}
            name={name}
            error={error}
            autoComplete="off"
            value={value?.phone}
            customInputClass={customClasses?.phone}
          />
        </div>
      </div>
    </WithLabelWrapper>
  );
};

export default PhoneWithCountry;
