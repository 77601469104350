import Button from 'components/Reusable/Button';
import { CircleLoader } from 'components/Reusable/Loaders';
import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './editLibraryReleaseDialog.module.scss';

const FullScreenWrapper = ({ children }) => (
  <div className="d-flex f-align-center f-justify-center w-100 h-100">{children}</div>
);

export const LoaderFullScreen = () => (
  <div className="d-flex f-align-center f-justify-center w-100 h-100">
    <CircleLoader />
  </div>
);

export function PendingRequestDiscard({ isLoading, data, isDiscarded, discardRequest, children }) {
  const { t } = useTranslation('common');
  return (
    <div>
      {isLoading ? (
        <FullScreenWrapper>
          <CircleLoader />
        </FullScreenWrapper>
      ) : data && !isDiscarded ? (
        <FullScreenWrapper>
          <div
            className={combineClassNames(
              'f-column f-align-center text-align-center',
              styles.pending_request_discard
            )}
          >
            <span className="d-block">{t('You already have an existing request')}</span>
            <span className="d-block">
              {t('Do you want to discard it and send a new request?')}
            </span>
            <Button
              customClasses={[styles.discard_button]}
              size="sm"
              label={t('Discard')}
              onSubmit={discardRequest}
            />
          </div>
        </FullScreenWrapper>
      ) : (
        children
      )}
    </div>
  );
}
