import React from 'react';
import { getCountryCallingCode } from 'libphonenumber-js';
import { getCountry } from 'helpers/http-helper';
import styles from './formField.module.scss';
import { combineClassNames } from 'helpers/styling-helper';

interface IProps {
  error?: any;
  name?: string;
  change?: any;
  blur?: any;
  value?: {
    phone: string;
    country: string;
  };
}

export default function PhoneInput({ value, error, change, blur, name }: IProps) {
  const [countryCode, setCountryCode] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [fullPhoneNumber, setFullPhoneNumber] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (value?.country) {
      setCountryCode(value.country);
      setPhone(value.phone);
      return;
    }
    const handleCountry = (country: any) => {
      setCountryCode(getCountryCallingCode(country));
      setPhone(value.phone || '');
      setLoading(false);
      return;
    };
    // TODO: make autocmplete work right
    getCountry(handleCountry, () => {
      setCountryCode('');
      setLoading(false);
    });
  }, [value]);

  React.useEffect(() => {
    change({
      country: countryCode,
      phone: phone,
      fullPhone: fullPhoneNumber
    });
  }, [fullPhoneNumber]);

  React.useEffect(() => {
    setFullPhoneNumber(countryCode + phone);
  }, [countryCode, phone]);

  const handleBlur = () => {
    blur({
      target: {
        value: fullPhoneNumber
      }
    });
  };

  return (
    <div className="d-flex">
      <input
        placeholder={countryCode}
        onChange={(e) => setCountryCode(e.target.value)}
        name="countryCode"
        onBlur={handleBlur}
        autoComplete="off"
        id="telcountrycode"
        value={countryCode}
        disabled={loading}
        className={combineClassNames(styles.customTextInput, styles.small, error && styles.error)}
      />
      <input
        id="telnational"
        placeholder="000 000"
        onChange={(e) => setPhone(e.target.value)}
        onBlur={handleBlur}
        name={name}
        autoComplete="off"
        value={phone}
        className={combineClassNames(styles.customTextInput, error && styles.error)}
      />
    </div>
  );
}
