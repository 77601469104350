import React from 'react';
import { useTranslation } from 'react-i18next';
import colors from 'styles/partials/_colors_exports.module.scss';
import { ReactComponent as PendingIcon } from 'icons/status-pending.svg';
import { ReactComponent as FailedIcon } from 'icons/status-failed.svg';
import { ReactComponent as SucceededIcon } from 'icons/status-succeed.svg';
import { combineClassNames } from 'helpers/styling-helper';
import styles from './videoUpload.module.scss';
import { VIDEO_STATUS } from '@anghami/neogateway/dist/endpoints/getUserLibrary';

const getVideoStatusObject = (status: VIDEO_STATUS, t: (s: string) => string) => {
  const orange = {
    color: colors['orange'],
    icon: PendingIcon
  };
  const red = {
    color: colors['failure-color'],
    icon: FailedIcon
  };
  const green = {
    color: colors['success-color'],
    icon: SucceededIcon
  };
  const statusMapping: Record<
    VIDEO_STATUS,
    {
      color: string;
      icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
      label: string;
    }
  > = {
    [VIDEO_STATUS.PRENDING_APPROVAL]: { ...orange, label: t('Pending Approval') },
    [VIDEO_STATUS.PROCESSING]: { ...orange, label: t('Processing') },
    [VIDEO_STATUS.LIVE]: { ...green, label: t('Live') },
    [VIDEO_STATUS.TAKEN_DOWN]: { ...red, label: t('Taken Down') },
    [VIDEO_STATUS.PROCESSING_ERROR]: { ...red, label: t('Facing Processing Error') },
    [VIDEO_STATUS.REJECTED]: { ...red, label: t('Rejected') }
  };
  return statusMapping[status];
};
export default function VideoStatus({ status }: { status: VIDEO_STATUS }) {
  const { t } = useTranslation();
  const statusObject = getVideoStatusObject(status, t);
  if (!statusObject) return <></>;
  return (
    <div className={combineClassNames('d-flex f-align-center gap-05', styles.videoStatus)}>
      <statusObject.icon className={styles.icon} />
      <span className="text-ellipsis" style={{ color: statusObject.color }}>
        {t('music video is').replace('%@', statusObject.label)}
      </span>
    </div>
  );
}
