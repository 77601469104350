import { PlusOutlined } from '@ant-design/icons';
import Button from 'components/Reusable/Button';
import useUserConditions from 'hooks/useUserConditions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet } from 'react-router-dom';

export default function RSSFeedsPage() {
  const { canCreateRelease } = useUserConditions();
  const { t } = useTranslation();
  return (
    <>
      <div className="p-2">
        <div className="w-100 d-flex f-align-center f-justify-between gap-05">
          <h1>{t('RSS Feeds')}</h1>
          {canCreateRelease && (
            <Link to={'/create/show/rss'}>
              <Button
                label={t('Add Rss feed')}
                icon={<PlusOutlined />}
                customClasses={['purpleFilled']}
              />
            </Link>
          )}
        </div>
      </div>
      <Outlet />
    </>
  );
}
