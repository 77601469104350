import React from 'react';
import { Skeleton } from 'antd';
import { getCoverArtImage } from 'helpers/releases-helper';
import { combineClassNames } from 'helpers/styling-helper';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import styles from './analyticsArtistHeader.module.scss';
import { ReactComponent as ArrowIcon } from 'icons/arrow-left.svg';
import { useTranslation } from 'react-i18next';
import { getArtistProfileEndpoint } from 'helpers/http-helper';

export default function AnalyticsArtistHeader({ id }) {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { data, isLoading, error } = useQuery([id], async () => {
    const { data } = await getArtistProfileEndpoint(id);
    return data;
  });
  if (isLoading) {
    return (
      <div className="d-flex f-align-center gap-1">
        <Skeleton.Avatar
          active={true}
          style={{ width: '4rem', height: '4rem', borderRadius: '6px' }}
        />
        <Skeleton.Input active={true} size="large" />
      </div>
    );
  }

  if (!data || error) {
    return <></>;
  }

  return (
    <div className={combineClassNames('d-flex f-align-center gap-1', styles.container)}>
      <div
        className={combineClassNames('f-center', styles.backButton)}
        onClick={() => navigate(-1)}
      >
        <ArrowIcon width={15} height={15} />
      </div>
      <img src={getCoverArtImage(String(data?.ArtistArt))} alt="coverart" />
      <div className="f-column">
        <h3>{t('release analytics').replace('%@', data?.name)}</h3>
      </div>
    </div>
  );
}
