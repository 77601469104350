import { IGetManagePodcastsItem } from '@anghami/neogateway/dist/endpoints/getManagePodcasts';
import { TableColumnType } from 'components/Library/Columns/tabledatatype';
import DataTable from 'components/Reusable/Table';
import { toastContext } from 'components/contexts/toast/toast.context';
import { GET_RSS_SERVERS } from 'constants/reactQueryKeys';
import managePodcasts from 'helpers/manage-podcasts-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import {
  RssActionsCell,
  RssCategoriesCell,
  RssEpisodesCell,
  RssReleaseInfoCell
} from '../RssTableCells';
import { StatusTableCell } from 'components/Library/Cells';
import { getErrorCode, getErrorMessage } from 'helpers/api-helper';
import { ALBUM_IMAGE_PLACEHOLDER } from 'constants/releases.constants';
import RssActiveFilter from '../RssActiveFilter';
import Empty from 'antd/es/empty';
import styles from './rssTables.module.scss';

export const columns = (t: any, isActive = true): TableColumnType[] => [
  {
    title: t('Release Info'),
    width: '16rem',
    key: '0',
    fixed: 'left',
    dataIndex: 'name',
    render: (name: string, data: IGetManagePodcastsItem) => (
      <RssReleaseInfoCell
        name={name}
        description={(data as any).description}
        coverart={(data as any).image}
        imagePlaceHolder={ALBUM_IMAGE_PLACEHOLDER}
      />
    )
  },
  {
    title: t('Categories'),
    width: '12rem',
    dataIndex: 'category',
    key: '7',
    render: (value: string) => {
      return <RssCategoriesCell categories={value} />;
    }
  },
  {
    title: t('Status'),
    width: '8rem',
    dataIndex: 'status',
    key: '1',
    render: (value: string) => <StatusTableCell status={value} />
  },
  {
    title: t('Episodes'),
    width: '8rem',
    dataIndex: 'filescount',
    key: '1',
    render: (value: string, data: IGetManagePodcastsItem) => (
      <RssEpisodesCell feedId={data.id} episodesCount={value} feedName={data.name} />
    )
  },
  {
    title: t('Last fetched'),
    width: '10rem',
    dataIndex: 'lastfetched',
    key: '2',
    render: (value: string) => <div>{value}</div>
  },
  {
    title: t('Actions'),
    width: '12rem',
    dataIndex: 'added_on',
    key: '3',
    render: (_: string, data: IGetManagePodcastsItem) => (
      <RssActionsCell rssItem={data} isActive={isActive} />
    )
  }
];

export default function RSSFeedTable() {
  const [activeFilterValue, setActiveFilterValue] = React.useState<1 | 0>(1);
  const { openToast } = React.useContext(toastContext);
  const { t } = useTranslation();
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery(
    [GET_RSS_SERVERS, activeFilterValue],
    async ({ pageParam = 1 }) => {
      const data = await managePodcasts.getServers(activeFilterValue, pageParam);
      if ((!data || data.error) && pageParam === 1 && getErrorCode(data.error) !== 108) {
        openToast(getErrorMessage(data?.error, t('failed to fetch rss feed')), 'error');
        return [];
      }
      return data.response || [];
    },
    {
      getNextPageParam: (lastPage, allPages) =>
        !lastPage || lastPage.length === 0 ? undefined : allPages.length + 1,
      keepPreviousData: true
    }
  );
  return (
    <>
      <div className="d-flex f-align-center gap-05 mb-1 px-2">
        <span>{t('Filter')}</span>
        <RssActiveFilter
          selectedFilter={activeFilterValue}
          setSelectedFilter={setActiveFilterValue}
        />
      </div>
      <div className={styles.tableContainer}>
        <DataTable
          isLoading={isLoading}
          data={data?.pages?.flat() || []}
          tableColumns={columns(t, !!activeFilterValue)}
          rowSelection={false}
          rowKey={'id'}
          onFetch={hasNextPage && fetchNextPage}
          isInfiniteLoading={isFetchingNextPage}
          className="rss-custom-table"
          locale={{
            emptyText: isLoading ? (
              t('Fetching Data')
            ) : (
              <Empty description={t('No RSS servers found')} />
            )
          }}
        />
      </div>
    </>
  );
}
