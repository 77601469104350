import React from 'react';
import { gateway } from 'helpers/gateway-helper';
import getGenres from '@anghami/neogateway/dist/endpoints/getGenres';
import DropDown from 'components/Reusable/DropDown';
import { IDropDownProps } from 'components/Reusable/DropDown/dropDown';
import { useQuery } from 'react-query';

interface IGenreSelectProps extends IDropDownProps {
  languageid?: 0 | 1 | 2;
}

export default function GenreSelect(props: IGenreSelectProps) {
  const { data } = useQuery(
    ['genreDropDown', props.languageid || 1],
    async () => {
      const { data } = await gateway.callEndpoint(getGenres, {
        langid: props.languageid || 1
      });
      if (data?.genres?.length > 0) {
        return data?.genres.map((genre) => ({ label: genre, value: genre }));
      } else {
        throw new Error('Something went wrong, please try again');
      }
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: 3,
      staleTime: Infinity
    }
  );

  return <DropDown {...props} options={data || []} />;
}
