import React from 'react';
import FormField from 'components/Landing/CreateFormField/formField';
import {
  ValidateEmail,
  ValidatePhoneNumber,
  ValidateRegisterFormFirstStep,
  ValidateRegisterFormSecondStep
} from 'helpers/form-validator-helper';
import {
  USER_CLICKED_BUTTON,
  USER_CREATE_ACCOUNT_FAIL,
  USER_CREATE_ACCOUNT_SUCCESS
} from 'constants/amplitudeEvents';
import { useTranslation } from 'react-i18next';
import { logAmplitudeEvent } from 'helpers/analytics-helper';
import { gateway } from 'helpers/gateway-helper';
import { postRegisterAttempt, registerUser, updateProfile } from 'helpers/http-helper';
import {
  ICreateAccountState,
  IFormInforType,
  IRegistrationForm
} from 'components/Landing/landing.interface';
import message from 'antd/es/message';
import getEmailExists from '@anghami/neogateway/dist/endpoints/getEmailExists';
import { IArtist } from 'interfaces/artist.interface';
import { getSignupForm } from 'components/Landing/frames/createAccountForm';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import styles from './createAccount.module.scss';
import Button from 'components/Reusable/Button';
import { ReactComponent as BackArrow } from 'icons/arrow-left.svg';
import commonStyles from 'components/Landing/frames/common.module.scss';
import { ReactComponent as ArrowIcon } from 'icons/back-arrow.svg';
import { combineClassNames } from 'helpers/styling-helper';
import ArtistItem from 'components/Landing/ArtistItem';
import { getErrorMessage } from 'helpers/api-helper';

const checkEmailExist = async (email: string) => {
  return gateway.callEndpoint(getEmailExists, { email });
};

const scope = 'app.containers.HomePage';

// autocomplete tags https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#attr-fe-autocomplete-tel

const CreateAccount = () => {
  const [error, setError] = React.useState<ICreateAccountState['error']>({});
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [form, setForm] = React.useState<ICreateAccountState['form']>({});
  const [currentScreen, setCurrentScreen] = React.useState<0 | 1>(0);
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(false);
  const [claimedArtist, setClaimedArtist] = React.useState<ICreateAccountState['claimedArtist']>();
  const { t } = useTranslation('common');
  const formInfo = getSignupForm(t);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const artistIdQP = searchParams.get('claim');

  // Error messages translations
  const errorMessages = {
    fieldrequired: t(`${scope}.form.fieldRequired`),
    passwordsdontmatch: t(`${scope}.form.passwordMisMatch`),
    emailinvalid: t(`${scope}.form.emailInvalid`),
    mobileinvalid: t(`${scope}.form.mobileInvalid`)
  };

  React.useEffect(() => {
    const catchEnterClick = (e: KeyboardEvent) => {
      if (e.key !== 'Enter') return true;
      e.preventDefault();
      handleNextClick();
      return false;
    };
    window.addEventListener('keypress', catchEnterClick);
    return () => window.removeEventListener('keypress', catchEnterClick);
  }, []);

  React.useEffect(() => {
    if (!artistIdQP) return;
    const artist: IArtist = {
      id: Number(artistIdQP),
      ArtistArt: searchParams.get('ArtistArt'),
      name: searchParams.get('name'),
      is_podcaster: Boolean(Number(searchParams.get('is_podcaster')))
    };
    setClaimedArtist(artist);
    setForm({
      ...form,
      usertype: Number(artist?.is_podcaster) ? 5 : 2,
      artistname: artist.name,
      musiclanguage: 2,
      artistId: String(artist.id)
    });
  }, [artistIdQP]);

  const valueChange = (key: string, value: any) => {
    const newForm = { ...form };
    // Handle Phone number validation and value

    newForm[key] = key !== formInfo.phoneNumber.name && value?.target ? value.target.value : value;
    setForm(newForm);
  };

  const checkIfEmailExists = async (email: string) => {
    const { data } = await checkEmailExist(email);
    if (!data || data.error) {
      error[formInfo.email.name] = getErrorMessage(data.error, '');
      setError(error);
      return;
    }
    if (data.exists) {
      error[formInfo.email.name] = t(`${scope}.form.emailExists`);
      setError(error);
    }
  };

  const inputBlur = (key: string, { target }: React.ChangeEvent<HTMLInputElement>) => {
    if (!target?.value) return;
    if (target.value.length <= 2) return;
    // verify email if exists
    if (key === formInfo.email.name) {
      const emailinvalid = t(`${scope}.form.emailInvalid`);
      const email = String(target.value)?.trim();
      const isEmailValid = ValidateEmail(email);
      if (!isEmailValid) {
        error[formInfo.email.name] = emailinvalid;
        setError(error);
        return;
      }
      error[formInfo.email.name] = null;
      checkIfEmailExists(email);
      return;
    }
    if (key === formInfo.phoneNumber.name) {
      const mobileinvalid = t(`${scope}.form.mobileInvalid`);
      const valid = ValidatePhoneNumber(form[formInfo.phoneNumber.name]);
      error[formInfo.phoneNumber.name] = !valid ? mobileinvalid : null;
      setError({ ...error });
    }
  };

  const handleFirstNext = () => {
    logAmplitudeEvent(USER_CLICKED_BUTTON, {
      page: 'sign-up',
      button: 'next'
    });
    const { error, errorExistFirstStep } = ValidateRegisterFormFirstStep(
      form,
      formInfo,
      logAmplitudeEvent,
      errorMessages.fieldrequired
    );
    setError(error);
    // If no error found move to next page
    if (errorExistFirstStep) return;
    setCurrentScreen(1);
  };

  const handleSecondNext = () => {
    setIsSubmitDisabled(true);
    logAmplitudeEvent(USER_CLICKED_BUTTON, {
      page: 'sign-up',
      button: 'done'
    });
    const { error: validationError, errorExistSecondStep } = ValidateRegisterFormSecondStep(
      form,
      formInfo,
      error,
      errorMessages
    );
    setError(validationError);
    if (errorExistSecondStep) {
      setIsSubmitDisabled(false);
      return;
    }
    const params = {
      ...form,
      countrycode: form.phone?.country,
      phoneNumber: form.phone?.phone,
      artistId: claimedArtist ? String(claimedArtist.id) : null
    };
    setForm(params);
    postRegisterAttempt(params);
    register(params);
  };

  const handleNextClick = async () => {
    if (isSubmitDisabled) return;
    setErrorMessage(null);
    if (currentScreen === 0) {
      handleFirstNext();
      return;
    }
    handleSecondNext();
  };

  const returnUserTypeCustomForm = (value: number) => {
    switch (value) {
      // artist
      case 2:
        return (
          <>
            <div className="d-flex">
              <FormField
                info={formInfo.musicLanguage}
                value={form.musiclanguage}
                error={error[formInfo.musicLanguage.name]}
                onChange={valueChange.bind(this, formInfo.musicLanguage.name)}
              />
            </div>
            {returnArtistNameForm(t(`${scope}.form.userType.artist`))}
          </>
        );
      // label
      case 3:
        return (
          <>
            {returnArtistNameForm(t(`${scope}.form.userType.labelAndAggregator`))}
            <FormField {...returnFormField(formInfo.isExistingLabel)} />
          </>
        );
      // podcaster
      case 5:
        return returnArtistNameForm(t(`${scope}.form.userType.podcaster`));
      default:
        return null;
    }
  };

  const returnFormField = (fieldInfo: IFormInforType) => ({
    info: fieldInfo,
    value: form[fieldInfo.name],
    error: t(error[fieldInfo.name]),
    onChange: (e: any) => valueChange(fieldInfo.name, e),
    onBlur: (e: any) => inputBlur(fieldInfo.name, e)
  });

  const returnArtistNameForm = (userTypeName: string) => {
    if (form?.usertype === 2 && form.musiclanguage === 1) {
      return (
        <div className={styles.customDiv}>
          <FormField {...returnFormField(formInfo.artistName)} />
          <FormField {...returnFormField(formInfo.artistNameAr)} />
        </div>
      );
    }
    const getFormattedProps = () => ({
      ...formInfo.artistName,
      label: String(t(`${scope}.form.customUserName`)).replace('{usertype}', userTypeName),
      data: {
        placeholder: t(`${scope}.form.inputPlaceHolder`).replace(
          '{label}',
          String(t(`${scope}.form.customUserName`)).replace('{usertype}', userTypeName)
        )
      }
    });
    return (
      <div className="d-flex">
        <FormField {...returnFormField(getFormattedProps())} />
      </div>
    );
  };

  const handleBackPress = () => {
    if (currentScreen === 1) {
      setCurrentScreen(0);
      return;
    }
    if (claimedArtist?.id) {
      navigate(`/claim`);
    }
    navigate(-1);
  };

  const handleError = (error: string) => {
    setIsSubmitDisabled(false);
    setErrorMessage(error);
    showErrorToast(error);
  };

  const register = async (signupForm: IRegistrationForm) => {
    // TODO: show loading
    try {
      const { data } = await registerUser(signupForm);
      if (!data || data.error) {
        logAmplitudeEvent(USER_CREATE_ACCOUNT_FAIL, {
          error: data?.error?.message || 'not returned',
          step: 'registeruser'
        });
        setIsSubmitDisabled(false);
        handleError(data.error.message || t('Something went wrong'));
        return;
      }
      logAmplitudeEvent(USER_CREATE_ACCOUNT_SUCCESS, {
        user: data.registerUserResult.anid,
        type: signupForm.artistId ? 'claim' : 'new',
        ...(signupForm.artistId && { artistid: signupForm.artistId })
      });
      const { data: updateProfileData } = await updateProfile(signupForm, data.registerUserResult);
      if (!updateProfileData || updateProfileData.error || !updateProfileData.response) {
        logAmplitudeEvent(USER_CREATE_ACCOUNT_FAIL, {
          error: updateProfileData?.error?.message || 'not returned',
          step: 'updateprofile'
        });
        const error = getErrorMessage(
          updateProfileData?.error,
          t('Unable to Signup please try again!')
        );
        handleError(error);
        return;
      }
      // success, redirect to email verification page
      navigate('/verify-email', {
        state: { email: signupForm.email, isEmailSentBeforeRedirect: true }
      });
    } catch (err: any) {
      logAmplitudeEvent(USER_CREATE_ACCOUNT_FAIL, {
        error: err?.message || 'not returned',
        step: 'registeruser-catch'
      });
      handleError(t('Unable to Signup please try again!'));
    }
  };

  const showErrorToast = (error) => {
    message.error(
      {
        type: 'error',
        content: error,
        className: 'custom-class',
        style: {
          marginTop: '1em'
        }
      },
      5
    );
  };

  return (
    <div className="w-100 h-100 p-2 d-flex f-column f-justify-between">
      <div className="d-flex f-justify-end">
        <Link to={'/claim'}>
          <Button
            label={t('Claim your profile')}
            customClasses={[commonStyles.landingTopButton]}
          ></Button>
        </Link>
      </div>
      <div className="f-column f-justify-center">
        <h1 className={commonStyles.landingHeader} style={{ marginBottom: '0.1em' }}>
          {(!!claimedArtist?.id || currentScreen === 1) && (
            <BackArrow className={commonStyles.backIcon} onClick={handleBackPress} />
          )}
          {t(`${scope}.form.createAnAccount`)}
        </h1>
        <span className={styles.subHeaderSpan}>
          {t(`${scope}.form.formStep`)} {(currentScreen || 0) + 1}/2
        </span>
        {errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
        <form autoComplete="off">
          {currentScreen === 0 ? (
            <>
              {claimedArtist?.id ? (
                <ArtistItem {...claimedArtist} half selectArtist={() => navigate('/claim')} />
              ) : null}
              <div className="d-flex">
                <FormField {...returnFormField(formInfo?.userType)} />
              </div>
              {returnUserTypeCustomForm(form?.usertype || 2)}
            </>
          ) : (
            <>
              <div className={styles.customDiv}>
                <FormField {...returnFormField(formInfo.firstName)} />
                <FormField {...returnFormField(formInfo.lastName)} />
              </div>
              <div className={styles.customDiv}>
                <FormField {...returnFormField(formInfo.email)} />
                <FormField {...returnFormField(formInfo.phoneNumber)} />
              </div>
              <div className={styles.customDiv}>
                <FormField {...returnFormField(formInfo.password)} />
                <FormField {...returnFormField(formInfo.confirmPassword)} />
              </div>
            </>
          )}
          <Button
            disabled={isSubmitDisabled}
            customClasses={[styles.customButton]}
            onSubmit={handleNextClick}
            icon={<ArrowIcon className={styles.arrowIcon} width={15} height={15} />}
            label={currentScreen === 0 ? t(`${scope}.form.next`) : t(`${scope}.form.done`)}
          />
        </form>
      </div>
      <div
        className={combineClassNames('d-flex f-align-center gap-1', commonStyles.alternativeFooter)}
      >
        <span>{t('Already have any account')}</span>
        <Link to={'/login'}>
          <Button label={t('Login instead')} customClasses={[commonStyles.ctaButton]} />
        </Link>
      </div>
    </div>
  );
};

export default CreateAccount;
