import { combineClassNames } from 'helpers/styling-helper';
import React, { ReactElement } from 'react';
import common from '../common.module.scss';
import styles from './multiSelect.module.scss';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as DropDownIcon } from 'icons/dropdown.svg';
import WithLabelWrapper from '../WithLabelWrapper';
import colors from 'styles/partials/_colors_exports.module.scss';
import { IOption } from 'interfaces/formsComponents.interface';
import { IInputWithLabel } from 'interfaces/formsComponents.interface';
import { useTranslation } from 'react-i18next';
import useCloseOnScrollAndClickOutside from 'hooks/useCloseOnScrollAndClickOutside';

export interface IMultiSelectProps extends Omit<IInputWithLabel, 'value' | 'onChange'> {
  customClassName?: string;
  options?: IOption[];
  onChange: (value: IOption[]) => void;
  placeholder?: string;
  value?: IOption[];
  error?: string;
  disabled?: boolean;
  defaultValue?: string[];
  allowClear?: boolean;
  customInputClass?: string;
  hideArrow?: boolean;
  customIcon?: ReactElement;
  onSearchQuery?: (query: string) => void;
  limit?: number;
}

export default function MultiSelect(props: IMultiSelectProps) {
  const {
    onChange,
    options,
    placeholder,
    value,
    error,
    customInputClass,
    disabled,
    onSearchQuery,
    hideArrow,
    limit
  } = props;
  const [selected, setSelected] = React.useState(value?.length > 0 ? [...value] : []);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [hasExceededLimit, setHasExceededLimit] = React.useState(false);
  const { t } = useTranslation('common');
  const [showingDropDown, setShowingDropDown, ref] = useCloseOnScrollAndClickOutside({
    defaultValue: false
  });
  React.useEffect(() => {
    if (onSearchQuery) {
      setIsLoading(false);
    }
  }, [options]);
  React.useEffect(() => {
    setSelected(value || []);
  }, [value]);
  const handleChange = (value: IOption[]) => {
    if (hasExceededLimit) return;
    setInputValue('');
    setSelected(value);
    onChange(value);
    if (limit) {
      setHasExceededLimit(value.length >= limit);
    }
  };
  const removeOption = (tag: IOption) => {
    if (disabled) return;
    const filtred = selected.filter((option) => option.value !== tag.value);
    setSelected(filtred);
    onChange(filtred);
    if (limit) {
      setHasExceededLimit(filtred.length >= limit);
    }
  };

  const searchOption = (option: IOption, query: string) => {
    return String(option.label + option.value)
      .toLowerCase()
      .includes(query?.toLowerCase());
  };

  const swithShowingDropDown = () => setShowingDropDown(!showingDropDown);
  return (
    <div ref={ref}>
      <WithLabelWrapper {...props}>
        <div
          className={combineClassNames(
            common.input,
            customInputClass,
            styles.multiSelect,
            disabled && common.disabled,
            error && common.error
          )}
        >
          <div className={styles.input_items_container}>
            <input
              type={'text'}
              className={combineClassNames(styles.hidden_input, disabled && styles.disabled)}
              onChange={(e) => {
                setInputValue(e.target.value);
                setShowingDropDown(true);
                if (onSearchQuery) {
                  setIsLoading(true);
                  onSearchQuery(e.target.value);
                } else {
                  setSearchQuery(e.target.value);
                }
              }}
              onClick={(e) => {
                e.stopPropagation();
                setShowingDropDown(true);
              }}
              placeholder={selected?.length === 0 ? placeholder : ''}
              disabled={disabled || hasExceededLimit}
              value={inputValue}
            ></input>
            {selected.map((option, index) => (
              <div
                key={option.value + index}
                className={combineClassNames('no-select', styles.tag, disabled && styles.disabled)}
                onClick={() => removeOption(option)}
              >
                <span>{option.label}</span>
                <CloseIcon width={10} fill={colors['dark-10']}></CloseIcon>
              </div>
            ))}
          </div>
          {!hideArrow && !hasExceededLimit && (
            <div className={styles.dropDownIcon} onClick={swithShowingDropDown}>
              <DropDownIcon
                fill={colors['dark-10']}
                className={combineClassNames(
                  styles.icon,
                  showingDropDown && options?.length !== 0 && styles.open
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  swithShowingDropDown();
                }}
              ></DropDownIcon>
            </div>
          )}
          {!hasExceededLimit && showingDropDown && (!onSearchQuery || inputValue?.length > 0) && (
            <div className={styles.selectContainer}>
              {options?.length > 0 ? (
                options
                  .filter(
                    (opt) =>
                      !selected.map((op) => op.value).includes(opt.value) &&
                      (!searchQuery || searchOption(opt, searchQuery))
                  )
                  .map((option, index) => (
                    <div
                      key={option.value + index}
                      className={styles.selectItem}
                      onClick={() => {
                        setShowingDropDown(false);
                        handleChange([option, ...selected]);
                      }}
                    >
                      {option.label}
                    </div>
                  ))
              ) : (
                <span className={styles.no_data}>
                  {isLoading ? t('Fetching Data') + '...' : t('No Results Found')}
                </span>
              )}
            </div>
          )}
        </div>
      </WithLabelWrapper>
    </div>
  );
}
