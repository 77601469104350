import React from 'react';
import styles from './customToolTip.module.scss';

const CustomToolTip = ({ active, payload }: { active?: boolean; payload?: any[] }) => {
  if (!active || !payload || payload.length === 0) return null;
  return (
    <div className={styles.tooltip}>
      <div className={styles.title}>{payload[0].payload.key}</div>
      {payload.map((p, index) => (
        <span
          className="d-block"
          key={p.payload.value + '-' + index}
          style={{ color: p.color }}
        >{`${
          isNaN(p.payload[p.dataKey])
            ? p.payload[p.dataKey]
            : new Intl.NumberFormat('en', {}).format(Number(p.payload[p.dataKey])) + ' ' + p.unit
        }`}</span>
      ))}
    </div>
  );
};

export default CustomToolTip;
