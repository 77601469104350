import React from 'react';
import HomeLayout from 'components/Home/Layout/homeLayout';
import { Outlet } from 'react-router-dom';
import LoaderSuspence from 'components/contexts/loader/loaderSuspence';

export default function HomePage() {
  return (
    <HomeLayout>
      <LoaderSuspence>
        <Outlet></Outlet>
      </LoaderSuspence>
    </HomeLayout>
  );
}
