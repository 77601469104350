import React from 'react';
import { ReactComponent as PlayFilledIcon } from 'icons/play-filled.svg';
import { ReactComponent as PauseFilledIcon } from 'icons/pause-filled.svg';
import { IUploadedFile } from 'interfaces/formsComponents.interface';
import styles from './songPreview.module.scss';
import { combineClassNames } from 'helpers/styling-helper';
import useCommonPlayer from 'hooks/useCommonPlayer';

interface ISongPreviewProps {
  song: IUploadedFile;
  customClass?: string;
  onMouseDown?: (any) => void;
}

export default function SongPreview({ song, customClass, onMouseDown }: ISongPreviewProps) {
  const [isPlaying, play, pause] = useCommonPlayer({ song });
  React.useEffect(() => {
    return () => pause();
  }, []);
  return (
    <div className={combineClassNames('d-flex f-align-center gap-05', customClass)}>
      {song?.src &&
        (isPlaying ? (
          <PauseFilledIcon
            onMouseDown={onMouseDown}
            className={styles.play_icon}
            width={30}
            onClick={pause}
          />
        ) : (
          <PlayFilledIcon
            onMouseDown={onMouseDown}
            className={styles.play_icon}
            width={30}
            onClick={play}
          />
        ))}
      <span className={styles.song_name}>{song?.name}</span>
    </div>
  );
}
