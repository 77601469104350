import React from 'react';
import styles from './emailStatusImage.module.scss';
import { ReactComponent as SuccessIcon } from 'icons/purple-success.svg';
import { ReactComponent as PendingIcon } from 'icons/pending.svg';
import { ReactComponent as ErrorIcon } from 'icons/failure.svg';

const statusIconMap: Record<
  'success' | 'pending' | 'error',
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >
> = {
  success: SuccessIcon,
  pending: PendingIcon,
  error: ErrorIcon
};

export default function EmailStatusImage({ status }: { status: 'success' | 'pending' | 'error' }) {
  const [isShowingStatus, setIsShowingStatus] = React.useState<boolean>(false);
  const StatusIcon = statusIconMap[status];
  return (
    <div className={styles.imageContainer}>
      <img
        src={'/assets/images/email.svg'}
        alt="email"
        className={styles.emailImage}
        onLoad={() => setIsShowingStatus(true)}
      />
      <StatusIcon className={styles.checkImage} style={{ opacity: isShowingStatus ? 1 : 0 }} />
    </div>
  );
}
