import React from 'react';
import type { ColumnsType } from 'antd/es/table';
import getArtistPromotionRequest, {
  ARTIST_PROMOTIONS_STATUS,
  IArtistPromotionRequest
} from '@anghami/neogateway/dist/endpoints/getArtistPromotionRequest';
import PromotionStatusField from '../PromotionStatusField/promotionStatusField';
import { Table } from 'antd';
import { gateway } from 'helpers/gateway-helper';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

export default function PromotionRequestsTable({ status }: { status: ARTIST_PROMOTIONS_STATUS }) {
  const { t } = useTranslation('common');
  const [currentPage, setCurrentPage] = React.useState(1);

  const columns: ColumnsType<IArtistPromotionRequest> = [
    {
      title: t('Request ID'),
      dataIndex: 'requestid',
      key: 'requestid',
      render: (text) => <div>{text}</div>
    },
    {
      title: t('Request Info'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: t('Requested On'),
      dataIndex: 'createdon',
      key: 'createdon'
    },
    {
      title: t('Status'),
      key: 'status',
      dataIndex: 'status',
      render: (_, data) => <PromotionStatusField row={data} />
    }
  ];
  const { data, isLoading } = useQuery(['content-promotions', status, currentPage], async () => {
    const { data } = await gateway.callEndpoint(getArtistPromotionRequest, {
      offset: currentPage,
      status: status,
      output: 'json'
    });
    return data;
  });

  return (
    <Table
      loading={isLoading}
      rowKey={'requestid'}
      columns={columns}
      dataSource={data?.requests}
      pagination={{
        total: Number(data?.count),
        pageSize: 20,
        hideOnSinglePage: true,
        current: currentPage,
        onChange: setCurrentPage
      }}
      locale={{
        emptyText: t('No data')
      }}
    />
  );
}
