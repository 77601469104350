import React from 'react';
import styles from './artistsBenefits.module.scss';
import { combineClassNames } from 'helpers/styling-helper';
import { useTranslation } from 'react-i18next';

const Card = ({ data }) => (
  <div className={styles.cardContainer}>
    <h3>{data.title}</h3>
    <p>{data.content}</p>
  </div>
);

function ArtistsBenefits() {
  const { t } = useTranslation();
  const cardsData = [
    {
      id: 0,
      title: ` ${t('creators.landing.section6.perks.analytics.title')} `,
      content: ` ${t('creators.landing.section6.perks.analytics.description')} `
    },
    {
      id: 1,
      title: ` ${t('creators.landing.section6.perks.control.title')} `,
      content: ` ${t('creators.landing.section6.perks.control.description')} `
    },
    {
      id: 2,
      title: ` ${t('creators.landing.section6.perks.pitch.title')} `,
      content: ` ${t('creators.landing.section6.perks.pitch.description')} `
    },
    {
      id: 3,
      title: ` ${t('creators.landing.section6.perks.financials.title')} `,
      content: ` ${t('creators.landing.section6.perks.financials.description')} `
    },
    {
      id: 4,
      title: `${t('creators.landing.section6.perks.live.title')} `,
      content: ` ${t('creators.landing.section6.perks.live.description')} `
    }
  ];
  const cardsNumber = cardsData.length;

  return (
    <div
      className={combineClassNames(
        'd-flex',
        'f-column',
        'f-justify-center',
        'f-align-center',
        styles.sectionContainer
      )}
    >
      <div className={styles.sectionTitle}>{` ${t(
        'creators.landing.section6.perks.title',
        'Perks & Benefits'
      )} `}</div>

      <div className={styles.cardsContainer}>
        <div className="d-flex f-column">
          {cardsData.slice(0, cardsNumber / 2).map((c) => (
            <Card key={c.id} data={c} />
          ))}
        </div>
        <div className="d-flex f-column">
          {cardsData.slice(cardsNumber / 2, cardsNumber).map((c) => (
            <Card key={c.id} data={c} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ArtistsBenefits;
