import React from 'react';
import Button from 'components/Reusable/Button';
import { combineClassNames } from 'helpers/styling-helper';
import { ReactComponent as BackIcon } from 'icons/back-arrow.svg';
import { useTranslation } from 'react-i18next';
import styles from './videoUpload.module.scss';
import VideoStatus from './videoStatus';
import { VIDEO_STATUS } from '@anghami/neogateway/dist/endpoints/getUserLibrary';

export default function VideoUploadHeader({
  handleBackButton,
  nextButtonLabel: label,
  isNextButtonDisabled,
  handleNextButton,
  isLastStep,
  status
}: {
  handleBackButton: () => void;
  nextButtonLabel: string;
  isNextButtonDisabled: boolean;
  handleNextButton: () => void;
  isLastStep?: boolean;
  status: VIDEO_STATUS;
}) {
  const { t } = useTranslation();
  return (
    <div className={combineClassNames('d-flex f-align-center f-justify-between', styles.header)}>
      <div className="d-flex f-align-center gap-2">
        {isLastStep ? (
          <div className="f-column f-justify-start f-align-start">
            <h1>{t('music video details')}</h1>
            {status && <VideoStatus status={status} />}
          </div>
        ) : (
          <>
            <BackIcon
              width={20}
              height={20}
              className={styles.backButton}
              onClick={handleBackButton}
            />
            <div className="f-column f-justify-start f-align-start">
              <h1>{t('Upload a music video')}</h1>
              <span>{t('upload video constraint message')}</span>
            </div>
          </>
        )}
      </div>
      <Button
        label={label}
        customClasses={['purpleBackground']}
        disabled={isNextButtonDisabled}
        size="md"
        onSubmit={handleNextButton}
      />
    </div>
  );
}
