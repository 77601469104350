import CreateReleaseDialog from 'components/Dialogs/CreateReleaseDialog';
import SignContractDialog from 'components/Dialogs/SignContractDialog';
import Button from 'components/Reusable/Button';
import { dialogsContext } from 'components/contexts/dialogs/toast/dialogs.context';
import { CLICKED_ON_CREATE_RELEASE, VIEW_CONTRACT_SIGNING } from 'constants/amplitudeEvents';
import { logAmplitudeEvent } from 'helpers/analytics-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import colors from 'styles/partials/_colors_exports.module.scss';
import { ReactComponent as AddSIcon } from 'icons/add-sm.svg';
import useUserConditions from 'hooks/useUserConditions';
import { SidemenuContext } from './sidemenu.context';
import styles from './sidemenuCreateButton.module.scss';
import Popover from 'antd/es/popover';
import SideMenuPopover from './sideMenuPopover';

export default function SidemenuCreateButton() {
  const { isMinimized, isMobile, setIsMobileExpanded } = React.useContext(SidemenuContext);
  const { t } = useTranslation();
  const { showDialog } = React.useContext(dialogsContext);
  const { shouldSignAgreement, canCreateRelease } = useUserConditions();
  const showCreateReleaseDialog = () => {
    logAmplitudeEvent(CLICKED_ON_CREATE_RELEASE, {
      source: 'navbar'
    });
    showDialog({
      dialog: <CreateReleaseDialog />,
      backdrop: true
    });
  };

  const showSignContractDialog = () => {
    logAmplitudeEvent(VIEW_CONTRACT_SIGNING, {});
    showDialog({
      dialog: <SignContractDialog onSuccessFn={() => showCreateReleaseDialog()} />,
      backdrop: true
    });
  };

  const handleCreateAction = () => {
    setIsMobileExpanded(false);
    if (shouldSignAgreement) {
      showSignContractDialog();
      return;
    }
    showCreateReleaseDialog();
  };

  return (
    canCreateRelease &&
    (isMinimized && !isMobile ? (
      <Popover
        content={<SideMenuPopover title={t('Create a release')} />}
        placement="right"
        trigger="hover"
        color="#131415"
        style={{ borderRadius: 5, padding: 0 }}
        arrow={false}
        className="sidemenu-custom-class"
      >
        <div className={styles.minimizedButtonSection}>
          <Button
            size={'lg'}
            icon={<AddSIcon fill={colors['light-10']} />}
            customClasses={[
              'default',
              'w-100',
              'white',
              styles.customCreateReleaseButton,
              styles.isMinimized
            ]}
            onSubmit={handleCreateAction}
          />
          <div className={styles.animationSection} />
        </div>
      </Popover>
    ) : (
      <div className="w-100">
        <Button
          label={t('Create a release')}
          icon={<AddSIcon fill={colors['light-10']} />}
          customClasses={[
            'default',
            'w-100',
            'sm',
            'white',
            'px-1',
            styles.customCreateReleaseButton
          ]}
          onSubmit={handleCreateAction}
        />
      </div>
    ))
  );
}
