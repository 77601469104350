interface AudioQuality {
  streamOptions: string;
  downloadOptions: string;
  streamDefaultValue: string;
  downloadDefaultValue: string;
  freeUsersOptions: string;
}

interface Pushpermissions {
  enabled: number;
  music: number;
  offers: number;
  playlists: number;
  friends: number;
  videoexpression: number;
  fb_messenger: number;
  messaging_notifications: number;
  _static: number;
}

interface Ws {
  host: string;
  port: string;
  path: string;
}

interface DArtist {
  id: string;
  name: string;
  namear: string;
  handle?: any;
  coverart: string;
}

export interface IAuthenticateRes {
  audioQuality: AudioQuality;
  noad: string;
  offlinereport: string;
  failplayurl: string;
  plan: string;
  anid: string;
  plantype: string;
  allowforceoffline: string;
  user: string;
  method: string;
  serverurl: string;
  download3g: string;
  streaming: string;
  offline: string;
  audiowifi: string;
  audio3g: string;
  offlinesongs: string;
  ping: number;
  shareurl: string;
  allowoffline: string;
  lastrelease: string;
  upgradeurl: string;
  showpurchase: number;
  showpurchasebutton: number;
  purchaseoptionsxml: string;
  fbpermissions: string;
  hasfacebook: string;
  changefbpublish: string;
  pushalias: string;
  partnerid: string;
  adopens: string;
  adfrequency: string;
  adpressfrequency: string;
  adPressTag: string;
  adsecscounter: number;
  partnerlogo: string;
  language: string;
  showupgradeoption: number;
  expired: number;
  fbpublish: string;
  isgoogleplus: number;
  googleid: string;
  askconnect: string;
  disablecode: string;
  newuser: number;
  intersttag: string;
  dldadtag: string;
  likeadtag: string;
  mputag: string;
  videopostrolltag: string;
  videopostrollfrequency: number;
  videomputag: string;
  forcedfirstad: string;
  forcedfirstadtimer: number;
  artworklocation: string;
  sentuseragent: string;
  havephone: string;
  mpn: string;
  mpntick?: any;
  arabicletters: number;
  receivedservername: string;
  suuid: number;
  lookahead3g: string;
  lookaheadwifi: string;
  lookaheadpreload: string;
  lookaheadresumethreshold: string;
  upsellOnCloseAd: string;
  adbreak: string;
  audioadbreak: string;
  displayadbreak: string;
  enabledlyrics: string;
  lyricsfreeenabled: string;
  uservideolength: string;
  skipTimeThreshold: number;
  uservideolikes: string;
  uservideocomments: string;
  edgetimer: number;
  'overlay-insta': string;
  interstitialsize: string;
  ExpressionsFrame: string;
  allowResumableDownloads: number;
  contacts_invite_num: number;
  contacts_invite_plusdays: number;
  inviteeduration: number;
  acccid: string;
  assets_ssl_validation: number;
  allowskipaftersecs: number;
  minskipad: number;
  havepassword: string;
  expressionadtag: string;
  expressionadfrequency: number;
  usercountry: string;
  explicit: string;
  fullname: string;
  fname: string;
  lname: string;
  msidn: string;
  androidplanid?: any;
  _srvip: string;
  _srvln: number;
  _srvcache: string;
  _srvdevmode: number;
  _srvprdsv: number;
  infiniteplaymode: number;
  hidephone: number;
  auto_stories?: any;
  vibes_algo_version: number;
  vibes_algo_ttl: number;
  ispublic: number;
  songresolver: string;
  web_push_token: string;
  pqsync: number;
  AutoCustomImages: string;
  audioadttl: number;
  adservertype: string;
  audioadservertype: string;
  hqsetting: string;
  pushpermissions: Pushpermissions;
  allowvideoadson3G: string;
  playmode: string;
  darkmode: number;
  planMessage: string;
  allow_autodownload: number;
  last_login_on_different_device: string;
  email: string;
  _accountemail: string;
  _sessionemail: string;
  lfpublish: string;
  bucketuserid: string;
  adimage: string;
  adurl: string;
  adwhy: string;
  uservideo: string;
  uvtutorial: number;
  devmode: string;
  'alarm-ad-tag': string;
  alarmEnabled: string;
  ws: Ws;
  tooltipshash: string;
  dialogshash: string;
  RecentlyActiveOnMultipleDevices: number;
  caps: number;
  zenhide: string;
  adTag?: any;
  album_button_type: string;
  allowcarmode: string;
  artist_button_type: string;
  artist_page_all_time_hits: string;
  artist_page_revamp_trending_live_users: string;
  atmos_avplayer: string;
  audioad_start: string;
  branch_link: string;
  collabplaylist: string;
  disablecloudflare: string;
  downloads_button_type: string;
  enablevideopreroll: string;
  enable_steps: string;
  first_shuffle_dialog: string;
  free_plus_features: string;
  headphonespush: string;
  hideoffline: string;
  hideradar: string;
  interstitial_adbreak: string;
  inviteesPerRequest: string;
  likes_button_type: string;
  lyrics_default: string;
  miniplayer_button: string;
  more_shuffle_dialog: string;
  onboarding_closable: string;
  onboarding_pages: string;
  percentPlayOnce: string;
  playlistonlike: string;
  playlistOnLike: string;
  pqsyncon: string;
  pqsynconsoc: string;
  privatesession_allowed: string;
  privatesession_intervals: number[];
  product_instrumentation: string;
  recently_played_threshold_seconds: string;
  reset_ads_in: string;
  searchsessionexpiry: string;
  sendgift: string;
  show_live_tab: string;
  show_tab_names: string;
  silo_song_tracking: string;
  spq_not_supported_dialog: string;
  spq_web: string;
  support_chat: string;
  videoadbreak: string;
  videoadtag: string;
  videoadtag_videos: string;
  videoad_start: string;
  webplayevent: string;
  fallbackfaq: string;
  hasapp: number;
  sss: string;
  socketsessionid: string;
  musiclanguage: number;
  atags: string;
  tags: string;
  dashboard: number;
  ownerid: string;
  owner_name: string;
  d_userid: string;
  d_statusid: string;
  d_typeid: string;
  d_email: string;
  is_podcaster: string;
  isOwnerWhiteListed: string;
  d_name: string;
  profilepic: string;
  d_artist: DArtist;
  show_nps: number;
  account_id: string;
  account_name: string;
  linked_account: boolean;
  hasPodcasts: string;
  artist_has_uploads: boolean;
  fbid?: any;
  fbprofilepic?: any;
  d_roles: string[];
  afc?: {
    scopes?: string[];
    access_token?: string;
    expires_at?: number;
    refresh_token?: string;
    type?: string;
  };
  artist_cover_art_id?: string;
  hide_opt_out?: boolean;
  redirect_url?: string;
  has_contract?: boolean;
  has_eoy_stats?: boolean;
}

export enum AccountTypeEnums {
  ADMIN = 'ADMIN',
  ARTIST = 'ARTIST',
  LABEL = 'LABEL',
  PODCASTER = 'PODCASTER',
  UNKOWN = 'UNKOWN'
}

export interface IPermissions {
  upload_single?: boolean;
  upload_album?: boolean;
  upload_video?: boolean;
  upload_episode?: boolean;
  upload_show?: boolean;
  manage_artist_profile?: boolean;
  edit_account_info?: boolean;
  view_agreement?: boolean;
  view_upcoming_releases?: boolean;
  manage_upcoming_releases?: boolean;
  manage_catalog_meta?: boolean;
  edit_release_info?: boolean;
  edit_release_lyrics_genre?: boolean;
  edit_release_audio_cover?: boolean;
  promo_presave?: boolean;
  promo_pitch_playlist?: boolean;
  promo_create?: boolean;
  promo_view_analytics?: boolean;
  view_content_analytics?: boolean;
  view_profile_analytics?: boolean;
  view_artist_analytics?: boolean;
  view_financial_report?: boolean;
  send_financial_invoice?: boolean;
  skip_agreement?: boolean;
  manage_featured_items?: boolean;
}
