import React from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { ARTIST_PROMOTIONS_STATUS } from '@anghami/neogateway/dist/endpoints/getArtistPromotionRequest';
import { useTranslation } from 'react-i18next';
import Button from 'components/Reusable/Button';
import PromotionRequestDialog from 'components/PitchPlaylists/PromotionRequestDialog';
import { dialogsContext } from 'components/contexts/dialogs/toast/dialogs.context';
import PromotionRequestsTable from 'components/PitchPlaylists/PromotionRequestsTable/promotionRequestsTable';
import styles from './contentPromotion.module.scss';
import { logAmplitudeEvent } from 'helpers/analytics-helper';
import { CLICK_CREATE_PROMOTION_REQUEST } from 'constants/amplitudeEvents';

export default function ContentPromotion() {
  const { showDialog } = React.useContext(dialogsContext);
  const { t } = useTranslation('common');
  const items: TabsProps['items'] = [
    {
      key: ARTIST_PROMOTIONS_STATUS.pending,
      label: <div className={styles.tabItem}>{t('Pending Review')}</div>,
      children: <PromotionRequestsTable status={ARTIST_PROMOTIONS_STATUS.pending} />
    },
    {
      key: ARTIST_PROMOTIONS_STATUS.pendingpayment,
      label: <div className={styles.tabItem}>{t('Pending Payment')}</div>,
      children: <PromotionRequestsTable status={ARTIST_PROMOTIONS_STATUS.pendingpayment} />
    },
    {
      key: ARTIST_PROMOTIONS_STATUS.approved,
      label: <div className={styles.tabItem}>{t('Approved')}</div>,
      children: <PromotionRequestsTable status={ARTIST_PROMOTIONS_STATUS.approved} />
    },
    {
      key: ARTIST_PROMOTIONS_STATUS.rejected,
      label: <div className={styles.tabItem}>{t('Rejected')}</div>,
      children: <PromotionRequestsTable status={ARTIST_PROMOTIONS_STATUS.rejected} />
    }
  ];

  const handleContentPromotion = () => {
    logAmplitudeEvent(CLICK_CREATE_PROMOTION_REQUEST, {});
    showDialog({
      dialog: <PromotionRequestDialog />,
      backdrop: true
    });
  };

  const handleTabChange = (key: string) => {
    console.log(key);
  };

  return (
    <div>
      <div className="p-2 d-flex f-align-center f-justify-between">
        <h1>{t('Promotion Requests')}</h1>
        <Button
          label={t('Promote Content')}
          onSubmit={handleContentPromotion}
          customClasses={['purpleFilled']}
        />
      </div>
      <Tabs
        defaultActiveKey={ARTIST_PROMOTIONS_STATUS.pending}
        items={items}
        onChange={handleTabChange}
      />
    </div>
  );
}
