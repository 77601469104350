import { combineClassNames } from 'helpers/styling-helper';
import styles from './toast.module.scss';
import React from 'react';

/*
  isOpen: boolean;  open or close toast
  onClose: () => void; function to close toast
  type: 'success' | 'error' | 'warning'; type of toast
  title: string; main title or message
  subtitle?: string; optional subtitle
  children?: string;  if you send a children you have are free to create the component you want in toast
*/

export interface IToastProps {
  isOpen?: boolean;
  onClose?: () => void;
  type: 'success' | 'error' | 'warning' | 'neutral';
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  closeTimer?: number;
}

export default function Toast({
  isOpen,
  onClose,
  type,
  title,
  subtitle,
  children,
  closeTimer
}: IToastProps) {
  let timeout;
  React.useEffect(() => {
    if (isOpen) {
      timeout = setTimeout(() => {
        onClose();
      }, closeTimer || 5000);
    }
    return () => clearTimeout(timeout);
  }, [isOpen]);
  return (
    <div className={combineClassNames(styles.container, isOpen && styles.isOpen)} onClick={onClose}>
      <div
        className={combineClassNames(styles.toast, styles[type])}
        onClick={(e) => e.stopPropagation()}
      >
        {children ? (
          children
        ) : (
          <>
            <span className={styles.title}>{title}</span>
            <span className={styles.subtitle}>{subtitle}</span>
            <span className={styles.close_button} onClick={onClose}>
              X
            </span>
          </>
        )}
      </div>
    </div>
  );
}
