import React from 'react';

export default function ShowVisual({ className, secondaryclass }) {
  return (
    <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect className={secondaryclass} x="7" width="42" height="42" rx="10" fill="#E5E7EA" />
      <rect className={className} y="7" width="42" height="42" rx="10" fill="#CCD0D3" />
      <path
        d="M21.1707 31.9178C21.2083 31.9206 21.246 31.9206 21.2836 31.9178H21.303C22.5655 31.9178 23.7766 31.4172 24.6707 30.5257C25.5648 29.6342 26.0689 28.4247 26.0726 27.1621V20.7206C26.0551 19.4626 25.5431 18.2621 24.6473 17.3787C23.7515 16.4953 22.544 16 21.2859 16C20.0278 16 18.8202 16.4953 17.9244 17.3787C17.0287 18.2621 16.5167 19.4626 16.4992 20.7206V27.1297C16.4831 28.383 16.9656 29.5914 17.8404 30.489C18.7153 31.3865 19.9109 31.8998 21.1642 31.9159H21.1707V31.9178Z"
        fill="white"
      />
      <path
        d="M22.0829 34.2104C23.8247 34.0279 25.4353 33.2008 26.5984 31.8915C27.7615 30.5822 28.393 28.8853 28.3689 27.1342V23.8959C28.3755 23.7254 28.3145 23.5593 28.1993 23.4336C28.0842 23.3078 27.924 23.2326 27.7537 23.2242H27.6907C27.6067 23.2207 27.5228 23.2338 27.4439 23.2628C27.3649 23.2917 27.2924 23.3359 27.2306 23.3929C27.1687 23.4498 27.1187 23.5184 27.0834 23.5947C27.048 23.6711 27.028 23.7536 27.0246 23.8376V27.1999C27.027 28.718 26.4266 30.175 25.3553 31.2507C24.284 32.3263 22.8294 32.9326 21.3113 32.9363C19.7889 32.9305 18.3301 32.3252 17.2509 31.2515C16.1716 30.1778 15.5588 28.7222 15.5452 27.1999V23.894C15.5519 23.7236 15.4911 23.5573 15.3759 23.4314C15.3188 23.3695 15.25 23.3195 15.1734 23.2844C15.0969 23.2493 15.0141 23.2298 14.9299 23.2269H14.7995C14.6056 23.2275 14.4183 23.2975 14.2717 23.4243C14.125 23.5511 14.0286 23.7263 14.0001 23.9181V27.136C13.9918 28.8832 14.6289 30.5718 15.7892 31.8781C16.9494 33.1843 18.5511 34.0162 20.287 34.2141L20.4092 34.2289V37.7262H18.0452C17.8673 37.7262 17.6967 37.7969 17.5709 37.9227C17.4451 38.0485 17.3744 38.2192 17.3744 38.3971C17.3744 38.575 17.4451 38.7456 17.5709 38.8714C17.6967 38.9972 17.8673 39.0678 18.0452 39.0678H24.4552C24.6331 39.0678 24.8037 38.9972 24.9295 38.8714C25.0553 38.7456 25.126 38.575 25.126 38.3971C25.126 38.2192 25.0553 38.0485 24.9295 37.9227C24.8037 37.7969 24.6331 37.7262 24.4552 37.7262H21.9571V34.2243L22.0829 34.2104Z"
        fill="white"
      />
    </svg>
  );
}
