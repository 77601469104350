import React from 'react';
import styles from './tableHeader.module.scss';
import './override.styles.scss';
import { ReactComponent as BackIcon } from 'icons/back-arrow.svg';
import { libaryContext } from 'components/Library/Context/library.context';

interface ITabsHeaderProps {
  albumName: string;
}

export default function TabsHeader({ albumName }: ITabsHeaderProps) {
  const { setSelectedAlbum } = React.useContext(libaryContext);

  return (
    <div className={styles.TableHeader}>
      <div className={styles.back_button} onClick={() => setSelectedAlbum(null)}>
        <BackIcon />
      </div>
      <h3>{albumName || 'Album Name'}</h3>
    </div>
  );
}
