import React from 'react';
import {
  InfoTableCell,
  StatusTableCell,
  ReleaseTypeCell,
  VideoContentCell,
  ReleaseDateCell
} from 'components/Library/Cells';
import { IReleaseItem } from '@anghami/neogateway/dist/endpoints/getUserLibrary';
import {
  AVAILABLE_ACTIONS,
  RELEASE_STATUSES,
  RELEASE_TYPES
} from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import { columnCellsWidth, TableColumnType } from './tabledatatype';
import {
  getIsPodcastFromReleaseType,
  getIsReleaseAlbum,
  getIsReleaseSingle,
  getReleaseLink
} from 'helpers/releases-helper';
import LinkItem from 'components/Reusable/LinkItem';

export const libraryTableColumns = (t: any, noFixed = false): TableColumnType[] => [
  {
    title: t('Release Info'),
    width: columnCellsWidth.release_info,
    key: '0',
    ...(noFixed ? {} : { fixed: 'left' }),
    dataIndex: 'name',
    render: (value: string, data: IReleaseItem) => (
      <InfoTableCell
        name={data.release_name}
        nameAr={data.release_name_ar}
        coverart={data.cover_url}
        actions={data.actions}
        albumId={data.album_id}
        releaseId={getIsReleaseSingle(data.release_type) ? data.song_id : data.album_id}
        releaseType={data.release_type}
        releaseStatus={data.release_status}
      />
    )
  },
  {
    title: t('Release Type'),
    width: columnCellsWidth.release_type,
    dataIndex: 'release_type',
    key: '7',
    render: (value: RELEASE_TYPES, data: IReleaseItem) => {
      return (
        <ReleaseTypeCell
          release_type={value}
          album_info={{
            album_id: data.album_id,
            album_name: data.release_name || data.release_name_ar,
            is_podcast: value === RELEASE_TYPES.Show
          }}
        />
      );
    }
  },
  {
    title: t('Video Content'),
    width: columnCellsWidth.video_content,
    dataIndex: 'has_video',
    key: '6',
    render: (value: string, data: IReleaseItem) => (
      <VideoContentCell
        songId={getIsReleaseSingle(data.release_type) ? data.song_id : data.album_id}
        hasVideo={!!value}
        video={data.video}
        showUploadButton={data?.actions?.includes(AVAILABLE_ACTIONS.ADD_VIDEO) || false}
        isPodcast={getIsPodcastFromReleaseType(data.release_type)}
      />
    )
  },
  {
    title: t('Status'),
    width: columnCellsWidth.release_status,
    dataIndex: 'release_status',
    key: '1',
    render: (value: string) => <StatusTableCell status={value} />
  },
  {
    title: t('Release Date'),
    width: columnCellsWidth.release_date,
    dataIndex: 'release_date',
    key: '2',
    render: (value: string, { release_type }: IReleaseItem) => (
      <ReleaseDateCell timestamp={value} hideTime={getIsReleaseAlbum(release_type)} />
    )
  },
  {
    title: t('Date Submitted'),
    width: columnCellsWidth.date_submitted,
    dataIndex: 'added_on',
    key: '3',
    render: (value: string) => <ReleaseDateCell timestamp={value} />
  },
  {
    title: t('Collab Artists'),
    width: columnCellsWidth.collab_artists,
    dataIndex: 'collaborating_artists',
    key: '4',
    render: (value: IReleaseItem['collaborating_artists']) => (
      <div>{value?.length > 0 ? value.map((ar) => ar.label)?.join(',') : `${t('None')}`}</div>
    )
  },
  {
    title: t('Featuring Artists'),
    width: columnCellsWidth.featuring_artists,
    dataIndex: 'featuring_artists',
    key: '5',
    render: (value: IReleaseItem['featuring_artists']) => (
      <div>{value?.length > 0 ? value.map((ar) => ar.label)?.join(',') : `${t('None')}`}</div>
    )
  },
  {
    title: t('ID'),
    width: columnCellsWidth.release_id,
    dataIndex: 'song_id',
    key: '6',
    render: (value: string, data: IReleaseItem) => {
      const id = getIsReleaseAlbum(data.release_type) ? data.album_id : value;
      return (
        <div>
          {data.release_status === RELEASE_STATUSES.LIVE ? (
            <LinkItem link={getReleaseLink(data.release_type, id)} text={id} />
          ) : (
            <span>{id}</span>
          )}
        </div>
      );
    }
  }
];
