import getReleaseDetails, {
  IGetReleaseDetailsResponse,
  RELEASE_TYPES
} from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import { GET_RELEASE_DETAILS } from 'constants/reactQueryKeys';
import { gateway } from 'helpers/gateway-helper';
import { useQuery } from 'react-query';

interface IUseGetReleaseDetailsProps {
  release_id: string;
  release_type: RELEASE_TYPES;
  onError?: (err: unknown) => void;
  onSuccess?: (data: IGetReleaseDetailsResponse) => void;
}
const useGetReleaseDetails = ({
  release_id,
  release_type,
  onSuccess,
  onError
}: IUseGetReleaseDetailsProps) =>
  useQuery(
    [GET_RELEASE_DETAILS, release_type, release_id],
    async () => {
      const { data } = await gateway.callEndpoint(getReleaseDetails, {
        release_id: release_id,
        release_type: release_type,
        output: 'jsonhp'
      });
      return data;
    },
    {
      retry: 3,
      staleTime: 30000,
      onError: (err) => {
        console.log(`🔴 ~ getReleaseDetails for ${release_type} of id ${release_id} failed`, err);
        onError && onError(err);
      },
      ...(onSuccess && { onSuccess })
    }
  );

export default useGetReleaseDetails;
