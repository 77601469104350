import React, { useContext } from 'react';
import Button from 'components/Reusable/Button';
import { LIBRARY_TAGS } from 'components/Library/LibraryTableHOC/releasetype_constants';
import { useTranslation } from 'react-i18next';
import { IAlbumInfo, libaryContext } from 'components/Library/Context/library.context';
import { getIsReleaseAlbum } from 'helpers/releases-helper';
import { RELEASE_TYPES } from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import { logAmplitudeEvent } from 'helpers/analytics-helper';
import { GO_TO_ALBUM_VIEW } from 'constants/amplitudeEvents';

interface ReleaseTypeCellProps {
  release_type: RELEASE_TYPES;
  album_info?: IAlbumInfo;
}

export default function ReleaseTypeCell({ release_type, album_info }: ReleaseTypeCellProps) {
  const { setSelectedAlbum } = useContext(libaryContext);
  const { t } = useTranslation('common');
  const releaseTypeInfo = LIBRARY_TAGS[release_type];
  const Icon = releaseTypeInfo?.icon;

  const setSelectedAlbumWrapper = (albumInfo: IAlbumInfo) => {
    logAmplitudeEvent(GO_TO_ALBUM_VIEW, {
      content_type: release_type,
      id: albumInfo.album_id,
      name: albumInfo.album_name
    });
    setSelectedAlbum(albumInfo);
  };
  return (
    <div className="d-flex f-align-center gap-1">
      {releaseTypeInfo && (
        <>
          <Icon width={18} height={18} />
          {t(releaseTypeInfo.label)}
          {getIsReleaseAlbum(release_type) && (
            <Button
              label={t('view')}
              size="xsm"
              customClasses={['default']}
              onSubmit={() => {
                setSelectedAlbumWrapper(album_info);
              }}
            />
          )}
        </>
      )}
    </div>
  );
}
