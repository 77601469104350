import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './coverArtSelectAndCrop.module.scss';
import Button from 'components/Reusable/Button';
import { ReactComponent as UploadVisual } from 'icons/upload-visual.svg';
import { IUploadedFile } from 'interfaces/formsComponents.interface';
import { ALLOWED_IMAGE_FILETYPES } from 'constants/releases.constants';
import { toastContext } from 'components/contexts/toast/toast.context';
import Skeleton from 'antd/es/skeleton/Skeleton';
const Cropper = React.lazy(() => import('react-cropper'));

interface ICoverArtSelectAndCropProps {
  onUploadCoverArt: (coverArt: IUploadedFile) => void;
  coverArt: IUploadedFile;
  isCropState: boolean;
  setIsCropState: (isCropState: boolean) => void;
}
export default function CoverArtSelectAndCrop({
  onUploadCoverArt,
  coverArt,
  isCropState,
  setIsCropState
}: ICoverArtSelectAndCropProps) {
  const [imageBlob, setImageBlob] = React.useState(coverArt?.src || null);
  const cropperRef = React.useRef<HTMLImageElement>(null);
  const { t } = useTranslation('common');
  const { openToast } = React.useContext(toastContext);
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files.item(0);
      if (
        file.type.indexOf('image/') === -1 ||
        ALLOWED_IMAGE_FILETYPES.indexOf('.' + file.type.replace('image/', '')) === -1
      ) {
        openToast(t('file type is not supported').replace('%@', file.type), 'error');
        return;
      }
      onUploadCoverArt({
        ...coverArt,
        name: file.name,
        type: file.type,
        size: file.size
      });
      setImageBlob(URL.createObjectURL(file));
      setIsCropState(true);
    }
  };

  React.useEffect(() => {
    if (!imageBlob) return;
    return () => {
      URL.revokeObjectURL(imageBlob);
    };
  }, []);

  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    // passing the image original size will avoid making the cropperjs library to increase the file size
    onUploadCoverArt({
      ...coverArt,
      src: cropper.getCroppedCanvas()?.toDataURL(coverArt.type) || ''
    });
  };
  return isCropState ? (
    <div dir="ltr" className={combineClassNames('f-column', styles.cropping_container)}>
      <React.Suspense
        fallback={
          <div className="w-100 h-100 f-center">
            <Skeleton.Avatar
              active={true}
              size={'large'}
              shape={'square'}
              style={{ height: 400, width: 500, borderRadius: 15 }}
            />
          </div>
        }
      >
        <Cropper
          src={imageBlob}
          style={{ height: 400, width: '100%' }}
          initialAspectRatio={1 / 1}
          aspectRatio={1 / 1}
          dir="ltr"
          responsive={true}
          viewMode={2}
          guides={false}
          minCropBoxWidth={100}
          crop={onCrop}
          ref={cropperRef}
          className={styles.cropper}
        />
      </React.Suspense>
    </div>
  ) : (
    <div className="f-column">
      <span className={styles.upload_info_top}>{t('image format description')}</span>
      <div className={styles.upload_box}>
        <input
          type={'file'}
          onChange={onSelectFile}
          accept={ALLOWED_IMAGE_FILETYPES.join(',')}
        ></input>
        {imageBlob ? (
          <img src={imageBlob} className={styles.preview_image} alt="preview" />
        ) : (
          <>
            <UploadVisual></UploadVisual>
            <span>{t('Drag and drop or choose from your desktop')}</span>
          </>
        )}
        <Button
          customClasses={[styles.browse_button]}
          withBackground={'#C4C4C4'}
          onSubmit={null}
          label={imageBlob ? t('Change') : t('Browse')}
        />
      </div>
      <span className={styles.upload_info_bottom}>{t('image upload description')}</span>
    </div>
  );
}
