import React from 'react';
import styles from './deleteWithConfirmation.module.scss';
import { ReactComponent as DeleteIcon } from 'icons/delete.svg';
import Button from 'components/Reusable/Button';
import { combineClassNames } from 'helpers/styling-helper';
import { useTranslation } from 'react-i18next';

export default function DeleteWithConfirmation({ onDelete }) {
  const { t } = useTranslation();
  const [isConfirmState, setIsConfirmState] = React.useState(false);
  return (
    <>
      {isConfirmState ? (
        <div className={combineClassNames('d-flex f-align-center gap-1', styles.buttons_container)}>
          <Button
            label={t('delete')}
            customClasses={[styles.customButton, styles.confirmDelete]}
            size="sm"
            onSubmit={() => {
              onDelete();
              setIsConfirmState(false);
            }}
          />
          <Button
            label={t('Cancel')}
            customClasses={['default', styles.customButton]}
            size="sm"
            onSubmit={() => setIsConfirmState(false)}
          />
        </div>
      ) : (
        <div className={styles.delete_button} onClick={() => setIsConfirmState(true)}>
          <DeleteIcon />
        </div>
      )}
    </>
  );
}
