import React from 'react';
import styles from './uploadCoverArtDialog.module.scss';
import { ReactComponent as BackArrow } from 'icons/back-arrow.svg';
import Button from 'components/Reusable/Button';
import 'cropperjs/dist/cropper.css';
import { IUploadedFile } from 'interfaces/formsComponents.interface';
import { useTranslation } from 'react-i18next';
import colors from 'styles/partials/_colors_exports.module.scss';
import CoverArtSelectAndCrop from 'components/CreateRelease/CoverArtSelectAndCrop';

interface IUploadCoverArtDialogProps {
  onUploadCoverArt: (coverArt: IUploadedFile) => void;
  closeDialog: () => void;
  customTitle?: string;
}

export default function UploadCoverArtDialog({
  closeDialog,
  onUploadCoverArt,
  customTitle
}: IUploadCoverArtDialogProps) {
  const [isuploadImageStep, setIsUploadImageStep] = React.useState(false);
  const [imageMetas, setImageMetas] = React.useState<IUploadedFile>({ type: '', name: '' });
  const { t } = useTranslation('common');

  return (
    <div className={styles.create_release_dialog}>
      <div className={styles.dialog_header}>
        <BackArrow
          className={styles.back_icon}
          onClick={isuploadImageStep ? () => setIsUploadImageStep(false) : closeDialog}
        />
        <h2>{customTitle || t('Upload a release cover')}</h2>
      </div>
      <div className={styles.dialog_body}>
        <CoverArtSelectAndCrop
          coverArt={imageMetas}
          onUploadCoverArt={setImageMetas}
          isCropState={isuploadImageStep}
          setIsCropState={setIsUploadImageStep}
        />
      </div>
      <div className="d-flex w-100 f-justify-center">
        {isuploadImageStep && (
          <Button
            onSubmit={() => {
              onUploadCoverArt(imageMetas);
              closeDialog();
            }}
            label={t('Submit')}
            withBackground={colors['branding-purple']}
            customClasses={[styles.proceed_button]}
          ></Button>
        )}
      </div>
    </div>
  );
}
