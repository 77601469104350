import { ISongForPitching } from '@anghami/neogateway/dist/endpoints/getSongsForPitching';
import postPitchRequest, {
  IPostPitchRequestRequest,
  IPostPitchRequestResponse
} from '@anghami/neogateway/dist/endpoints/postPitchRequest';
import { userContext } from 'components/contexts/auth/auth.context';
import Button from 'components/Reusable/Button';
import Form from 'components/Reusable/Form';
import { convertFormFieldsToValues } from 'helpers/form-helper';
import { gateway } from 'helpers/gateway-helper';
import { FormFieldTypes, IFormFields, ISection } from 'interfaces/formsComponents.interface';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import styles from './pitchFormDialog.module.scss';
import { ReactComponent as LoaderIcon } from 'icons/loaders/tail-spin.svg';
import { toastContext } from 'components/contexts/toast/toast.context';
import { validateFormFields } from 'helpers/create-release-helper';
import SongToPitch from '../SongToPitch';
import CloseIcon from 'components/Reusable/CloseIcon';
import { SUBMIT_PITCH_PLAYLIST } from 'constants/amplitudeEvents';
import { logAmplitudeEvent } from 'helpers/analytics-helper';
import { GET_SONGS_FOR_PITCHING } from 'constants/reactQueryKeys';

interface PitchFormDialog {
  closeDialog?: () => void;
  selectedSong: Partial<ISongForPitching>;
}

export default function PitchFormDialog({ closeDialog, selectedSong }: PitchFormDialog) {
  const { t } = useTranslation('common');
  const { user } = React.useContext(userContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const { openToast } = React.useContext(toastContext);
  const reactQueryClient = useQueryClient();
  const sections: ISection[] = [
    {
      title: null,
      data: [
        ['genre', 'mood'],
        ['country', 'description']
      ]
    }
  ];
  const [formFields, setFormFields] = React.useState<IFormFields>({
    genre: {
      type: FormFieldTypes.GENRE_SELECT,
      label: t('Genre'),
      name: 'genre',
      extraValueParam: 'genreValue',
      value: null,
      placeholder: t('select a genre'),
      required: true,
      languageid: 2,
      isMultiSelect: true
    },
    mood: {
      type: FormFieldTypes.MOODS_SELECT,
      label: t('moods-and-styles'),
      name: 'mood',
      extraValueParam: 'moodValue',
      value: null,
      placeholder: t('select a mood'),
      required: true
    },
    country: {
      type: FormFieldTypes.NATIONALITY_SELECT,
      label: t('artists-nationality'),
      name: 'country',
      extraValueParam: 'countryValue',
      value: null,
      placeholder: t('choose nationality'),
      required: true
    },
    description: {
      type: FormFieldTypes.TEXT_AREA,
      label: t('Brief Description about the song'),
      name: 'description',
      value: null,
      placeholder: t('Describe the song in a few words'),
      required: true
    }
  });

  const mutation = useMutation(
    (form: any) => {
      return gateway.callEndpoint(postPitchRequest, { ...form, output: 'jsonhp' });
    },
    {
      retry: 3,
      mutationKey: 'postPitchRequest',
      onSettled(data: IPostPitchRequestResponse, error) {
        setIsLoading(false);
        if (!data?.data || error) {
          logAmplitudeEvent(SUBMIT_PITCH_PLAYLIST, {
            objectid: selectedSong.id,
            status: 'fail',
            error: error || 'unkown error'
          });
          setErrorMessage(t('something-went-wrong-try-again'));
          return;
        }
        if (data.data.error) {
          logAmplitudeEvent(SUBMIT_PITCH_PLAYLIST, {
            objectid: selectedSong.id,
            status: 'fail',
            error: data.data.error
          });
          setErrorMessage(data.data.error.message || t('something-went-wrong-try-again'));
          return;
        }
        logAmplitudeEvent(SUBMIT_PITCH_PLAYLIST, { objectid: selectedSong.id, status: 'success' });
        reactQueryClient.refetchQueries({
          queryKey: [GET_SONGS_FOR_PITCHING]
        });
        openToast(t('song-pitch-request-created'), 'success');
        closeDialog();
      }
    }
  );

  const handleSubmit = () => {
    const { isValid, lastErrorMessage, updatedFormFields } = validateFormFields(formFields);
    if (!isValid) {
      setErrorMessage(lastErrorMessage);
      setFormFields(updatedFormFields);
      return;
    }
    setErrorMessage(null);
    setIsLoading(true);
    const convertedValues = convertFormFieldsToValues(formFields);
    const requestObject: Partial<IPostPitchRequestRequest> = {
      mood: convertedValues?.mood?.join(','),
      genre: convertedValues?.genre?.join(','),
      country: convertedValues?.country,
      description: convertedValues?.description,
      songid: selectedSong.id,
      albumid: selectedSong.albumid,
      artistid: user.d_artist.id,
      artistname: user.d_artist.name
    };
    mutation.mutate(requestObject);
  };

  const handleFormChange = (newFormFields: IFormFields, fieldName: string) => {
    const tempFormFields: IFormFields = {
      ...newFormFields,
      [fieldName]: { ...newFormFields[fieldName], error: null }
    };
    setFormFields(tempFormFields);
  };

  return (
    <div className="position-relative">
      <div className="header-2">
        <h2>{t('Tell us more')}</h2>
        <p>{t('share info description')}</p>
      </div>
      <CloseIcon customClassName={styles.closeIcon} onClick={closeDialog} />
      <SongToPitch song={selectedSong} />
      <span className={styles.errorMessage}>{errorMessage}</span>
      <div>
        <Form
          id="pitchplaylist"
          formValues={formFields}
          setFormValues={handleFormChange}
          sections={sections}
          customClasses={{ sectionData: styles.formContainer }}
          isDisabled={isLoading}
        />
        <Button
          label={t('Send Request')}
          onSubmit={handleSubmit}
          customClasses={[styles.submitButton]}
          icon={isLoading ? <LoaderIcon width={20} height={20} /> : null}
          disabled={isLoading}
        />
      </div>
    </div>
  );
}
