export const LOGIN_ERROR = 'login-error';
export const MSISD_ERROR = 'msisdn-error';
export const REGISTRATION_ERROR = 'registration-error';
export const TOKEN_VALIDATION_ERROR = 'token-validation-error';
export const CUSTOM_DASHBOARD_ERROR = 'custom-dashboard-error';

export const raygunTagsArray = [
  CUSTOM_DASHBOARD_ERROR,
  LOGIN_ERROR,
  MSISD_ERROR,
  REGISTRATION_ERROR,
  TOKEN_VALIDATION_ERROR
];
