import { combineClassNames } from 'helpers/styling-helper';
import React, { useEffect } from 'react';
import styles from './tooltip.module.scss';

interface ITooltipProps {
  isOpen: boolean;
  children: React.ReactNode;
}

export default function Tooltip({ children, isOpen }: ITooltipProps) {
  const [openAnimation, setOpenAnimation] = React.useState(false);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setOpenAnimation(true);
      }, 10);
    } else {
      setTimeout(() => {
        setOpenAnimation(false);
      }, 10);
    }
  }, [isOpen]);

  return isOpen ? (
    <div className={styles.container} onClick={null}>
      <div
        className={combineClassNames(styles.dialog, openAnimation && styles.show)}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  ) : (
    <></>
  );
}
