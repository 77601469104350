import React from 'react';

export interface IAlbumInfo {
  album_id: string;
  album_name: string;
  is_podcast: boolean;
}

export interface IArtistInfo {
  artist_id: string;
  artist_name: string;
  cover_art_id: string;
}

export const libaryContext = React.createContext<{
  selectedAlbum: IAlbumInfo;
  setSelectedAlbum: (value: IAlbumInfo) => void;
  selectedArtist: IArtistInfo;
}>({
  selectedAlbum: null,
  setSelectedAlbum: null,
  selectedArtist: null
});
