import { toastContext } from 'components/contexts/toast/toast.context';
import { IUploadedFile } from 'interfaces/formsComponents.interface';
import React from 'react';
import { useTranslation } from 'react-i18next';

function useCommonPlayer({ song }: { song: IUploadedFile }): [boolean, () => void, () => void] {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const { t } = useTranslation();
  const { openToast } = React.useContext(toastContext);
  React.useEffect(() => {
    const audioElement = createOrGetAudioElement();
    if (!audioElement.paused) {
      handleAudioPlay();
    }
    audioElement.addEventListener('play', handleAudioPlay);
    audioElement.addEventListener('pause', handleAudioPause);
    return () => {
      audioElement.removeEventListener('play', handleAudioPlay);
      audioElement.removeEventListener('pause', handleAudioPause);
    };
  }, []);
  const createOrGetAudioElement = (): HTMLAudioElement => {
    const audio = document.getElementById('player-audio-element');
    if (audio) {
      return audio as HTMLAudioElement;
    }
    const newAudio = document.createElement('audio');
    newAudio.id = 'player-audio-element';
    newAudio.hidden = true;
    newAudio.preload = 'auto';
    document.body.appendChild(newAudio);
    return newAudio;
  };
  const handleAudioPlay = () => {
    if (!song) return;
    setIsPlaying(window['currentSongId'] && window['currentSongId'] === song.name + song.size);
  };
  const handleAudioPause = () => {
    setIsPlaying(false);
  };

  const play = () => {
    const audioElement = createOrGetAudioElement();
    audioElement.src = song.src;
    window['currentSongId'] = song.name + song.size;
    audioElement.load();
    audioElement
      .play()
      .then(() => {
        setIsPlaying(true);
      })
      .catch(() => {
        setIsPlaying(false);
        openToast(t('Unable to play audio file'), 'warning');
      });
  };
  const pause = () => {
    const audioElement = createOrGetAudioElement();
    audioElement.pause();
    audioElement.src = null;
    window['currentSongId'] = null;
    setIsPlaying(false);
  };
  return [isPlaying, play, pause];
}

export default useCommonPlayer;
