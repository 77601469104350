import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import { ReactComponent as ArrowOpenIcon } from 'icons/arrow-open.svg';
import styles from './sidemenuMinimizeButton.module.scss';
import { SidemenuContext } from './sidemenu.context';

export default function SidemenuMinimizeButton() {
  const { isMinimized, setIsMinimized } = React.useContext(SidemenuContext);
  return (
    <div
      role="button"
      className={combineClassNames(
        styles.sidemenu_minimize_button,
        'd-flex',
        'f-align-center',
        'f-justify-center',
        isMinimized ? styles.toggled_sidemenu_hide : ''
      )}
      onClick={() => {
        setIsMinimized(!isMinimized);
      }}
      onKeyDown={null}
      tabIndex={0}
    >
      <ArrowOpenIcon width={25} height={25} />
    </div>
  );
}
