import React from 'react';
import styles from './RadioButton.module.scss';

interface IProps {
  value: any;
  group: string;
  checked: boolean;
  label: string;
  change: any;
}

export default function RadioButton({ group, value, checked, label, change }: IProps) {
  const radioID = `radio-${group}${value}`;
  return (
    <div
      className={styles['radio-input-component']}
      onClick={() => {
        change({ target: { value: value } });
      }}
      aria-hidden="true"
      role="radio"
      aria-checked={checked}
    >
      <input type="radio" id={radioID} name={group} value={value} checked={checked} readOnly />
      <label htmlFor={radioID} className={styles['radio-label']}>
        {label}
      </label>
    </div>
  );
}
