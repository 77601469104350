import React from 'react';
import { combineClassNames } from 'helpers/styling-helper';
import styles from './infoTableCell.module.scss';
import {
  AVAILABLE_ACTIONS,
  RELEASE_STATUSES,
  RELEASE_TYPES
} from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import ActionsMenu from 'components/Library/ActionsMenu/actionsMenu';
import useHandleActions from './useHandleActions';
import { getReleaseCoverPlaceHolder } from 'helpers/releases-helper';

interface IInfoTableCellProps {
  name: string;
  nameAr: string;
  coverart: string;
  actions: AVAILABLE_ACTIONS[];
  releaseId: string;
  releaseType: RELEASE_TYPES;
  releaseStatus: RELEASE_STATUSES;
  albumId: string;
}

export default function InfoTableCell({
  name,
  coverart,
  nameAr,
  actions,
  releaseId,
  releaseStatus,
  releaseType,
  albumId
}: IInfoTableCellProps) {
  const handleActionClick = useHandleActions({
    releaseId,
    coverart,
    releaseStatus,
    releaseType,
    albumId,
    releaseName: name || nameAr
  });

  return (
    <div
      className={combineClassNames(
        'd-flex f-align-center',
        styles.infoTableCell,
        actions?.length > 0 && styles.hasActions
      )}
    >
      <img
        src={coverart || getReleaseCoverPlaceHolder(releaseType)}
        alt={name}
        onError={(e) => ((e.target as any).src = getReleaseCoverPlaceHolder(releaseType))}
      />
      <div className={combineClassNames('f-column h-100 f-grow-1', styles.textAndActions)}>
        <div className="f-column f-justify-center f-grow-1">
          {name && <h4 className={styles.ellipsisOverflow}>{name}</h4>}
          {nameAr && nameAr !== name && <h4 className={styles.ellipsisOverflow}>{nameAr}</h4>}
        </div>
        {actions?.length > 0 && (
          <div className={styles.actionMenuContainer}>
            <ActionsMenu actions={actions} onActionClick={handleActionClick} />
          </div>
        )}
      </div>
    </div>
  );
}
