import { getQueryStringValueFromURL } from './url-helper';

export const parseUtmParamsFromLocation = () => {
  const utm_campaign = getQueryStringValueFromURL('utm_campaign');
  const utm_source = getQueryStringValueFromURL('utm_source');
  const utm_content = getQueryStringValueFromURL('utm_content');

  if (!utm_campaign?.length) {
    return {};
  }

  return {
    utm_campaign,
    utm_source,
    utm_content
  };
};
