import Button from 'components/Reusable/Button';
import { IButton } from 'helpers/interfaces';
import React from 'react';
import styles from './release-header.module.scss';
import { combineClassNames } from 'helpers/styling-helper';

interface ReleaseHeaderProps {
  title: string;
  description?: string;
  actionButtons: IButton[];
  routingButton?: IButton;
  smallerPadding?: boolean;
}

export default function ReleaseHeader({
  title,
  description,
  actionButtons,
  routingButton,
  smallerPadding
}: ReleaseHeaderProps) {
  return (
    <div
      className={combineClassNames(
        styles.releaseHeader_container,
        smallerPadding && styles.smallerPadding
      )}
    >
      <div className="f-column f-align-start">
        <div className="d-flex gap-05 f-align-center">
          <div className={styles.title}>{title}</div>
          {routingButton && <Button {...routingButton} />}
        </div>
        <div className={styles.subtitle}>{description}</div>
      </div>
      <div className="d-flex">
        {actionButtons.map((button, index) => {
          return (
            <div className={styles.button_wrapper} key={`${button.label}-${index}`}>
              <Button {...button} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
