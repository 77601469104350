export const getQueryStringValueFromURL = (queryStringName: string): string => {
  return new URLSearchParams(location.search).get(queryStringName) || '';
};

export const getQueryParams = () => {
  return Object.fromEntries(new URLSearchParams(location.search));
};

export const createQueryStringFromObject = (queryObject: any) => {
  return new URLSearchParams(queryObject).toString();
};

export const getSidFromUrl = () => {
  if (typeof window !== 'undefined') {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    return params.get('sid') || params.get('appsid');
  }

  return '';
};

export const setPageTitle = (page) => {
  document.title = `Anghami Creators - ${page}`;
};

export const decodeQueryParams = (encodedString: string) =>
  decodeURIComponent(escape(window.atob(encodedString)));

export const encodeQueryParams = (decodedString: string) =>
  btoa(unescape(encodeURIComponent(decodedString)));

export const getEncodedQueryParam = (formQueryParam: string) => {
  // encoded query param is a stringified json object base64 encoded
  return formQueryParam ? JSON.parse(decodeQueryParams(formQueryParam)) : {};
};
