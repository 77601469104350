import { IDPReponse } from '@anghami/neogateway/dist/endpoints/getDashboardProfile';
import { getCoverArtImage } from 'helpers/releases-helper';
import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SocialPlatformItem, { SocialsEnums } from '../SocialPlatformItem/socialPlatformItem';
import styles from '../profileCommon.module.scss';

export default function ProfilePreview({ profileData }: { profileData: IDPReponse }) {
  const { t } = useTranslation('common');
  const musicLanguageStringMap = {
    0: t('Mixed'),
    1: t('Arabic'),
    2: t('International')
  };
  return (
    <div>
      <div className={styles.section}>
        <h2>{t('Artist Profile pic')}</h2>
        <p>{t('Choose a picture for your artist profile')}</p>
        <div className="d-flex f-align-center gap-1">
          <img
            src={
              profileData?.coverartid == 'FB'
                ? profileData.facebookdata.profile_picture
                : getCoverArtImage(profileData.coverartid)
            }
            className={styles.profilePic}
            alt="profile-pic"
          />
          {profileData?.coverartid == 'FB' && (
            <span>{t('youre-using-picture-from-facebook-page-as-default-artist-picture')}</span>
          )}
        </div>
      </div>
      <div className={styles.section}>
        <h2>{t('Artist Info')}</h2>
        <p>{t('Your basic information')}</p>
        <div className="d-flex f-align-center gap-2 mb-1 f-wrap">
          <div className={combineClassNames('f-column', styles.infoItem)}>
            <span className="bold">{t('Arabic Name')}</span>
            <span>{profileData.artistnamearabic}</span>
          </div>
          <div className={combineClassNames('f-column', styles.infoItem)}>
            <span className="bold">{t('English Name')}</span>
            <span>{profileData.artistname}</span>
          </div>
        </div>
        <div className="d-flex f-align-center gap-2 mb-1">
          <div className={combineClassNames('f-column', styles.infoItem)}>
            <span className="bold">{t('Music Language')}</span>
            <span>{musicLanguageStringMap[Number(profileData.artistlanguage) || 0]}</span>
          </div>
        </div>
        <div className={combineClassNames('d-flex f-align-center gap-2 mb-1 f-wrap')}>
          <div className={combineClassNames('f-column', styles.infoItem)}>
            <span className="bold">{t('Arabic bio')}</span>
            <div className={styles.bioText}>{profileData.ar_bio}</div>
          </div>
          <div className={combineClassNames('f-column', styles.infoItem)}>
            <span className="bold">{t('English Bio')}</span>
            <div className={styles.bioText}>{profileData.en_bio}</div>
          </div>
        </div>
      </div>
      <div className="d-flex f-align-center gap-2 mb-1 f-wrap">
        <div className={styles.section}>
          <h2>{t('Personal Information')}</h2>
          <p>{t('these-information-wont-show-on-your-artist-profile')}</p>
          <div className={combineClassNames('f-column', styles.infoItem)}>
            <span className="bold">{t('Name')}</span>
            <span>{profileData.artistname}</span>
          </div>
          <div className={combineClassNames('f-column', styles.infoItem)}>
            <span className="bold">{t('Email')}</span>
            <span>{profileData.email}</span>
          </div>
          <div className={combineClassNames('f-column', styles.infoItem)}>
            <span className="bold">{t('Phone Number')}</span>
            <span>{profileData.phone}</span>
          </div>
        </div>
        <div className={styles.section}>
          <h2 className={styles.socialsTitle}>{t('Social Links')}</h2>
          {Object.values(SocialsEnums)
            ?.filter((sp) => profileData[sp])
            .map((sp) => {
              return (
                <div key={sp} className="mb-1">
                  <SocialPlatformItem type={sp} account={profileData[sp]} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
