import AnghamiAnalytics from '@anghami/neoanalytics';
import { Amplitude } from '@anghami/neoanalytics/dist/sdks/amplitude';
import { Raygun } from '@anghami/neoanalytics/dist/sdks/raygun';
import { GoogleAnalytics } from '@anghami/neoanalytics/dist/sdks/google-analytics';
import { IConfig } from '@anghami/neoanalytics/dist/interfaces';
import { PLATFORMS } from '@anghami/neoanalytics/dist/enums/platforms';
import packageFile from '../../package.json';
import { CUSTOM_DASHBOARD_ERROR } from './../constants/raygunTags';
import { environment } from 'env/environment';
import { parseUtmParamsFromLocation } from './utm-helper';
import { GENERAL_ERROR_EVENT, OPEN_APP } from 'constants/amplitudeEvents';
import Cookies from './cookies-helper';
import NeoAnalytics from '@anghami/neoanalytics';
import { IAuthenticateRes } from 'interfaces/auth.interface';

const CONFIG: IConfig = {
  project: {
    name: PLATFORMS.CREATORS,
    defaultProperties: {
      web_medium: PLATFORMS.CREATORS,
      useragent: navigator.userAgent
    },
    config: {
      version: packageFile.version
    }
  },
  amplitude: {
    key: environment.amplitudeKey || ''
  },
  googleAnalytics: {
    key: environment.ganalyticsKey || ''
  },
  raygun: {
    key: environment.raygun || ''
  }
};

type WindowR4js = Window & typeof globalThis & { rg4js: any };

const setUserData = (user?: IAuthenticateRes) => {
  const fp = Cookies.get('fingerprint');
  const country = user?.usercountry || Cookies.get('country');
  const email = user?.d_email || Cookies.get('userEmail');
  const anid = user?.anid || Cookies.get('anid');
  const name = user?.d_name || Cookies.get('fullname');
  const data = {
    email,
    fp,
    country,
    anid,
    name,
    fname: name,
    lname: ''
  };
  NeoAnalytics.setUserData(data);
};

const initAnalytics = async (defaultProperties: typeof CONFIG.project.defaultProperties) => {
  CONFIG.project.defaultProperties = {
    ...CONFIG.project.defaultProperties,
    ...defaultProperties
  };

  new AnghamiAnalytics(CONFIG);

  Amplitude.getAmplitudeInstance().then((instance) => {
    const utmtrackers = parseUtmParamsFromLocation();
    instance.setUtmCampaignObject(utmtrackers);
    instance.logAmplitudeEvent(OPEN_APP);
  });

  await Raygun.getRaygunInstance();

  GoogleAnalytics.getGoogleAnalyticsInstance();
};

const logAmplitudeEvent = (eventName: string, eventProperties: any = {}) => {
  Amplitude.getAmplitudeInstance().then((instance) => {
    instance.logAmplitudeEvent(eventName, eventProperties);
  });
};

const logGeneralErrorEvent = (error, page, extraData = {}) => {
  logRaygunCustomError(error, [page], { page, ...extraData });
  logAmplitudeEvent(GENERAL_ERROR_EVENT, {
    error: error?.message || error,
    page
  });
};

const logGoogleAnalyticsPageview = (url: string) => {
  // delay google event for 1s after everything loads
  setTimeout(
    () =>
      GoogleAnalytics.getGoogleAnalyticsInstance().then((instance) =>
        instance.logGoogleAnalyticsPageviewEvent(url)
      ),
    1000
  );
};

const logRaygunCustomError = (error, tags: string[], customData: any = {}) => {
  if (!(window as WindowR4js).rg4js) return;
  (window as WindowR4js).rg4js('send', {
    error: error,
    tags: [CUSTOM_DASHBOARD_ERROR, ...tags],
    customData
  });
};

const logRaygunBreadcrumb = (
  message: string,
  level: 'info' | 'warning' | 'error' | 'debug' = 'info',
  location = '',
  customData: Record<string, string | number> = null
) => {
  (window as WindowR4js).rg4js('recordBreadcrumb', {
    message: message,
    ...(customData && {
      metadata: {
        object: customData
      }
    }),
    level: level,
    location: location
  });
};

export {
  initAnalytics,
  logAmplitudeEvent,
  logGeneralErrorEvent,
  logGoogleAnalyticsPageview,
  logRaygunCustomError,
  setUserData,
  logRaygunBreadcrumb
};
