import React from 'react';
import { environment } from 'env/environment';
import { combineClassNames } from 'helpers/styling-helper';
import { useTranslation } from 'react-i18next';
import styles from './verificationPending.module.scss';

export default function VerificationPending({ isDialog }: { isDialog?: boolean }) {
  const { t } = useTranslation('common');
  return (
    <div className={combineClassNames(styles.responsiveDiv, isDialog && styles.dialog)}>
      <span>{t('artist_signup_pending_review')}</span>
      <img
        src={environment.web_assets_cdn_url + '/images/Dash-WaitingApproval.png'}
        alt="waiting-approval"
      />
    </div>
  );
}
