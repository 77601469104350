import Dialog from 'components/Reusable/Dialog';
import React from 'react';
import { dialogsContext, IDialogsData } from './dialogs.context';

interface IProps {
  children?: any;
}

const DialogWrapper = ({ children }: IProps) => {
  const [currentDialog, setCurrentDialog] = React.useState<IDialogsData>(null);
  const closeDialog = () => {
    setCurrentDialog(null);
  };
  const showDialog = (data: IDialogsData) => {
    setCurrentDialog(data);
  };

  return (
    <dialogsContext.Provider value={{ showDialog, closeDialog }}>
      {children}
      {currentDialog && (
        <Dialog
          isOpen={!!currentDialog?.dialog}
          onClose={closeDialog}
          isBackDrop={currentDialog?.backdrop}
          customClassName={currentDialog?.customClassName}
          hasCloseIcon={currentDialog?.hasCloseIcon}
        >
          {React.cloneElement(currentDialog?.dialog, {
            ...currentDialog?.dialog.props,
            closeDialog
          })}
        </Dialog>
      )}
    </dialogsContext.Provider>
  );
};

export default DialogWrapper;
