import React, { ChangeEvent, ReactElement } from 'react';
import { IUploadedFile } from 'interfaces/formsComponents.interface';
import { combineClassNames } from 'helpers/styling-helper';
import { toastContext } from 'components/contexts/toast/toast.context';
import { useTranslation } from 'react-i18next';
import styles from './fileUploadButton.module.scss';

interface FileUploadButtonProps {
  onUpload: (event: IUploadedFile[]) => void;
  multiple?: boolean;
  fileTypes: string;
  label: string;
  customClass?: string;
  customIcon?: ReactElement;
}
export default function FileUploadButton({
  multiple,
  fileTypes,
  onUpload,
  label,
  customClass,
  customIcon
}: FileUploadButtonProps) {
  const [uploadedFiles, setUploadedFiles] = React.useState<IUploadedFile[]>([]);
  const { openToast } = React.useContext(toastContext);
  const { t } = useTranslation('common');
  React.useEffect(() => {
    if (uploadedFiles.length > 0) {
      onUpload(uploadedFiles);
    }
  }, [uploadedFiles]);

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedfiles = [];
    if (!e.target.files || e.target.files.length === 0) return;
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const mainType = '.' + file.type?.split('/')[1];
      if (fileTypes.indexOf(mainType) === -1) {
        openToast(t('file type is not supported').replace('%@', file.type), 'error');
        return;
      }
      uploadedfiles.push({
        type: file.type,
        name: file.name,
        size: file.size,
        file: file,
        src: URL.createObjectURL(file)
      });
      if (uploadedfiles.length === files.length) {
        setUploadedFiles(uploadedfiles);
      }
    });
  };

  return (
    <div
      className={combineClassNames(
        'd-flex f-justify-center f-align-center',
        styles.upload_button,
        customClass
      )}
    >
      <input multiple={multiple} type={'file'} onChange={onSelectFile} accept={fileTypes}></input>
      {customIcon}
      <span>{label}</span>
    </div>
  );
}
