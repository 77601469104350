import React from 'react';
import TimePicker from 'components/Reusable/CustomAntdCompnents/TimePicker';
import { ReactComponent as TimeIcon } from 'icons/time.svg';
import common from '../common.module.scss';
import { combineClassNames } from 'helpers/styling-helper';
import WithLabelWrapper from 'components/Reusable/WithLabelWrapper';
import { IInputWithLabel } from 'interfaces/formsComponents.interface';
// allowClear	Whether allow clearing text	boolean	true
// autoFocus	If get focus when component mounted	boolean	false
// bordered	Whether has border style	boolean	true
// className	The className of picker	string	-
// clearIcon	The custom clear icon	ReactNode	-
// clearText	The clear tooltip of icon	string	clear
// defaultValue	To set default time	moment	-
// disabled	Determine whether the TimePicker is disabled	boolean	false
// disabledTime	To specify the time that cannot be selected	DisabledTime	-	4.19.0
// format	To set the time format	string	HH:mm:ss
// getPopupContainer	To set the container of the floating layer, while the default is to create a div element in body	function(trigger)	-
// hideDisabledOptions	Whether hide the options that can not be selected	boolean	false
// hourStep	Interval between hours in picker	number	1
// inputReadOnly	Set the readonly attribute of the input tag (avoids virtual keyboard on touch devices)	boolean	false
// minuteStep	Interval between minutes in picker	number	1
// open	Whether to popup panel	boolean	false
// placeholder	Display when there's no value	string | [string, string]	Select a time
// placement	The position where the selection box pops up	bottomLeft bottomRight topLeft topRight	bottomLeft
// popupClassName	The className of panel	string	-
// popupStyle	The style of panel	CSSProperties	-
// renderExtraFooter	Called from time picker panel to render some addon to its bottom	() => ReactNode	-
// secondStep	Interval between seconds in picker	number	1
// showNow	Whether to show Now button on panel	boolean	-	4.4.0
// status	Set validation status	'error' | 'warning' | 'success' | 'validating'	-	4.19.0
// suffixIcon	The custom suffix icon	ReactNode	-
// use12Hours	Display as 12 hours format, with default format h:mm:ss a	boolean	false
// value	To set time	moment	-
// onChange	A callback function, can be executed when the selected time is changing	function(time: moment, timeString: string): void	-
// onOpenChange	A callback function which will be called while panel opening/closing	(open: boolean) => void	-
// onSelect	A callback function, executes when a value is selected	function(time: moment): void	-

interface TimeInputProps extends Omit<IInputWithLabel, 'value' | 'onChange'> {
  value: Date;
  onChange: (value: Date, dateString: string) => void;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  customInputClass?: string;
  allowClear?: boolean;
}

export default function TimeInput(props: TimeInputProps) {
  const { error, value, onChange, placeholder, disabled, customInputClass, allowClear } = props;
  return (
    <WithLabelWrapper {...props}>
      <TimePicker
        suffixIcon={<TimeIcon width={25} height={25} fill="grey" />}
        className={combineClassNames(
          common.input,
          common.input_picker,
          customInputClass,
          error ? common.error : ''
        )}
        allowClear={allowClear}
        disabled={disabled}
        placeholder={placeholder}
        status={error ? 'error' : ''}
        onChange={onChange}
        value={value}
      ></TimePicker>
    </WithLabelWrapper>
  );
}
