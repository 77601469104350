import {
  AnalyticsType,
  ChartTypes,
  IAnalyticsSection
} from '@anghami/neogateway/dist/endpoints/getAnalyticsSections';
import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import SectionItem from '../SectionItem/sectionItem';
import styles from './analyticsSection.module.scss';
import './override.scss';

interface IAnalyticsSectionProps extends IAnalyticsSection {
  id: string;
  analyticsType: AnalyticsType;
}

export default function AnalyticsSection({
  title,
  data,
  id,
  analyticsType
}: IAnalyticsSectionProps) {
  return (
    <section className={styles.sectionContainer}>
      <h3>{title}</h3>
      {data.map((row, index) => (
        <div
          className="d-flex gap-1 f-align-center f-justify-between f-wrap"
          key={'analytics-section-' + index}
          style={{ marginBottom: '2rem' }}
        >
          {row.map((item, index) =>
            item?.id ? (
              <div
                key={item.title + '-' + index}
                className={combineClassNames(
                  'f-1 w-50 f-basis-auto',
                  styles.sectionItemContainer,
                  row.length === 1 || item.type === ChartTypes.percentageChart
                    ? `w-100`
                    : styles.fixedHeight
                )}
              >
                <SectionItem
                  id={id}
                  analyticsType={analyticsType}
                  title={item.title}
                  chartId={item.id}
                  allTimeData={item.all_time_data}
                  chartType={item.type}
                />
              </div>
            ) : (
              <div key={'empty' + '-' + index} className="w-50 f-1 f-basis-auto"></div>
            )
          )}
        </div>
      ))}
    </section>
  );
}
