export const isPlatformServer = () => typeof window === 'undefined';
export const isPlatformBrowser = () => typeof window !== 'undefined';
export const getMainApiUrl = (): string => {
  const isHttps = window.location.href.indexOf('https') > -1;
  const isLocal = window.location.href.indexOf('angha.me') > -1;
  return isLocal
    ? isHttps
      ? 'https://api.angha.me/gateway.php'
      : 'http://api.angha.me/gateway.php'
    : 'https://api.anghami.com/gateway.php';
};
