import React, { ReactElement } from 'react';

export interface IDialogsContext {
  showDialog: (dialogsData: IDialogsData) => void;
  closeDialog: () => void;
}

export interface IDialogsData {
  dialog: ReactElement;
  backdrop: boolean;
  customClassName?: string;
  hasCloseIcon?: boolean;
}

export const dialogsContext = React.createContext<IDialogsContext>({
  showDialog: () => null,
  closeDialog: () => null
});
