import React from 'react';
import styles from './closeIcon.module.scss';
import { ReactComponent as CloseIc } from 'icons/close.svg';
import { combineClassNames } from 'helpers/styling-helper';

interface ICloseIconProps {
  onClick: () => void;
  customClassName?: string;
}

export default function CloseIcon({ onClick, customClassName }: ICloseIconProps) {
  return (
    <CloseIc className={combineClassNames(styles.closeIcon, customClassName)} onClick={onClick} />
  );
}
