import React from 'react';
import { IPeriodValue } from '../constants/analytics.contants';

export const periodContext = React.createContext<{
  selectedPeriod: IPeriodValue;
  setSelectedPeriod: (value: IPeriodValue) => void;
}>({
  selectedPeriod: null,
  setSelectedPeriod: () => null
});
