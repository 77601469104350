import Auth from 'components/contexts/auth';
import { initNeoGatewayBrowser } from 'helpers/gateway-helper';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';
import { initAnalytics, setUserData } from 'helpers/analytics-helper';
import { useTranslation } from 'react-i18next';
import { combineClassNames } from 'helpers/styling-helper';
import { environment } from 'env/environment';
import Cookies from 'helpers/cookies-helper';
import { ConfigProvider } from 'antd';
import colors from 'styles/partials/_colors_exports.module.scss';
import ToastWrapper from 'components/contexts/toast';
const Zendesk = React.lazy(() => import('react-zendesk'));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: true,
      refetchOnWindowFocus: !window.location.href.includes('angha.me')
    }
  }
});

function AppSetupWrapper({ children }) {
  const [isGatewayInitialized, setIsGatewayInitialized] = React.useState(false);
  const [isConnectionLost, setIsConnectionLost] = React.useState(false);
  const [showZendeskButton, setShowZendeskButton] = React.useState(false);
  const { t, i18n } = useTranslation('common');
  const handleLang = () => {
    const lang = Cookies.get('c-lang');
    i18n.changeLanguage(lang);
    document.documentElement.lang = lang || 'en';
  };
  React.useEffect(() => {
    handleLang();
    initAnalytics({
      useragent: navigator.userAgent
    }).then(() => setUserData());
    initNeoGatewayBrowser().then(async (gatewayInstance) => {
      await gatewayInstance.loadSodium();
      setIsGatewayInitialized(true);
    });
    setTimeout(() => {
      setShowZendeskButton(true);
    }, 100);
  }, []);

  const retryOnConnectionLost = () => {
    setIsConnectionLost(false);
    window.location.reload();
  };
  const setting = {
    color: {
      theme: '#000'
      // launcher: '#CC3A83', // This will also update the badge
      // launcherText: '#E589B7',
      // button: '#8A0648',
      // resultLists: '#691840',
      // header: '#203D9D',
      // articleLinks: '#FF4500'
    },
    cookies: false,
    launcher: {
      label: { '*': t('Need Help') }
    },
    contactForm: {
      title: {
        '*': 'Feedback'
      },
      attachments: true,
      subject: false,
      suppress: false,
      fields: [
        { id: 'description', prefill: { '*': t('Fill your description here') } },
        { id: 2142225, prefill: { '*': 'My text' } }
        // { id: 'user_id', prefill: { '*': t('Fill your description here') } }
      ]
      // ticketForms: [
      //   {
      //     id: 426353,
      //     title: true
      //   }
      // ]
    }
  };
  return (
    <CookiesProvider>
      {isConnectionLost && (
        <div className={combineClassNames('no-internet')}>
          <span>{t('Unable to connect')}</span>
          <button onClick={retryOnConnectionLost}>{t('Retry')}</button>
        </div>
      )}
      {isGatewayInitialized && (
        <ToastWrapper>
          <Auth key={'main'} setIsConnectionLost={setIsConnectionLost}>
            <QueryClientProvider client={queryClient}>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: colors['branding-purple'],
                    colorInfo: colors['branding-purple'],
                    fontFamily: 'AdapterArabicDisplay',
                    colorBgBase: colors['light-10'],
                    colorError: colors['failure-color'],
                    colorSuccess: colors['success-color'],
                    colorWarning: colors['orange']
                  }
                }}
              >
                <div style={isConnectionLost ? { paddingTop: '2.5rem' } : {}}>{children}</div>
              </ConfigProvider>
            </QueryClientProvider>
          </Auth>
        </ToastWrapper>
      )}
      {showZendeskButton && (
        <React.Suspense fallback={<></>}>
          <Zendesk
            defer
            zendeskKey={environment.zendesk_key}
            {...setting}
            position={{
              horizontal: i18n.language !== 'ar' ? 'right' : 'left',
              vertical: 'bottom'
            }}
          />
        </React.Suspense>
      )}
    </CookiesProvider>
  );
}

export default AppSetupWrapper;
