import React from 'react';
import { getCoverArtImage } from 'helpers/releases-helper';
import { combineClassNames } from 'helpers/styling-helper';
import styles from './songToPitch.module.scss';
import { ISongForPitching } from '@anghami/neogateway/dist/endpoints/getSongsForPitching';
import { useQuery } from 'react-query';
import getReleaseDetails, {
  RELEASE_TYPES
} from '@anghami/neogateway/dist/endpoints/getreleasedetails';
import { gateway } from 'helpers/gateway-helper';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LoaderIcon } from 'icons/loaders/oval.svg';
import { GET_RELEASE_DETAILS } from 'constants/reactQueryKeys';

// Although the endpoint is called getSongsForPitching api still return album items, so here song might mean album too

type PartialSong = Partial<ISongForPitching> & { coverurl?: string };
interface ISongToPitch {
  song: PartialSong;
  handleClick?: (song: PartialSong) => void;
}
export default function SongToPitch({ song, handleClick }: ISongToPitch) {
  const { t } = useTranslation();
  const [selectedAlbumId, setSelectedAlbumId] = React.useState(null);
  const { data: albumSongs, isLoading: isAlbumSongsLoading } = useQuery(
    [GET_RELEASE_DETAILS, RELEASE_TYPES.Album, selectedAlbumId],
    async () => {
      const { data } = await gateway.callEndpoint(getReleaseDetails, {
        release_id: selectedAlbumId,
        release_type: RELEASE_TYPES.Album
      });
      return (
        data?.data?.songs?.map((song) => {
          const newsong: PartialSong = {
            id: song.songid,
            title: song.songname,
            releasedate: song.releasedate,
            type: song.releasetype,
            coverurl: song.coverurl,
            albumid: song.albumid
          };
          return newsong;
        }) || null
      );
    },
    {
      enabled: !!selectedAlbumId,
      refetchOnWindowFocus: false
    }
  );

  const handleItemClick = (item: PartialSong) => {
    // in case in preview mode (in dialog)
    if (!handleClick) return;
    if (item.type === 'album') {
      setSelectedAlbumId(item.id === selectedAlbumId ? null : item.id);
      return;
    }
    handleClick(item);
  };

  return (
    <>
      <div
        className={combineClassNames(
          'd-flex f-align-center gap-1',
          styles.tableItemContainer,
          handleClick && styles.clickable,
          song?.id === selectedAlbumId && styles.selected
        )}
        onClick={() => handleItemClick(song)}
      >
        <img src={song.coverurl || getCoverArtImage(song.coverArt)} alt={'release-cover'} />
        <div
          className={combineClassNames(
            'd-flex f-justify-between f-align-center f-grow-1',
            styles.textSection
          )}
        >
          <div className={combineClassNames('f-column', styles.textContainer)}>
            <h5>{song.title}</h5>
            <div className="d-flex f-justify-between">
              {song.type === 'album' && <p>{song.type}</p>}
            </div>
          </div>
          {song.type === 'album' &&
            (song.id === selectedAlbumId ? (
              isAlbumSongsLoading ? (
                <LoaderIcon className={styles.loaderIcon} width={25} />
              ) : (
                <span className={styles.exandButton}>{t('Hide')}</span>
              )
            ) : (
              <span className={styles.exandButton}>{t('view')}</span>
            ))}
        </div>
      </div>
      {albumSongs?.length > 0 &&
        albumSongs.map((albumSong, index) => (
          <SongToPitch
            key={song.id + '-' + index}
            song={albumSong}
            handleClick={() => handleItemClick(albumSong)}
          />
        ))}
    </>
  );
}
