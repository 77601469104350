import React from 'react';
import Button from 'components/Reusable/Button';
import CloseIcon from 'components/Reusable/CloseIcon';
import styles from './confirmationDialog.module.scss';

const ConfirmationDialog = ({
  closeDialog,
  onSubmit,
  title,
  submitButtonLabel,
  cancelButtonLabel
}: {
  closeDialog?: () => void;
  onSubmit: () => void;
  title: string;
  submitButtonLabel: string;
  cancelButtonLabel?: string;
}) => (
  <div className="f-column f-center p-2 position-relative gap-1">
    <h3>{title}</h3>
    <div className="f-center gap-1">
      {cancelButtonLabel && (
        <Button
          label={cancelButtonLabel}
          customClasses={['default']}
          onSubmit={closeDialog}
          size="md"
        ></Button>
      )}
      <Button
        label={submitButtonLabel}
        customClasses={['purpleFilled', 'f-self-end']}
        onSubmit={onSubmit}
        size="md"
      ></Button>
    </div>
    <CloseIcon onClick={closeDialog} customClassName={styles.dialogCloseButton} />
  </div>
);
export default ConfirmationDialog;
