import React from 'react';
import Button from 'components/Reusable/Button';
import TextArea from 'components/Reusable/TextArea';
import TextInput from 'components/Reusable/TextInput';
import CloseIcon from 'components/Reusable/CloseIcon';
import styles from './inputDialog.module.scss';

const InputDialog = ({
  closeDialog,
  onSubmit,
  isTextArea,
  title,
  buttonLabel,
  initialValue = ''
}: {
  closeDialog?: () => void;
  onSubmit: (value: string) => void;
  isTextArea?: boolean;
  title: string;
  buttonLabel: string;
  initialValue?: string;
}) => {
  const [value, setValue] = React.useState(initialValue);
  return (
    <div className="f-column f-justify-center p-2 position-relative gap-1">
      <h3>{title}</h3>
      {isTextArea ? (
        <TextArea
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      ) : (
        <TextInput
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      )}
      <Button
        label={buttonLabel}
        customClasses={['purpleFilled', 'f-self-end']}
        onSubmit={() => onSubmit(value)}
        disabled={!value || value === initialValue}
      ></Button>
      <CloseIcon onClick={closeDialog} customClassName={styles.dialogCloseButton} />
    </div>
  );
};
export default InputDialog;
