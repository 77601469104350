import React from 'react';
import Select from 'antd/es/select';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SucessStatus } from 'icons/status-succeed.svg';
import { ReactComponent as FailedStatus } from 'icons/status-failed.svg';

export default function RssActiveFilter({
  selectedFilter = 1,
  setSelectedFilter
}: {
  selectedFilter: 0 | 1;
  setSelectedFilter: (val: 0 | 1) => void;
}) {
  const { t } = useTranslation();
  const statusFilterItems = [
    {
      value: 1,
      label: (
        <span className="d-flex f-align-center gap-05">
          <SucessStatus width={15} />
          {t('Active')}
        </span>
      )
    },
    {
      value: 0,
      label: (
        <span className="d-flex f-align-center gap-05">
          <FailedStatus width={15} />
          {t('Deactivated')}
        </span>
      )
    }
  ];
  return (
    <Select
      defaultValue={selectedFilter}
      style={{ width: 155 }}
      onChange={(value: 0 | 1) => setSelectedFilter(value)}
      options={statusFilterItems}
      optionLabelProp="label"
    />
  );
}
