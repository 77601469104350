import { combineClassNames } from 'helpers/styling-helper';
import React from 'react';
import styles from './checkBox.module.scss';

interface CheckBoxProps {
  isChecked: boolean;
  label?: string;
  onChange: (value: boolean) => void;
  customInputClass?: string;
}

export default function CheckBox({ onChange, isChecked, label, customInputClass }: CheckBoxProps) {
  return (
    <div
      key={label}
      className={combineClassNames('d-flex', 'f-align-center', styles.checkbox_item_container)}
    >
      <input
        className={combineClassNames(
          styles.checkboxInput,
          customInputClass || '',
          isChecked && styles.checked
        )}
        type={'checkbox'}
        checked={isChecked}
        onChange={(_e) => onChange(_e.target.checked)}
      />
      <div className={styles.label}>{label}</div>
    </div>
  );
}
