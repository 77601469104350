import { IEnhancedSectionData } from '../../interfaces';
import NoData from 'components/Reusable/NoData';
import React from 'react';
import SectionItemHeader from '../SectionItemHeader/sectionItemHeader';
import { IChartItemValue } from '@anghami/neogateway/dist/endpoints/getAnalyticsSections';
import { shortenNumbers } from 'helpers/numbers-helper';

const SummaryItem = ({ title, subtitle }) => {
  return (
    <div className="f-column f-justify-start">
      <span className="d-block">{title}</span>
      <span className="bold">{subtitle}</span>
    </div>
  );
};

export default function SummaryChart({ data, title, allTimeData }: IEnhancedSectionData) {
  const formatNumber = (item: IChartItemValue) => {
    if (item.extras?.unit) {
      if (item.extras.unit === '%') {
        return item.value + item.extras.unit;
      }
      return shortenNumbers(Number(item.value)) + ' ' + item.extras.unit;
    }
    return shortenNumbers(Number(item.value));
  };
  return (
    <div>
      <SectionItemHeader title={title} allTimeData={allTimeData} />
      {data.length === 0 ? (
        <NoData allTimeData={allTimeData} />
      ) : (
        <div className="d-flex f-align-center f-justify-start gap-2 overflow-scroll">
          {data.map((item, index) => (
            <SummaryItem
              key={item.key + '-' + index}
              title={item.key}
              subtitle={formatNumber(item)}
            />
          ))}
        </div>
      )}
    </div>
  );
}
