import React, { ReactElement } from 'react';
import { ReactComponent as FbIcon } from 'icons/fb-filled.svg';
import { ReactComponent as InstagramIcon } from 'icons/instagram-filled.svg';
import { ReactComponent as TwitterIcon } from 'icons/twitter-filled.svg';
// import { ReactComponent as TiktokIcon } from 'icons/tiktok-filled.svg';
// import { ReactComponent as YoutubeIcon } from 'icons/youtube-filled.svg';
import LinkItem from 'components/Reusable/LinkItem';
import TextInput from 'components/Reusable/TextInput';
import { useTranslation } from 'react-i18next';

export enum SocialsEnums {
  fb = 'facebook',
  instagram = 'instagram',
  twitter = 'twitter'
  // youtube = 'youtube',
  // tiktok = 'tiktok'
}

interface ISocialPlatform {
  name: string;
  icon: ReactElement;
  getLink?: (account: string) => string;
  extractAccount?: (input: string) => string;
  validateFunction?: (account: string) => Promise<boolean>;
}

const SocialPlatforms: Record<SocialsEnums, ISocialPlatform> = {
  [SocialsEnums.fb]: {
    name: 'Facebook',
    icon: <FbIcon />,
    getLink: (account) => `https://facebook.com/${account}`,
    extractAccount: (textInput: string) => {
      if (textInput.includes('facebook.com')) {
        const url = new URL(
          textInput.includes('https')
            ? textInput.replace('www.', '')
            : 'https://facebook.com' + textInput.split('facebook.com')[1]
        );
        const accountName = url
          .toString()
          .replace(url.search, '')
          .replace('https://facebook.com/', '');
        return accountName;
      }
      return textInput;
    },
    validateFunction: async (facebookaccount: string) => {
      const res = await fetch(
        `https://graph.facebook.com/${facebookaccount}/picture?width=1000&height=1000&redirect=false`,
        {}
      );
      const data = await res.json();
      if (data.error) {
        return false;
      }
      return true;
    }
  },
  [SocialsEnums.instagram]: {
    name: 'Instagram',
    icon: <InstagramIcon />,
    getLink: (account) => `https://instagram.com/${account}`
  },
  [SocialsEnums.twitter]: {
    name: 'Twitter',
    icon: <TwitterIcon />,
    getLink: (account) => `https://twitter.com/${account}`
  }
  // [SocialsEnums.youtube]: {
  //   name: 'Youtube',
  //   icon: <YoutubeIcon />,
  //   getLink: (account) => `https://youtube.com/${account}`
  // },
  // [SocialsEnums.tiktok]: {
  //   name: 'Tiktok',
  //   icon: <TiktokIcon />,
  //   getLink: (account) => `https://tiktok.com/${account}`
  // }
};

interface SocialPlatformItemProps {
  type: SocialsEnums;
  account: string;
  isEdit?: boolean;
  onChange?: (value: string) => void;
}

export default function SocialPlatformItem({
  type,
  account,
  isEdit,
  onChange
}: SocialPlatformItemProps) {
  const { t } = useTranslation('common');
  const [error, setError] = React.useState('');
  const [tempValue, setTempValue] = React.useState(account);
  const handleBlur = async (e) => {
    const account = SocialPlatforms[type].extractAccount
      ? SocialPlatforms[type].extractAccount(e.target.value)
      : e.target.value;
    const valid = SocialPlatforms[type].validateFunction
      ? await SocialPlatforms[type].validateFunction(account)
      : true;
    setError(!valid ? t('Facebook account not found') : '');
    if (valid) {
      onChange(account);
    }
  };
  return (
    <div className="d-flex f-align-center gap-1">
      {SocialPlatforms[type].icon}
      <div>
        {isEdit ? (
          <TextInput
            value={tempValue}
            placeholder={t('social account').replace('%@', SocialPlatforms[type].name)}
            onChange={(e) => setTempValue(e.target.value)}
            onBlur={handleBlur}
            error={error}
            showErrorText={true}
          />
        ) : (
          <LinkItem
            link={SocialPlatforms[type].getLink(account)}
            text={t('go to social profile').replace('%@', SocialPlatforms[type].name)}
            newTab={true}
            customClassName="f-row-reverse"
          />
        )}
      </div>
    </div>
  );
}
