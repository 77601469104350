import { environment } from 'env/environment';
import React from 'react';

const anghamiSocialMedia = [
  {
    platform: 'twitter',
    handler: 'anghami4artists',
    hide: false
  },
  {
    platform: 'instagram',
    handler: 'anghami',
    hide: true
  },
  {
    platform: 'facebook',
    handler: 'anghami',
    hide: true
  }
];

export default function SocialMediaLinks() {
  return (
    <div className="d-flex">
      {anghamiSocialMedia
        .filter((a) => !a.hide)
        .map((sm, idx) => (
          <a
            key={idx}
            target="_blank"
            href={`https://www.${sm.platform}.com/${sm.handler}`}
            rel="noreferrer"
          >
            <img
              style={{ width: '2em' }}
              src={`${environment.creators_assets_cdn}/images/${sm.platform}_icon.svg`}
              alt={sm.platform}
            />
          </a>
        ))}
    </div>
  );
}
