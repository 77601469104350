import React from 'react';
import { combineClassNames } from 'helpers/styling-helper';
import { useTranslation } from 'react-i18next';
import { IFormField, IFormFields, ISection } from 'interfaces/formsComponents.interface';
import FormField from './formField';
import styles from './form.module.scss';

interface FormProps {
  id: string;
  sections: ISection[];
  // TODO: rename to formFields
  formValues: IFormFields;
  setFormValues: (newValues: IFormFields, changedValue: string) => void;
  customClasses?: {
    container?: string;
    sectionContainer?: string;
    sectionData?: string;
  };
  isDisabled?: boolean;
}

export default function Form({
  id,
  sections,
  formValues,
  setFormValues,
  customClasses,
  isDisabled
}: FormProps) {
  const { t } = useTranslation('common');

  const updateFormFieldsValues = (fieldName: string, value: any, extraValue: any = null) => {
    const newFormValues = { ...formValues };
    newFormValues[fieldName] = { ...newFormValues[fieldName], value, extraValue: extraValue };
    setFormValues(newFormValues, fieldName);
  };

  return (
    <form key={id} className={combineClassNames(styles.form_container, customClasses?.container)}>
      {sections?.map((section, index) => {
        return (
          <div
            key={`${section}-${index}`}
            className={combineClassNames(styles.SectionContainer, customClasses?.sectionContainer)}
          >
            <div className={styles.sectionTitle}>{section?.title}</div>
            <div className={combineClassNames(styles.sectionData, customClasses?.sectionData)}>
              {section?.data.map((fieldRowName, index) => {
                return (
                  <div key={`row-${index}`} className={styles.form_row}>
                    {fieldRowName
                      ?.filter((fn) => formValues[fn])
                      .map((fieldName) => {
                        const field: IFormField = {
                          ...formValues[fieldName],
                          extraLabel:
                            formValues[fieldName].extraLabel ||
                            (!formValues[fieldName].required && t('optional')) ||
                            '',
                          // force remove required when input is hidden
                          required: formValues[fieldName].isHidden
                            ? false
                            : formValues[fieldName].required,
                          disabled: isDisabled || formValues[fieldName].disabled
                        };
                        const key = `${id}-${field.type}-${field.name}`;
                        return (
                          <FormField
                            key={key}
                            id={key}
                            field={field}
                            onUpdate={updateFormFieldsValues}
                          />
                        );
                      })}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </form>
  );
}
