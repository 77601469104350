import {
  differenceInCalendarDays,
  differenceInCalendarWeeks,
  differenceInCalendarMonths,
  subDays,
  subWeeks,
  subMonths,
  format
} from 'date-fns';

export const kFormatter = (num: any, fixed?: number) => {
  num = parseFloat(num);
  if (num === null) {
    return null;
  }
  if (num === 0) {
    return '0';
  }
  fixed = !fixed || fixed < 0 ? 0 : fixed;
  const b = num.toPrecision(2).split('e');
  const k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3);
  const c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed);
  const d = c < 0 ? c : Math.abs(c);
  const e = d + ['', 'K', 'M', 'B', 'T'][k];
  return e;
};

export const convertIntObj = (obj: any): Array<any> => {
  const excludedProps = ['date', 'age_range'];
  const res = [];

  for (const key in obj) {
    res[key] = {};
    for (const prop in obj[key]) {
      const parsed = parseInt(obj[key][prop], 10);
      res[key][prop] = isNaN(parsed) || excludedProps.includes(prop) ? obj[key][prop] : parsed;
    }
  }

  return res;
};

const dateTruncUtilitiesMap = {
  day: {
    diffFunction: differenceInCalendarDays,
    subFunction: subDays,
    format: 'dd LLL yyyy',
    offset: 1
  },
  week: {
    diffFunction: differenceInCalendarWeeks,
    subFunction: subWeeks,
    format: 'dd LLL yyyy',
    offset: -1
  },
  month: {
    diffFunction: differenceInCalendarMonths,
    subFunction: subMonths,
    format: 'LLL yy',
    offset: 0
  }
};

export const fillMissingDates = (
  data: any,
  startDate: string,
  endDate: string,
  dateTrunc: string,
  yAxisKey: string
) => {
  const dates: string[] = [];
  const startObj: Date = new Date(startDate);
  const endObj: Date = new Date(endDate);
  const utilitiesObj = dateTruncUtilitiesMap[dateTrunc];
  const DIFF_VALUE: number = utilitiesObj.diffFunction(endObj, startObj) + utilitiesObj.offset;

  // if the dates and data arrays have the same length then we don't have missing data
  if (DIFF_VALUE === data.length) {
    return data;
  }

  // first we build an array that contains all the dates between start and end date
  for (let i = DIFF_VALUE - 1; i >= 0; --i) {
    const newDate: Date = utilitiesObj.subFunction(endObj, i);
    dates.push(format(newDate, utilitiesObj.format));
  }

  const filledData = [];

  // we traverse the data and dates array at the same time
  // if there is a missing date we fill it with a 0 value
  let j = 0;
  for (let i = 0; i < dates.length; ++i) {
    const currentDate = dates[i];
    const dateObj = data[j];

    if (!dateObj || dateObj.date !== currentDate) {
      filledData.push({
        period_label: dateTrunc,
        date: currentDate,
        [yAxisKey]: 0
      });
    } else {
      filledData[i] = dateObj;
      ++j;
    }
  }

  return filledData;
};

export const numberWithCommas = (x: number): string => {
  if (!x) return null;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const isSameList = (lst1, lst2) => {
  return Boolean(JSON.stringify(lst1) === JSON.stringify(lst2));
};

export const compareArraysScalar = (array1, array2) =>
  array1.length === array2.length && array1.every((value, index) => value === array2[index]);

export const clearObjectOfNulls = (obj: unknown) =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.fromEntries(Object.entries(obj)?.filter(([_, v]) => v !== null));

// check if a value (string or number exist in an enum)
export const existValueInEnum = (type: any, value: any) => Object.values(type).includes(value);

export const generateGuid = (): string => {
  let result, i, j;
  result = '';
  for (j = 0; j < 32; j++) {
    if (j == 8 || j == 12 || j == 16 || j == 20) result = result + '-';
    i = Math.floor(Math.random() * 16)
      .toString(16)
      .toUpperCase();
    result = result + i;
  }
  return result;
};
