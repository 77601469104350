import React, { useEffect, useRef, useState } from 'react';
import { Table } from 'antd';
import { SpinningLoader } from 'components/Reusable/Loaders';
import './override.styles.scss';
import styles from './table.module.scss';
import { combineClassNames } from 'helpers/styling-helper';
import type { TableLocale } from 'antd/es/table/interface';

type Props = {
  data: any[];
  tableColumns: any[]; // set to any to allow using the table in other pages like invoices
  onChange?: (selectedKeys: number[], selectedRows: object[]) => void;
  selectedIDs?: number[];
  isLoading: boolean;
  onFetch?: () => void;
  isInfiniteLoading?: boolean;
  rowSelection: boolean;
  rowKey?: string;
  locale?: TableLocale;
  className?: string;
  tableId?: string;
};

let scrollTimeout: ReturnType<typeof setTimeout> = null;

const DataTable = ({
  data,
  tableColumns,
  onChange = () => null,
  selectedIDs = [],
  isLoading,
  onFetch,
  isInfiniteLoading = false,
  rowSelection,
  rowKey,
  locale,
  className,
  tableId
}: Props) => {
  const [tableHeight, setTableHeight] = useState('');
  const [refreshKey, setRefreshKey] = useState(1);

  const tableRef = useRef<HTMLDivElement>(null);
  // we have to use querySelector we don't have access to table body component
  const tBody = tableRef.current?.querySelector('.ant-table-body');

  const onScroll = (e: any) => {
    if (!onFetch) return;
    const element = e.target;
    if (
      !scrollTimeout &&
      element.scrollTop > 0 &&
      element?.offsetHeight + element?.scrollTop >= element?.scrollHeight - 50
    ) {
      scrollTimeout = setTimeout(() => {
        onFetch();
        clearTimeout(scrollTimeout);
        scrollTimeout = null;
      }, 5) as unknown as ReturnType<typeof setTimeout>;
    }
  };

  // to trigger onFetch method on scroll end
  // to force the table to take the remaining height
  useEffect(() => {
    if (!tBody) return;
    const bodyTop = tBody.getBoundingClientRect().top;
    const height = `calc(100vh - ${bodyTop}px)`;
    setTableHeight(height);
    tBody.addEventListener('scroll', onScroll);
    return () => {
      if (!tBody) return;
      tBody.removeEventListener('scroll', onScroll);
    };
  }, [data, tableColumns, refreshKey]);

  const scrollToTop = () => {
    setTimeout(() => {
      tBody.scrollTop = 0;
    }, 200);
  };

  // to reset the table scrollTop
  useEffect(() => {
    if (!tBody) return;
    scrollToTop();
  }, [tableId]);

  useEffect(() => {
    setRefreshKey(refreshKey + 1);
    return () => clearInterval(scrollTimeout);
  }, []);

  return (
    <div className={combineClassNames('DataTable', styles.dataTable)}>
      <Table
        bordered={true}
        rowKey={rowKey || 'key'}
        ref={tableRef}
        pagination={false}
        rowSelection={
          rowSelection
            ? {
                type: 'checkbox',
                onChange: (selectedKeys, selectedRows) =>
                  onChange(selectedKeys as number[], selectedRows),
                selectedRowKeys: selectedIDs
              }
            : (false as any)
        }
        columns={tableColumns as any}
        dataSource={data as any}
        scroll={{ x: 1500, y: tableHeight }}
        locale={locale}
        className={className}
      />

      {isLoading ? (
        <div className={styles.loader}>
          <SpinningLoader />
        </div>
      ) : null}

      <div className={`${styles.infinite_loader} ${isInfiniteLoading ? styles.active : ''}`}>
        <div className={styles.wrapper}>
          <SpinningLoader />
        </div>
      </div>
    </div>
  );
};
export default DataTable;
